import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { reduxForm, Field, FieldArray, formValueSelector, change } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, ButtonsGroup, TextEditor, Upload, UploadImagesFieldArray } from './../../../components/ui';
import { InputTextField, InputDateField, SelectField, OptionField } from './../../../components/ui/input';
import { BlogService } from './../../../services';
import { UserSelect } from './../../../scenes';

import TEXT from './../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.title) {
    errors.title = TEXT.validation.required;
  }else if(values.title.length > 1024){
    errors.title = TEXT.validation.maxlength(1024);
  }

  if (!values.preview) {
    errors.preview = TEXT.validation.required;
  }else if(values.preview.length > 2048){
    errors.preview = TEXT.validation.maxlength(2048);
  }

  return errors;

}

class Page__BlogForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        title: '...',
        loading: true,
        submited: false,
        id: null,
        type: 'category',
        entity: null,
        categories: [],
        images: []
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      // @Service
      // @Description: Find categories
      this._Categories();

      const id = this.props.match.params.id;

      if(id){

        // @Service
        this._Find(id);

      }else{

        // @State
        this.setState({ loading: false, title: 'Nueva nota' });

      }

    }

    /*
    ** @Service
    ** @Description: Save Note
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      BlogService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: `La nota fue creada.`
        });

        // @Redirect
        this.props.history.push('/blog');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    }

    /*
    ** @Service
    ** @Description: Save Category
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      BlogService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: `La nota fue editada.`
        });

        // @Redirect
        this.props.history.push('/blog');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data : TEXT.message.error
        });

      });

    }

    /*
    ** @Service
    ** @Description: Save Category
    */

    _Find = (id) => {

      // @Service
      BlogService.find(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set note update into state
        this.setState({
          loading: false,
          id: response.note._id,
          entity: response.note,
          title: response.note.title
        });

        // @Form
        // @Description: Set form fields
        this.props.dispatch(change(form, 'title', this.state.entity.title ));
        this.props.dispatch(change(form, 'category', this.state.entity.category ));
        this.props.dispatch(change(form, 'preview', this.state.entity.preview ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));
        this.props.dispatch(change(form, 'featured', this.state.entity.featured ));
        this.props.dispatch(change(form, 'description', this.state.entity.description ));
        this.props.dispatch(change(form, 'images', this.state.entity.images ));
        this.props.dispatch(change(form, 'minutes_read', this.state.entity.minutes_read ));

        if(this.state.entity.date){
          this.props.dispatch(change(form, 'date', this.state.entity.date ));
        }

        if(this.state.entity.user){
          this.props.dispatch(change(form, 'user', this.state.entity.user._id ));
        }

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    }

    /*
    ** @Service
    ** @Description: Find Categories
    */

    _Categories = () => {

      // @Service
      BlogService.categories().then((response) => {

        // @State
        // @Description: Set category update into state
        this.setState({
          categories: response.categories
        });

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data : TEXT.message.error
        });

      });

    }

    /*
    ** @Event
    ** @Description: OnUpload file
    */

    onUploadFile = (files) => {
      this.props.dispatch(change(this.props.form, 'images', files ));
    }

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    }

    /*
    ** @Event
    ** @Description: On selected user
    */
    handleOnSelectUser = (user) => {
      this.props.dispatch(change(this.props.form, 'user', user ? user._id : ''));
    };

    // @Render
    render(){

      const { handleSubmit, invalid, images } = this.props;
      const { loading, title, submited, categories } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar title="Blog" subtitle="Formulario de notas" dividerMobile>
              <Button
                component={ NavLink }
                to="/blog"
                icon="back"
                size="medium"
              >
                Volver
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              {/* Form Category */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* Configuration */}
                  <Content marginBottom>
                    <Toolbar
                      title="General"
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Field
                          name="title"
                          component={ InputTextField }
                          label="Título"
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Field
                          name="category"
                          component={ SelectField }
                          label="Categoría"
                        >
                          <OptionField value="-1">Seleccionar...</OptionField>
                          { categories.map((category, index) => {
                            return(
                              <OptionField key={ category._id } value={ category._id }>
                                { category.name }
                              </OptionField>
                            )
                          })}
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Field
                          name="date"
                          component={InputDateField}
                          label="Fecha"
                          type="text"
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="preview"
                          component={ InputTextField }
                          label="Subtitulo"
                          type="text"
                          multiline
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label="Estado"
                          required
                        >
                          <OptionField value="true">Activo</OptionField>
                          <OptionField value="false">Bloqueado</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="featured"
                          component={ SelectField }
                          label="Destacada"
                          required
                        >
                          <OptionField value="true">Activo</OptionField>
                          <OptionField value="false">Bloqueado</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="user"
                          component={UserSelect}
                          label="Autor"
                          onChange={this.handleOnSelectUser}
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="minutes_read"
                          component={ InputTextField }
                          label="Minutos de lectura"
                          type="number"
                          min="1"
                          required
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                        <Upload
                          attachments={images ? images : []}
                          label="Imágenes"
                          multiple={true}
                          avatar={true}
                          paper={true}
                          onSuccess={this.onUploadFile.bind(this)}
                        />

                        {/* Array of Images */}
                        <FieldArray
                          name="images"
                          component={ UploadImagesFieldArray }
                        />

                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="description"
                          component={ TextEditor }
                          label="Descripción"
                          type="text"
                        />
                      </Grid>
                    </Grid>
                  </Content>

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/blog"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      Guardar
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__BlogForm = reduxForm({
  form: 'Page__BlogForm',
  validate,
  initialValues: {
    featured: true,
    enabled: true,
    images: [],
    minutes_read: 1,
    date: new Date()
  }
})(Page__BlogForm);

const selector = formValueSelector('Page__BlogForm');

Page__BlogForm = connect(state => {
  const images = selector(state, 'images');
  return { images }
})(Page__BlogForm)

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page__BlogForm);
