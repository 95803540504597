import React from 'react';
import PropTypes from 'prop-types';

const Page = ({ children, ...rest }) => {
  return (
    <div className="ds--page">
      { children }
    </div>
  )
};

Page.propTypes = {
  children: PropTypes.node.isRequired
};

export default Page;
