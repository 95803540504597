import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Typography } from './../../../assets/theme/default';
import { Page, Wrapper, Paper, Button, ButtonsGroup, TooltipHtml, TooltipContainer, TooltipRow, Toolbar, Modal, Table, Row, Cell, Date, LinkUser, LinkClient, DateToString } from './../../../components/ui';
import { SupportService } from './../../../services';
import { isUndefined, parseQueryString, findPermissionProps } from './../../../utilities/utils';
import UtilSupportFilter from './../filter';

import API from './../../../config/api';
import TEXT from './../../../config/text';

class Page__SupportList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        loading: false,
        page: 0,
        total: 0,
        id: null,
        entities: [],
        thead: [
          { id: 'seq', name: this.props.t('field.id') },
          { id: 'subject', name: this.props.t('field.subject') },
          { id: 'client', name: this.props.t('field.client') },
          { id: 'user', name: this.props.t('field.user') },
          { id: 'created_at', name: this.props.t('field.date') },
          { id: 'actions', name: this.props.t('field.actions'), align: 'right' }
        ],
        supportFilterShow: false,
        emailShow: false,
        emailTemplate: null
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.support, this.props.t('text.support')) });

      const page = this.props.match.params.page || 0;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          this._All(page);
        }else{
          this.props.history.push('/support');
        }
      }else{
        this._All(this.state.page);
      }

    };

    /*
    ** @Service
    ** @Description: FindAll
    */

    _All = (page) => {

      SupportService.list(page, parseQueryString(this.props.location.search)).then((response) => {

        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.support,
          total: response.total
        });

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/support/page/${ page }`,
        search: this.props.location.search
      });

    };

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ supportFilterShow: true });
    };

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ supportFilterShow: false });
    };

        /*
    ** @Event
    ** @Description: Show email
    */

    handleShowEmail = (entity) => {
      this.setState({ emailShow: true, emailTemplate: entity });
    };

    /*
    ** @Event
    ** @Description: Close email
    */

    handleShowEmailClose = () => {
      this.setState({ emailShow: false });
    };

    render(){

      const { permission, name, loading, entities, thead, total, page, supportFilterShow, emailShow, emailTemplate } = this.state;

      let emailTemplateTitle = ''

      if(emailTemplate){
        emailTemplateTitle = `${emailTemplate.seq ? '#' + emailTemplate.seq : '[No disponible]'} - ${emailTemplate.subject} (${ DateToString(emailTemplate.created_at) })`;
      }

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <ButtonsGroup>
                { this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title="Eliminar filtros"
                    placement="left"
                    component={ NavLink }
                    to="/support"
                  />
                )}
                <Button
                  icon="filter"
                  size="medium"
                  onClick={ this.handleFilter }
                >
                  {this.props.t('button.filter')}
                </Button>
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

              <Modal
                  status={ supportFilterShow }
                  title={this.props.t('text.filter_support')}
                  handleCancel={ () => this.handleFilterClose() }
                  maxWidth="sm">
                  <UtilSupportFilter onSuccess={ this.handleFilterClose }/>
              </Modal>

              <Modal
                status={ emailShow }
                title={emailTemplateTitle}
                handleCancel={ () => this.handleShowEmailClose() }
                maxWidth="md"
              >
                  { emailTemplate ? (
                    <div dangerouslySetInnerHTML={{__html: emailTemplate.message }}/>
                  ) : (
                    <Typography>Información no disponible.</Typography>
                  )}
              </Modal>

              <Table
                thead={ thead }
                loading={ loading }
                total={ total }
                page={ page }
                onChangePage={ this.handleChangePage }
              >
                { entities.length > 0 && (
                  entities.map(entity => {
                    return (
                      <Row key={entity._id}>
                        <Cell nowrap>
                          {entity.seq || '[No disponible]'}
                        </Cell>
                        <Cell nowrap>
                          <TooltipHtml arrow title={
                            <TooltipContainer>
                              <TooltipRow value={entity.ga_client_id || '[No disponible]'} text="gaClientID" />
                              <TooltipRow value={entity.ga_track_id || '[No disponible]'} text="gaTrackID" />
                            </TooltipContainer>
                          }>
                            <span style={{ cursor: 'default' }}>
                              {entity.subject}
                            </span>
                          </TooltipHtml>
                        </Cell>
                        <Cell>
                          { entity.client ? (
                            <LinkClient client={entity.client} />
                          ) : (
                            <>
                              { (entity.name && entity.surname) ? (
                                <span>{ entity.name } { entity.surname }</span>
                              ) : (
                                <span>[No disponible]</span>
                              )}
                            </>
                          )}
                        </Cell>
                        <Cell>
                          <LinkUser user={entity.user} />
                        </Cell>
                        <Cell>
                          <Date value={entity.created_at} />
                        </Cell>
                        <Cell align="right">
                          <Button
                            icon="info"
                            title="Ver email enviado"
                            onClick={() => this.handleShowEmail(entity)}
                          />
                        </Cell>
                      </Row>
                    )
                  })
                )}

                { (loading && entities.length === 0) && (
                  <Row>
                    <Cell colSpan={ thead.length } align="center">
                      { TEXT.message.without_results }
                    </Cell>
                  </Row>
                )}

              </Table>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__SupportList));
