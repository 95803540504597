import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles, Box } from './../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    position: 'relative',
    //overflow: 'hidden'
  },
  divider: {
    borderBottom: `1px solid ${ theme.palette.border.main }`,
    paddingBottom: `${ theme.spacing(2) }px !important`,
    marginBottom: theme.spacing(2)
  },
  disablePadding: {
    padding: 0
  },
  disableMargin: {
    margin: `${ 0 }px !important`
  },
  marginTop: {
    padding: 0,
    marginTop: theme.spacing(2)
  },
  marginBottom: {
    padding: 0,
    marginBottom: theme.spacing(2)
  },
  marginLeft: {
    padding: 0,
    marginLeft: theme.spacing(2)
  },
  marginRight: {
    padding: 0,
    marginRight: theme.spacing(2)
  }
}));

const Content = ({ children, divider, disablePadding, disableMargin, marginLeft, marginRight, marginTop, marginBottom, ...rest }) => {

  const classes  = useStyles();
  const _disablePadding = disablePadding ? classes['disablePadding'] : null;
  const _disableMargin = disableMargin ? classes['disableMargin'] : null;
  const _mt = marginTop ? classes['marginTop'] : null;
  const _mb = marginBottom ? classes['marginBottom'] : null;
  const _ml = marginLeft ? classes['marginLeft'] : null;
  const _mr = marginRight ? classes['marginRight'] : null;
  const _divider = divider ? classes['divider'] : null;

  return (
    <Box className={ classNames(classes.root, _disablePadding, _disableMargin, _mt, _mb, _ml, _mr, _divider) } { ...rest }>
      { children }
    </Box>
  )

}

Content.propTypes = {
  children: PropTypes.node.isRequired,
  divider: PropTypes.bool,
  disablePadding: PropTypes.bool,
  marginTop: PropTypes.bool,
  marginBottom: PropTypes.bool,
  marginRight: PropTypes.bool,
  marginLeft: PropTypes.bool
};

export default Content;
