import request from './../../../utilities/request';

function list() {
  return request({
    url:    '/currencies',
    method: 'GET'
  });
}

const CurrencyService = {
  list
}

export default CurrencyService;
