import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change, formValueSelector  } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Content, Button, ButtonsGroup, Loading } from './../../../components/ui';
import { InputDateField, SelectField, OptionField, InputTextField } from './../../../components/ui/input';
import { ShippingService } from './../../../services';
import { stringifyQueryString, parseQueryString } from './../../../utilities/utils';
import { ShippingLocationAutocomplete, EntityAutocomplete  } from './../../../scenes';
import { withTranslation } from 'react-i18next';


class UtilShippingOrderFilter extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      entity: null,
      aux_date_to: null,
      events: []
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  };

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {

    // @Service
    this._Events();

    // @Search
    // @Description: Set query values
    const search = parseQueryString(this.props.location.search);

    Object.entries(search).forEach(([key,value]) => {
      this.props.dispatch(change(this.props.form, key, value));
    });

  };

  /*
  ** @Service
  ** @Description: Find Events
  */

  _Events = () => {
    ShippingService.eventsList().then((response) => {
      this.setState({ events: response.events, loading: false });
    });
  };

  /*
  ** @Service
  ** @Description: Filter
  */

  _Filter = (values) => {

    // @Redirect
    // @Description: Redirect with query filter
    this.props.history.push(`/shipping?${ stringifyQueryString(values) }`);

    // @Props
    // @Description:
    this.props.onSuccess();

  };

  /*
  ** @Event
  ** @Description: On selected from
  */
  handleOnSelectFrom = (location) => {
    this.props.dispatch(change(this.props.form, 'from', location ? location._id : '' ));
    this.setState({ entity: { ...this.state.entity, from: location }});
  };

  /*
  ** @Event
  ** @Description: On selected to
  */
  handleOnSelectTo = (location) => {
    this.props.dispatch(change(this.props.form, 'to', location ? location._id : '' ));
    this.setState({ entity: { ...this.state.entity, to: location }});
  };

  /*
  ** @Event
  ** @Description: On selected entity
  */
  handleOnSelectEntity = (entity) => {
    this.setState({ entity: { ...this.state.entity, entity: entity }});
    this.props.dispatch(change(this.props.form, 'entity', entity ? entity._id : '' ));
  };

  /*
  ** @Event
  ** @Description: On selected date
  */
  handleDateSelect = tag => (date) => {
    if(this.state.aux_date_to === null || this.state.aux_date_to < date){
      this.setState({aux_date_to: date});
      this.props.dispatch(change('UtilShippingOrderFilter', tag, date));
    }
  };

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {

    // @If
    // @Description: Filter function
    if(values.action === 'filter'){
      this._Filter(values);
    }

  };

  /*
  ** @Event
  ** @Description: Back to form
  */

  handleBackToForm = () => {

    // @State
    // @Description: Set entity null
    this.setState({
      entity: null
    });

  };

  render() {

    const { handleSubmit, invalid, entityAsCompany, entity, date_from, etd_from , eta_from } = this.props;
    const { loading, events } = this.state;

    return(
      !loading ? (
        <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

          <Content disablePadding divider>
            <Grid container spacing={3}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Field
                  name="code_internal"
                  component={ InputTextField }
                  label="OV"
                  type="text"
                  />
              </Grid>
              {/* Fecha de creación */}
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Grid container spacing={3}>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Field
                      name="date_from"
                      component={ InputDateField }
                      label="Carga OV (Desde)"
                      type="text"
                      disableFuture
                      onChange={this.handleDateSelect("date_to")}
                      />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Field
                      name="date_to"
                      component={ InputDateField }
                      label="Carga OV (Hasta)"
                      type="text"
                      disabled={!date_from}
                      minDate={new Date(date_from)}
                      disableFuture
                      />
                  </Grid>
                </Grid>
              </Grid>
              {/* Fecha ETA Origen */}
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Grid container spacing={3}>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Field
                      name="etd_from"
                      component={ InputDateField }
                      label="ETD (Desde)"
                      type="text"
                      onChange={this.handleDateSelect("etd_to")}
                      />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Field
                      name="etd_to"
                      component={ InputDateField }
                      label="ETD (Hasta)"
                      type="text"
                      disabled={!etd_from}
                      minDate={new Date(etd_from)}
                      />
                  </Grid>
                </Grid>
              </Grid>
              {/* ETA */}
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Grid container spacing={3}>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Field
                      name="eta_from"
                      component={ InputDateField }
                      label="ETA (Desde)"
                      type="text"
                      onChange={this.handleDateSelect("eta_to")}
                      />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Field
                      name="eta_to"
                      component={ InputDateField }
                      label="ETA (Hasta)"
                      type="text"
                      disabled={!eta_from}
                      minDate={new Date(eta_from)}
                      />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Field
                  name="event"
                  component={ SelectField }
                  label="Estado">
                  <OptionField value="none">Todos</OptionField>
                  { events.map((event, index) => {
                    return(
                      <OptionField key={ index } value={ event._id }>
                        { event.name }
                      </OptionField>
                    )
                  })}
                </Field>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <EntityAutocomplete
                  entity={entityAsCompany}
                  onSelect={this.handleOnSelectEntity}
                  />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <ShippingLocationAutocomplete
                  label="Origen"
                  location={entity && entity.from ? entity.from : null}
                  onSelect={this.handleOnSelectFrom}
                  />
                <Field
                  name="from"
                  component={ InputTextField }
                  type="text"
                  required
                  fieldHidden
                  />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <ShippingLocationAutocomplete
                  label="Destino"
                  location={entity && entity.to ? entity.to : null}
                  onSelect={this.handleOnSelectTo}
                  />
                <Field
                  name="to"
                  component={ InputTextField }
                  required
                  fieldHidden
                  />
              </Grid>
            </Grid>
          </Content>

          <Content disablePadding>
            <ButtonsGroup align="right">
              <Button
                aria-label="Confirmar"
                icon="save"
                color="primary"
                type="button"
                disabled={ invalid }
                onClick={
                  handleSubmit(values =>
                    this.handleSubmitForm({
                      ...values,
                      action: 'filter'
                    }
                  ))
                }
                >
                Aplicar
              </Button>
            </ButtonsGroup>
          </Content>

        </form>
      ) : (
        <Loading/>
      )
    )
  }

}

const selector = formValueSelector('UtilShippingOrderFilter');

UtilShippingOrderFilter = reduxForm({
  form: 'UtilShippingOrderFilter',
  initialValues: {
    event: 'none',
  },
  enableReinitialize: true
})(UtilShippingOrderFilter);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

UtilShippingOrderFilter = connect(
  state => {
    const date_from = selector(state, 'date_from');
    const date_to = selector(state, 'date_to');
    const etd_from = selector(state, 'etd_from');
    const etd_to = selector(state, 'etd_to');
    const eta_from = selector(state, 'eta_from');
    const eta_to = selector(state, 'eta_to');

    return { etd_from, etd_to, eta_from, eta_to, date_from, date_to }
  }
)(UtilShippingOrderFilter);

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(UtilShippingOrderFilter)));
