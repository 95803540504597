import React from 'react';
import { Grid } from './../../../../assets/theme/layout';
import { Content, Toolbar, Date, Data } from './../../../../components/ui';
import { ShippingEventList } from './../../event';
import { isShippingOrderEvent } from './../../../../utilities/utils';

class ShippingSchedule extends React.Component {

  render(){

      const { entity } = this.props;


      return(
        <Content divider disableMargin>

          {/* Configuration */}
          <Content disablePadding divider>
            <Toolbar
              title="Cut-Off"
              size="small"
            />
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="Cut-Off IMO"
                  text={(entity && entity.cut_off_imo) ? <Date value={entity.cut_off_imo} format="DD/MM/YYYY HH:mm [hs.]"/> : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="Cut-Off Documental"
                  text={(entity && entity.cut_off_doc) ? <Date value={entity.cut_off_doc} format="DD/MM/YYYY HH:mm [hs.]"/> : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="Cut-Off VGM"
                  text={(entity && entity.cut_off_vgm) ? <Date value={entity.cut_off_vgm} format="DD/MM/YYYY HH:mm [hs.]"/> : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="Cut-Off Físico"
                  text={(entity && entity.cut_off_phy) ? <Date value={entity.cut_off_phy} format="DD/MM/YYYY HH:mm [hs.]"/> : '-'}
                />
              </Grid>
            </Grid>
          </Content>

          {/* Schedule */}
          <Content disablePadding marginBottom>
            <Toolbar
              title="Schedule"
              size="small"
            />
            <Grid container spacing={3}>
              <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                <Data
                  label="ETA origen"
                  text={(entity && entity.eta_origin) ? <Date value={entity.eta_origin}/> : '-'}
                />
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                <Data
                  label="ETD origen"
                  text={(entity && entity.etd) ? <Date value={entity.etd}/> : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="Origen"
                  text={(entity && entity.from) ? entity.from.name : '-'}
                />
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                <Data
                  label="ETA"
                  text={(entity && entity.eta) ? <Date value={entity.eta}/> : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="Destino"
                  text={(entity && entity.to) ? entity.to.name : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="Estado ETD origen"
                  text={isShippingOrderEvent(entity.from_status)}
                />
            </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="Estado ETA destino"
                  text={isShippingOrderEvent(entity.to_status)}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="Transportista"
                  text={(entity && entity.driver) ? entity.driver : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="Medio de transporte"
                  text={(entity && entity.vehicle) ? entity.vehicle : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="Viaje"
                  text={(entity && entity.journey) ? entity.journey : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="Terminal"
                  text={(entity && entity.terminal) ? entity.terminal : '-'}
                />
              </Grid>
            </Grid>
          </Content>

          {/* Events */}
          <Content disablePadding>
            <ShippingEventList
              form={{
                eta: entity.eta,
                eta_origin: entity.eta_origin,
                eta_update: entity.from_status_updated,
                eta_updated_by: entity.from_status_updated_by,
                etd: entity.etd,
                from: entity.from,
                to: entity.to,
                from_status: entity.from_status,
                to_status: entity.to_status,
                etd_update: entity.to_status_updated,
                etd_updated_by: entity.to_status_updated_by,
                vehicle: entity.vehicle,
                journey: entity.journey
              }}
            />
          </Content>

        </Content>
      )
  };

};

export default ShippingSchedule;
