import React from 'react';
import { Box } from './../../../../assets/theme/default';

const Panel__c = ({ children, value, index, ...other }) => {

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </Box>
  )

};

export default Panel__c;
