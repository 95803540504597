import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { makeStyles, Typography } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: 1,
    whiteSpace: 'nowrap'
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  removed: {
    color: theme.palette.secondary.main
  }
}));

const LinkProduct = props => {

  const classes = useStyles();
  let product   = props.product.product || props.product;
  let removed;

  if(product){
    removed = product.removed ? classes['removed'] : null;
  }

  return (
    product ? (
      <React.Fragment>
        <Typography
          className={ classNames(
            classes.root,
            classes.link,
            removed
          )}
          component={NavLink}
          to={`/products/detail/${product._id}`}
        >
          { product.name }
        </Typography>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Typography className={classes.root}>
          [No disponible]
        </Typography>
      </React.Fragment>
    )
  )

};

LinkProduct.propTypes = {
  client: PropTypes.object
};

export default LinkProduct;
