import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, ButtonsGroup } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { CountrySelect, StateSelect, UserAutocomplete } from './../../../scenes';
import { EntityService } from './../../../services';
import { isEmail, findPermissionProps } from './../../../utilities/utils';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const selector = formValueSelector('Page__EntityForm');

const validate = values => {

  const errors = {
    address: {}
  };

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 1024){
    errors.name = TEXT.validation.maxlength(1024);
  }

  if (!values.identifire) {
    errors.identifire = TEXT.validation.required;
  }else if(values.identifire.length > 255){
    errors.identifire = TEXT.validation.maxlength(255);
  }

  if (values.email) {
    if (isEmail(values.email)) {
      errors.email = TEXT.validation.email_invalid;
    } else if (values.email.length > 255){
      errors.email = TEXT.validation.maxlength(255);
    }
  }

  if (values.area) {
    if (values.area.length > 255) {
      errors.area = TEXT.validation.maxlength(255);
    }
  }

  if (values.cuit) {
    if (values.cuit.length > 255) {
      errors.cuit = TEXT.validation.maxlength(255);
    }
  }

  if (values.sales_phone) {
    if (values.sales_phone.length > 255) {
      errors.sales_phone = TEXT.validation.maxlength(255);
    }
  }

  if (values.fax_phone) {
    if (values.fax_phone.length > 255) {
      errors.fax_phone = TEXT.validation.maxlength(255);
    }
  }

  // @Address
  if(values.address){
    if (!values.address.street) {
      errors.address.street = TEXT.validation.required;
    } else if (values.address.street.length > 255) {
      errors.address.street = TEXT.validation.maxlength(255);
    }

    if (!values.address.number) {
      errors.address.number = TEXT.validation.required;
    } else if (values.address.number.length > 255) {
      errors.address.number = TEXT.validation.maxlength(255);
    }

    if (values.address.floor) {
      if (values.address.floor.length > 255) {
        errors.address.floor = TEXT.validation.maxlength(255);
      }
    }

    if (values.address.apartment) {
      if (values.address.apartment.length > 255) {
        errors.address.apartment = TEXT.validation.maxlength(255);
      }
    }

    if (!values.address.zip) {
      errors.address.zip = TEXT.validation.required;
    } else if (values.address.zip.length > 10) {
      errors.address.zip = TEXT.validation.maxlength(10);
    }

    if (values.address.country) {
      if (values.address.country === '-1') {
        errors.address.country = TEXT.validation.required;
      }
    }

    if (values.address.state) {
      if (values.address.state === '-1') {
        errors.address.state = TEXT.validation.required;
      }
    }

    if (values.address.city) {
      if (values.address.city.length > 255) {
        errors.address.city = TEXT.validation.maxlength(255);
      }
    }
  }

  return errors;

}

class Page__EntityForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        title: '...',
        loading: true,
        submited: false,
        id: null,
        entity: null,
        categories: [],
        images: [],
        country: null,
        countries: [],
        states: []
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.entities, this.props.t('page.entities.name')) });

      const id = this.props.match.params.id;

      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false, title: this.props.t('text.new_entry') });
      }

    };

    /*
    ** @Service
    ** @Description: Save Entity
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      EntityService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        // @Redirect
        this.props.history.push('/entities');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Entity
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      EntityService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        // @Redirect
        this.props.history.push('/entities');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Entity
    */

    _Find = (id) => {

      // @Service
      EntityService.find(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set note update into state
        this.setState({
          loading: false,
          id: response.entity._id,
          entity: response.entity,
          title: response.entity.name
        });

        // @Form
        // @Description: Set form fields

        // @General
        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));
        this.props.dispatch(change(form, 'identifire', this.state.entity.identifire ));
        this.props.dispatch(change(form, 'cuit', this.state.entity.cuit ));
        this.props.dispatch(change(form, 'area', this.state.entity.area ));

        if(this.state.entity.user){
          this.props.dispatch(change(form, 'user', this.state.entity.user._id ));
        }

        // @Contact
        this.props.dispatch(change(form, 'email', this.state.entity.email ));
        this.props.dispatch(change(form, 'phone', this.state.entity.phone ));
        this.props.dispatch(change(form, 'fax_phone', this.state.entity.fax_phone ));
        this.props.dispatch(change(form, 'web_page', this.state.entity.web_page ));

        // @Address
        if(this.state.entity.address){
          this.props.dispatch(change(form, 'address.street', this.state.entity.address.street ));
          this.props.dispatch(change(form, 'address.number', this.state.entity.address.number ));
          this.props.dispatch(change(form, 'address.floor', this.state.entity.address.floor ));
          this.props.dispatch(change(form, 'address.apartment', this.state.entity.address.apartment ));
          this.props.dispatch(change(form, 'address.zip', this.state.entity.address.zip ));
          this.props.dispatch(change(form, 'address.city', this.state.entity.address.city ));

          // @Country
          if(this.state.entity.address.country){
            this.props.dispatch(change(form, 'address.country', this.state.entity.address.country._id ));
            this.setState({ country: this.state.entity.address.country._id });
          }

          // @State
          if(this.state.entity.address.state){
            this.props.dispatch(change(form, 'address.state', this.state.entity.address.state._id ));
            this.setState({ country: this.state.entity.address.country._id });
          }

        }

      }).catch((error) => {

        // @State
        // @Description:
        this.setState({ loading: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };

    // @Event
    // @Description: On change country
    handleOnChangeCountry = (event) => {

      // @Entity
      // @Description: Set country id
      this.setState({ country: event.target.value });
      // @Form
      // @Description: Set state id
      this.props.dispatch(change(this.props.form, 'state', '' ));

    };

    // @Event
    // @Description: Load default country
    handleOnLoadCountry = (country) => {

      if(country){
        // @Entity
        // @Description: Set country id
        this.setState({ country: country._id });

        // @Form
        // @Description: Set country id
        this.props.dispatch(change(this.props.form, 'country', country._id ));
      }

    };

    /*
    ** @Event
    ** @Description: On selected user
    */
    handleOnSelectUser = (user) => {
      this.props.dispatch(change(this.props.form, 'user', user ? user._id : '' ));
      this.setState({ entity: { ...this.state.entity, user: user }});
    };

    // @Render
    render(){

      const { handleSubmit, invalid } = this.props;
      const { permission, name, loading, title, submited, country, entity } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/entities"
                icon="back"
                size="medium"
              >
                {this.props.t('button.back')}
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              {/* Form Category */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* General */}
                  <Content marginBottom>
                    <Toolbar
                      title={this.props.t('text.general')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <Field
                          name="name"
                          component={ InputTextField }
                          label={this.props.t('field.name')}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <UserAutocomplete
                          user={entity && entity.user ? entity.user : null}
                          onSelect={this.handleOnSelectUser}
                        />
                        <Field
                            name="user"
                            component={ InputTextField }
                            type="text"
                            required
                            fieldHidden
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label={this.props.t('field.status')}
                          required
                        >
                          <OptionField value="true">{this.props.t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{this.props.t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                        <Field
                          name="identifire"
                          component={ InputTextField }
                          label={this.props.t('field.identifier')}
                          type="string"
                          required
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                        <Field
                          name="area"
                          component={ InputTextField }
                          label={this.props.t('field.sector')}
                          type="string"
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                        <Field
                          name="cuit"
                          component={ InputTextField }
                          label={this.props.t('field.cuit')}
                          type="string"
                        />
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Contact information */}
                  <Content disablePadding divider>
                    <Toolbar
                      title={this.props.t('text.contact')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="email"
                          component={ InputTextField }
                          label={this.props.t('field.email')}
                          type="email"
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="phone"
                          component={ InputTextField }
                          label={this.props.t('field.phone')}
                          type="text"
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="fax_phone"
                          component={ InputTextField }
                          label={this.props.t('field.fax')}
                          type="text"
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="web_page"
                          component={ InputTextField }
                          label={this.props.t('field.website')}
                          type="text"
                        />
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Location */}
                  <Content disablePadding>
                    <Toolbar
                      title={this.props.t('text.address')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                        <Field
                          name="address.street"
                          component={ InputTextField }
                          label={this.props.t('field.street')}
                          type="text"
                          autoComplete="no"
                          required
                        />
                      </Grid>
                      <Grid item xl={4} lg={5} md={12} sm={12} xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                            <Field
                              name="address.number"
                              component={ InputTextField }
                              label={this.props.t('field.number')}
                              type="text"
                              autoComplete="no"
                              required
                            />
                          </Grid>
                          <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                            <Field
                              name="address.floor"
                              component={ InputTextField }
                              label={this.props.t('field.floor')}
                              type="text"
                              autoComplete="no"
                            />
                          </Grid>
                          <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                            <Field
                              name="address.apartment"
                              component={ InputTextField }
                              label={this.props.t('field.office')}
                              type="text"
                              autoComplete="no"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                        <Field
                          name="address.zip"
                          component={ InputTextField }
                          label={this.props.t('field.postal_code')}
                          type="text"
                          autoComplete="no"
                          placeholder={this.props.t('placeholder.cp')}
                          required
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Field
                          name="address.country"
                          component={ CountrySelect }
                          label={this.props.t('field.country')}
                          required
                          onLoad={ this.handleOnLoadCountry }
                          onChange={ this.handleOnChangeCountry }
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Field
                          name="address.state"
                          component={ StateSelect }
                          label={this.props.t('field.state')}
                          country={ country }
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Field
                          name="address.city"
                          component={ InputTextField }
                          label={this.props.t('field.city')}
                          type="text"
                        />
                      </Grid>
                    </Grid>
                  </Content>

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/entities"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      {this.props.t('button.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      {this.props.t('button.save')}
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__EntityForm = reduxForm({
  form: 'Page__EntityForm',
  validate,
  initialValues: {
    enabled: true,
    address: {}
  }
})(Page__EntityForm);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

Page__EntityForm = connect(
  state => {
    const user = selector(state, 'user') || null;
    return { user }
  }
)(Page__EntityForm);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__EntityForm));
