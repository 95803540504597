import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Typography } from './../../../../assets/theme/default';
import { ButtonBase } from './../../../../assets/theme/button';

const Styles = makeStyles(theme => ({
  root: {
    //textTransform: 'none',
    //minWidth: theme.spacing(9),
    minHeight: theme.spacing(6),
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  typography: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  divider: {
    borderRight: `1px solid ${ theme.palette.common.white }`
  },
  primary: {
    background: theme.palette.primary.light
  }
}));

const TabButton = ({ children, color = 'primary', divider = true, ...rest }) => {

  const classes  = Styles();
  const _divider = divider ? classes['divider'] : null;
  const _color   = color ? classes[color] : classes['primary'];

  return (
    <ButtonBase
      classes={{ root: classNames(classes.root, _color,  _divider) }}
      { ...rest }
    >
      <Typography
        component="span"
        variant="h6"
        color={ color }
        classes={{ root: classes.typography }}
      >
      { children }
      </Typography>
    </ButtonBase>
  )

};

TabButton.propTypes = {
  children: PropTypes.node.isRequired,
  divider: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
};

export default TabButton;
