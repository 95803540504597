import React from 'react';
import { Field } from 'redux-form';
import { InputTextField } from './../../input';

const FieldsCustomArray = ({ fields, meta: { error, submitFailed } }) => (
  fields.length > 0 && (
    fields.map((entity, index) => {
      return(
        <div style={{display:'none'}} key={index}>
          <Field name={`${entity}.entity`} component={ InputTextField } type="text" required/>
          <Field name={`${entity}.value`} component={ InputTextField } type="text" required/>
        </div>
      )
    })
  )
);

export default FieldsCustomArray;
