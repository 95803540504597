import React from 'react';
import classnames from 'classnames';
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import { makeStyles, Box, Typography } from './../../../assets/theme/default';
import { FormControl, FormLabel, TextField, Checkbox, Radio, RadioGroup, FormControlLabel, FormHelperText, MenuItem } from './../../../assets/theme/input';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: theme.typography.fontWeightBold,
    position: 'relative',
    transition: 'none',
    transform: 'none',
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.fontSize,
    color: theme.palette.secondary.main,
    display: 'block'
  },
  inputRoot: {
    marginTop: `0 !important`,
    border: `1px solid ${ theme.palette.grey.dark }`,
    background: '#fafbfc',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: `3px !important`
  },
  inputInput: {
    padding: 0
  },
  selectRoot: {
    marginTop: `0 !important`,
    border: `1px solid ${ theme.palette.grey.dark }`,
    background: '#fafbfc',
    borderRadius: 3
  },
  selectInput: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: `3px !important`
  },
  startAdornment: {
    paddingLeft: theme.spacing(1)
  },
  disabled: {
    background: '#f3f3f3'
  },
  focused: {
    background: '#fbfafa',
    borderRadius: theme.spacing(0.5)
  },
  select: {
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${ theme.palette.grey.dark }`,
    fontSize: theme.typography.fontSize,
    '&:focus': {
      backgroundColor: theme.palette.common.white
    }
  },
  selectMenuProps: {
    boxShadow: "none"
  },
  option: {
    fontSize: theme.typography.fontSize,
    height: theme.spacing(2)
  },
  underline: {
    '&:before, &:after': {
        display: 'none'
    }
  },
  validation: {
    //marginTop: theme.spacing(0.5),
    fontSize: 13,
    color: theme.palette.danger.main
  },
  fieldHidden: {
    opacity: 0,
    position: 'fixed',
    left: -99999
  },
  groupFieldRoot: {

  },
  groupFieldChildren: {
    display: 'flex',
    flexDirection: 'row'
  },
  groupFieldChild: {
    flexGrow: 1,
    '&:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  inputContentRoot: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    border: `1px solid ${ theme.palette.grey.dark }`,
    background: '#fafbfc',
    borderRadius: 3
  }
}));

const FormLabelField = ({ children, required, ...props }) => {
  const classes = useStyles();
  return (
    <FormLabel {...props} classes={{root: classes.label }}>
      {children}
      { required && (
        <React.Fragment>&nbsp;*</React.Fragment>
      )}
    </FormLabel>
  )
};

const InputTextField = ({ input, label, placeholder, disabled, readonly, required, fieldHidden, type, min, max, autoComplete, multiline, rowsMax, startAdornment, endAdornment, disablePadding, meta, ...other }) => {

  const classes = useStyles();
  const _fieldHidden = fieldHidden ? classes['fieldHidden'] : null;

  return (
    <FormControl fullWidth className={ _fieldHidden }>
      <TextField {...input}
        //size="small"
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.label
          }
        }}
        InputProps={{
          inputProps: {
            autoComplete: autoComplete,
            min: min,
            max: max,
            minLength: other.minLength,
            maxLength: other.maxLength,
            step: other.step,
            precision: other.precision,
            readOnly: readonly
          },
          startAdornment: startAdornment,
          endAdornment: endAdornment,
          classes:{
            root: classes.inputRoot,
            input: classes.inputInput,
            underline: classes.underline,
            disabled: classes.disabled,
            focused: classes.focused
          }
        }}
        disabled={ disabled ? disabled : false }
        type={ type }
        label={ label }
        required={ required ? true : false }
        multiline={ multiline ? true : false }
        rowsMax={ rowsMax }
        placeholder={ placeholder }
        { ...other }
      />
      { meta && (
        <div className={ classes.validation }>
          { ((meta.touched && meta.error) || (meta.touched && meta.warning)) && (
            meta.touched && ((meta.error && <span>{meta.error}</span>) || (meta.warning && <span>{meta.warning}</span>))
          )}
       </div>
     )}
    </FormControl>
  )

};

const InputDateField = ({ input, label, disabled, required, format, disablePast, disableFuture, autoOk, meta: { touched, error, warning }, ...other }) => {

  const classes = useStyles();

  return (
    <FormControl fullWidth>
      <DatePicker
        size="small"
        disableFuture={ disableFuture ? disableFuture : false }
        disablePast={ disablePast ? disablePast : false }
        autoOk={ autoOk ? autoOk : true }
        value={ input.value ? input.value : null }
        //variant="filled"
        //inputVariant="outlined"
        format="dd/MM/yyyy"
        label={ label }
        cancelLabel="Cancelar"
        okLabel="Aceptar"
        required={ required ? true : false }
        initialFocusedDate={ moment().format() }
        onChange={ event => { input.onChange(moment(event).format()); } }
        disabled={ disabled }
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.label
          }
        }}
        InputProps={{
          classes:{
            root: classes.inputRoot,
            input: classes.inputInput,
            underline: classes.underline
          }
        }}
        { ...other }
      />
      <div className={ classes.validation }>
        { ((touched && error) || (touched && warning)) && (
          touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))
        )}
      </div>
    </FormControl>
  )

};

const InputDateTimeField = ({ input, label, disabled, required, format, disablePast, disableFuture, autoOk, meta: { touched, error, warning }, ...other }) => {

  const classes = useStyles();

  return (
    <FormControl fullWidth>
      <DateTimePicker
        size="small"
        disableFuture={ disableFuture ? disableFuture : false }
        disablePast={ disablePast ? disablePast : false }
        autoOk={ autoOk ? autoOk : true }
        value={ input.value ? input.value : null }
        //variant="filled"
        //inputVariant="outlined"
        ampm={false}
        format="dd/MM/yyyy hh:mm a"
        label={ label }
        cancelLabel="Cancelar"
        okLabel="Aceptar"
        required={ required ? true : false }
        initialFocusedDate={ moment().format() }
        onChange={ event => { input.onChange(moment(event).format()); } }
        disabled={ disabled }
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.label
          }
        }}
        InputProps={{
          classes:{
            root: classes.inputRoot,
            input: classes.inputInput,
            underline: classes.underline
          }
        }}
        { ...other }
      />
      <div className={ classes.validation }>
        { ((touched && error) || (touched && warning)) && (
          touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))
        )}
      </div>
    </FormControl>
  )

};

const SelectField = ({ input, label, disabled, required, native = true, startAdornment, endAdornment, meta: { touched, error }, children, ...custom }) => {

  const classes = useStyles();

  return (
    <FormControl fullWidth className={ classes.root } disabled={ disabled ? disabled : false }>
      <TextField {...input}
        size="small"
        //variant="outlined"
        select
        label={ label }
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.label
          },
          required: required ? true : false
        }}
        InputProps={{
          startAdornment: startAdornment,
          endAdornment: endAdornment,
          classes:{
            root: classnames(classes.selectRoot, startAdornment ? classes.startAdornment : null),
            input: classes.selectInput,
            underline: classes.underline,
            focused: classes.focused
          }
        }}
        SelectProps={{
          native: native
        }}
        disabled={ disabled ? disabled : false }
      >
        { children }
      </TextField>
      { (touched && error)  && (
        <FormHelperText component="div" className={ classes.validation }>
          {touched && ((error && <span>{error}</span>))}
        </FormHelperText>
      )}
    </FormControl>
  )

};

const OptionField = ({ children, ...other }) => {
  return (
    <option { ...other }>
      { children }
    </option>
  )
};

const MenuField = ({ children, ...other }) => {
  return (
    <MenuItem { ...other }>
      { children }
    </MenuItem>
  )
};

const OptionAutocomplete = ({ children, ...other }) => {
  return (
    <Typography component="div" display="block">
      {children}
    </Typography>
  )
};

const CheckboxField = ({ input, label, ...rest }) => {
  return (
    <FormControlLabel
      {...rest}
      control={<Checkbox color="primary" checked={input.value ? true : false} onChange={input.onChange}/>}
      label={label}
    />
  )
};

const RadioField = ({ label, value, ...rest }) => {
  return (
    <FormControlLabel
      {...rest}
      value={value}
      control={<Radio color="primary"/>}
      label={label}
    />
  )
};

const RadioGroupField = ({ input, ...rest }) => {
  return (
    <RadioGroup
      {...input}
      {...rest}
      valueselected={input.value}
      onChange={(event, value) => input.onChange(value)}
    />
  )
};

const GroupField = ({ children, label, ...rest }) => {
  const classes = useStyles();
  return (
    <Box className={ classes.groupFieldRoot }>
      {label && (
        <FormLabelField>{label}</FormLabelField>
      )}
      <Box className={ classes.groupFieldChildren }>
        {React.Children.map(children, child => (
         <Box className={ classes.groupFieldChild }>
             {React.cloneElement(child)}
         </Box>
        ))}
      </Box>
    </Box>
  )
};

const InputContent = ({children, ...rest}) => {
  const classes = useStyles();
  return(
    <Box className={ classes.inputContentRoot } {...rest}>
      {children}
    </Box>
  )
};

export { FormLabelField, InputContent, InputTextField, SelectField, InputDateField, InputDateTimeField, OptionField, MenuField, CheckboxField, RadioField, RadioGroupField, GroupField, OptionAutocomplete };
