import React, {Suspense} from 'react';
import { Switch, HashRouter, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { CssBaseline, Box } from './assets/theme/default';
import { Backend, Frontend } from './components/layout';
import { Loading } from './components/ui';

import RedirectToState from './scenes/login/redirect';

import API from './config/api';

import {

    // @Login
    PageLogin,

    // @Dashboard
    Page__Dashboard,

    // @Payments
    Page__PaymentList,
    Page__PaymentDetail,

    // @Categories
    Page__CategoryList,
    Page__CategoryForm,

    // @Client
    Page__ClientList,
    Page__ClientForm,
    Page__ClientProfile,

    // @Lead
    Page__LeadList,
    Page__LeadDetail,

    // @Products
    Page__ProductList,
    Page__ProductForm,
    Page__ProductDetail,

    // @Users
    Page__UserList,
    Page__UserForm,
    Page__UserProfile,

    // @Settings
    Page__Setting,

    // @Support
    Page__SupportList,
    Page__SupportForm,

    // @MercadoPago
    Page__MP_PreferenceList,
    Page__MP_PreferenceForm,
    Page__MP_PreferenceDetail,
    Page__MP_PreferenceNotification,

    // @TodoPago
    Page__TP_PreferenceList,
    Page__TP_PreferenceForm,
    Page__TP_PreferenceDetail,
    Page__TP_PreferenceNotification,

    // @Form
    Page__Form,
    Page__FormList,
    Page__FormRecordList,

    // @Shipping
    Page__ShippingList,
    Page__ShippingForm,
    Page__ShippingDetail,
    Page__ShippingLocationList,
    Page__ShippingLocationForm,

    // @Offer
    Page__OfferList,
    Page__OfferForm,

    // @Blog
    Page__BlogList,
    Page__BlogForm,

    // @Store
    Page__StoreList,
    Page__StoreForm,

    // @Franchise
    Page__FranchiseList,
    Page__FranchiseForm,

    // @Entity
    Page__EntityList,
    Page__EntityForm,

    // @Provider
    Page__ProviderList,
    Page__ProviderForm,

    // @Featured
    Page__FeaturedList,
    Page__FeaturedForm,

    // @Slider
    Page__SliderList,
    Page__SliderForm,

    // @Shop Cupons
    Page__ShopCuponList,
    Page__ShopCuponForm,

    // @Shop Cart
    Page__ShopCartList,

    // @QrCodes
    Page__QrCodesList,
    Page__QrCodesForm,
    Page__QrCodesRedirect,

    // @Campaigns
    Page__CampaignsList,
    Page__CampaignsForm

} from './pages';

const App = (props) => {
  return(
    <Suspense fallback={<Loading/>}>
      <MuiPickersUtilsProvider utils={ DateFnsUtils } locale={esLocale}>

        { props.user ? (

          <React.Fragment>

              <CssBaseline />

              <Box className="ds--app">
                <HashRouter>
                  <Switch>

                    <Backend exact path="/dashboard" component={ Page__Dashboard } />

                    {/* @Users */}
                    <Backend exact path="/users" component={ Page__UserList } permission={ API.permissions.users }/>
                    <Backend exact path="/users/page/:page" component={ Page__UserList } permission={ API.permissions.users }/>
                    <Backend exact path="/users/create" component={ Page__UserForm } permission={ API.permissions.users_management }/>
                    <Backend exact path="/users/edit/:id" component={ Page__UserForm } permission={ API.permissions.users_management }/>
                    <Backend exact path="/users/profile/:id" component={ Page__UserProfile } />
                    <Backend exact path="/users/profile/:id/:section" component={ Page__UserProfile } />
                    <Backend exact path="/users/profile/:id/:section/page/:page" component={ Page__UserProfile } />
                    <Backend exact path="/me" component={ Page__UserProfile } />

                    {/* @Settings */}
                    <Backend exact path="/settings" component={ Page__Setting } permission={ API.permissions.settings }/>

                    {/* @Support */}
                    <Backend exact path="/support" component={ Page__SupportList } permission={ API.permissions.usesupportrs }/>
                    <Backend exact path="/support/page/:page" component={ Page__SupportList } permission={ API.permissions.support }/>
                    <Backend exact path="/support/create/:id" component={ Page__SupportForm } permission={ API.permissions.support }/>

                    {/* @Categories */}
                    <Backend exact path="/categories" component={ Page__CategoryList } permission={ API.permissions.categories }/>
                    <Backend exact path="/categories/page/:page" component={ Page__CategoryList } permission={ API.permissions.categories }/>
                    <Backend exact path="/categories/parent/:id" component={ Page__CategoryList } permission={ API.permissions.categories }/>
                    <Backend exact path="/categories/parent/:id/:page" component={ Page__CategoryList } permission={ API.permissions.categories }/>
                    <Backend exact path="/categories/create" component={ Page__CategoryForm } permission={ API.permissions.categories_management }/>
                    <Backend exact path="/categories/edit/:id" component={ Page__CategoryForm } permission={ API.permissions.categories_management }/>

                    {/* @Clients */}
                    <Backend exact path="/clients" component={ Page__ClientList } permission={ API.permissions.clients }/>
                    <Backend exact path="/clients/page/:page" component={ Page__ClientList } permission={ API.permissions.clients }/>
                    <Backend exact path="/clients/create" component={ Page__ClientForm } permission={ API.permissions.clients_management }/>
                    <Backend exact path="/clients/edit/:id" component={ Page__ClientForm } permission={ API.permissions.clients_management }/>
                    <Backend exact path="/clients/profile/:id" component={ Page__ClientProfile } />
                    <Backend exact path="/clients/profile/:id/:section" component={ Page__ClientProfile } />
                    <Backend exact path="/clients/profile/:id/:section/page/:page" component={ Page__ClientProfile } />

                    {/* @Leads */}
                    <Backend exact path="/leads" component={ Page__LeadList } permission={ API.permissions.leads }/>
                    <Backend exact path="/leads/page/:page" component={ Page__LeadList } permission={ API.permissions.leads }/>
                    <Backend exact path="/leads/detail/:id" component={ Page__LeadDetail } permission={ API.permissions.leads }/>

                    {/* @Products */}
                    <Backend exact path="/products" component={ Page__ProductList } permission={ API.permissions.products }/>
                    <Backend exact path="/products/page/:page" component={ Page__ProductList } permission={ API.permissions.products }/>
                    <Backend exact path="/products/create" component={ Page__ProductForm } permission={ API.permissions.products_management }/>
                    <Backend exact path="/products/edit/:id" component={ Page__ProductForm } permission={ API.permissions.products_management }/>
                    <Backend exact path="/products/detail/:id" component={ Page__ProductDetail } permission={ API.permissions.products }/>

                    {/* @Payments */}
                    <Backend exact path="/payments" component={ Page__PaymentList } permission={ API.permissions.payments }/>
                    <Backend exact path="/payments/page/:page" component={ Page__PaymentList } permission={ API.permissions.payments }/>
                    <Backend exact path="/payments/detail/:id" component={ Page__PaymentDetail } permission={ API.permissions.payments_management }/>

                    {/* @MercadoPago */}
                    <Backend exact path="/api/mercadopago/checkout" component={ Page__MP_PreferenceList } permission={ API.permissions.api_mercado_pago }/>
                    <Backend exact path="/api/mercadopago/checkout/page/:page" component={ Page__MP_PreferenceList } permission={ API.permissions.api_mercado_pago }/>
                    <Backend exact path="/api/mercadopago/checkout/create" component={ Page__MP_PreferenceForm } permission={ API.permissions.api_mercado_pago }/>
                    <Backend exact path="/api/mercadopago/checkout/detail/:id" component={ Page__MP_PreferenceDetail } permission={ API.permissions.api_mercado_pago }/>
                    <Frontend exact path="/api/mercadopago/notification/:status/:id" component={ Page__MP_PreferenceNotification } />

                    {/* @TodoPago */}
                    <Backend exact path="/api/todopago/checkout" component={ Page__TP_PreferenceList } permission={ API.permissions.api_todo_pago }/>
                    <Backend exact path="/api/todopago/checkout/page/:page" component={ Page__TP_PreferenceList } permission={ API.permissions.api_todo_pago }/>
                    <Backend exact path="/api/todopago/checkout/create" component={ Page__TP_PreferenceForm } permission={ API.permissions.api_todo_pago }/>
                    <Backend exact path="/api/todopago/checkout/detail/:id" component={ Page__TP_PreferenceDetail } permission={ API.permissions.api_todo_pago }/>
                    <Frontend exact path="/api/todopago/notification/:status/:id" component={ Page__TP_PreferenceNotification } />

                    {/* @Form */}
                    <Backend exact path="/forms" component={ Page__FormList } permission={ API.permissions.api_forms }/>
                    <Backend exact path="/forms/page/:page" component={ Page__FormList } permission={ API.permissions.api_forms }/>
                    <Backend exact path="/forms/records/:id" component={ Page__FormRecordList } permission={ API.permissions.api_forms }/>
                    <Backend exact path="/forms/records/:id/page/:page" component={ Page__FormRecordList } permission={ API.permissions.api_forms }/>
                    <Frontend exact path="/form/:slug" component={ Page__Form } />

                    {/* @Shipping */}
                    <Backend exact path="/shipping" component={ Page__ShippingList } permission={ API.permissions.shipping }/>
                    <Backend exact path="/shipping/page/:page" component={ Page__ShippingList } permission={ API.permissions.shipping }/>
                    <Backend exact path="/shipping/create" component={ Page__ShippingForm } permission={ API.permissions.shipping_management }/>
                    <Backend exact path="/shipping/edit/:id" component={ Page__ShippingForm } permission={ API.permissions.shipping_management }/>
                    <Backend exact path="/shipping/detail/:id" component={ Page__ShippingDetail } permission={ API.permissions.shipping }/>

                    {/* @Shipping Location */}
                    <Backend exact path="/locations" component={ Page__ShippingLocationList } permission={ API.permissions.shipping_location }/>
                    <Backend exact path="/locations/page/:page" component={ Page__ShippingLocationList } permission={ API.permissions.shipping_location }/>
                    <Backend exact path="/locations/create" component={ Page__ShippingLocationForm } permission={ API.permissions.shipping_location_management }/>
                    <Backend exact path="/locations/edit/:id" component={ Page__ShippingLocationForm } permission={ API.permissions.shipping_location_management }/>

                    {/* @Blog */}
                    <Backend exact path="/blog" component={ Page__BlogList } permission={ API.permissions.blog }/>
                    <Backend exact path="/blog/page/:page" component={ Page__BlogList } permission={ API.permissions.blog }/>
                    <Backend exact path="/blog/create" component={ Page__BlogForm } permission={ API.permissions.blog_management }/>
                    <Backend exact path="/blog/edit/:id" component={ Page__BlogForm } permission={ API.permissions.blog_management }/>

                    {/* @Offer */}
                    <Backend exact path="/offer" component={ Page__OfferList } permission={ API.permissions.offer }/>
                    <Backend exact path="/offer/page/:page" component={ Page__OfferList } permission={ API.permissions.offer }/>
                    <Backend exact path="/offer/create" component={ Page__OfferForm } permission={ API.permissions.offer_management }/>
                    <Backend exact path="/offer/edit/:id" component={ Page__OfferForm } permission={ API.permissions.offer_management }/>

                    {/* @Store */}
                    <Backend exact path="/stores" component={ Page__StoreList } permission={ API.permissions.store }/>
                    <Backend exact path="/stores/page/:page" component={ Page__StoreList } permission={ API.permissions.store }/>
                    <Backend exact path="/stores/create" component={ Page__StoreForm } permission={ API.permissions.store_management }/>
                    <Backend exact path="/stores/edit/:id" component={ Page__StoreForm } permission={ API.permissions.store_management }/>

                    {/* @Franchise */}
                    <Backend exact path="/franchises" component={ Page__FranchiseList } permission={ API.permissions.franchise }/>
                    <Backend exact path="/franchises/page/:page" component={ Page__FranchiseList } permission={ API.permissions.franchise }/>
                    <Backend exact path="/franchises/create" component={ Page__FranchiseForm } permission={ API.permissions.franchise_management }/>
                    <Backend exact path="/franchises/edit/:id" component={ Page__FranchiseForm } permission={ API.permissions.franchise_management }/>

                    {/* @Entities */}
                    <Backend exact path="/entities" component={ Page__EntityList } permission={ API.permissions.entities }/>
                    <Backend exact path="/entities/page/:page" component={ Page__EntityList } permission={ API.permissions.entities }/>
                    <Backend exact path="/entities/create" component={ Page__EntityForm } permission={ API.permissions.entities_management }/>
                    <Backend exact path="/entities/edit/:id" component={ Page__EntityForm } permission={ API.permissions.entities_management }/>

                    {/* @Providers */}
                    <Backend exact path="/providers" component={ Page__ProviderList } permission={ API.permissions.providers }/>
                    <Backend exact path="/providers/page/:page" component={ Page__ProviderList } permission={ API.permissions.providers }/>
                    <Backend exact path="/providers/create" component={ Page__ProviderForm } permission={ API.permissions.providers_management }/>
                    <Backend exact path="/providers/edit/:id" component={ Page__ProviderForm } permission={ API.permissions.providers_management }/>

                    {/* @Featured */}
                    <Backend exact path="/featured" component={ Page__FeaturedList } permission={ API.permissions.featured }/>
                    <Backend exact path="/featured/page/:page" component={ Page__FeaturedList } permission={ API.permissions.featured }/>
                    <Backend exact path="/featured/create" component={ Page__FeaturedForm } permission={ API.permissions.featured_management }/>
                    <Backend exact path="/featured/edit/:id" component={ Page__FeaturedForm } permission={ API.permissions.featured_management }/>

                    {/* @Slider */}
                    <Backend exact path="/slider" component={ Page__SliderList } permission={ API.permissions.slider }/>
                    <Backend exact path="/slider/page/:page" component={ Page__SliderList } permission={ API.permissions.slider }/>
                    <Backend exact path="/slider/create" component={ Page__SliderForm } permission={ API.permissions.slider_management }/>
                    <Backend exact path="/slider/edit/:id" component={ Page__SliderForm } permission={ API.permissions.slider_management }/>

                    {/* @Shop Cupons */}
                    <Backend exact path="/cupons" component={ Page__ShopCuponList } permission={ API.permissions.shop_cupon }/>
                    <Backend exact path="/cupons/page/:page" component={ Page__ShopCuponList } permission={ API.permissions.shop_cupon }/>
                    <Backend exact path="/cupons/create" component={ Page__ShopCuponForm } permission={ API.permissions.shop_cupon_management }/>
                    <Backend exact path="/cupons/edit/:id" component={ Page__ShopCuponForm } permission={ API.permissions.shop_cupon_management }/>

                    {/* @Shop Cart */}
                    <Backend exact path="/shop/cart" component={ Page__ShopCartList } permission={ API.permissions.shop_cart }/>
                    <Backend exact path="/shop/cart/page/:page" component={ Page__ShopCartList } permission={ API.permissions.shop_cart }/>

                    {/* @QrCodes */}
                    <Backend exact path="/qr-codes" component={ Page__QrCodesList } permission={ API.permissions.qr_code }/>
                    <Backend exact path="/qr-codes/page/:page" component={ Page__QrCodesList } permission={ API.permissions.qr_code }/>
                    <Backend exact path="/qr-codes/create" component={ Page__QrCodesForm } permission={ API.permissions.qr_code_management }/>
                    <Backend exact path="/qr-codes/edit/:id" component={ Page__QrCodesForm } permission={ API.permissions.qr_code_management }/>
                    <Frontend exact path="/qr-code/:slug" component={ Page__QrCodesRedirect } />

                    {/* @Campaigns */}
                    <Backend exact path="/campaigns" component={ Page__CampaignsList } permission={ API.permissions.god }/>
                    <Backend exact path="/campaigns/page/:page" component={ Page__CampaignsList } permission={ API.permissions.god }/>
                    <Backend exact path="/campaigns/create" component={ Page__CampaignsForm } permission={ API.permissions.god }/>
                    <Backend exact path="/campaigns/edit/:id" component={ Page__CampaignsForm } permission={ API.permissions.god }/>


                    <Redirect to="/dashboard" />

                  </Switch>
                </HashRouter>
              </Box>

          </React.Fragment>

        ) : (

            <React.Fragment>

              <CssBaseline />

              <Box className="ds--app">
                <HashRouter>
                  <Switch>

                    <Frontend path="/login" component={ PageLogin }/>

                    {/* @MercadoPago */}
                    <Frontend exact path="/api/mercadopago/notification/:status/:id" component={ Page__MP_PreferenceNotification } />

                    {/* @TodoPago */}
                    <Frontend exact path="/api/todopago/notification/:status/:id" component={ Page__TP_PreferenceNotification } />

                    {/* @Form */}
                    <Frontend exact path="/form/:slug" component={ Page__Form } />

                    {/* @QrCodes */}
                    <Frontend exact path="/qr-code/:slug" component={ Page__QrCodesRedirect } />

                    <RedirectToState />

                  </Switch>
                </HashRouter>
              </Box>

            </React.Fragment>

        )}

      </MuiPickersUtilsProvider>
    </Suspense>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.userState
  }
}

export default withTranslation()(connect(mapStateToProps)(App));
