import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field, change } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, ButtonsGroup } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { CategoryService } from './../../../services';
import { CategoryAutocomplete } from './../../../scenes'
import { findPermissionProps } from './../../../utilities/utils';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 1024){
    errors.name = TEXT.validation.maxlength(1024);
  }

  if (!values.position) {
    errors.position = TEXT.validation.required;
  }else if(values.position <= 0){
    errors.position = TEXT.validation.minlength(1);
  }

  if (values.type === 'link') {
    if (!values.link) {
      errors.link = TEXT.validation.required;
    }
  }

  return errors;

}

class Page__CategoryForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: '...',
        title: '...',
        loading: true,
        submited: false,
        id: null,
        type: 'category',
        entity: null,
        categories: []
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.category, "Categorías") });

      const id = this.props.match.params.id;

      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false, title: this.props.t('text.new_entry') });
      }

    };

    /*
    ** @Service
    ** @Description: Save Category
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      CategoryService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: `La categoría fue creada.`
        });

        // @Redirect
        this.props.history.push('/categories');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Category
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      CategoryService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: `La categoría fue editada.`
        });

        // @Redirect
        this.props.history.push('/categories');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Category
    */

    _Find = (id) => {

      // @Service
      CategoryService.find(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set category update into state
        this.setState({
          loading: false,
          id: response.category.id,
          entity: response.category,
          type: response.category.type,
          title: response.category.name
        });

        // @Form
        // @Description: Set form fields
        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'type', this.state.entity.type ));
        this.props.dispatch(change(form, 'position', this.state.entity.position ));
        this.props.dispatch(change(form, 'target', this.state.entity.target ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));

        if(this.state.entity.parent){
          this.props.dispatch(change(form, 'parent', this.state.entity.parent._id ));
        }

        if(this.state.entity.link){
          this.props.dispatch(change(form, 'link', this.state.entity.link ));
        }

        this.handleOnSelectedCategories();

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: TEXT.message.error
        });

      });

    };

    /*
    ** @Submit
    ** @Description: Category selected
    */

    handleTypeChange = (event) => {

      // @State
      // @Description: Set category type
      this.setState({ type: event.target.value });

      // @Form
      // @Description: Reset fields
      this.props.dispatch(change(this.props.form, 'link',  ));
      this.props.dispatch(change(this.props.form, 'parent', '' ));

    };

    /*
    ** @Event
    ** @Description: On select category
    */
    handleOnSelectCategories = (categories) => {
      this.props.dispatch(change(this.props.form, 'parent', categories ? categories._id : '' ));
    };

    /*
    ** @Event
    ** @Description: Selected categories
    */
    handleOnSelectedCategories = () => {

      let categories = [];

      if(this.state.entity){
        if(this.state.entity.parent){
          categories.unshift(this.state.entity.parent);
          if(this.state.entity.parent.parent){
            categories.unshift(this.state.entity.parent.parent);
          }
        }
      }

      this.setState({ categories: categories });

    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };

    /*
    ** @Event
    ** @Description: OnChange name
    */
    handleChangeName = (event) => {
      this.setState({ title: event.target.value !== '' ? event.target.value : '...' });
    };

    // @Render
    render(){

      const { handleSubmit, invalid } = this.props;
      const { loading, title, submited, categories, type } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar title={this.props.t('page.categories.name')} subtitle={this.props.t('page.categories.form.subtitle')} dividerMobile>
              <Button
                component={ NavLink }
                to="/categories"
                icon="back"
                size="medium"
              >
                {this.props.t('button.back')}
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              {/* Form Category */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* Configuration */}
                  <Content marginBottom>
                    <Toolbar
                      title={this.props.t('text.configuration')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="type"
                          component={ SelectField }
                          label={this.props.t('field.type')}
                          required
                          onChange={ (e) => this.handleTypeChange(e) }
                        >
                          <OptionField value="category">{this.props.t('field.option_field.category')}</OptionField>
                          <OptionField value="link">{this.props.t('field.option_field.link')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="position"
                          component={ InputTextField }
                          label={this.props.t('field.position')}
                          type="number"
                          min="1"
                          required
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="target"
                          component={ SelectField }
                          label={this.props.t('field.target')}
                          required
                        >
                          <OptionField value="_self">{this.props.t('field.option_field.same_page')}</OptionField>
                          <OptionField value="_blank">{this.props.t('field.option_field.new_page')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label={this.props.t('field.status')}
                          required
                        >
                          <OptionField value="true">{this.props.t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{this.props.t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Configuration */}
                  <Content marginBottom>
                    <Grid container spacing={3}>

                      {/* Row 1 */}
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Field
                          name="name"
                          component={ InputTextField }
                          label={this.props.t('field.name')}
                          type="text"
                          required
                          //onChange={ (event) => this.handleChangeName(event) }
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        { type === 'category' ? (
                          <React.Fragment>

                            {/* Category Autocomplete */}
                            <CategoryAutocomplete
                              label="Categoría padre"
                              thirdLevelHide={true}
                              categories={ categories }
                              onSelect={ this.handleOnSelectCategories }
                            />

                            <Field
                                name="parent"
                                component={ InputTextField }
                                type="text"
                                required
                                fieldHidden
                            />

                          </React.Fragment>
                        ) : (
                          <Field
                            name="link"
                            component={ InputTextField }
                            label="Enlace"
                            type="text"
                            placeholder="https://firehub.com.ar"
                            required
                          />
                        )}

                      </Grid>

                    </Grid>
                  </Content>

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/categories"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      {this.props.t('button.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      {this.props.t('button.save')}
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__CategoryForm = reduxForm({
  form: 'Page__CategoryForm',
  validate,
  initialValues: {
    type: 'category',
    position: 1,
    target: '_self',
    enabled: true
  }
})(Page__CategoryForm);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__CategoryForm));
