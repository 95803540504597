import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, Modal, AlertConfirm, Table, Row, Cell, Tag, Currency } from './../../../../components/ui';
import { ShopCuponService } from './../../../../services';
import { isUndefined, isStatus, isStatusColor, hasPermission, findPermissionProps, parseQueryString, isCuponName } from './../../../../utilities/utils';
import UtilShopCuponFilter from './../filter';

import API from './../../../../config/api';
import TEXT from './../../../../config/text';

class Page__ShopCuponList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        loading: false,
        page: 0,
        total: 0,
        id: null,
        entities: [],
        remove: false,
        thead: [
          { id: 'name', name: this.props.t('field.name') },
          { id: 'code', name: this.props.t('field.code') },
          { id: 'type', name: this.props.t('field.type') },
          { id: 'value', name: this.props.t('field.amount') },
          { id: 'value_min', name: this.props.t('field.purchase_min_amount') },
          { id: 'enabled', name: this.props.t('field.status') },
          { id: 'actions', name: this.props.t('field.actions'), align: 'right' }
        ],
        cuponFilterShow: false
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.match.params.id !== prev_props.match.params.id){
        this._All(this.props.match.params.page || 0, this.props.match.params.id);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.shop_cupon, this.props.t('text.coupons')) });

      const page = this.props.match.params.page || 0;
      const id   = this.props.match.params.id;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          if(id){
            this._All(page, id);
          }else{
            this._All(page);
          }
        }else{
          this.props.history.push('/cupons');
        }
      }else{
        this._All(this.state.page);
      }

    };

    /*
    ** @Service
    ** @Description: All Cupons
    */

    _All = (page, id) => {

      // @State
      // @Description: Refresh after each service
      this.setState({ loading: false, entities: [] });

      // @Service
      ShopCuponService.list(page, parseQueryString(this.props.location.search)).then((response) => {

        // @State
        // @Descripcion: Set values
        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.cupons,
          total: response.total
        });

      }).catch((error) => {

        // @State
        // @Descripcion: Loading disabled
        this.setState({ loading: true });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Remove Cupon
    */

    _Remove = (id) => {

      ShopCuponService.remove(id).then((response) => {

        // @State
        this.setState({ remove: false });

        // @Service find all by page
        this._Init();

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

      }).catch((error) => {

        // @State
        this.setState({ remove: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : API.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description: Remove category
    */

    handleRemove = (id) => {
      this.setState({ id: id, remove: true });
    };

    /*
    ** @Click
    ** @Description: Remove category confirm
    */

    handleConfirmRemoveConfirm = () => {
      this._Remove(this.state.id);
    };

    /*
    ** @Click
    ** @Description: Remove category cancel
    */

    handleConfirmRemoveCancel = () => {
      this.setState({ remove: false });
    };

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/cupons/page/${ page }`,
        search: this.props.location.search
      });

    };

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ cuponFilterShow: true });
    };

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ cuponFilterShow: false });
    };

    render(){

      const { session, t } = this.props;
      const { permission, name, loading, entities, thead, total, page, remove, cuponFilterShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <ButtonsGroup>

                { this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title="Eliminar filtros"
                    placement="left"
                    component={ NavLink }
                    to="/cupons"
                  />
                )}
                <Button
                  icon="filter"
                  size="medium"
                  onClick={ this.handleFilter }
                >
                  {t('button.filter')}
                </Button>

                { hasPermission(session.user.permissions, API.permissions.shop_cupon_management) && (
                  <Button
                    component={ NavLink }
                    to="/cupons/create"
                    icon="add"
                    size="medium"
                  >
                    {t('button.create')}
                  </Button>
                )}
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

                {/* Alert confirm Delete */}
                <AlertConfirm
                   status={ remove }
                   message={t('messages.delete_confirm')}
                   cancel={() => this.handleConfirmRemoveCancel()}
                   confirm={() => this.handleConfirmRemoveConfirm()}
                />

                {/* Filter */}
                <Modal
                    status={cuponFilterShow}
                    title={t('text.filter')}
                    handleCancel={() => this.handleFilterClose()}
                    maxWidth="sm">
                    <UtilShopCuponFilter onSuccess={this.handleFilterClose}/>
                </Modal>

                <Table
                  thead={ thead }
                  loading={ loading }
                  total={ total }
                  page={ page }
                  onChangePage={ this.handleChangePage }
                >

                  { entities.length > 0 && (

                    entities.map(entity => {
                      return (
                        <Row key={ entity._id }>
                          <Cell nowrap>
                            { entity.name }
                          </Cell>
                          <Cell nowrap>
                            { entity.code }
                          </Cell>
                          <Cell nowrap>
                            <Tag text={ isCuponName(entity.type) }/>
                          </Cell>
                          <Cell nowrap>
                            { entity.type === 'amount' && (
                              <Currency value={ entity.value }/>
                            )}
                            { entity.type === 'percentage' && (
                              <React.Fragment>
                                { entity.value }%
                              </React.Fragment>
                            )}
                          </Cell>
                          <Cell nowrap>
                            { entity.value_min ? (
                              <Currency value={ entity.value_min }/>
                            ) : (
                              <React.Fragment>
                                { TEXT.message.not_available }
                              </React.Fragment>
                            )}
                          </Cell>
                          <Cell>
                            <Tag
                              text={ isStatus(entity.enabled) }
                              color={ isStatusColor(entity.enabled) }
                            />
                          </Cell>
                          <Cell align="right" nowrap>
                            { hasPermission(session.user.permissions, API.permissions.shop_cupon_management) && (
                              <React.Fragment>
                                <Button
                                  color="primary"
                                  icon="edit"
                                  component={ NavLink }
                                  title={t('button.edit')}
                                  to={ `/cupons/edit/${ entity._id }` }
                                />
                                <Button
                                  color="danger"
                                  icon="cancel"
                                  title={t('button.delete')}
                                  onClick={ () => this.handleRemove(entity._id)  }
                                />
                              </React.Fragment>
                            )}
                          </Cell>
                        </Row>
                      )
                    })

                  )}

                  { (loading && entities.length === 0) && (
                    <Row>
                      <Cell colSpan={ thead.length } align="center">
                        {TEXT.message.without_results}
                      </Cell>
                    </Row>
                  )}

                </Table>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__ShopCuponList));
