import React from 'react';
import { Field } from 'redux-form';
import { InputTextField } from './../../input';

const UploadImagesFieldArray = ({ fields, meta: { error, submitFailed } }) => (
  fields.length > 0 && (
    fields.map((entity, index) => (
      <div key={index} style={{display:'none'}}>
        <Field
            name={`${entity}.name`}
            component={ InputTextField }
            type="text"
            required
        />
        <Field
            name={`${entity}.path`}
            component={ InputTextField }
            type="text"
            required
        />
        <Field
            name={`${entity}.size`}
            component={ InputTextField }
            type="text"
            required
        />
        <Field
            name={`${entity}.type`}
            component={ InputTextField }
            type="text"
            required
        />
      </div>
    ))
  )
);

export default UploadImagesFieldArray;
