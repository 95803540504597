import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { Drawer, Box } from './../../../../../assets/theme/default';
import { Grid } from './../../../../../assets/theme/layout';
import { Page, Wrapper, Alert, Button, Label, ButtonsGroup, Toolbar, Paper, Content, Loading } from './../../../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../../../components/ui/input';
import { ClientAutocomplete, CurrencySelect } from './../../../../../scenes';
import { TodoPagoService } from './../../../../../services';

import UtilMPSummary from './../../../utils/summary';
import UtilProductsSelector from './../../../../../scenes/product/selector';

import API from './../../../../../config/api';
import TEXT from './../../../../../config/text';

// @Validation
// @Description: Validation Form
const validate = values => {

  const errors = {};

  if (!values.client) {
    errors.client = TEXT.validation.required;
  }

  if(values.type){
    if(values.type === 'simple'){
      if(!values.amount){
        errors.amount = TEXT.validation.required;
      }else if(values.amount <= 0){
        errors.amount = TEXT.validation.minlength(1);
      }
    }
    if(values.type === 'combo'){
      if(parseInt(values.products_length,10) <= 0){
        errors.products_length = TEXT.validation.required;
      }
    }
  }

  if (values.currency) {
    if (values.currency === '-1') {
      errors.currency = TEXT.validation.required;
    }
  }

  if (!values.amount) {
    errors.amount = TEXT.validation.required;
  }

  if (!values.reference) {
    errors.reference = TEXT.validation.required;
  }

  if (values.timeout) {
    if (values.timeout === '-1') {
      errors.timeout = TEXT.validation.required;
    }
  }

  if (values.min_installments) {
    if (values.min_installments === '-1') {
      errors.min_installments = TEXT.validation.required;
    }
  }

  if (values.max_installments) {
    if (values.max_installments !== '-1') {
      if (parseInt(values.max_installments,10) < parseInt(values.min_installments,10)) {
        errors.max_installments = 'Máximo de cuotas no puede ser menor a el Mínimo número de cuotas';
      }
    }
  }

  return errors;

};

class Page__TP_PreferenceForm extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      submited: false,
      canBuy: false,
      canBuyRequired: null,
      //installments: [1,3,6,9,12],
      // installments: [
      //   { name: 1, value: 1},
      //   { name: 3, value: 13}
      // ],
      timeout: [
        { name: '½ hora', value: '30' },
        { name: '1 hora', value: '60' },
        { name: '1 ½ hora', value: '90' },
        { name: '2 horas', value: '120' },
        { name: '2 ½ horas', value: '150' },
        { name: '3 horas', value: '180' },
        { name: '3 ½ horas', value: '210' },
        { name: '4 horas', value: '240' },
        { name: '4 ½ horas', value: '270' },
        { name: '5 horas', value: '300' },
        { name: '5 ½ horas', value: '330' },
        { name: '6 horas', value: '360' },
      ],
      type: 'combo',
      productSelectorShow: false,
      parentRef: {}
    };

    this.contentRef = React.createRef();
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){

    this.handleOnReize();

    // @Event
    // @Description: Event on reize window
    window.addEventListener("resize", this.handleOnReize.bind(this));

  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component will recive props.
  */
  componentDidUpdate(prev_props){
    if(this.props.checkout.products.length !== prev_props.checkout.products.length){
      this.props.dispatch(change(this.props.form, 'products_length', this.props.checkout.products.length));
    }
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component will unmount.
  */
  componentWillUnmount(){
   this.props.checkoutClearStateDispatch({
     type: 'combo',
     update: Date.now()
   });
  }

  /*
  ** @Event
  ** @Description: On resize
  */
  handleOnReize = () => {
    let current = this.contentRef.current;
    if(current){
      this.setState({ parentRef: current.getBoundingClientRect() });
    }
  }

  /*
  ** @Event
  ** @Description: On selected client
  */
  handleValidationCanBuy = (client) => {

    if(client){

      let canBuyRequired = [];

      if(
          client.country &&
          client.state &&
          client.city &&
          client.zip &&
          client.address_street &&
          client.address_number &&
          client.mobile_phone
      ){
        this.setState({ canBuy: true });
      }

      if(!client.country) canBuyRequired.push('País');
      if(!client.state) canBuyRequired.push('Provincia');
      if(!client.city) canBuyRequired.push('Localidad');
      if(!client.zip) canBuyRequired.push('Código postal');
      if(!client.address_street) canBuyRequired.push('Calle');
      if(!client.address_number) canBuyRequired.push('Número');
      if(!client.mobile_phone) canBuyRequired.push('Celular');

      this.setState({ canBuyRequired: canBuyRequired.join(', ') });

    }else{

      this.setState({ canBuy: false, canBuyRequired: null });

    }

  }

  /*
  ** @Event
  ** @Description: On selected client
  */
  handleOnSelectClient = (client) => {

    // @Validation
    // @Description: Validation client
    this.handleValidationCanBuy(client);

    // @Form
    // @Description: Set client ID
    this.props.dispatch(change(this.props.form, 'client', client ? client._id : '' ));

    // @Store
    // @Description: Set client into stroe
    this.props.checkoutClientAddStateDispatch(client);

  }

  // @Event
  // @Description: On change type payment
  handleOnChangeType = (event, value) => {

    // @State
    // @Description: Select checkout type
    this.setState({ type: value });

    // @Dispatch
    // @Description: Clear checkout
    this.props.checkoutClearStateDispatch({
      type: value,
      update: Date.now()
    });

    // @Form
    // @Description: Clear values
    this.props.dispatch(change(this.props.form, 'amount', '' ));

  }

  // @Event
  // @Description: Add total to store
  handleOnChangeAmount = (event) => {

    let amount = parseInt(event.target.value !== '' ? event.target.value : 0, 10);

    // @Store
    // @Description: Send amount to store
    this.props.checkoutAmountAddStateDispatch({
      amount: amount,
      update:  Date.now()
    });

  }

  // @Event
  // @Description: Add total to store
  handleOnLoadCurrency = (currency) => {
    this.props.dispatch(change(this.props.form, 'currency', currency.prefix ));
  }

  // @Event
  // @Description: Add product to store
  handleToggleProductSelector = (status) => {
    this.setState({ productSelectorShow: status });
  }

  // @Event
  // @Description: Add product to store
  handleSelectProduct = (product) => {
    this.props.checkoutProductAddStateDispatch({
      product: product,
      update:  Date.now()
    });
  }

  /*
  ** @Event
  ** @Description: Submit form
  */
  handleSubmitForm = (values) => {

    let toSend = Object.assign({
      products: this.props.checkout.products,
      total:    this.props.checkout.total,
      subtotal: this.props.checkout.subtotal,
      discount: this.props.checkout.discount
    }, values);

    // @Loadig
    // @Description: Show loading
    this.setState({ loading: true });

    // @Service
    // @Description: Save preference
    TodoPagoService.preferenceSave(toSend).then((response) => {

      // @Dispatch alert success
      this.props.alertShow({
        open: true,
        severity: 'success',
        message: `El checkout fue creado.`
      });

      // @Redirect
      this.props.history.push(`/api/todopago/checkout/detail/${ response.preference._id }`);

    }).catch((error) => {

      // @State
      // @Description: Loading hide
      this.setState({ loading: false });

      // @Dispatch alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: TEXT.message.error
      });

    });

  }

  render(){

    const { checkout, handleSubmit, invalid } = this.props;
    const { submited, loading, timeout, type, canBuy, canBuyRequired, productSelectorShow, parentRef } = this.state;

    return(
      <Page>

        <Wrapper>

          <Toolbar
            title="Todo Pago"
            subtitle="Formulario de checkout"
            dividerMobile
          >
            <Button
              size="medium"
              component={ NavLink }
              to="/api/todopago/checkout"
              icon="back"
            >
              Volver
            </Button>
          </Toolbar>

          <Box ref={ this.contentRef }>
            <Content disablePadding>

              { (submited || loading) && (
                <Loading variant="absolute"/>
              )}

              <Grid container spacing={1}>

                {/* Form */}
                <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                  <Paper disablePadding>

                    <Toolbar
                      title="Nuevo checkout"
                      size="medium"
                      variant="divider"
                      disableMargin
                    />

                    <Drawer
                      anchor="top"
                      open={ productSelectorShow }
                      onClose={ () => this.handleToggleProductSelector(false) }
                    >
                      <UtilProductsSelector
                        onSelect={ this.handleSelectProduct }
                        onClose={ () => this.handleToggleProductSelector(false)  }
                      />
                    </Drawer>

                    <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>
                      <Content>

                        {/* Client Autocomplete */}
                        { (checkout.client && !canBuy) && (
                          <Content marginBottom>
                            <Alert severity="warning">
                              <strong>Atención:</strong> Para poder generar un checkout al cliente { checkout.client.name } { checkout.client.surname } deberá editar el perfil y completar los campos { canBuyRequired }.
                            </Alert>
                          </Content>
                        )}

                        {/* Client Autocomplete */}
                        <Content divider marginBottom>

                          <ClientAutocomplete
                            onSelect={ this.handleOnSelectClient }
                          />

                          <Field
                              name="client"
                              component={ InputTextField }
                              type="text"
                              required
                              fieldHidden
                          />

                        </Content>

                        {/* Tipo de pago */}
                        <Content divider disablePadding>
                          <Toolbar
                            title="Tipo"
                            subtitle="Selección de típo de checkout a generar."
                            size="small"
                            style={{ marginBottom: 24 }}
                          />

                          <Grid container spacing={3}>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                              <Field
                                name="type"
                                component={ SelectField }
                                label="Tipo"
                                required
                                onChange={ this.handleOnChangeType }
                              >
                                <OptionField value="simple">Simple</OptionField>
                                <OptionField value="combo">Combo</OptionField>
                              </Field>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                              <Field
                                name="currency"
                                component={ CurrencySelect }
                                label="Moneda"
                                required
                                onLoad={ this.handleOnLoadCurrency }
                              />
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                              { type === 'simple' && (
                                <Field
                                  name="amount"
                                  component={ InputTextField }
                                  label="Monto"
                                  type="number"
                                  required
                                  onChange={ this.handleOnChangeAmount }
                                />
                              )}
                              { type === 'combo' && (
                                <React.Fragment>
                                  <Label>
                                    Productos
                                  </Label>
                                  <Button
                                    size="medium"
                                    icon="add"
                                    fullWidth
                                    onClick={ () => this.handleToggleProductSelector(true) }
                                  >
                                      Agregar
                                  </Button>
                                  <Field
                                    name="products_length"
                                    component={ InputTextField }
                                    type="number"
                                    fieldHidden
                                  />
                                </React.Fragment>
                              )}
                            </Grid>
                          </Grid>
                        </Content>

                        {/* Todo Pago Information */}
                        <Content divider disablePadding>
                          <Toolbar
                            title="Configuración"
                            subtitle="Información para generar el checkout sobre Todo Pago."
                            size="small"
                            style={{ marginBottom: 24 }}
                          />

                          <Grid container spacing={3}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Field
                                name="reference"
                                component={ InputTextField }
                                label="Detalle de la compra"
                                type="text"
                                required
                              />
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                              <Field
                                name="timeout"
                                component={ SelectField }
                                label="Expiración"
                                required
                              >
                                <OptionField value="-1">Seleccionar...</OptionField>
                                { timeout.map((option, index) => {
                                  return(
                                    <OptionField key={ index } value={ option.value }>
                                      { option.name }
                                    </OptionField>
                                  )
                                })}
                              </Field>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                              <Field
                                name="min_installments"
                                component={ SelectField }
                                label="Mínimo número de cuotas"
                                required
                              >
                                <OptionField value="-1">Seleccionar...</OptionField>
                                { API.todopago.preference.installments.map((installment, index) => {
                                  return(
                                    <OptionField key={ index } value={ installment.value }>
                                      { installment.name }
                                    </OptionField>
                                  )
                                })}
                              </Field>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                              <Field
                                name="max_installments"
                                component={ SelectField }
                                label="Máximo número de cuotas"
                                required
                              >
                                <OptionField value="-1">Seleccionar...</OptionField>
                                { API.todopago.preference.installments.map((installment, index) => {
                                  return(
                                    <OptionField key={ index } value={ installment.value }>
                                      { installment.name }
                                    </OptionField>
                                  )
                                })}
                              </Field>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                              <Field
                                name="email"
                                component={ SelectField }
                                label="¿Enviar email?"
                              >
                                <OptionField value="true">Si</OptionField>
                                <OptionField value="false">No</OptionField>
                              </Field>
                            </Grid>
                          </Grid>
                        </Content>

                        {/* Footer */}
                        <Content disablePadding>
                          <ButtonsGroup align="right">
                            <Button
                              component={ NavLink }
                              to="/api/todopago/checkout"
                              color="danger"
                              size="medium"
                              icon="cancel"
                            >
                              Cancelar
                            </Button>
                            <Button
                              type="submit"
                              size="medium"
                              icon="add"
                              color="success"
                              disabled={ invalid || submited }
                            >
                              Confirmar
                            </Button>
                          </ButtonsGroup>
                        </Content>

                      </Content>
                    </form>

                  </Paper>
                </Grid>

                {/* Sumarry */}
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <UtilMPSummary parentRef={ parentRef }/>
                </Grid>

              </Grid>

            </Content>
          </Box>

        </Wrapper>

      </Page>
    )
  }

};

Page__TP_PreferenceForm = reduxForm({
  form: 'Page__TP_PreferenceForm',
  validate,
  initialValues: {
    type: 'combo',
    currency: '-1',
    email: false,
    min_installments: '-1',
    max_installments: '-1',
    timeout: '-1',
    expiration_date_from: moment.utc().format(),
    expiration_date_to: moment.utc().add(API.mercadopago.preference.expires_add_days, 'days').format(),
    products_length: 0
  }
})(Page__TP_PreferenceForm);

const mapStateToProps = (state, ownProps) => {
  return {
    checkout: state.mpCheckoutState
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    },
    checkoutClientAddStateDispatch: (data) => {
      dispatch({
        type: 'MP_CHECKOUT_CLIENT_ADD',
        data: data
      });
    },
    checkoutAmountAddStateDispatch: (data) => {
      dispatch({
        type: 'MP_CHECKOUT_AMOUNT_ADD',
        data: data
      });
    },
    // checkoutDiscountClearStateDispatch: () => {
    //   dispatch({
    //     type: 'MP_CHECKOUT_DISCOUNT_CLEAR'
    //   });
    // },
    checkoutProductAddStateDispatch: (data) => {
      dispatch({
        type: 'MP_CHECKOUT_PRODUCT_ADD',
        data: data
      });
    },
    checkoutClearStateDispatch: (data) => {
      dispatch({
        type: 'MP_CHECKOUT_CLEAR',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page__TP_PreferenceForm);
