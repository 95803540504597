import React from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Page, Wrapper, Paper, Button, Toolbar } from './../../../../components/ui';
import ComponentClientForm from './../component'
import { findPermissionProps } from './../../../../utilities/utils';

import API from './../../../../config/api';

class Page__ClientForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: '...',
        title: '...',
        loading: true,
        submited: false,
        id: null,
        type: 'category',
        entity: null,
        categories: []
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.clients, "Clientes") });

      const id = this.props.match.params.id;

      if(id){
        this.setState({ loading: false, title: this.props.t('text.registry_edit') });
      }else{
        this.setState({ loading: false, title: this.props.t('text.registry_new') });
      }

    };

    /*
    ** @Event
    ** @Description: On Submit Form
    */
    handleOnSubmit = () => {
      this.props.history.push('/clients');
    };

    /*
    ** @Event
    ** @Description: On Error Form
    */
    handleOnError = () => {
      this.props.history.push('/clients');
    };

    /*
    ** @Event
    ** @Description: On Edit Form
    */
    handleOnEdit = (client) => {

    };

    // @Render
    render(){

      const { permission, name, title } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/clients"
                icon="back"
                size="medium"
              >
                {this.props.t('button.back')}
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              {/* Form Client */}
              <ComponentClientForm
                onEdit={ this.handleOnEdit }
                onSubmit={ this.handleOnSubmit }
                onError={ this.handleOnError }
              />

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

export default withTranslation()(Page__ClientForm);
