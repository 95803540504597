import React from 'react';
import { Field } from 'redux-form';
import { InputAdornment } from './../../../../assets/theme/input';
import { Toolbar, Button, Paper, Table, Row, Cell } from './../../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../../components/ui/input';
import { ShippingService } from './../../../../services';

class ShippingContainerFieldArray extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      containers: [],
      loading: false,
      thead: [
        { id: 'container', name: 'Equipo' },
        { id: 'enrollment', name: 'Nro. equipo' },
        { id: 'weight', name: 'Peso' },
        { id: 'volume', name: 'Volumen' },
        { id: 'actions', name: 'Acciones', align: 'right' }
      ]
    };
  };

  /**
  * @Event
  * @Description: Is invoked immediately after a component will recive props.
  */
  componentDidUpdate(prevProps, prevState){
    if(this.props.transport !== prevProps.transport){
      this._Find(this.props.transport);
    }
  };

  /*
  ** @Service
  ** @Description: List all containers
  */

  _Find = (id) => {
    ShippingService.containersList({transport:id}).then((response) => {
      this.setState({ containers: response.containers || [] });
    });
  };

  render(){

    const { fields } = this.props;
    const { containers, thead } = this.state;

    return(
      <Paper>
        <Toolbar
          title="Equipos"
          subtitle="Configuración de equipos disponibles en la reserva."
          size="small"
          dividerMobile
        >
          <Button icon="add" onClick={() => fields.push({})}>
            Agregar
          </Button>
        </Toolbar>
        <Paper disablePadding>
          <Table
            thead={thead}
            loading={true}
          >
            {fields.length > 0 ? (
              <React.Fragment>
                {fields.map((entity, index) => (
                  <Row key={index}>
                    <Cell style={{width:340}}>
                      <Field
                        name={`${entity}.entity`}
                        component={ SelectField }
                        required
                      >
                        <OptionField value="-1">Seleccionar...</OptionField>
                        { containers.map((container, index) => {
                          return(
                            <OptionField key={index} value={container._id} disabled={!container.enabled}>
                              { container.name }
                              { !container.enabled && (' [Bloqueado]')}
                            </OptionField>
                          )
                        })}
                      </Field>
                    </Cell>
                    <Cell style={{width:190}}>
                      <Field
                        name={`${entity}.enrollment`}
                        component={ InputTextField }
                        type="text"
                        required
                      />
                    </Cell>
                    <Cell style={{minWidth:120}}>
                      <Field
                        name={`${entity}.weight`}
                        component={ InputTextField }
                        type="number"
                        min={0}
                        required
                        endAdornment={
                          <InputAdornment position="end">Kgs.</InputAdornment>
                        }
                      />
                    </Cell>
                    <Cell style={{minWidth:120}}>
                      <Field
                        name={`${entity}.volume`}
                        component={ InputTextField }
                        type="number"
                        min={0}
                        required
                        endAdornment={
                          <InputAdornment position="end">m³</InputAdornment>
                        }
                      />
                    </Cell>
                    <Cell align="right" nowrap>
                      <Button
                        icon="cancel"
                        color="danger"
                        size="medium"
                        title="Eliminar"
                        onClick={() => fields.remove(index)}
                      />
                    </Cell>
                  </Row>
                ))}
              </React.Fragment>
            ) : (
              <Row>
                <Cell colSpan={thead.length} align="center">
                  Atención, no se encontraron equipos disponibles, para eso puedes dar click en "Agregar".
                </Cell>
              </Row>
            )}
          </Table>
        </Paper>
      </Paper>
    )
  }

};

export default ShippingContainerFieldArray;
