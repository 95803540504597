import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, ButtonsGroup, TextEditor } from './../../../components/ui';
import { InputTextField } from './../../../components/ui/input';
import { EmailLayoutSelect, EmailTemplateSelect } from './../../../scenes';
import { EmailService, ClientService, SupportService, StoreService, ShopCartService } from './../../../services';
import { findPermissionProps } from './../../../utilities/utils';
import * as Currency from './../../../utilities/currency';

import UtilClientData from './../../client/section/data';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.subject) {
    errors.subject = TEXT.validation.required;
  }else if(values.subject.length > 1024){
    errors.subject = TEXT.validation.maxlength(1024);
  }

  if (!values.body) {
    errors.body = TEXT.validation.required;
  }

  return errors;

}

class Page__SupportForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        title: '...',
        loading: true,
        submited: false,
        id: null,
        entity: null,
        client: null,
        layout: null,
        template: null
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.support, "Soporte") });

      this._Find(this.props.match.params.id);

    };

    /*
    ** @Service
    ** @Description: Find Client By Id
    */

    _Find = (id) => {

      ClientService.find(id).then((response) => {

        // @State
        this.setState({
          loading: false,
          title: `${ response.client.name } ${ response.client.surname }`,
          client: response.client
        });

        // @Form
        this.props.dispatch(change(this.props.form, 'client', response.client._id ));

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Send email
    */

    _Send = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      SupportService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        // @Redirect
        this.props.history.push(`/clients/profile/${this.state.client._id}`);

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find tempaltes
    */

    _Template = (id) => {

      EmailService.findTemplateById(id).then((response) => {

        const form = this.props.form;

        this.setState({ template: response.template }, async () => {

          // @Subject
          this.props.dispatch(change(form, 'subject', this.state.template.subject ));

          // @If
          // @Description: If template body exist
          if(this.state.template.body){

            let stores;
            let shopCartAbandoned;

            if(this.state.template.body.includes('stores_list')){
              stores = await StoreService.list(0, {items:100});
            }

            if(this.state.template.body.includes('shop_cart_abandoned')){
              shopCartAbandoned = await ShopCartService.find(this.state.client._id);
            }

            // @Template
            // @Description: replace variables
            let template = _.template(this.state.template.body);

            // @Layout
            // @Description: replace variables
            let layout = _.template(this.state.layout.body);

            // @Variables
            // @Description: Default values
            let params = {
              'client_name':    this.state.client.name,
              'client_surname': this.state.client.surname,
              'user_name':      this.props.session.user.name
            };

            // @Variable
            // @Description: Add stores
            if(stores && stores.stores.length > 0){
              params = Object.assign({
                'stores_list': stores.stores
              }, params);
            }

            // @Variable
            // @Description: Add shopCartAbandoned
            if(shopCartAbandoned && shopCartAbandoned.items.length > 0){
              params = Object.assign({
                'shop_cart_abandoned': shopCartAbandoned.items
              }, params);
            } 

            let html = template({ 
                        param: params, 
                        web: API.endpoint.web, 
                        uploads: API.endpoint.uploads,
                        helpers: {
                          currencyFormat: (value) => {
                            return Currency.format(value)
                          }
                        }
                      });

                html = layout({ content: html, url: API.endpoint.app });

            // @Message
            this.props.dispatch(change(form, 'message', html));

          }

        });

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find layout
    */

    _Layout = (id) => {

      EmailService.findLayoutById(id).then((response) => {
        this.setState({ layout: response.layout }, () => {
          if(this.state.template){
            this._Template(this.state.template._id);
          }
        });

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    // @Event
    // @Description: On change layout
    handleOnChangeLayout = (event) => {
      const id = event.target.value;
      if(id && id !== '-1'){
        this._Layout(event.target.value);
      }else{
        this.setState({ layout: null, template: null });
        this.props.reset();
      }
    };

    // @Event
    // @Description: On change template
    handleOnChangeTemplate = (event) => {
      const id = event.target.value;
      if(id && id !== '-1'){
        this._Template(event.target.value);
      }else{
        this.props.reset();
      }
    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      this._Send(values);
    };

    // @Render
    render(){

      const { handleSubmit, invalid } = this.props;
      const { permission, name, loading, title, submited, client } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            />

            <Paper disablePadding>

              <Toolbar
                title={title}
                size="medium"
                variant="divider"
                disableMargin
              />

              { client && (
                <UtilClientData client={client} hideLocation hideConfiguration/>
              )}

              {/* Form Email */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Field
                  name="client"
                  component={ InputTextField }
                  required
                  fieldHidden
                />

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* General */}
                  <Content marginBottom>
                    <Toolbar
                      title="Configuración"
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <Field
                          name="layout"
                          component={ EmailLayoutSelect }
                          label="Composición"
                          required
                          onChange={this.handleOnChangeLayout}
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <Field
                          name="template"
                          component={ EmailTemplateSelect }
                          label="Plantilla"
                          disabled={!this.state.layout}
                          required
                          onChange={this.handleOnChangeTemplate}
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="subject"
                          component={ InputTextField }
                          label="Asunto"
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="message"
                          component={ TextEditor }
                          label="Mensaje"
                          type="text"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Content>

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      Enviar
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__SupportForm = reduxForm({
  form: 'Page__SupportForm',
  validate,
  initialValues: {
    message: ''
  }
})(Page__SupportForm);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Page__SupportForm);
