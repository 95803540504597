import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { makeStyles, Typography } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: 1,
    whiteSpace: 'nowrap'
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const LinkBlog = ({ blog }) => {

  const classes  = useStyles();

  return (
    <Typography
      className={ classNames(
        classes.root,
        classes.link
      )}
      component={ NavLink }
      to={ `/blog/detail/${ blog._id }` }
    >
      { blog.title }
    </Typography>
  )

};

LinkBlog.propTypes = {
  blog: PropTypes.object
};

export default LinkBlog;
