import React from 'react';
import { withTranslation } from 'react-i18next';
import { Typography } from './../../../assets/theme/default';
import { QrCodeService } from './../../../services';
import { Loading } from './../../../components/ui';


class Page__QrCodesRedirect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            slug: null
        };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount() {
        this._Init();
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
     componentDidUpdate(prevProps, prevState) {
        if(prevProps.match.params.slug !== this.props.match.params.slug){
            this._Init();
        }
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {
        const slug = this.props.match.params.slug;
        if (slug) {
            this._Find(slug);
        }
    }

    /*
    ** @Service
    ** @Description: QrCode service
    */

    _Find = (slug) => {

        this.setState({ loading: true });

        QrCodeService.findByPublicAccess(slug).then((response) => {
            window.location.href = response.qrCode.link;
        }).catch((error) => {
            this.setState({
                loading: false,
                slug: null
            });
        });

    }

    render() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <Typography>{this.props.t('messages.qr_not_available')}</Typography>
                )}
            </div>
        )
    }

}

export default withTranslation()(Page__QrCodesRedirect);
