
const Sum = function sumValuesByPrice (entities) {
  let value = 0;
  entities.map(entity => {
      return value += (entity.price * entity.qty);
  });
  return value;
};

const Discount = function discount (total, discount) {
  if(total > 0 && discount > 0){
    return Number(((total * discount) / 100));
  }else{
    return 0;
  }
};

const Total = function total (total, discount) {
  if(discount > 0){
    return Number(total - discount);
  }else{
    return total;
  }
};

const Percentage = function calculatePercentage (a, b) {
  return Math.round(((a - b) / a) * 100);
};

const sumByPercentaje = function calculatePercentage (value, percentage) {
  let v = value;
  let p = percentage;
  if(typeof percentage !== 'number'){
    p = parseInt(percentage,10);
  }
  if(p > 0){
    v = ((value*p)/100)+value;
  }
  return v;
};

const restByPercentaje = function calculatePercentage (value, percentage) {
  let v = value;
  let p = percentage;
  if(typeof percentage !== 'number'){
    p = parseInt(percentage,10);
  }
  if(p > 0){
    v = value-((value*p)/100);
  }
  return v;
};

export {
  Sum, Discount, Total, Percentage, sumByPercentaje, restByPercentaje
}
