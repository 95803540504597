import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field, change } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, ButtonsGroup } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { ProviderService } from './../../../services';
import { isEmail, findPermissionProps } from './../../../utilities/utils';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const validate = values => {

  const errors = {
    address: {}
  };

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 1024){
    errors.name = TEXT.validation.maxlength(1024);
  }

  if (!values.identifire) {
    errors.identifire = TEXT.validation.required;
  }else if(values.identifire.length > 255){
    errors.identifire = TEXT.validation.maxlength(255);
  }

  if (!values.email) {
    errors.email = TEXT.validation.required;
  } else if (isEmail(values.email)) {
    errors.email = TEXT.validation.email_invalid;
  } else if (values.email.length > 255){
    errors.email = TEXT.validation.maxlength(255);
  }

  if (values.area) {
    if (values.area.length > 255) {
      errors.area = TEXT.validation.maxlength(255);
    }
  }

  if (values.cuit) {
    if (values.cuit.length > 255) {
      errors.cuit = TEXT.validation.maxlength(255);
    }
  }

  if (values.sales_phone) {
    if (values.sales_phone.length > 255) {
      errors.sales_phone = TEXT.validation.maxlength(255);
    }
  }

  if (values.fax_phone) {
    if (values.fax_phone.length > 255) {
      errors.fax_phone = TEXT.validation.maxlength(255);
    }
  }

  // @Address
  if(values.address){
    if (!values.address.street) {
      errors.address.street = TEXT.validation.required;
    } else if (values.address.street.length > 255) {
      errors.address.street = TEXT.validation.maxlength(255);
    }

    if (!values.address.number) {
      errors.address.number = TEXT.validation.required;
    } else if (values.address.number.length > 255) {
      errors.address.number = TEXT.validation.maxlength(255);
    }

    if (values.address.floor) {
      if (values.address.floor.length > 255) {
        errors.address.floor = TEXT.validation.maxlength(255);
      }
    }

    if (values.address.apartment) {
      if (values.address.apartment.length > 255) {
        errors.address.apartment = TEXT.validation.maxlength(255);
      }
    }

    if (!values.address.zip) {
      errors.address.zip = TEXT.validation.required;
    } else if (values.address.zip.length > 10) {
      errors.address.zip = TEXT.validation.maxlength(10);
    }

    if (values.address.country) {
      if (values.address.country === '-1') {
        errors.address.country = TEXT.validation.required;
      }
    }

    if (values.address.state) {
      if (values.address.state === '-1') {
        errors.address.state = TEXT.validation.required;
      }
    }

    if (values.address.city) {
      if (values.address.city.length > 255) {
        errors.address.city = TEXT.validation.maxlength(255);
      }
    }
  }

  return errors;

}

class Page__ProviderForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        title: '...',
        loading: true,
        submited: false,
        id: null,
        entity: null
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.providers, "Proveedores") });

      const id = this.props.match.params.id;

      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false, title: 'Nuevo registro' });
      }

    };

    /*
    ** @Service
    ** @Description: Save Entity
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      ProviderService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        // @Redirect
        this.props.history.push('/providers');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Entity
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      ProviderService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        // @Redirect
        this.props.history.push('/providers');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Entity
    */

    _Find = (id) => {

      // @Service
      ProviderService.find(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set note update into state
        this.setState({
          loading: false,
          id: response.provider._id,
          entity: response.provider,
          title: response.provider.name
        });

        // @Form
        // @Description: Set form fields

        // @General
        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));
        this.props.dispatch(change(form, 'identifire', this.state.entity.identifire ));

      }).catch((error) => {

        // @State
        // @Description:
        this.setState({ loading: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };

    // @Render
    render(){

      const { handleSubmit, invalid, t } = this.props;
      const { permission, name, loading, title, submited } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/providers"
                icon="back"
                size="medium"
              >
                {t('button.back')}
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              {/* Form Category */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* General */}
                  <Content marginBottom>
                    <Toolbar
                      title={t('text.general')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <Field
                          name="name"
                          component={ InputTextField }
                          label={t('field.name')}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <Field
                          name="identifire"
                          component={ InputTextField }
                          label={t('field.identifier')}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label={t('field.status')}
                          required
                        >
                          <OptionField value="true">{t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                    </Grid>
                  </Content>

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/providers"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      {t('button.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      {t('button.save')}
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__ProviderForm = reduxForm({
  form: 'Page__ProviderForm',
  validate,
  initialValues: {
    enabled: true
  }
})(Page__ProviderForm);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__ProviderForm));
