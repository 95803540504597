import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, query) {
  return request({
    url: `/shipping/order/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: query
  });
};

function orderSave(values) {
  return request({
    url:    '/shipping/order/save',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

function find(id) {
  return request({
    url: `/shipping/order/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function orderUpdate(id, values) {
  return request({
    url:    `/shipping/order/update/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'PUT',
    data:   values
  });
};

function orderRemove(id) {
  return request({
    url:    `/shipping/order/remove/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'DELETE'
  });
};

function eventsList(params) {
  return request({
    url:    '/shipping/event/list',
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: params
  });
};

function saveEvent(id, values) {
  return request({
    url:    `/shipping/order/event/save/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'PUT',
    data:   values
  });
};

function updateEvent(id, values) {
  return request({
    url:    `/shipping/order/event/update/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'PUT',
    data:   values
  });
};

function removeEvent(id, values) {
  return request({
    url:    `/shipping/order/event/remove/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'DELETE',
    data:   values
  });
};

function transportsList(params) {
  return request({
    url:    '/shipping/transport/list',
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: params
  });
};

function containersList(params) {
  return request({
    url:    '/shipping/container/list',
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: params
  });
};

function filterLocations(params) {
  return request({
    url:    '/shipping/location/filter',
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: params
  });
};

// @Locations

function locationList(page, query) {
  return request({
    url: `/shipping/location/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: query
  });
};

function locationFind(id) {
  return request({
    url: `/shipping/location/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function locationSave(values) {
  return request({
    url:    '/shipping/location/save',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

function locationUpdate(id, values) {
  return request({
    url:    `/shipping/location/update/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'PUT',
    data:   values
  });
};

function locationRemove(id) {
  return request({
    url:    `/shipping/location/remove/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'DELETE'
  });
};

const ShippingService = {
  list, orderSave, find, orderUpdate, orderRemove,
  eventsList, saveEvent, updateEvent, removeEvent,
  transportsList,
  containersList,
  filterLocations,
  locationList, locationFind, locationSave, locationUpdate, locationRemove
};

export default ShippingService;
