import axios     from 'axios';
import api       from './../../config/api';
import auth      from './../../services/auth';

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: api.endpoint.services
});

client.interceptors.request.use(function(config) {
  config.params = {
   api_key: api.endpoint.token,
    ...config.params,
  };
  return config;
});

/**
 * Request Wrapper with default success/error actions
 */
const request = function(options) {
  const onSuccess = function(response) {
    return response.data;
  }

  const onError = function(error) {

    if (error.response) {
      if(error.response.data.action){
        if(error.response.data.action === 'LOGOUT'){
          auth.signout();
          document.location.reload();
        }
      }
    }

    return Promise.reject(error.response || error.message);
  }

  return client(options)
            .then(onSuccess)
            .catch(onError);
}

export default request;
