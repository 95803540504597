import React from 'react';
import { Field } from 'redux-form';
import { InputAdornment } from './../../../assets/theme/input';
import { Toolbar, Button, Paper, Table, Row, Cell } from './../../../components/ui';
import { InputTextField } from './../../../components/ui/input';
import { ShippingService } from './../../../services';

class SessionsFieldArray extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      containers: [],
      loading: false,
      thead: [
        { id: 'sessions', name: 'Quantity' },
        { id: 'discount', name: 'Discount' }
      ]
    };
  };

  /**
  * @Event
  * @Description: Is invoked immediately after a component will recive props.
  */
  componentDidUpdate(prevProps, prevState){
    if(this.props.transport !== prevProps.transport){
      this._Find(this.props.transport);
    }
  };

  /*
  ** @Service
  ** @Description: List all containers
  */

  _Find = (id) => {
    ShippingService.containersList({transport:id}).then((response) => {
      this.setState({ containers: response.containers || [] });
    });
  };

  render(){

    const { fields } = this.props;
    const { thead } = this.state;
    
    return(
      <Paper>
        <Toolbar
          title="Discount intervals"
          size="small"
          dividerMobile
        >
          <Button icon="add" onClick={() => fields.push({})}>
            Agregar
          </Button>
        </Toolbar>
        <Paper disablePadding>
          <Table
            thead={thead}
            loading={true}
          >
            {fields.length > 0 ? (
              <React.Fragment>
                {fields.map((entity, index) => (
                  <Row key={index}>
                    <Cell>
                      <Field
                        name={`${entity}.sessions`}
                        component={ InputTextField }
                        type="number"
                        min={0}
                        required
                      />
                    </Cell>
                    <Cell>
                      <Field
                        name={`${entity}.discount`}
                        component={ InputTextField }
                        type="number"
                        min={0}
                        max={100}
                        required
                        endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                        }
                      />
                    </Cell>
                  </Row>
                ))}
              </React.Fragment>
            ) : (
              <Row>
                <Cell colSpan={thead.length} align="center">
                  Atención, no se encontraron sesiones configuradas, para eso puedes dar click en "Agregar".
                </Cell>
              </Row>
            )}
          </Table>
        </Paper>
      </Paper>
    )
  }

};

export default SessionsFieldArray;
