import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Table, Row, Cell, Currency, LinkProduct, Date } from './../../../../components/ui';
import { ItemService } from './../../../../services';

import TEXT from './../../../../config/text';

class UtilClientItem extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: false,
        entities: [],
        thead: [
          { id: 'sku', name: 'Sku' },
          { id: 'name', name: this.props.t('field.name') },
          { id: 'quantity', name: this.props.t('field.quantity') },
          { id: 'price', name: this.props.t('field.price') },
          { id: 'total', name: this.props.t('field.total') },
          { id: 'created_at', name: this.props.t('field.date') }
        ]
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._All();
    }

    /*
    ** @Service
    ** @Description: List
    */

    _All = () => {

      ItemService.listByClientId(this.props.id).then((response) => {

        this.setState({
          loading: true,
          entities: response.items
        });

      }).catch((error) => {

        this.setState({ loading: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: TEXT.message.error
        });

      });

    }


    render(){

      const { loading, entities, thead } = this.state;

      return(

        <Table
          thead={ thead }
          loading={ loading }
        >

          { entities.length > 0 && (

            entities.map(entity => {
              return (
                <Row key={ entity._id }>
                  <Cell>
                    { entity.product ? entity.product.sku ? entity.product.sku : '-' : '-' }
                  </Cell>
                  <Cell nowrap>
                    <LinkProduct product={ entity }/>
                  </Cell>
                  <Cell>
                    { entity.quantity }
                  </Cell>
                  <Cell>
                    <Currency value={ entity.unit_price }/>
                  </Cell>
                  <Cell>
                    <Currency value={ entity.total }/>
                  </Cell>
                  <Cell>
                    <Date value={entity.created_at}/>
                  </Cell>
                </Row>
              )
            })

          )}

          { (loading && entities.length === 0) && (
            <Row>
              <Cell colSpan={ thead.length } align="center">
                { TEXT.message.without_results }
              </Cell>
            </Row>
          )}

        </Table>

      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UtilClientItem));
