import React from 'react';
import { ProductService } from './../../../services';
import { FieldsCustom, Loading } from './../../../components/ui';

class PageProductField extends React.Component {

  constructor(props){
    super(props);
    this.state = {loading: true, fields: []};
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  }

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {
    this._Fields();
  };

  /*
  ** @Service
  ** @Description: Find Custom Fields
  */

  _Fields = () => {
    ProductService.fields().then((response) => {
      this.setState({ fields: response.fields, loading: false });
    }).catch((error) => {
      console.log(error);
    });
  };

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  onSubmit = (fields) => {
    this.props.onSubmit(fields);
  };

  render(){
    const { loading, fields } = this.state;

    return(
      !loading ? (
        <FieldsCustom
          fields={fields}
          currentValues={this.props.currentValues}
          onSubmit={this.onSubmit}
        />
      ) : (
        <Loading/>
      )

    )
  };

};

export default PageProductField;
