import React from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change  } from 'redux-form';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Typography } from './../../../assets/theme/default';
import { Grid } from './../../../assets/theme/layout';
import { Content, Button, ButtonsGroup, Loading, Date } from './../../../components/ui';
import { InputTextField, InputDateField, SelectField, OptionField } from './../../../components/ui/input';
import { LeadService } from './../../../services';
import { stringifyQueryString, parseQueryString } from './../../../utilities/utils';

import API from './../../../config/api';

class UtilLeadFilter extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      entity: null,
      sources: []
    };
  };

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  };

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {

    // @Service
    this._Sources();

    // @Search
    // @Description: Set query values
    const search = parseQueryString(this.props.location.search);

    Object.entries(search).forEach(([key,value]) => {
      this.props.dispatch(change(this.props.form, key, value));
    });

  };

  /*
  ** @Service
  ** @Description: Find Sources
  */

  _Sources = () => {

    LeadService.sources().then((response) => {

      // @State
      // @Description:
      this.setState({
        sources: response.sources
      });

    }).catch((error) => {

      // @Dispatch
      // @Description: alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: error.data.message
      });

    });

  };

  /*
  ** @Service
  ** @Description: Export link to download
  */

  _Export = (values) => {

    // @State
    // @Description: Set loading show
    this.setState({ loading: false });

    // @Service
    // @Description:
    LeadService.generateTokenReport(values).then((response) => {

      // @State
      // @Description: Set response
      this.setState({
        loading: false,
        entity: response
      });

    }).catch((error) => {

      // @State
      // @Description: Set loading hide
      this.setState({ loading: false });

      // @Dispatch
      // @Description: Show alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: error.data.message
      });

    });

  };

  /*
  ** @Service
  ** @Description: Filter
  */

  _Filter = (values) => {

    // @Redirect
    // @Description: Redirect with query filter
    this.props.history.push(`/leads?${ stringifyQueryString(values) }`);

    // @Props
    // @Description:
    this.props.onSuccess();

  };

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {

    // @If
    // @Description: Filter function
    if(values.action === 'filter'){
      this._Filter(values);
    }

    // @If
    // @Description: Export function
    if(values.action === 'export'){
      this._Export(values);
    }

  };

  /*
  ** @Event
  ** @Description: Copy link report
  */

  handleCopyLink = () => {

    // @Dispatch alert success
    this.props.alertShow({
      open: true,
      severity: 'info',
      message: this.props.t('text.download_link')
    });

  };

  /*
  ** @Event
  ** @Description: Back to form
  */

  handleBackToForm = () => {

    // @State
    // @Description: Set entity null
    this.setState({
      entity: null
    });

  };

  render() {

    const { handleSubmit, invalid } = this.props;
    const { loading, entity, sources } = this.state;

    return(
      !loading ? (
        !entity ? (
          <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

            <Content disablePadding divider>
              <Grid container spacing={3}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Field
                    name="date_from"
                    component={ InputDateField }
                    label={this.props.t('field.from')}
                    type="text"
                    required
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Field
                    name="date_to"
                    component={ InputDateField }
                    label={this.props.t('field.to')}
                    type="text"
                    disableFuture
                    required
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Field
                      name="name"
                      component={ InputTextField }
                      label={this.props.t('field.name')}
                      type="text"
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Field
                      name="surname"
                      component={ InputTextField }
                      label={this.props.t('field.surname')}
                      type="text"
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Field
                      name="email"
                      component={ InputTextField }
                      label="Email"
                      type="text"
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Field
                      name="source"
                      component={ SelectField }
                      label={this.props.t('field.campaign')}>
                        <OptionField value="none">{this.props.t('field.option_field.all')}</OptionField>
                        { sources.map((source, index) => {
                          return(
                            <OptionField key={ index } value={ source }>
                              { source }
                            </OptionField>
                          )
                        })}
                  </Field>
                </Grid>
              </Grid>
            </Content>

            <Content disablePadding>
              <ButtonsGroup align="right">
                <Button
                  aria-label="Exportar"
                  icon="export"
                  color="primary"
                  type="button"
                  disabled={ invalid }
                  onClick={
                    handleSubmit(values =>
                      this.handleSubmitForm({
                        ...values,
                        action: 'export'
                      }
                    ))
                  }
                >
                  {this.props.t('button.export')}
                </Button>
                <Button
                  aria-label="Confirmar"
                  icon="save"
                  color="primary"
                  type="button"
                  disabled={ invalid }
                  onClick={
                    handleSubmit(values =>
                      this.handleSubmitForm({
                        ...values,
                        action: 'filter'
                      }
                    ))
                  }
                >
                  {this.props.t('button.apply')}
                </Button>
              </ButtonsGroup>
            </Content>

          </form>
        ) : (
          <Content disablePadding disableMargin>

            <Content disablePadding divider>
              <Typography align="center" variant="body2">
                <strong>{this.props.t('text.download_link_ready1')},</strong>
                <br/>
                {this.props.t('text.download_link_ready2')}
                <br/>
                ({this.props.t('text.download_link_ready3')} <Date value={ moment.unix(entity.exp).format() } format="DD/MM/YYYY hh:mm a"/>)
              </Typography>
            </Content>

            <Content disablePadding>
              <ButtonsGroup align="center">
                <Button
                  color="primary"
                  size="medium"
                  component="a"
                  target="_blank"
                  href={ `${ API.endpoint.services }/lead/report/download/${ entity.token }` }
                >
                  {this.props.t('button.download')}
                </Button>
                <CopyToClipboard
                  text={ `${ API.endpoint.services }/lead/report/download/${ entity.token }` }
                  onCopy={ this.handleCopyLink }
                >
                  <Button color="primary" size="medium">
                    {this.props.t('button.copy')}
                  </Button>
                </CopyToClipboard>
                <Button
                  color="danger"
                  size="medium"
                  onClick={ this.handleBackToForm }
                >
                  {this.props.t('button.cancel')}
                </Button>
              </ButtonsGroup>
            </Content>

          </Content>
        )
      ) : (
        <Loading/>
      )
    )
  }

};

UtilLeadFilter = reduxForm({
  form: 'UtilLeadFilter',
  initialValues: {
    source: 'none',
    date_from: moment().subtract(7, 'days').startOf('day').format(),
    date_to: moment().endOf('day').format()
  },
  enableReinitialize: true
})(UtilLeadFilter);

export default withTranslation()(withRouter(UtilLeadFilter));
