import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Grid } from './../../../assets/theme/layout';
import { Page, Wrapper, Paper, Content, Loading, Alert, Button, ButtonsGroup, Toolbar, Data, Currency, LinkClient, LinkUser, Date } from './../../../components/ui';
import { PaymentService } from './../../../services';
import { CheckoutStatus, ShopCuponList } from './../../../scenes';
import { findPermissionProps, hasPermission } from './../../../utilities/utils';

import MercadoPagoPaymentDetail from './../../../pages/api/mercadopago/payment/detail';
import TodoPagoPaymentDetail from './../../../pages/api/todopago/payment/detail';
import ProductItemList from './../../product/items';

import API from './../../../config/api';
import TEXT from './../../../config/text';

class Page__PaymentDetail extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        loading: false,
        entity: null
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.payments, "Pagos") });

      this._Find(this.props.match.params.id);

    };

    /*
    ** @Service
    ** @Description: FindAll
    */

    _Find = (id) => {

      PaymentService.find(id).then((response) => {

        this.setState({ loading: true, entity: response.payment });

      }).catch((error) => {

        let message = TEXT.message.error;

        // @Error
        // @Description: Entitad no encontrada
        if(error.data.code === 'ENTITY_NOT_FOUNDED'){
          message = TEXT.message.without_results;
        }

        // @Error
        // @Description: Server error
        if(error.data.code === 'SERVER_ERROR'){
          message = TEXT.message.entity_not_founded;
        }

        // @Redirect
        this.props.history.push('/payments');

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: message
        });

      });

    };

    /*
    ** @Utils
    ** @Description: Return phone
    */
    handlePhone = (phone) => {
      let p = '';
      if(phone.area_code !== '' && phone.number !== ''){
        p = `(${ phone.area_code }) ${ phone.number }`;
        if(phone.extension !== ''){
          p = `${ p} [${ phone.extension }]`;
        }
      }
      return p;
    };

    render(){

      const { session, t  } = this.props;
      const { permission, name, loading, entity } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              subtitle={ loading ? entity.payment_code : '...' }
              dividerMobile
            >
              <ButtonsGroup>
                { (hasPermission(session.user.permissions, API.permissions.support) && loading) && (
                  <Button
                    component={ NavLink }
                    to={ `/support/create/${ entity.client._id }` }
                    icon="email"
                    size="medium"
                  >
                    {t('button.message')}
                  </Button>
                )}
                <Button
                  size="medium"
                  component={ NavLink }
                  to="/payments"
                  icon="back"
                >
                  {t('button.back')}
                </Button>
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

              { loading ? (

                <React.Fragment>

                  {/* Checkout Status */}
                  <CheckoutStatus status={ entity.payment_status }/>

                  {/* Client Information */}
                  <Content divider disableMargin>

                    <Toolbar
                      title={t('text.customer')}
                      size="small"
                    />

                    { entity.client ? (
                      <Grid container spacing={3}>
                        <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
                          <Data
                            label={`${t('field.name')}/${t('field.surname')}`}
                            text={ <LinkClient client={ entity.client }/> }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
                          <Data
                            label={t('field.email')}
                            text={ entity.client.email }
                            email
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
                          <Data
                            label={t('field.document_number')}
                            text={ entity.client.identifire }
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Alert severity="warning">
                        {t('messages.client_not_found')}
                      </Alert>
                    )}

                  </Content>

                  {/* Client Information */}
                  <Content divider disableMargin>

                    <Toolbar
                      title={t('text.user')}
                      size="small"
                    />

                    { entity.user ? (
                      <Grid container spacing={3}>
                        <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
                          <Data
                            label={`${t('field.name')}/${t('field.surname')}`}
                            text={ <LinkUser user={ entity.user }/> }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
                          <Data
                            label={t('field.email')}
                            text={ entity.user.email }
                            email
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Alert severity="warning">
                        {t('messages.user_not_found')}
                      </Alert>
                    )}

                  </Content>

                  {/* Payment Information */}
                  <Content divider disableMargin>
                    <Toolbar
                      title={t('text.payment')}
                      size="small"
                    />
                  <Grid container spacing={3}>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label={t('field.code')}
                          text={ entity.payment_code }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label={t('field.subtotal')}
                          text={ <Currency value={ entity.subtotal }/> }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label={t('field.discount')}
                          text={ <Currency value={ entity.discount }/> }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label={t('field.total')}
                          text={ <Currency value={ entity.total }/> }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label={t('field.date')}
                          text={ <Date value={ entity.created_at }/> }
                        />
                      </Grid>
                      { (entity.cupons && entity.cupons.length > 0) && (
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Data
                            label={t('field.coupons')}
                            text={<ShopCuponList entities={entity.cupons}/>}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Content>

                  {/* Platform Information */}
                  <Content divider disableMargin>

                    {/* Mercado Pago */}
                    { (entity.from === 'MERCADOPAGO' || entity.from === 'MERCADOPAGO_PREFERENCE') && (
                      <MercadoPagoPaymentDetail
                        payment={ entity.payment_id }
                      />
                    )}

                    {/* Todo Pago */}
                    { entity.from === 'TODOPAGO_PREFERENCE' && (
                      <TodoPagoPaymentDetail
                        payment={ entity.payment_id }
                      />
                    )}

                  </Content>

                  {/* Items */}
                  <Content>

                    <Toolbar
                      title={t('text.products')}
                      size="small"
                    />

                    { (entity.products && entity.products.length > 0) ? (
                      <ProductItemList entities={entity.products}/>
                    ) : (
                      <Alert severity="warning">
                        {t('messages.products_not_found')}
                      </Alert>
                    )}

                  </Content>

                </React.Fragment>

              ) : (

                <Loading/>

              )}

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__PaymentDetail));
