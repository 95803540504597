import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from './../../../../assets/theme/default';
import { TableRow } from './../../../../assets/theme/table';

const useStyles = makeStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.primary.ultraLight
    }
  }
}));

const Row = ({ children, ...rest}) => {

  const classes = useStyles();

  return (
    <TableRow className={ classes.root }>
      { children }
    </TableRow>
  );

}

Row.propTypes = {
  children: PropTypes.node
};

export default Row;
