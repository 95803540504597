
const paymentsFrom = function MercadoPagoPaymentsStatus () {

  let payments = [
    { id: 'MERCADOPAGO_PREFERENCE', name: 'Mercado Pago' },
    { id: 'TODOPAGO_PREFERENCE', name: 'Todo Pago' }
  ];

  return payments;

};

const isPaymentFrom = function checkPaymentFrom (value) {

  let status;

  if( value === 'MERCADOPAGO' || value === 'MERCADOPAGO_PREFERENCE' ) status = 'Mercado Pago';
  if( value === 'TODOPAGO_PREFERENCE' ) status = 'Todo Pago';
  if( value === 'STRIPE' ) status = 'Stripe';
  if( value === 'FISERV' ) status = 'First Data';

  return status;

}

const paymentsStatus = function MercadoPagoPaymentsStatus () {

  let status = [
    { id: 'pending', name: 'Pendiente', description: 'El usuario no completo el proceso de pago todavía.'},
    { id: 'approved', name: 'Aprobado', description: 'El pago fue aprobado y acreditado.'},
    { id: 'authorized', name: 'Autorizado', description: 'El pago fue autorizado pero no capturado todavía.'},
    { id: 'in_process', name: 'En Proceso', description: 'El pago está en revisión.'},
    { id: 'in_mediation', name: 'En Mediación', description: 'El usuario inició una disputa.'},
    { id: 'rejected', name: 'Rechazado', description: 'El pago fue rechazado. El usuario podría reintentar el pago.'},
    { id: 'cancelled', name: 'Cancelado', description: 'El pago fue cancelado por una de las partes o el pago expiró.'},
    { id: 'refunded', name: 'Devuelto', description: 'El pago fue devuelto al usuario.'},
    { id: 'charged_back', name: 'Contracargo', description: 'Se ha realizado un contracargo en la tarjeta de crédito del comprador.'}
  ];

  return status;

};

const isPaymentStatus = function checkMercadoPagoPaymentStatus (value) {

  let status = {};

  if( value === 'pending' ) status = { name: 'Pendiente', color: 'primary', description: 'El usuario no completo el proceso de pago todavía.'};
  if( value === 'approved' || value === 'complete') status = { name: 'Aprobado', color: 'success', description: 'El pago fue aprobado y acreditado.'};
  if( value === 'authorized' ) status = { name: 'Autorizado', color: 'success', description: 'El pago fue autorizado pero no capturado todavía.'};
  if( value === 'in_process' ) status = { name: 'En Proceso', color: 'info', description: 'El pago está en revisión.'};
  if( value === 'in_mediation' ) status = { name: 'En Mediación', color: 'warning', description: 'El usuario inició una disputa.'};
  if( value === 'rejected' ) status = { name: 'Rechazado', color: 'danger', description: 'El pago fue rechazado. El usuario podría reintentar el pago.'};
  if( value === 'cancelled' ) status = { name: 'Cancelado', color: 'danger', description: 'El pago fue cancelado por una de las partes o el pago expiró.'};
  if( value === 'refunded' ) status = { name: 'Devuelto', color: 'danger', description: 'El pago fue devuelto al usuario.'};
  if( value === 'charged_back' ) status = { name: 'Contracargo', color: 'warning', description: 'Se ha realizado un contracargo en la tarjeta de crédito del comprador.'};

  return status;

};

const isPaymentType = function checkPaymentFrom (value) {

  let method;

  if( value === 'account_money' ) method = 'Dinero en cuenta';
  if( value === 'ticket' ) method = 'Ticket impreso';
  if( value === 'bank_transfer' ) method = 'Transferencia bancaria';
  if( value === 'atm' ) method = 'ATM';
  if( value === 'credit_card' ) method = 'Tarjeta de crédito';
  if( value === 'debit_card' ) method = 'Tarjeta de débito';
  if( value === 'prepaid_card' ) method = 'Tarjeta prepaga';

  return method;

}

const isPaymentFeeType = function checkPaymentFrom (value) {

  let fee;

  if( value === 'mercadopago_fee' ) fee = 'Costo de usar Mercado Pago';
  if( value === 'coupon_fee' ) fee = 'Descuento otorgado por un cupón';
  if( value === 'financing_fee' ) fee = 'Costo de financiación';
  if( value === 'shipping_fee' ) fee = 'Costo de envío';
  if( value === 'application_fee' ) fee = 'Comisión de servicio del Marketplace';
  if( value === 'discount_fee' ) fee = 'Descuento otorgado por el vendedor absorviendo el costo';

  return fee;

}

const isPaymentFeePayer = function checkPaymentFrom (value) {

  let fee;

  if( value === 'collector' ) fee = 'El vendedor absorve el costo';
  if( value === 'payer' ) fee = 'El comprador absorve el costo';

  return fee;

}

export {
  paymentsFrom,
  isPaymentFrom,
  paymentsStatus,
  isPaymentStatus,
  isPaymentType,
  isPaymentFeeType,
  isPaymentFeePayer
}
