import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { makeStyles, Typography, Box } from './../../../../assets/theme/default';
import { FaceIcon } from './../../../../assets/theme/icon';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: 1,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  icon: {
    marginLeft: theme.spacing(1)
  }
}));

const LinkLead = ({ lead, ...rest }) => {

  const classes  = useStyles();

  return (
    lead ? (
      <Box className={classes.root}>
        <Typography
          className={ classNames(
            classes.root,
            classes.link
          )
          }
          component={ NavLink }
          to={ `/leads/detail/${ lead._id }` }
        >
          { lead.name } { lead.surname }
        </Typography>

        { lead.client && (
          <FaceIcon fontSize="small" classes={{root: classes.icon}} color="primary"/>
        )}
      </Box>
    ) : (
      <React.Fragment>
        <Typography className={ classes.root }>
          [No disponible]
        </Typography>
      </React.Fragment>
    )
  )

};

LinkLead.propTypes = {
  lead: PropTypes.object
};

export default LinkLead;
