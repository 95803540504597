import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles, Paper } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    //boxShadow: theme.shadows[1],
    border: `1px solid ${ theme.palette.border.main }`,
    overflow: 'hidden',
    //height: '100%'
  },
  disablePadding: {
    padding: 0
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  small: {
    padding: theme.spacing(1)
  },
  primary: {
    background: theme.palette.primary.light,
    border: `1px solid #e1e7f1`
  }
}));

const Paper__c = ({ children, variant, size, gutterTop, disablePadding, ...rest }) => {

  const classes  = useStyles();
  const _variant = variant ? classes[variant] : null;
  const _size    = size ? classes[size] : null;
  const _padding = disablePadding ? classes['disablePadding'] : null;
  const _maringTop = gutterTop ? classes['marginTop'] : null;

  return (
    <Paper { ...rest } classes={{
      root: classnames(
        classes.root,
        _padding,
        _variant,
        _size,
        _maringTop
      )
    }} elevation={0}>
      { children }
    </Paper>
  )

};

Paper__c.propTypes = {
  children: PropTypes.node.isRequired
};

export default Paper__c;
