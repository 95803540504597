import React, { useEffect, useMemo } from 'react';
import { throttle } from 'lodash';
import { makeStyles, Box } from './../../../assets/theme/default';
import { Autocomplete } from './../../../assets/theme/input';
import { FormLabelField, InputTextField, OptionAutocomplete } from './../../../components/ui/input';
import { Button, ButtonsGroup, Modal } from './../../../components/ui';
import { ClientService } from './../../../services';

import ComponentClientForm from './../../../pages/client/form/component';

const useStyles = makeStyles((theme) => ({
  root: {},
  left: {
    marginBottom: theme.spacing(0.5)
  },
  right: {},
  paper: {
    background: theme.palette.background.default,
    border: `1px solid ${ theme.palette.grey.main }`
  },
  inputRoot: {
    marginTop: `0 !important`,
    border: `1px solid ${ theme.palette.grey.main }`,
    background: '#fbfbfb',
    padding: `${ theme.spacing(1) }px ${ theme.spacing(5) }px ${ theme.spacing(1) }px ${ theme.spacing(1) }px !important`,
    borderRadius: `3px !important`,
    '&:before': {
      display: 'none !important'
    },
    '&:after': {
      display: 'none !important'
    }
  },
  inputInput: {
    padding: '0 !important'
  },
  inputEndAdornment: {
    right: theme.spacing(1)
  }
}));

const ClientAutocomplete = ({ client, label, required, onSelect, addNew = true, ...rest }) => {

  const classes = useStyles();
  const [formClient, setFormClient] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  // @Event
  // @Description: Form client
  const handleFormClient = () => {
    setFormClient(!formClient);
  };

  // @Event
  // @Description: onSubmit client
  const onSubmitFormClient = (client) => {

    setValue(client);
    setInputValue(client);
    onSelect(client);

    // @Hook
    // @Description: Close form
    setFormClient(!formClient);

  };

  // @Service
  // @Description: Filter clients
  const filter = (request, callback) => {
    ClientService.filter({ keyword: request.input }).then((response) => {
      return((request, callback(response.clients)))
    });
  };

  // @Handler
  // @Description: Build label
  const handleOptionLabel = (option) => {
    return `${ option.surname } ${ option.name } [${ option.email }]`;
  };

  // @Memo
  // @Description:
  const fetch = useMemo(() =>
      throttle((request, callback) => {
        filter(request, callback);
      }, 1000),
    [],
  );

  useEffect(() => {

    let active = true;

    if(client && inputValue === ''){
      setValue(client);
      setInputValue(client);
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => { active = false; };

  }, [value, inputValue, fetch, client]);

  return (
    <Box className={ classes.root }>
      <Box className={ classes.left }>
        <Autocomplete
          freeSolo
          classes={{
            paper: classes.paper,
            inputRoot: classes.inputRoot,
            input: classes.inputInput,
            endAdornment: classes.inputEndAdornment
          }}
          size="small"
          getOptionLabel={
            (option) => `${ option.surname } ${ option.name } [${ option.email }]`
          }
          filterOptions={(x) => x}
          options={options}
          value={value}
          autoComplete
          includeInputInList
          filterSelectedOptions
          onChange={(event, newInputValue) => {
            setOptions(newInputValue ? [newInputValue, ...options] : options);
            setValue(newInputValue);
            onSelect(newInputValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => {
            return(
              <React.Fragment>
                <FormLabelField required={required}>{label ? label : 'Cliente'}</FormLabelField>
                <InputTextField {...params} placeholder="Nombre, apellido o identificador"/>
              </React.Fragment>
            )
          }}
          renderOption={(option, { selected }) => (
            <OptionAutocomplete>
              { handleOptionLabel(option) }
            </OptionAutocomplete>
          )}
        />
      </Box>
      { addNew && (
        <Box className={ classes.right }>
          <ButtonsGroup align="right">
            { value ? (
              <Button
                color="primary"
                onClick={ handleFormClient }
              >
                Editar cliente
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={ handleFormClient }
              >
                Crear cliente
              </Button>
            )}
          </ButtonsGroup>

          {/* Add new Client */}
          <Modal
            title={ value ? `Editar cliente` : "Crear cliente" }
            status={ formClient }
            maxWidth="md"
            handleCancel={ handleFormClient }
          >
            <ComponentClientForm
              id={ value ? value._id : null }
              isModal={ true }
              onCancel={ handleFormClient }
              onSubmit={ onSubmitFormClient }
            />
          </Modal>
        </Box>
      )}
    </Box>
  );
}

export default ClientAutocomplete;
