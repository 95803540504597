import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Paper, Loading, Toolbar, Button, Alert, Table, Row, Cell, Date } from './../../../../components/ui';
import { FieldService } from './../../../../services';

import TEXT from './../../../../config/text';

class FieldCustomList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      thead: [
        { id: 'label', name: this.props.t('field.label') },
        { id: 'value', name: this.props.t('field.value'), align: 'right' }
      ],
      fields: []
    };
  };

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  };

  /*
  ** @Event
  ** @Description: Init
  */
  _Init = () => {
    this._Fields();
  };

  /*
  ** @Service
  ** @Description: Find Custom Fields
  */

  _Fields = () => {
    FieldService.find({ component: this.props.component }).then((response) => {
      this.setState({ fields: response.fields, loading: false });
    });
  };

  /*
  ** @Event
  ** @Description: Show Form Fields
  */

  handleField = () => {
    this.props.onOpen();
  };

  /*
  ** @Handler
  ** @Description: Show Form Fields
  */

  handleFindValueById = (field) => {
    let result = TEXT.message.not_available;
    if(this.props.currentValues && this.props.currentValues.length > 0){
      const find = this.props.currentValues.find((entity) => entity.entity === field._id);
      if(find){
        result = find.value;
        if(field.block){
          if(field.block.input){
            if(field.block.input.type && field.block.input.type === 'date'){
              result = <Date value={ result } />
            }
          }
        }
        if(field.caption){
          result = `${ result } ${field.caption}`;
        }
      }
    }
    return result;
  };

  render() {

    const { onOpen, t } = this.props;
    const { loading, fields, thead } = this.state;

    return(
      fields.length > 0 && (
        <React.Fragment>
          <Toolbar
            title={t('text.custom_fields')}
            size="small"
          >
            { onOpen && (
              <Button icon="edit" onClick={this.handleField}>
                {t('button.edit')}
              </Button>
            )}
          </Toolbar>
          { !loading ? (
              fields.length > 0 ? (
                <Paper disablePadding>
                  <Table
                    thead={ thead }
                    loading={true }
                  >
                    { fields.map(field => {
                      return(
                        <Row key={ field._id }>
                          <Cell>{ field.label }</Cell>
                          <Cell align="right">
                            { this.handleFindValueById(field) }
                          </Cell>
                        </Row>
                      )
                    })}
                  </Table>
                </Paper>
              ) : (
                <Alert severity="warning">
                  { TEXT.message.fields_not_founded }
                </Alert>
              )
          ) : (
            <Loading/>
          )}
        </React.Fragment>
      )
    )
  };

};

FieldCustomList.propTypes = {
  component: PropTypes.string.isRequired,
  currentValues: PropTypes.array.isRequired,
  onOpen: PropTypes.func
};

export default withTranslation()(FieldCustomList);
