import React from 'react';
import { Tooltip } from './../../../../assets/theme/default';
import { Tag, ButtonsGroup } from './../../../../components/ui';

import API from './../../../../config/api';

const UtilShopCuponList = ({ entities, ...rest }) => {
  return(
     (entities && entities.length > 0) ? (
      <ButtonsGroup>
        {entities.map(entity => {
          return (
            <Tooltip
              key={entity._id}
              title={`${entity.type === 'percentage' ? '%' : API.client.currency } ${entity.value}`}
              arrow
              placement="right"
              disableFocusListener
              disableTouchListener
            >
              <span style={{cursor:'default'}}>
                <Tag text={entity.name} color="primary"/>
              </span>
            </Tooltip>
          )
        })}
      </ButtonsGroup>
    ) : null
  )
};

export default UtilShopCuponList;
