import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function generateTokenReport(values) {
  return request({
    url:    '/newsletter/report/token',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

const NewsletterService = {
  generateTokenReport
};

export default NewsletterService;
