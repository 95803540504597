import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, query) {
  return request({
    url:    `/client/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: query
  });
};

function save(values) {
  return request({
    url:    '/client/save',
    method: 'POST',
    headers: {'Authorization': localStorageToken()},
    data:   values
  });
};

function update(id, values) {
  return request({
    url:    `/client/update/${ id }`,
    method: 'PUT',
    headers: {'Authorization': localStorageToken()},
    data:   values
  });
};

function active(id) {
  return request({
    url:    `/client/active/${ id }`,
    method: 'PUT',
    headers: {'Authorization': localStorageToken()}
  });
};

function find(id) {
  return request({
    url:    `/client/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function findByEntity(id) {
  return request({
    url:    `/client/entity/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function findByPublicAccess(params) {
  return request({
    url:    '/p/client/find',
    method: 'GET',
    params: params
  });
};

function filter(params) {
  return request({
    url:    '/client/filter',
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: params
  });
};

function remove(id) {
  return request({
    url:    `/client/remove/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'PUT'
  });
};

function eliminate(id) {
  return request({
    url:    `/client/eliminate/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'DELETE'
  });
};

function importMany(values) {
  return request({
    url:    '/client/import',
    method: 'POST',
    headers: {'Authorization': localStorageToken()},
    data:   values
  });
};

const ClientService = {
  list, save, update, active, eliminate, find, findByEntity, filter, remove, importMany,
  findByPublicAccess
};

export default ClientService;
