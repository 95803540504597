import request from './../../../utilities/request';
import localStorageToken from './../../../utilities/storage';

function paymentMethods() {
  return request({
    url:    '/mercadopago/payment-methods',
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function paymentById(id) {
  return request({
    url:    `/mercadopago/payment/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function preferencesList(page, query) {
  return request({
    url:    `/mercadopago/preferences/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params:  query
  });
}

function preferencesListByRole(role, id) {
  return request({
    url:    `/mercadopago/preferences/list/${ role }/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function preferenceById(id) {
  return request({
    url:    `/mercadopago/preference/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function preferenceStatusById(id, values) {
  return request({
    url:    `/mercadopago/preferences/status/${ id }`,
    method: 'POST',
    data: values
  });
}

function preferenceSave(values) {
  return request({
    url:    '/mercadopago/preferences/save',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data: values
  });
}

const MercadoPagoService = {
  paymentMethods,
  paymentById,
  preferencesList,
  preferencesListByRole,
  preferenceById,
  preferenceStatusById,
  preferenceSave
}

export default MercadoPagoService;
