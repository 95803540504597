
const categoriesTree = function BuildCategoriesTree (listOfCategories) {

  let label      = '-';
  let categories = [];

  if(listOfCategories.length > 0){

    label = '';

    categories = listOfCategories.reduce((accumulator, currentValue, index) => {

      // @If
      // @Description: Add next symbol
      if(index > 0){ label = `${ label } → `; }

      // @Value
      // @Description: Label value
      label = `${ label } ${ currentValue.name }`;

      // @Acumulator
      // @Description: Push Id value
      accumulator.push(currentValue._id);

      return accumulator;

    }, []);

  }

  // @Return
  // @Description:
  return { categories: categories, label: label };

};

export {
  categoriesTree
}
