import React from 'react';
import moment from 'moment';
import ReactMoment from 'react-moment';
import { Tooltip } from './../../../../assets/theme/default';

const Date = ({ value, format }) => {

  let f = format !== undefined ? format : "DD/MM/YYYY";

  return(
    <Tooltip title={ <ReactMoment format={"LLL"}>{ value }</ReactMoment> }>
      <span><ReactMoment format={f}>{ value }</ReactMoment></span>
    </Tooltip>
  )

}

const DateToString = (value, format) => {
  let f = format !== undefined ? format : "DD/MM/YYYY HH:mm:ss [hs.]";
  console.log(value);
  return moment(value).format(f);
}

export default Date;
export { DateToString };
