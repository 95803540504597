import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { JsonToTable } from 'react-json-to-table';
import { Typography } from './../../../../../assets/theme/default';
import { Grid } from './../../../../../assets/theme/layout';
import { Content, Loading, Toolbar, Alert, Data, Currency, Tag, Paper, Button, Modal } from './../../../../../components/ui';
import { MercadoPagoService } from './../../../../../services';
import { isPaymentType, isPaymentStatus, isPaymentFeeType, isPaymentFeePayer } from './../../../../../utilities/payment';

import TEXT from './../../../../../config/text';

class MercadoPagoPaymentDetail extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: false,
        error: false,
        errorMessage: null,
        entity: null,
        showDetail: false
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      // @Service
      // @Description:
      this._Find(this.props.payment);

    };

    // @Service
    // @Description: Find payment detail
    _Find = (id) => {

      MercadoPagoService.paymentById(id).then((response) => {

        // @State
        // @Description:
        this.setState({
          loading: true,
          entity: response.payment
        });

      }).catch((error) => {

        let message = TEXT.message.error;

        if(error.data.message){
          message = error.data.message;
        }

        // @State
        // @Description:
        this.setState({
          loading: true,
          error: true,
          errorMessage: message
        });

      });

    };

    // @Handler
    // @Description: Toggle Mercado Pago Details
    handleToggleDetail = (status) => {
      this.setState({showDetail:status});
    };

    render(){

      const { loading, error, errorMessage, entity, showDetail } = this.state;

      return(

        <React.Fragment>

          <Toolbar
            title="Mercado Pago"
            size="small"
            dividerMobile
          >
            <Button
              onClick={() => this.handleToggleDetail(true)}
              disabled={!loading || error}
              icon="info"
            >
              Detalle
            </Button>
          </Toolbar>

          { loading ? (

            <React.Fragment>

              { !error ? (

                <React.Fragment>
                  <Modal
                    status={showDetail}
                    title={`Detalle del pago: ${entity.id}`}
                    handleCancel={() => this.handleToggleDetail(false)}
                    maxWidth="md"
                  >
                     <JsonToTable json={entity}/>
                  </Modal>
                  <Content disablePadding marginBottom>

                    <Grid container spacing={3}>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label="Método de pago"
                          text={ isPaymentType(entity.payment_type_id) }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label="Orden"
                          text={ entity.order.id }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label="Cuenta"
                          text={ entity.statement_descriptor ? entity.statement_descriptor : "-" }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label="Estado"
                          text={
                            <Tag
                              text={ isPaymentStatus(entity.status).name }
                              color={ isPaymentStatus(entity.status).color }
                            />
                          }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label="Total (abonado)"
                          text={
                              <Currency
                                value={ entity.transaction_details.total_paid_amount }
                              />
                          }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label="Total (neto)"
                          text={
                              <Currency
                                value={ entity.transaction_details.net_received_amount }
                              />
                          }
                        />
                      </Grid>
                    </Grid>

                  </Content>

                  {/* Payer */}
                  { entity.payer && (
                    <Content disablePadding marginBottom>

                      <Toolbar
                        title="Usuario"
                        size="xsmall"
                      />

                      <Grid container spacing={3}>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Nombre/Apellido"
                            text={ `${ entity.payer.first_name } ${ entity.payer.last_name }` }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Identificación"
                            text={ `${ entity.payer.identification.type } ${ entity.payer.identification.number }` }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Email"
                            text={ entity.payer.email }
                            email
                          />
                        </Grid>
                      </Grid>

                    </Content>
                  )}

                  {/* Credit Card */}
                  { entity.payment_type_id === 'credit_card' && (
                    <Content disablePadding marginBottom>

                      <Toolbar
                        title="Tarjeta"
                        size="xsmall"
                      />

                      <Grid container spacing={3}>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Nombre/Apellido"
                            text={ entity.card.cardholder.name }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Identificación"
                            text={ `${ entity.card.cardholder.identification.type } ${ entity.card.cardholder.identification.number }` }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Fecha de vencimiento"
                            text={ `${ entity.card.expiration_month }/${ entity.card.expiration_year }` }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Últimos 4 dígitos"
                            text={ `${ entity.card.last_four_digits } (${ entity.payment_method_id })` }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Cuotas"
                            text={ entity.installments }
                          />
                        </Grid>
                      </Grid>

                    </Content>
                  )}

                  {/* Fee details */}
                  { entity.fee_details.length > 0 && (
                    <Content disablePadding>

                      <Toolbar
                        title="Costos"
                        size="xsmall"
                      />

                      <Grid container spacing={3}>

                        { entity.fee_details.map((fee, index) => {
                          return (
                            <Grid item xl={4} lg={4} md={4} sm={4} xs={6} key={ index }>
                              <Paper>
                                <Typography variant="subtitle2" color="primary" display="block" gutterBottom>
                                  { isPaymentFeeType(fee.type) }
                                </Typography>
                                <Typography variant="h5">
                                  <Currency
                                    value={ fee.amount }
                                  />
                                </Typography>
                                <Typography variant="caption" color="secondary" display="block">
                                  { isPaymentFeePayer(fee.fee_payer) }
                                </Typography>
                              </Paper>
                            </Grid>
                          )
                        })}

                      </Grid>

                    </Content>
                  )}

                </React.Fragment>

              ) : (

                <Alert severity="warning">
                  { errorMessage }
                </Alert>

              )}

            </React.Fragment>

          ) : (

            <Loading/>

          )}

        </React.Fragment>

      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

MercadoPagoPaymentDetail.propTypes = {
  payment: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MercadoPagoPaymentDetail);
