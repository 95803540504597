import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { throttle } from 'lodash';
import { makeStyles, Box } from './../../../assets/theme/default';
import { Autocomplete } from './../../../assets/theme/input';
import { FormLabelField, InputTextField, OptionAutocomplete } from './../../../components/ui/input';
import { StoreService } from './../../../services';
import { findPermissionProps } from './../../../utilities/utils';

import API from './../../../config/api';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    background: theme.palette.background.default,
    border: `1px solid ${ theme.palette.grey.main }`
  },
  inputRoot: {
    marginTop: `0 !important`,
    border: `1px solid ${ theme.palette.grey.dark }`,
    background: '#fafbfc',
    padding: `${ theme.spacing(1) }px ${ theme.spacing(5) }px ${ theme.spacing(1) }px ${ theme.spacing(1) }px !important`,
    borderRadius: `3px !important`,
    '&:before': {
      display: 'none !important'
    },
    '&:after': {
      display: 'none !important'
    }
  },
  inputInput: {
    padding: '0 !important'
  },
  inputEndAdornment: {
    right: theme.spacing(1)
  }
}));

const StoreAutocomplete = ({ store, label, required, onSelect, session, ...rest }) => {

  const classes = useStyles();
  const [permission, setPermission] = React.useState(null);
  const [init, setInit] = React.useState(true);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  // @Service
  // @Description: Filter locations
  const filter = (request, callback) => {
    StoreService.filter({ keyword: request.input }).then((response) => {
      return((request, callback(response.stores)))
    });
  };

  // @Handler
  // @Description: Build label
  const handleOptionLabel = (option) => {
    return option.name;
  };

  // @Memo
  // @Description:
  const fetch = useMemo(() =>
      throttle((request, callback) => {
        filter(request, callback);
      }, 1000),
    [],
  );

  useEffect(() => {

    setPermission(findPermissionProps(session, API.permissions.store, "Sucursales"));

    let active = true;

    if(store && inputValue === ''){
      setValue(store);
      setInit(false);
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if(init){
      fetch({ input: inputValue }, (results) => {
        if (active) {
          let newOptions = [];
          if (value) {
            newOptions = [value];
          }
          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      });
    }

    return () => { active = false; };

  }, [value, inputValue, fetch, store, init, session]);

  return (
    <Box className={ classes.root }>
      <Autocomplete
        freeSolo
        classes={{
          paper: classes.paper,
          inputRoot: classes.inputRoot,
          input: classes.inputInput,
          endAdornment: classes.inputEndAdornment
        }}
        size="small"
        getOptionLabel={
          (option) => { return option.name; }
        }
        filterOptions={(x) => x}
        options={options}
        value={value}
        autoComplete
        includeInputInList
        filterSelectedOptions
        onChange={(event, newInputValue) => {
          setOptions(newInputValue ? [newInputValue, ...options] : options);
          setValue(newInputValue);
          onSelect(newInputValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInit(true);
          setInputValue(newInputValue);
        }}
        renderInput={(params) => {
          return(
            <React.Fragment>
              <FormLabelField required={required}>
                { label ? (
                  <React.Fragment>
                    {label}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {permission ? permission.name : "Sucursales"}
                  </React.Fragment>
                )}
              </FormLabelField>
              <InputTextField {...params} placeholder="Ingresar nombre..." />
            </React.Fragment>
          )
        }}
        renderOption={(option, { selected }) => (
          <OptionAutocomplete>
            { handleOptionLabel(option) }
          </OptionAutocomplete>
        )}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

export default connect(mapStateToProps)(StoreAutocomplete);
