import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { Grid } from './../../../../assets/theme/layout';
import { InputAdornment } from './../../../../assets/theme/input';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, ButtonsGroup } from './../../../../components/ui';
import { InputTextField, InputDateField, SelectField, OptionField } from './../../../../components/ui/input';
import { ShopCuponService } from './../../../../services';
import { findPermissionProps } from './../../../../utilities/utils';

import API from './../../../../config/api';
import TEXT from './../../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 1024){
    errors.name = TEXT.validation.maxlength(1024);
  }

  if (!values.code) {
    errors.code = TEXT.validation.required;
  }else if(values.code.length > 255){
    errors.code = TEXT.validation.maxlength(255);
  }

  if (values.description) {
    if (values.description.length > 1024) {
      errors.description = TEXT.validation.maxlength(1024);
    }
  }

  if (!values.value) {
    errors.value = TEXT.validation.required;
  }else if(values.value < 0){
    errors.value = TEXT.validation.minlength(0);
  }else if(values.type === 'percentage' && values.value > 100){
    errors.value = TEXT.validation.maxlength(100);
  }

  if (!values.value_min) {
    errors.value_min = TEXT.validation.required;
  }else if(values.value_min < 0){
    errors.value_min = TEXT.validation.minlength(0);
  }else if(values.type === 'amount' && Number(values.value_min) < Number(values.value)){
    errors.value_min = 'Importe mínimo de compra no puede ser menor al importe del cupón.'
  }

  if (values.uses_per_cupon) {
    if(values.uses_per_cupon < 0){
      errors.uses_per_cupon = TEXT.validation.minlength(0);
    }
  }

  if (!values.uses_per_client) {
    errors.uses_per_client = TEXT.validation.required;
  }else if(values.uses_per_client < 1){
    errors.uses_per_client = TEXT.validation.minlength(1);
  }else if(Number(values.uses_per_client) > Number(values.uses_per_cupon)){
    errors.uses_per_client = 'Usos por cliente no puede ser mayor a usos por cupón.'
  }

  return errors;

}

class Page__ShopCuponForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        title: '...',
        loading: true,
        submited: false,
        id: null,
        entity: null
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.shop_cupon, this.props.t('text.coupons')) });

      const id = this.props.match.params.id;

      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false, title: this.props.t('text.new_entry') });
      }

    };

    /*
    ** @Service
    ** @Description: Save Entity
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      ShopCuponService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        // @Redirect
        this.props.history.push('/cupons');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Entity
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      ShopCuponService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        // @Redirect
        this.props.history.push('/cupons');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Entity
    */

    _Find = (id) => {

      // @Service
      ShopCuponService.find(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set note update into state
        this.setState({
          loading: false,
          id: response.cupon._id,
          entity: response.cupon,
          title: response.cupon.name
        });

        // @Form
        // @Description: Set form fields

        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'description', this.state.entity.description ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));
        this.props.dispatch(change(form, 'code', this.state.entity.code ));
        this.props.dispatch(change(form, 'type', this.state.entity.type ));
        this.props.dispatch(change(form, 'value', this.state.entity.value ));
        this.props.dispatch(change(form, 'value_min', this.state.entity.value_min ));
        this.props.dispatch(change(form, 'value_max', this.state.entity.value_max ));
        this.props.dispatch(change(form, 'uses_per_cupon', this.state.entity.uses_per_cupon ));
        this.props.dispatch(change(form, 'uses_per_client', this.state.entity.uses_per_client ));
        this.props.dispatch(change(form, 'exclude_offer', this.state.entity.exclude_offer ));

        if(this.state.entity.start_at){
          this.props.dispatch(change(form, 'start_at', this.state.entity.start_at ));
        }

        if(this.state.entity.end_at){
          this.props.dispatch(change(form, 'end_at', this.state.entity.end_at ));
        }

      }).catch((error) => {

        // @State
        // @Description:
        this.setState({ loading: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };

    // @Render
    render(){

      const { handleSubmit, invalid, type, t } = this.props;
      const { permission, name, loading, title, submited } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/cupons"
                icon="back"
                size="medium"
              >
                {t('button.back')}
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              {/* Form Category */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* General */}
                  <Content marginBottom>
                    <Toolbar
                      title={t('text.general')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <Field
                          name="name"
                          component={ InputTextField }
                          label={t('field.name')}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <Field
                          name="code"
                          component={ InputTextField }
                          label={t('field.code')}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label={t('field.status')}
                          required
                        >
                          <OptionField value="true">{t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="description"
                          component={ InputTextField }
                          label={t('field.description')}
                          type="text"
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Configuration */}
                  <Content marginBottom>
                    <Toolbar
                      title={t('text.configuration')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Field
                          name="type"
                          component={ SelectField }
                          label={t('field.type')}
                          required
                        >
                          <OptionField value="percentage">{t('field.option_field.percentage')}</OptionField>
                          <OptionField value="amount">{t('field.option_field.fixed_amount')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Field
                          name="value"
                          component={ InputTextField }
                          label={t('field.coupon_amount')}
                          type="number"
                          required
                          startAdornment={
                            <InputAdornment position="start">{ type === 'percentage' ? '%' : API.client.currency }</InputAdornment>
                          }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Field
                          name="value_min"
                          component={ InputTextField }
                          label={t('field.purchase_min_amount')}
                          type="number"
                          required
                          startAdornment={ <InputAdornment position="start">{ API.client.currency }</InputAdornment> }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Field
                          name="exclude_offer"
                          component={ SelectField }
                          label={t('field.exclude_offers')}
                          required
                        >
                          <OptionField value="true">{t('field.option_field.yes')}</OptionField>
                          <OptionField value="false">{t('field.option_field.no')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Field
                          name="uses_per_cupon"
                          component={ InputTextField }
                          label={t('field.uses_per_coupon')}
                          type="number"
                          min={1}
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Field
                          name="uses_per_client"
                          component={ InputTextField }
                          label={t('field.uses_per_client')}
                          type="number"
                          min={1}
                          required
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Field
                          name="start_at"
                          component={ InputDateField }
                          label={t('field.start_date')}
                          type="text"
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                        <Field
                          name="end_at"
                          component={ InputDateField }
                          label={t('field.end_date')}
                          type="text"
                        />
                      </Grid>
                    </Grid>
                  </Content>

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/cupons"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      {t('button.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      {t('button.save')}
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__ShopCuponForm = reduxForm({
  form: 'Page__ShopCuponForm',
  validate,
  initialValues: {
    enabled: true,
    exclude_offer: true,
    type: 'percentage',
    uses_per_client: 1
  }
})(Page__ShopCuponForm);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

const selector = formValueSelector('Page__ShopCuponForm');

Page__ShopCuponForm = connect(
  state => {
    const type = selector(state, 'type') || [];
    return {
      type
    }
  }
)(Page__ShopCuponForm);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__ShopCuponForm));
