import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Avatar } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  small:  { width: theme.spacing(4), height: theme.spacing(4), fontSize: '0.875rem' },
  medium: { width: theme.spacing(5), height: theme.spacing(5), fontSize: '1rem' },
  large:  { width: theme.spacing(8), height: theme.spacing(8), fontSize: '1.375rem' },
  xlarge: { width: theme.spacing(12), height: theme.spacing(12), fontSize: '2rem' }
}));

const Avatar__c = ({ children, src, alt, size = 'medium', ...rest }) => {

  const classes = useStyles();

  return(
     <Avatar alt={ alt } src={ src } className={ classNames( classes[size] ) }>
      { children }
     </Avatar>
  )

}

Avatar__c.propTypes = {
  children:  PropTypes.node,
  src:  PropTypes.string,
  alt:  PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge'])
};

export default Avatar__c;
