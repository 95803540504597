import React from 'react';
import { Grid } from './../../../../assets/theme/layout';
import { Content, Paper, Toolbar, Table, Row, Cell, Date, Data, LinkClient, LinkEntity } from './../../../../components/ui';
import { FieldCustomList } from './../../../../scenes';
import { isUndefined, isBinary } from './../../../../utilities/utils';

import API from './../../../../config/api';
import TEXT from './../../../../config/text';

class ShippingDetail extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      id: null,
      loading: false,
      thead: [
        { id: 'container', name: 'Equipo' },
        { id: 'enrollment', name: 'Nro. equipo' },
        { id: 'weight', name: 'Peso' },
        { id: 'volume', name: 'Volumen' }
      ]
    };
  };

  render(){

      const { entity } = this.props;
      const { loading, thead } = this.state;

      return(
        <Content disableMargin>

          {/* Configuration */}
          <Content marginBottom disablePadding>
            <Paper>
              <Grid container spacing={3}>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Data
                    label="ETD"
                    text={(entity && entity.etd) ? <Date value={entity.etd}/> : '-'}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Data
                    label="Origen"
                    text={(entity && entity.from) ? entity.from.name : '-'}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Data
                    label="ETA"
                    text={(entity && entity.eta) ? <Date value={entity.eta}/> : '-'}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Data
                    label="Destino"
                    text={(entity && entity.to) ? entity.to.name : '-'}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Content>

          {/* General */}
          <Content divider disablePadding>
            <Toolbar
              title="General"
              size="small"
            />
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="OV"
                  text={entity.code_internal}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="Reserva"
                  text={entity.code_external}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="Estado"
                  text={entity.event.name}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="Consolidado"
                  text={isBinary(entity.consolidated)}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="Empresa"
                  text={<LinkEntity entity={entity.entity}/>}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="Cliente"
                  text={entity.client ? <LinkClient client={entity.client}/> : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="Exportador"
                  text={entity.exporter ? <LinkEntity entity={entity.exporter}/> : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="Importador"
                  text={entity.importer ? <LinkEntity entity={entity.importer}/> : ''}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="MBL número"
                  text={entity.mbl_number ? entity.mbl_number : ''}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="MBL express"
                  text={entity.mbl_express ? isBinary(entity.mbl_express) : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="HBL número"
                  text={entity.hbl_number ? entity.hbl_number : ''}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="MBL express"
                  text={entity.hbl_express ? isBinary(entity.hbl_express) : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="Agente"
                  text={(entity && entity.store) ? entity.store.name : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Data
                  label="ATA"
                  text={(entity && entity.provider) ? entity.provider.name : '-'}
                />
              </Grid>
            </Grid>
          </Content>

          {/* Configuration */}
          <Content disablePadding marginBottom>
            <Toolbar
              title="Detalle de embarque"
              size="small"
            />
            <Grid container spacing={3}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Data
                  label="Mercadería"
                  text={entity.content ? entity.content : '-'}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Data
                  label="Observación"
                  text={entity.observation ? entity.observation : '-'}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Data
                  label="Referencia cliente"
                  text={entity.reference ? entity.reference : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="Transporte"
                  text={entity.transport ? entity.transport.name : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="Peso"
                  text={!isUndefined(entity.weight) ? `${entity.weight} Kgs.` : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="Temperatura"
                  text={!isUndefined(entity.temperature) ? `${entity.temperature} ºC` : '-'}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                <Data
                  label="Volumen"
                  text={!isUndefined(entity.volume) ? `${entity.volume} m³` : '-'}
                />
              </Grid>
            </Grid>
          </Content>

          {/* Containers */}
          <Content divider={entity.fields && entity.fields.length > 0} disablePadding>
            <Paper>
              <Toolbar
                title="Equipos"
                subtitle="Configuración de equipos disponibles en la reserva."
                size="small"
              />
              <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Paper disablePadding>
                    <Table
                      thead={ thead }
                      loading={true}
                    >
                      { entity.containers.length > 0 && (
                        entity.containers.map(container => {
                          return (
                            <Row key={container._id}>
                              <Cell>{container.entity ? container.entity.name : '-'}</Cell>
                              <Cell>{container.enrollment ? container.enrollment : '-'}</Cell>
                              <Cell>{container.weight ? `${container.weight} Kgs.` : '-'}</Cell>
                              <Cell>{container.volume ? `${container.volume} m³` : '-'}</Cell>
                            </Row>
                          )
                        })
                      )}
                      { (loading && entity.containers.length === 0) && (
                        <Row>
                          <Cell colSpan={ thead.length } align="center">
                            { TEXT.message.without_results }
                          </Cell>
                        </Row>
                      )}
                    </Table>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Content>

          {/* Fields custom */}
          <Content disablePadding>
            <Grid container spacing={3}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <FieldCustomList
                  component={API.permissions.shipping}
                  currentValues={entity.fields}
                />
              </Grid>
            </Grid>
          </Content>

        </Content>
      )
  };

};

export default ShippingDetail;
