import React from 'react';
import { connect } from 'react-redux';
import { Snackbar } from './../../../../assets/theme/default';
import Alert from './../default'

class Snackbar__c extends React.Component {

  handleClose = (event, reason) => {
    this.props.alertHide();
  };

  render() {

    const { alert } = this.props;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={ alert.open }
        autoHideDuration={6000}
        onClose={ this.handleClose }
      >
        <Alert onClose={ this.handleClose } severity={ alert.severity } elevation={1}>
          { alert.message }
        </Alert>
      </Snackbar>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    alert: state.alertState
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    alertHide: (data) => {
      dispatch({
        type: 'ALERT_HIDE',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar__c);
