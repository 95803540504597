import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Wrapper, Paper, Jumbotron, Button } from './../../../components/ui';

const PageNotFound = props => {
  const { t } = useTranslation();
  return (
    <Wrapper gutterTop>
      <Paper disablePadding>
        <Jumbotron
          title={t('messages.404')}
          subtitle={`Hay algun problema con la página que estas buscando. <br/>Es posible que haya sido eliminada, que la dirección no exista o que no tengas permisos para acceder.`}
          actions={
            <Button
              component={ NavLink }
              to="/"
            >
              Continuar
            </Button>
          }
        />
      </Paper>
    </Wrapper>
  )
}

export default PageNotFound;
