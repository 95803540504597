import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Box, Snackbar } from './../../../assets/theme/default';
import { Table, Row, Cell, Button, Jumbotron, Currency, Tag, Alert } from './../../../components/ui';
import { ProductService } from './../../../services';
import { isStatus, isStatusColor, truncate } from './../../../utilities/utils';
import CategorySelector from './../../category/selector';

import TEXT from './../../../config/text';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  toolbar: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 2
  },
  content: {
    flex: 1,
    overflowY: 'auto'
  }
});

class Products__Selector extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      entities: [],
      category: null,
      thead: [
        { id: 'sku', name: 'Sku' },
        { id: 'name', name: 'Nombre' },
        { id: 'sessions', name: 'Sesiones' },
        { id: 'price', name: 'Precio' },
        { id: 'enabled', name: 'Estado' },
        { id: 'actions', name: 'Acciones', align: 'right' }
      ],
      toolbarHeight: 103,
      alertStatus: false,
      alertMessage: '',
      alertSeverity: 'success'
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){

  }

  /*
  ** @Service
  ** @Description: List all categories
  */

  _Find = (category) => {

    ProductService.listByCategoryId(category._id).then((response) => {

      // @State
      this.setState({
        loading: true,
        category: category,
        entities: response.products
      });

      //this.handleDimensions();

      // @Props
      // @Description: Send category to parent
      if(this.props.onSelectCategory){
        this.props.onSelectCategory(category);
      }

    }).catch((error) => {

      // @State
      // @Description: Alert error
      this.setState({
        alertStatus: true,
        alertMessage: error.data.message ? error.data.message : TEXT.error.server
      });

    });

  }

  // @Event
  // @Description: On Change Categories
  handleChange = (category) => {
    this._Find(category);
  }

  // @Event
  // @Description: Send product to store
  handleSelect = (product) => {

    // @Params
    // @Description: Add custom params
    product.qty = 1;

    // @Dispatch
    // @Description: Send product to parent
    this.props.onSelect(product);

    // @State
    // @Description: Alert success

    this.handleOnCloseAlert();

    setTimeout(() => {
      this.setState({
        alertStatus: true,
        alertMessage: `El producto ${ product.name } fue agregado al resumen.`
      });
    }, 500);

  }

  // @Event
  // @Description: On Load Categories
  handleOnLoadCategory = (params) => {

    let ref = params.ref.getBoundingClientRect();

    if(ref){
      this.setState({ toolbarHeight: ref.height });
    }

  }

  // @Event
  // @Description: On Close Categories
  handleOnCloseCategory = () => {
    if(this.props.onClose){
      this.props.onClose();
    }
  }

  handleOnCloseAlert = (event, reason) => {
    this.setState({ alertStatus: false });
  };

  render() {

    const { classes } = this.props;
    const { loading, alertStatus, alertMessage, alertSeverity, entities, thead, category, toolbarHeight } = this.state;

    return(
      <Box className={ classes.root }>

        {/* Categories Selector Component */}
        <Box className={ classes.toolbar }>
          <CategorySelector
            onChange={ this.handleChange }
            onLoad={ this.handleOnLoadCategory }
            onClose={ this.handleOnCloseCategory }
          />
        </Box>

        <Box className={ classes.content } style={{ marginTop: toolbarHeight }}>
          { category ? (
            <Table
              thead={ thead }
              loading={ loading }
            >

              { entities.length > 0 && (

                entities.map(entity => {
                  return (
                    <Row key={ entity._id }>
                      <Cell nowrap>
                        { entity.sku ? entity.sku : '-' }
                      </Cell>
                      <Cell>{ truncate(entity.name, 80) }</Cell>
                      <Cell>
                        { entity.sessions && (
                          <React.Fragment>
                            { entity.sessions }
                          </React.Fragment>
                        )}
                      </Cell>
                      <Cell>
                        <Currency value={ entity.price }/>
                      </Cell>
                      <Cell>
                        <Tag
                          text={ isStatus(entity.enabled) }
                          color={ isStatusColor(entity.enabled) }
                        />
                      </Cell>
                      <Cell align="right">
                        { entity.enabled && (
                          <Button
                            color="primary"
                            icon="add"
                            title="Agregar al resumen"
                            placement="left"
                            onClick={ () => this.handleSelect(entity) }
                          />
                        )}
                      </Cell>
                    </Row>
                  )
                })

              )}

              { (loading && entities.length === 0) && (
                <Row>
                  <Cell colSpan={ thead.length } align="center">
                    No pudimos encontrar resultados
                  </Cell>
                </Row>
              )}

            </Table>
          ) : (
            <Jumbotron
              subtitle="Por favor, para comenzar selecciona una categoría."
            />
          )}
        </Box>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={ alertStatus }
          autoHideDuration={6000}
          onClose={ this.handleOnCloseAlert }
        >
          <Alert onClose={ this.handleOnCloseAlert } severity={ alertSeverity } elevation={1}>
            { alertMessage }
          </Alert>
        </Snackbar>

      </Box>
    )

  }

}

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Products__Selector));
