import request from './../../../utilities/request';
import localStorageToken from './../../../utilities/storage';

function generate(values) {
  return request({
    url:    '/token/generate',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

const TokenService = {
  generate
};

export default TokenService;
