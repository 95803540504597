import React from 'react';
import { makeStyles, Chip } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 500
  }
}));

const Chip__c = ({ children, ...rest }) => {

  const classes  = useStyles();

  return (
    <Chip size="small" { ...rest } classes={{ root: classes.root }} component="span"/>
  )

};

export default Chip__c;
