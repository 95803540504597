import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, query) {
  return request({
    url:    `/campaigns/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params:  query
  });
};

function find(id) {
  return request({
    url:    `/campaigns/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function save(values) {
  return request({
    url:    '/campaigns/save',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

function update(id, values) {
  return request({
    url:    `/campaigns/update/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'PUT',
    data:   values
  });
};

function remove(id) {
  return request({
    url:    `/campaigns/remove/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'DELETE'
  });
};

const CampaignsService = {
  list, find, save, update, remove
};

export default CampaignsService;
