import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles, Box } from './../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    width: 320,
    padding: theme.spacing(2),
    background: theme.palette.common.white,
    borderLeft: `1px solid ${ theme.palette.border.main }`
  },
  disablePadding: {
    padding: 0
  }
}));

const Sidebar = ({ children, disablePadding = false }) => {

  const classes = useStyles();
  const _padding = disablePadding ? classes['disablePadding'] : null;

  return (
    <Box className={ classnames(classes.root, _padding) }>
      { children }
    </Box>
  )

};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  disablePadding: PropTypes.bool
};

export default Sidebar;
