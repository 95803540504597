import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Box } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      '&:not(:last-child)': {
        marginRight: theme.spacing(0.5),
      //  marginBottom: theme.spacing(0.5)
      }
    }
  },
  left: { justifyContent: 'flex-start' },
  center: { justifyContent: 'center' },
  right: { justifyContent: 'flex-end' }
}));

const ButtonsGroup = ({children, align, ...rest}) => {

  const classes = useStyles();
  const _align  = align ? classes[align] : null;

  return (
    <Box className={ classNames(classes.root, _align) } component="span">
      { children }
    </Box>
  )

}

ButtonsGroup.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(['left', 'center', 'right'])
};


export default ButtonsGroup;
