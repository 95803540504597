import React from 'react';
import { withStyles, CircularProgress } from './../../../assets/theme/default';
import { TextField, Autocomplete } from './../../../assets/theme/input';
import { CategoryService } from './../../../services';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  paper: {
    background: theme.palette.background.default,
    border: `1px solid ${ theme.palette.grey.main }`
  },
  label: {
    fontWeight: 500,
    position: 'relative',
    transition: 'none',
    transform: 'none',
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.fontSize
  },
  inputRoot: {
    marginTop: `0 !important`,
    border: `1px solid ${ theme.palette.grey.dark }`,
    background: '#fafbfc',
    padding: `${ theme.spacing(1) }px ${ theme.spacing(5) }px ${ theme.spacing(1) }px ${ theme.spacing(1) }px !important`,
    borderRadius: `3px !important`,
    '&:before': {
      display: 'none !important'
    },
    '&:after': {
      display: 'none !important'
    }
  },
  inputInput: {
    padding: '0 !important'
  },
  inputEndAdornment: {
    right: theme.spacing(1)
  }
});

class CategoryAutocomplete extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      entities: [],
      selected: null,
      value: ''
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._All();
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component will recive props.
  */
  componentDidUpdate(prev_props){
    if(this.props.categories !== prev_props.categories){
      this.handleSelect(this.props.categories);
    }
  }

  /*
  ** @Service
  ** @Description: List all categories
  */

  _All = () => {
    CategoryService.all().then((response) => {
      this.handleOrderCategories(response.categories);
    });
  }

  /*
  ** @Function
  ** @Description:
  */

  handleOrderCategories = (categories) => {

    let tree = [];

    // @Each
    // @Description

    categories.forEach((firstCategory, index) => {

      let firstLabel = firstCategory.name;

      tree.push({
        _id: firstCategory._id,
        selected: [firstCategory],
        //name: firstCategory.name,
        label: firstLabel
      });

      // @Second Level
      if(firstCategory.children && firstCategory.children.length > 0){
        firstCategory.children.forEach((secondCategory, index) => {

          let secondLabel = `${ firstLabel } → ${ secondCategory.name }`;

          tree.push({
            _id: secondCategory._id,
            selected: [firstCategory, secondCategory],
            label: secondLabel
          });

          // @Third Level
          // @Description: hide third level
          if(!this.props.thirdLevelHide){
            if(secondCategory.children && secondCategory.children.length > 0){
              secondCategory.children.forEach((thirdCategory, index) => {

                let thirdLabel = `${ secondLabel } → ${ thirdCategory.name }`;

                tree.push({
                  _id: thirdCategory._id,
                  selected: [firstCategory, secondCategory, thirdCategory],
                  label: thirdLabel
                });

              });
            }
          }

        });

      }

    });

    // @State
    // @Description: Set state with categories
    this.setState({
      loading: false,
      entities: tree
    });

  }

  /*
  ** @Event
  ** @Description: on select category
  */

  handleSelect = (listOfCategories) => {

    if(listOfCategories.length > 0){

      let label = '';

      let categories = listOfCategories.reduce((accumulator, currentValue, index) => {

        // @If
        // @Description: Add next symbol
        if(index > 0){ label = `${ label } → `; }

        // @Value
        // @Description: Label value
        label = `${ label } ${ currentValue.name }`;

        // @Acumulator
        // @Description: Push Id value
        accumulator.push(currentValue._id);

        return accumulator;

      }, []);

      // @Store
      // @Description: Set store values
      this.setState({
        selected: { categories: categories, label: label }
      });

    }

  }

  /*
  ** @Event
  ** @Description: on change selection
  */

  handleOnChange = (value) => {

    // @If
    // @Description: If value !== null select category
    if (value) {

      this.handleSelect(value.selected);

    }else{

      // @Store
      // @Description: Set store selected null
      this.setState({ selected: null });

    }

    // @Props
    // @Description: Send value to parent
    this.props.onSelect(value);

  }

  /*
  ** @Event
  ** @Description: on input change
  */

  handleOnInputChange = (value) => {
    this.setState({ value: value });
  }

  /*
  ** @Render
  */

  render() {

    const { classes } = this.props;
    const { loading, entities, selected, value } = this.state;

    return(
      <Autocomplete
        loading={ loading }
        loadingText="Cargando..."
        classes={{
          paper: classes.paper,
          inputRoot: classes.inputRoot,
          input: classes.inputInput,
          endAdornment: classes.inputEndAdornment
        }}
        size="small"
        noOptionsText="No se encontraron opciones"
        options={ entities }
        value={ selected }
        onChange={(event, newInputValue) => {
          this.handleOnChange(newInputValue)
        }}
        inputValue={ value }
        onInputChange={(event, newInputValue) => {
          this.handleOnInputChange(newInputValue);
        }}
        getOptionLabel={
          (option) => option.label
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={this.props.t('field.category')}
            placeholder="Seleccionar..."
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.label
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    )
  }

}

export default withTranslation()(withStyles(styles)(CategoryAutocomplete));
