import React from 'react';
import _ from 'lodash';
import { Grid } from './../../../../assets/theme/layout';
import { Content, Data, Date, Image, Loading } from './../../../../components/ui';
import { FormService } from './../../../../services';
import UtilClientData from './../../../client/section/data';

class UtilRecordAnswer extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      fields: [],
      loading: false
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  }

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {
    if(this.props.entity){
      this._Find(this.props.entity._id);
    }
  };

  /*
  ** @Service
  ** @Description: Find Answer
  */

  _Find = (id) => {

    FormService.record(id).then((response) => {

      let order = [];

      if(response.record.fields.length > 0){
        order = _.orderBy(response.record.fields, ['entity.position'], ['asc']);
      }

      this.setState({
        fields: order,
        loading: true
      });

    }).catch((error) => {

      // @Dispatch alert error
      // this.props.alertShow({
      //   open: true,
      //   severity: 'error',
      //   message: error.data.message ? error.data.message : TEXT.message.error
      // });

    });

  };

  handleLabelOrigin = (field) => {
    if(field.entity.block.label){
      return field.entity.label;
    }else{
      return field.entity.block.name;
    }
  }

  handleTextOrigin = (field) => {
    if(field.entity.block.element === 'input'){
      if(field.entity.block.input.type === 'date'){
        return(
          <Date value={field.answer}/>
        )
      }else{
        return field.answer;
      }
    }else{
      return field.answer;
    }
  }

  render() {

    const { entity } = this.props;
    const { loading, fields } = this.state;

    return(
      <Content disablePadding>
        <Grid container spacing={3}>

          { entity.client && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <UtilClientData
                client={ entity.client }
                titlePersonal="Cliente"
                hideContact
                hideLocation
                hideConfiguration
                isModal
              />
            </Grid>
          )}

          { loading ? (
            fields.length > 0 && (
              fields.map(field => {
                return(
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} key={field._id}>
                    { field.entity.block.element === 'signature' ? (
                        <Data
                          label={this.handleLabelOrigin(field)}
                          text={<Image src={ field.answer } alt="signature"/>}
                        />
                    ) : (
                      <Data
                        label={this.handleLabelOrigin(field)}
                        text={this.handleTextOrigin(field)}
                      />
                    )}
                  </Grid>
                )
              })
            )
          ) : (
            <Loading variant="absolute"/>
          )}

        </Grid>
      </Content>
    )
  }

}

export default UtilRecordAnswer;
