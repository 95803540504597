import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, query) {
  return request({
    url:    `/payment/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: query
  });
}

function listByRoleAndId(role, id, page) {
  return request({
    url:    `/payment/list/${ role }/${ id }/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function find(id) {
  return request({
    url:    `/payment/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function generateTokenReport(values) {
  return request({
    url:    '/payment/report/token',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
}


const PaymentService = {
  list, listByRoleAndId, find, generateTokenReport
}

export default PaymentService;
