import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, Modal, AlertConfirm, Table, Row, Cell, Tag } from './../../../../components/ui';
import { ShippingService } from './../../../../services';
import { isUndefined, isStatus, isStatusColor, hasPermission, findPermissionProps, parseQueryString } from './../../../../utilities/utils';
import UtilShippingLocationFilter from './../filter';

import API from './../../../../config/api';
import TEXT from './../../../../config/text';

class Page__ShippingLocationList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        loading: false,
        page: 0,
        total: 0,
        id: null,
        entities: [],
        remove: false,
        thead: [
          { id: 'name', name: 'Nombre' },
          { id: 'country', name: 'País' },
          { id: 'prefix', name: 'Prefijo' },
          { id: 'enabled', name: 'Estado' },
          { id: 'actions', name: 'Acciones', align: 'right' }
        ],
        locationFilterShow: false
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.match.params.id !== prev_props.match.params.id){
        this._All(this.props.match.params.page || 0, this.props.match.params.id);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.shipping_location, "Puertos") });

      const page = this.props.match.params.page || 0;
      const id   = this.props.match.params.id;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          if(id){
            this._All(page, id);
          }else{
            this._All(page);
          }
        }else{
          this.props.history.push('/locations');
        }
      }else{
        this._All(this.state.page);
      }

    };

    /*
    ** @Service
    ** @Description: All Locations
    */

    _All = (page, id) => {

      // @State
      // @Description: Refresh after each service
      this.setState({ loading: false, entities: [] });

      // @Service
      ShippingService.locationList(page, parseQueryString(this.props.location.search)).then((response) => {

        // @State
        // @Descripcion: Set values
        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.locations,
          total: response.total
        });

      }).catch((error) => {

        // @State
        // @Descripcion: Loading disabled
        this.setState({ loading: true });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Remove Location
    */

    _Remove = (id) => {

      ShippingService.locationRemove(id).then((response) => {

        // @State
        this.setState({ remove: false });

        // @Service find all by page
        this._Init();

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

      }).catch((error) => {

        // @State
        this.setState({ remove: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description: Remove Location
    */

    handleRemove = (id) => {
      this.setState({ id: id, remove: true });
    };

    /*
    ** @Click
    ** @Description: Remove location confirm
    */

    handleConfirmRemoveConfirm = () => {
      this._Remove(this.state.id);
    };

    /*
    ** @Click
    ** @Description: Remove category cancel
    */

    handleConfirmRemoveCancel = () => {
      this.setState({ remove: false });
    };

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/locations/page/${ page }`,
        search: this.props.location.search
      });

    };

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ locationFilterShow: true });
    };

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ locationFilterShow: false });
    };

    render(){

      const { session } = this.props;
      const { permission, name, loading, entities, thead, total, page, remove, locationFilterShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <ButtonsGroup>

                { this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title="Eliminar filtros"
                    placement="left"
                    component={ NavLink }
                    to="/locations"
                  />
                )}
                <Button
                  icon="filter"
                  size="medium"
                  onClick={ this.handleFilter }
                >
                  Filtrar
                </Button>

                { hasPermission(session.user.permissions, API.permissions.shipping_location_management) && (
                  <Button
                    component={ NavLink }
                    to="/locations/create"
                    icon="add"
                    size="medium"
                  >
                    Crear
                  </Button>
                )}
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

                {/* Alert confirm Delete */}
                <AlertConfirm
                   status={ remove }
                   message="¿Confirma la eliminación?"
                   cancel={() => this.handleConfirmRemoveCancel()}
                   confirm={() => this.handleConfirmRemoveConfirm()}
                />

                {/* Filter */}
                <Modal
                    status={locationFilterShow}
                    title="Filtrar"
                    handleCancel={() => this.handleFilterClose()}
                    maxWidth="sm">
                    <UtilShippingLocationFilter onSuccess={this.handleFilterClose}/>
                </Modal>

                <Table
                  thead={ thead }
                  loading={ loading }
                  total={ total }
                  page={ page }
                  onChangePage={ this.handleChangePage }
                >

                  { entities.length > 0 && (

                    entities.map(entity => {
                      return (
                        <Row key={ entity._id }>
                          <Cell nowrap>
                            { entity.name }
                          </Cell>
                          <Cell nowrap>
                            { entity.country ? entity.country.name : TEXT.message.not_available }
                          </Cell>
                          <Cell>
                            { entity.prefix ? entity.prefix : TEXT.message.not_available }
                          </Cell>
                          <Cell>
                            <Tag
                              text={ isStatus(entity.enabled) }
                              color={ isStatusColor(entity.enabled) }
                            />
                          </Cell>
                          <Cell align="right">
                            { hasPermission(session.user.permissions, API.permissions.shipping_location_management) && (
                              <React.Fragment>
                                <Button
                                  color="primary"
                                  icon="edit"
                                  component={ NavLink }
                                  title="Editar"
                                  to={ `/locations/edit/${ entity._id }` }
                                />
                                <Button
                                  color="danger"
                                  icon="cancel"
                                  title="Eliminar"
                                  onClick={ () => this.handleRemove(entity._id)  }
                                />
                              </React.Fragment>
                            )}
                          </Cell>
                        </Row>
                      )
                    })

                  )}

                  { (loading && entities.length === 0) && (
                    <Row>
                      <Cell colSpan={ thead.length } align="center">
                        {TEXT.message.without_results}
                      </Cell>
                    </Row>
                  )}

                </Table>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page__ShippingLocationList);
