import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles, CircularProgress, Tooltip } from './../../../../assets/theme/default';
import { Button } from './../../../../assets/theme/button';
import { InputIcon, SyncIcon, AddCircleOutlineIcon, CheckCircleIcon, ArrowBackIcon, ArrowForwardIcon, CancelIcon, InfoIcon, LinkIcon, SendIcon, FilterListIcon, OpenInNewIcon, NotesIcon, PaymentIcon, LockIcon, ReorderIcon, ImportExportIcon, FaceIcon, AssignmentIcon, AssignmentTurnedInIcon, DesktopIcon, PersonPinIcon, FileCopyIcon, MailOutlineIcon, DeleteIcon, DeleteForeverIcon, CenterFocusStrongIcon } from './../../../../assets/theme/icon';
import ThemeCustom from './../../../../assets/theme/custom';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 'auto',
    whiteSpace: 'nowrap',
    textTransform: 'inherit',
    //lineHeight: 1,
    boxShadow: theme.shadows[1]
  },
  icon: {
    marginRight: theme.spacing(0.5)
  },
  marginIcon: {
    marginRight: 0
  },
  sizeIcon: {
    paddingLeft: 4,
    paddingRight: 4
  },
  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  primary: {
    color: theme.palette.primary.main,
    background: theme.palette.primary.light,
    '&:hover': {
      background: '#d5e3f9'
    }
  },
  secondary: {
    color: theme.palette.secondary.main,
    background: theme.palette.secondary.ultraLight,
    '&:hover': {
      background: theme.palette.secondary.light
    }
  },
  success: {
    color: ThemeCustom.palette.success.main,
    background: ThemeCustom.palette.success.ultraLight,
    '&:hover': {
      background: ThemeCustom.palette.success.light
    }
  },
  info: {
    color: ThemeCustom.palette.info.main,
    background: ThemeCustom.palette.info.ultraLight,
    '&:hover': {
      background: ThemeCustom.palette.info.light
    }
  },
  warning: {
    color: ThemeCustom.palette.warning.main,
    background: ThemeCustom.palette.warning.ultraLight,
    '&:hover': {
      background: ThemeCustom.palette.warning.light
    }
  },
  danger: {
    color: ThemeCustom.palette.danger.main,
    background: ThemeCustom.palette.danger.ultraLight,
    '&:hover': {
      background: ThemeCustom.palette.danger.light
    }
  },
  inherit: {
    color: ThemeCustom.palette.inherit.main,
    background: ThemeCustom.palette.inherit.ultraLight,
    '&:hover': {
      background: ThemeCustom.palette.inherit.light
    }
  },
  xsmall: theme.typography.subtitle2
}));

const variantIcon = {
  add: AddCircleOutlineIcon,
  save: CheckCircleIcon,
  edit: ReorderIcon,
  back: ArrowBackIcon,
  forward: ArrowForwardIcon,
  cancel: CancelIcon,
  info: InfoIcon,
  link: LinkIcon,
  send: SendIcon,
  filter: FilterListIcon,
  menu: NotesIcon,
  logout: OpenInNewIcon,
  payment: PaymentIcon,
  lock: LockIcon,
  export: ImportExportIcon,
  client: FaceIcon,
  records: AssignmentTurnedInIcon,
  answers: AssignmentIcon,
  desktop: DesktopIcon,
  lead: PersonPinIcon,
  copy: FileCopyIcon,
  email: MailOutlineIcon,
  delete: DeleteIcon,
  deleteForever: DeleteForeverIcon,
  code: CenterFocusStrongIcon,
  sync: SyncIcon,
  input: InputIcon
};

const Button__c = ({ children, variant = 'contained', color, size = 'small', icon, disabled, loading, loadingText, title, placement = "top", ...rest }) => {

  const classes = useStyles();
  const _color  = color ? classes[color] : classes['primary'];
  const _marginIcon  = (icon && !children) ? classes['marginIcon'] : null;
  const Icon    = icon ? variantIcon[icon] : null;

  const TooltipButton = title ? Tooltip : 'span';

  return (
    <TooltipButton
      title={ title ? title : null }
      placement={ placement ? placement : null }
    >
      <Button
          { ...rest }
          classes={{ root: classnames(classes.root, _color), startIcon: _marginIcon }}
          variant={ variant }
          size={ size }
          disabled={ disabled || loading }
          aria-label={ children }
          disableRipple
          disableElevation
          startIcon={ icon ? <Icon/> : null }
        >
          <span>
            { children }
            { loading && (
              <span className={ classes.loading }>
                <CircularProgress size={18}/>
              </span>
            )}
          </span>
      </Button>
    </TooltipButton>
  )

}

Button__c.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'inherit']),
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'fab'])
};

export default Button__c;
