import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, query) {
  return request({
    url:    `/integration/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params:  query
  });
};

const IntegrationLogService = {
  list
};

export default IntegrationLogService;
