import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Pagination } from './../../../../assets/theme/default';
import Content from './../../content';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    background: 'rgba(255,255,255,0.9)',
    borderTop: `1px solid ${ theme.palette.grey[200] }`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  align: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const Pagination__c = ({ count, ...rest }) => {

  const classes = useStyles();

  return (
    <Box className={ classes.root }>
      <Content>
        <Box className={ classes.align }>
          <Pagination count={ count } { ...rest }/>
        </Box>
      </Content>
    </Box>
  )

};

Pagination__c.propTypes = {
  count: PropTypes.number.isRequired
};

export default Pagination__c;
