const API = {
  endpoint: {
    app: process.env.REACT_APP_APP_HOST,
    services: process.env.REACT_APP_API_HOST,
    socket: process.env.REACT_APP_SOCKET_HOST,
    web: process.env.REACT_APP_WEB_HOST,
    uploads: process.env.REACT_APP_API_UPLOADS
  },
  default: {
    paginate_items: 10,
    // eslint-disable-next-line
    slider_sources: eval(process.env.REACT_APP_SLIDER_SOURCES) || []
  },
  permissions: {
    settings: "SETTINGS",
    users: "USERS",
    users_management: "USERS_MANAGEMENT",
    categories: "CATEGORIES",
    categories_management: "CATEGORIES_MANAGEMENT",
    products: "PRODUCTS",
    products_management: "PRODUCTS_MANAGEMENT",
    clients: "CLIENTS",
    clients_management: "CLIENTS_MANAGEMENT",
    leads: "LEADS",
    payments: "PAYMENTS",
    payments_management: "PAYMENTS_MANAGEMENT",
    entities: "ENTITIES",
    entities_management: "ENTITIES_MANAGEMENT",
    providers: "PROVIDERS",
    providers_management: "PROVIDERS_MANAGEMENT",
    api_mercado_pago: "API_MERCADO_PAGO",
    api_todo_pago: "API_TODO_PAGO",
    api_forms: "API_FORMS",
    blog: "BLOG",
    blog_management: "BLOG_MANAGEMENT",
    store: "STORE",
    store_management: "STORE_MANAGEMENT",
    franchise: "FRANCHISE",
    franchise_management: "FRANCHISE_MANAGEMENT",
    featured: "FEATURED",
    featured_management: "FEATURED_MANAGEMENT",
    slider: "SLIDER",
    slider_management: "SLIDER_MANAGEMENT",
    shipping: "SHIPPING",
    shipping_management: "SHIPPING_MANAGEMENT",
    shipping_location: "SHIPPING_LOCATION",
    shipping_location_management: "SHIPPING_LOCATION_MANAGEMENT",
    offer: "OFFER",
    offer_management: "OFFER_MANAGEMENT",
    support: "SUPPORT",
    support_management: "SUPPORT_MANAGEMENT",
    newsletter_management: "NEWSLETTER_MANAGEMENT",
    shop_cart: "SHOP_CART",
    shop_cart_management: "SHOP_CART_MANAGEMENT",
    shop_cupon: "SHOP_CUPON",
    shop_cupon_management: "SHOP_CUPON_MANAGEMENT",
    qr_code: "QR_CODE",
    qr_code_management: "QR_CODE_MANAGEMENT",
    clinicea_sync_users: "CLINICEA_SYNC_USERS",
    clinicea_sync_clients: "CLINICEA_SYNC_CLIENTS",
    clinicea_sync_products: "CLINICEA_SYNC_PRODUCTS",
    clinicea_get_bills: "CLINICEA_GET_BILLS",
    god: "GOD"
  },
  client: {
    id: process.env.REACT_APP_CLIENT_ID,
    name: process.env.REACT_APP_CLIENT_NAME,
    currency: process.env.REACT_APP_CLIENT_CURRENCY,
    max_discount_percentage: parseInt(process.env.REACT_APP_CLIENT_MAX_DISCOUNT_PERCENTAGE, 10)
  },
  store: {
      schedule: [
        { day: 'Domingo', open_at: '09:00', close_at: '20:00', enabled: false },
        { day: 'Lunes', open_at: '09:00', close_at: '20:00', enabled: true },
        { day: 'Martes', open_at: '09:00', close_at: '20:00', enabled: true },
        { day: 'Miércoles', open_at: '09:00', close_at: '20:00', enabled: true },
        { day: 'Jueves', open_at: '09:00', close_at: '20:00', enabled: true },
        { day: 'Viernes', open_at: '09:00', close_at: '20:00', enabled: true },
        { day: 'Sábado', open_at: '09:00', close_at: '20:00', enabled: true }
      ]
  },
  routes: {
    product: {
      index_path: process.env.REACT_APP_WEB_ROUTES_PRODUCT_INDEX_PATH
    }
  },
  palette: {
    background: process.env.REACT_APP_PALETTE_BACKGROUND,
    primary: {
      light: process.env.REACT_APP_PALETTE_PRIMARY_0,
      main: process.env.REACT_APP_PALETTE_PRIMARY_1,
      dark: process.env.REACT_APP_PALETTE_PRIMARY_2,
      contrast: process.env.REACT_APP_PALETTE_PRIMARY_CONTRAST
    },
    secondary: {
      light: process.env.REACT_APP_PALETTE_SECONDARY_0,
      main: process.env.REACT_APP_PALETTE_SECONDARY_1,
      dark: process.env.REACT_APP_PALETTE_SECONDARY_2,
      contrast: process.env.REACT_APP_PALETTE_SECONDARY_CONTRAST
    }
  },
  upload: {
    attachment_max_size: process.env.REACT_APP_ATTACHMENT_MAX_SIZE * 1024 * 1024,
    attachment_available_ext: ['.png', '.gif', '.jpg', '.jpeg', '.doc',
        '.xls', '.mpp', '.pdf', 'pptx', '.tiff', '.bmp', '.docx', '.xlsx',
        '.pptx', '.ps', '.odt', '.ods', '.odp', '.odg', '.csv', '.rar', '.zip', '.txt', '.mp4']
  },
  mercadopago: {
    env: process.env.REACT_APP_MERCADOPAGO_ENV,
    preference: {
      // eslint-disable-next-line
      expires: eval(process.env.REACT_APP_MERCADOPAGO_PREFERENCE_EXPIRES),
      expires_add_days: parseInt(process.env.REACT_APP_MERCADOPAGO_PREFERENCE_EXPIRES_ADD_DAYS, 10),
      // eslint-disable-next-line
      excluded_payments: eval(process.env.REACT_APP_MERCADOPAGO_PREFERENCE_EXCLUDED_PAYMENTS)
    }
  },
  todopago: {
    preference: {
      // eslint-disable-next-line
      installments: eval(process.env.REACT_APP_TODOPAGO_PREFERENCE_INSTALMENTS)
    }
  },
  integration: {
    clinicea: {
      /* eslint-disable no-eval */
      enabled: eval(process.env.REACT_APP_CLINICEA_ENABLED) || false
    }
  },
  whatsapp: {
    schedule: [
      { day: 'Domingo', open_at: '09:00', close_at: '20:00', enabled: false },
      { day: 'Lunes', open_at: '09:00', close_at: '20:00', enabled: true },
      { day: 'Martes', open_at: '09:00', close_at: '20:00', enabled: true },
      { day: 'Miércoles', open_at: '09:00', close_at: '20:00', enabled: true },
      { day: 'Jueves', open_at: '09:00', close_at: '20:00', enabled: true },
      { day: 'Viernes', open_at: '09:00', close_at: '20:00', enabled: true },
      { day: 'Sábado', open_at: '09:00', close_at: '20:00', enabled: true }
    ]
},
};

export default API;
