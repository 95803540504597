import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Typography, Divider, Box } from './../../../assets/theme/default';
import { ProductService } from './../../../services';
import { Loading, Alert } from './../../../components/ui';

import PageProductField from './index';

import TEXT from './../../../config/text';

class WrapPageProductField extends React.Component {

    constructor(props) {
        super(props);
        this.state = { loading: true, entity: null, errorMessage: null };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount() {
        this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {
        this._Fields();
    };

    /*
    ** @Service
    ** @Description: Find Custom Fields
    */

    _Fields = () => {
        ProductService.find(this.props.id).then((response) => {
            this.setState({ entity: response.product, loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    };

    /*
    ** @Submit
    ** @Description: Submit filter form
    */

    onSubmit = (fields) => {
        ProductService.update(this.props.id, { ...this.state.entity, fields }).then(() => {

            // @Dispatch alert success
            this.props.alertShow({
                open: true,
                severity: 'success',
                message: TEXT.message.success
            });

            if (this.props.onSuccess) {
                this.props.onSuccess();
            }

        }).catch((error) => {

            this.setState({ errorMessage: error.data.message ? error.data.message : TEXT.message.error });

        });
    };

    render() {
        const { t } = this.props;
        const { loading, entity, errorMessage } = this.state;

        return (
            !loading ? (
                entity ? (
                    <>
                        {errorMessage && (
                            <Alert severity="warning">
                                {errorMessage}
                            </Alert>
                        )}

                        <Box style={{ marginBottom: 12 }}>
                            <Typography variant="subtitle2" gutterBottom>
                                {entity.sku} - {entity.title}
                            </Typography>
                            <Divider />
                        </Box>

                        <PageProductField
                            currentValues={entity.fields}
                            onSubmit={this.onSubmit}
                        />
                    </>
                ) : (
                    <div>{t('text.error_default')}</div>
                )
            ) : (
                <Loading />
            )
        )

    };

};

const mapDispatchToProps = (dispatch) => {
    return {
        alertShow: (data) => {
            dispatch({
                type: 'ALERT_SHOW',
                data: data
            });
        }
    }
};

export default withTranslation()(connect(null, mapDispatchToProps)(WrapPageProductField));

