import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { MuiThemeProvider } from './assets/theme/default';

import Store from './stores';
import ThemeCustom from './assets/theme/custom';

import './assets/scss/index.scss';
import './i18n';

ReactDOM.render(
  <Provider store={ Store }>
    <IntlProvider locale="en">
      <MuiThemeProvider theme={ ThemeCustom }>
        <App />
      </MuiThemeProvider>
    </IntlProvider>
  </Provider>
  , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
