import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, Modal, AlertConfirm, Table, Row, Cell, Tag, LinkClient } from './../../../components/ui';
import { ClientService } from './../../../services';
import { isUndefined, isStatus, isStatusColor, isBinary, hasPermission, findPermissionProps, parseQueryString } from './../../../utilities/utils';
import { CliniceaPatientSync } from './../../../scenes';
import UtilClientFilter from './../filter';

import API from './../../../config/api';
import TEXT from './../../../config/text';

class Page__ClientList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        loading: false,
        page: 0,
        total: 0,
        id: null,
        entities: [],
        remove: false,
        clientEliminateShow: false,
        thead: [
          { id: 'client', name: `${this.props.t('field.name')}/${this.props.t('field.surname')}` },
          { id: 'email', name: this.props.t('field.email') },
          { id: 'identifire', name: this.props.t('field.document_number') },
          { id: 'enabled', name: this.props.t('field.status') },
          { id: 'registered', name: this.props.t('field.registered') },
          { id: 'verified', name: this.props.t('field.verified') },
          { id: 'actions', name: this.props.t('field.actions'), align: 'right' }
        ],
        cliniceaSyncShow: false,
        clientFilterShow: false
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.client, this.props.t('text.customers')) });

      const page = this.props.match.params.page || 0;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          this._All(page);
        }else{
          this.props.history.push('/clients');
        }
      }else{
        this._All(this.state.page);
      }

    }

    /*
    ** @Service
    ** @Description: FindAll
    */

    _All = (page) => {

      ClientService.list(page, parseQueryString(this.props.location.search)).then((response) => {
        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.clients || [],
          total: response.total
        });

      }).catch((error) => {

        // @State
        // @Descripcion: Loading disabled
        this.setState({ loading: true });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Remove
    */

    _Remove = (id) => {

      ClientService.remove(id).then((response) => {

        // @State
        this.setState({ remove: false });

        // @Service find all by page
        this._Init();

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description: Remove
    */

    handleRemove = (id) => {
      this.setState({ id: id, remove: true });
    };

    /*
    ** @Click
    ** @Description: Remove confirm
    */

    handleConfirmRemoveConfirm = () => {
      this._Remove(this.state.id);
    };

    /*
    ** @Click
    ** @Description: Remove cancel
    */

    handleConfirmRemoveCancel = () => {
      this.setState({ remove: false });
    };

    /*
    ** @Service
    ** @Description: Eliminate
    */

    _Eliminate = (id) => {

      ClientService.eliminate(id).then((response) => {

        // @State
        this.setState({ clientEliminateShow : false, id : null });

        // @Service find all by page
        this._Init();

        // @Dispatch
        // @Descripcion: Alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: 'El cliente fue eliminado.'
        });

      }).catch((error) => {

        // @Dispatch
        // @Descripcion: Alert errro
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description: Eliminate
    */

    handleEliminate = (id) => {
      this.setState({ clientEliminateShow: true, id: id });
    };

    /*
    ** @Click
    ** @Description: Eliminate confirm
    */

    handleConfirmEliminateConfirm = () => {
      this._Eliminate(this.state.id);
    };

    /*
    ** @Click
    ** @Description: Eliminate cancel
    */

    handleConfirmEliminateCancel = () => {
      this.setState({ clientEliminateShow: false, id: null });
    };

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/clients/page/${ page }`,
        search: this.props.location.search
      });

    };

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ clientFilterShow: true });
    };

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ clientFilterShow: false });
    };

     /*
    ** @Event
    ** @Description: Show Clinicea Sync
    */

    handleCliniceaSync = () => {
      this.setState({ cliniceaSyncShow: true });
    }

    /*
    ** @Event
    ** @Description: Clinicea Sync
    */

    handleCliniceaSyncClose = () => {
      this.setState({ cliniceaSyncShow: false });
    };

    /*
    ** @Event
    ** @Description: Clinicea Sync Refresh
    */

    handleSuccesOnSync = () => {

      // @State
      this.setState({ loading: false });

      this._All(this.props.match.params.page || 0);
      
    };

    render(){

      const { session } = this.props;
      const { permission, name, loading, entities, thead, total, page, remove, clientFilterShow, clientEliminateShow, cliniceaSyncShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <ButtonsGroup>
                { this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title={this.props.t('button.remove_filters')}
                    placement="left"
                    component={ NavLink }
                    to="/clients"
                  />
                )}
                <Button
                  icon="filter"
                  size="medium"
                  onClick={ this.handleFilter }
                >
                  {this.props.t('button.filter')}
                </Button>
                
                { hasPermission(session.user.permissions, API.permissions.clinicea_sync_clients) && (
                  API.integration.clinicea.enabled && (
                    <Button
                      icon="sync"
                      size="medium"
                      onClick={this.handleCliniceaSync}
                    >
                      {this.props.t('button.sync')}
                    </Button>
                  )
                )}

                { hasPermission(session.user.permissions, API.permissions.clients_management) && (
                  <Button
                    component={ NavLink }
                    to="/clients/create"
                    icon="add"
                    size="medium"
                  >
                    {this.props.t('button.create')}
                  </Button>
                )}
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

                {/* Alert confirm Delete */}
                <AlertConfirm
                   status={ remove }
                   message="¿Confirma la eliminación del cliente?"
                   cancel={ () => this.handleConfirmRemoveCancel() }
                   confirm={ () => this.handleConfirmRemoveConfirm() }
                />

                {/* Alert confirm Eliminate */}
                <AlertConfirm
                   status={ clientEliminateShow }
                   message={this.props.t('messages.client_delete_confirm')}
                   cancel={ () => this.handleConfirmEliminateCancel() }
                   confirm={ () => this.handleConfirmEliminateConfirm() }
                />

                {/* Filter */}
                <Modal
                    status={ clientFilterShow }
                    title="Filtrar clientes"
                    handleCancel={ () => this.handleFilterClose() }
                    maxWidth="sm">
                    <UtilClientFilter onSuccess={ this.handleFilterClose }/>
                </Modal>

                {/* Clinicea Sync */}
                {hasPermission(session.user.permissions, API.permissions.clinicea_sync_clients) && (
                  API.integration.clinicea.enabled && (
                    <Modal
                      status={cliniceaSyncShow}
                      title={this.props.t('text.clinicea_patient_sync')}
                      handleCancel={() => this.handleCliniceaSyncClose()}
                      maxWidth="md">
                      <CliniceaPatientSync 
                        onSuccess={this.handleSuccesOnSync}
                      />
                    </Modal>
                  )
                )}

                <Table
                  thead={ thead }
                  loading={ loading }
                  total={ total }
                  page={ page }
                  onChangePage={ this.handleChangePage }
                >

                  { entities.length > 0 && (
                    entities.map(entity => {
                      return (
                        <Row key={ entity._id }>
                          <Cell>
                            <LinkClient client={ entity }/>
                          </Cell>
                          <Cell>{ entity.email }</Cell>
                          <Cell>{ entity.identifire ? entity.identifire : '-' }</Cell>
                          <Cell>
                            <Tag
                              text={ isStatus(entity.enabled) }
                              color={ isStatusColor(entity.enabled) }
                            />
                          </Cell>
                          <Cell>
                            <Tag
                              text={ isBinary(entity.registered) }
                              color={ isStatusColor(entity.registered) }
                            />
                          </Cell>
                          <Cell>
                            <Tag
                              text={ isBinary(entity.verified) }
                              color={ isStatusColor(entity.verified) }
                            />
                          </Cell>
                          <Cell align="right" nowrap>
                            { hasPermission(session.user.permissions, API.permissions.support) && (
                              <Button
                                icon="email"
                                component={ NavLink }
                                title="Enviar mensaje de soporte"
                                to={ `/support/create/${ entity._id }`}
                              />
                            )}
                            { hasPermission(session.user.permissions, API.permissions.clients_management) && (
                              <React.Fragment>
                                <Button
                                  color="primary"
                                  icon="edit"
                                  component={ NavLink }
                                  title={this.props.t('button.edit')}
                                  to={ `/clients/edit/${ entity._id }` }
                                />
                              { !entity.removed  && (
                                <Button
                                  color="danger"
                                  icon="cancel"
                                  title={this.props.t('button.delete')}
                                  onClick={ () => this.handleRemove(entity._id)  }
                                />
                              )}
                              { entity.removed  && (
                                <Button
                                  color="danger"
                                  icon="deleteForever"
                                  title={this.props.t('button.delete')}
                                  onClick={ () => this.handleEliminate(entity._id)  }
                                />
                              )}
                              </React.Fragment>
                            )}
                          </Cell>
                        </Row>
                      )
                    })
                  )}

                  { (loading && entities.length === 0) && (
                    <Row>
                      <Cell colSpan={ thead.length } align="center">
                        { TEXT.message.without_results }
                      </Cell>
                    </Row>
                  )}

                </Table>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__ClientList));
