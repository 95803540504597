import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: `1px solid ${ theme.palette.border.main }`
  },

    title: {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.fontWeightMedium
    },
    subtitle: {
      fontSize: theme.typography.h6.fontSize,
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1
    },
  top: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
    topLeft: {
      flex: 1
    },
  bottom: {
    display: 'flex',
  },
    bottomLeft: {
      flex: 1
    },
    bottomRight: {

    }
}));

const ToolbarSecondary__c = ({ title, subtitle, topRight, bottomLeft, bottomRight, ...rest }) => {

  const classes = useStyles();

  return(
    <Box className={ classes.root }>

      { (title || subtitle || topRight) && (
        <Box className={ classes.top }>
          <Box className={ classes.topLeft }>
            { title && (
              <Typography component="h3" classes={{ root: classes.title }}>
                { title }
              </Typography>
            )}
            { subtitle && (
              <Typography component="p" classes={{ root: classes.subtitle }}>
                { subtitle }
              </Typography>
            )}
          </Box>
          { topRight && (
            <Box className={ classes.topRight }>
              { topRight }
            </Box>
          )}
        </Box>
      )}

      { (bottomLeft || bottomRight) && (
        <Box className={ classes.bottom }>
          { bottomLeft && (
            <Box className={ classes.bottomLeft }>
              { bottomLeft }
            </Box>
          )}
          { bottomRight && (
            <Box className={ classes.bottomRight }>
              { bottomRight }
            </Box>
          )}
        </Box>
      )}

    </Box>
  )

}

ToolbarSecondary__c.propTypes = {
  topRight: PropTypes.node,
  bottomLeft: PropTypes.node,
  bottomRight: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default ToolbarSecondary__c;
