import React from 'react';
import { withTranslation } from 'react-i18next';
import { Content, Alert, Table, Row, Cell, Tag, LinkLead } from './../../../components/ui';
import { LeadService } from './../../../services';

import TEXT from './../../../config/text';

class UtilLeadList extends React.Component {

  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      error: false,
      entities: [],
      thead: [
        { id: 'source', name: this.props.t('field.campaign') },
        { id: 'client', name: this.props.t('field.full_name') },
        { id: 'email', name: this.props.t('field.email') }
      ]
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {
    this._All(0);
  }

  /*
  ** @Service
  ** @Description: List
  */

  _All = (page) => {

    this._isMounted = true;

    LeadService.list(page).then((response) => {

      if (this._isMounted) {
        // @State
        // @Description: Set response
        this.setState({
          loading: true,
          entities: response.leads
        });
      }

    }).catch((error) => {

      if (this._isMounted) {
        // @State
        // @Description: Set error
        this.setState({ error: true });
      }

    });

  }

  render(){

    const { loading, error, entities, thead } = this.state;

    return(

      !error ? (
        <Table
          thead={ thead }
          loading={ loading }
        >

          { entities.length > 0 && (
            entities.map(entity => {
              return (
                <Row key={ entity._id }>
                  <Cell>
                    { entity.source ? (
                      <Tag
                        text={entity.source}
                        color="primary"
                      />
                    ) : (
                      <React.Fragment>
                        -
                      </React.Fragment>
                    )}
                  </Cell>
                  <Cell>
                    <LinkLead lead={ entity }/>
                  </Cell>
                  <Cell>{ entity.email }</Cell>
                </Row>
              )
            })
          )}

          { (loading && entities.length === 0) && (
            <Row>
              <Cell colSpan={ thead.length } align="center">
                { TEXT.message.without_results }
              </Cell>
            </Row>
          )}

        </Table>
      ) : (
        <Content>
          <Alert severity="info">
            <strong>{this.props.t('messages.attention')}:</strong> {this.props.t('messages.information_request_error')}
          </Alert>
        </Content>
      )

    )

  }

}

export default withTranslation()(UtilLeadList);
