
import { isNull } from './../utils';

function localStorageToken (){
  return localStorage.getItem('token');
}

function localStorageSalesForce (){
  let storage = localStorage.getItem('salesforce');

  return !isNull(storage) ? Object.assign({ status: true }, JSON.parse(storage)) : { status: false };
}

const asyncLocalStorage = {
    setItem: (key, value) => {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, JSON.stringify(value));
        });
    },
    getItem: (key) => {
        return new Promise(function(resolve) {
          setTimeout(()=>{
            resolve( JSON.parse(localStorage.getItem(key)) )
          },1000);
        });
    }
};

export default localStorageToken;
export { localStorageSalesForce, asyncLocalStorage };
