import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { makeStyles, Typography } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: 1,
    whiteSpace: 'nowrap'
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const LinkShipping = ({ shipping }) => {

  const classes  = useStyles();

  return (
    <Typography
      className={ classNames(
        classes.root,
        classes.link
      )}
      component={ NavLink }
      to={ `/shipping/detail/${ shipping._id }` }
    >
      { shipping.code_internal }
    </Typography>
  )

};

LinkShipping.propTypes = {
  shipping: PropTypes.object
};

export default LinkShipping;
