import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Box, Typography } from './../../../../assets/theme/default';
import { List, ListItem, ListItemSecondaryAction, ListItemText } from './../../../../assets/theme/list';
import { Toolbar, Paper, Currency } from './../../../../components/ui';
import { Discount, Total } from './../../../../utilities/operation';

import ProductCheckout from './../../../../scenes/product/checkout';
import { CheckoutBalance, CheckoutDiscount } from './../../../../scenes';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      height: 'auto !important'
    }
  },
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    top: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(2)
      // paddingTop: theme.spacing(3),
      // paddingBottom: theme.spacing(3)
    },
    middle: {
      flex: 1,
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      overflowX: 'auto',
      [theme.breakpoints.down('md')]: {
        maxHeight: 'none !important'
      }
    },
    bottom: {
      padding: theme.spacing(2),
      background: theme.palette.primary.light
      // borderTop: `1px solid ${ theme.palette.border.main }`,
      // marginTop: theme.spacing(2)
    },
  checkoutType: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: theme.spacing(0.5)
  },
  discountList: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${ theme.palette.primary.ultraLight }`
  },
  balanceList: {
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    //borderBottom: `1px solid ${ theme.palette.grey[200] }`
  }
});

class UtilMPSummary extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        disabled: true,
        //discounts: [10, 20],
        discountSelected: null,
        rootStyle: {},
        middleStyle: {}
      };

      this.root    = React.createRef();
      this.toolbar = React.createRef();
      this.content = React.createRef();
      this.middle  = React.createRef();
      this.bottom  = React.createRef();
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.parentRef !== prev_props.parentRef){
        this.handleDimensions();
      }

      if(this.props.checkout.update !== prev_props.checkout.update){
        this.handleValidation();
      }

    }

    // @Event
    // @Description: Set resume styles
    handleDimensions = () => {

      let root = this.root.current.getBoundingClientRect();
      let win  = window.innerHeight - root.top - 16;

      if(this.props.parentRef.height > win){
        this.setState({
          rootStyle: {
            height: win
          }
        });
      }else{
        this.setState({
          rootStyle: {
            height: this.props.parentRef.height - 10
          }
        });
      }

      setTimeout(() => {

        root = this.root.current.getBoundingClientRect();
        let toolbar = this.toolbar.current.getBoundingClientRect();
        let bottom  = this.bottom.current.getBoundingClientRect();

        if(root.height > this.props.parentRef.height){
          root.height = this.props.parentRef.height;
        }

        this.setState({
          middleStyle: {
            maxHeight: root.height - (toolbar.height + bottom.height)
          }
        });

      },1000);

    }

    // @Event
    // @Description: Send product to partent
    handleDelete = (index) => {

      // @Store
      // @Description: Remove product from store
      this.props.checkoutRemoveStateDispatch({
        index: index,
        update: Date.now()
      });

    }

    // @Event
    // @Description: Validation
    handleValidation = () => {

      // @If
      // @Description: Validation type simple
      if(this.props.checkout.type === 'simple'){

        this.setState({ disabled: this.props.checkout.total > 0 ? false : true });

        // @Function
        // @Description: Balance
        this.handleBalanceSimple(this.props.checkout);

      }

      // @If
      // @Description: Validation type combo
      if(this.props.checkout.type === 'combo'){

        this.setState({
          disabled: this.props.checkout.products.length > 0 ? false : true
        });

        // @Function
        // @Description: Balance
        this.handleBalanceCombo(this.props.checkout);

      }

    }

    // @Event
    // @Description: Balance
    handleBalanceSimple = (checkout) => {

      let subtotal = checkout.subtotal;

      // @Utils
      // @Description: Calculate discount
      //let discount = Discount(subtotal, this.state.discountSelected);
      let discount = 0;

      // @If
      // @Description: If discount exist
      if(this.state.discountSelected){

        // @If
        // @Description: If discount is percentage
        if(this.state.discountSelected.type === 'percentage'){
          discount = Discount(this.props.checkout.subtotal, this.state.discountSelected.value);
        }

        // @If
        // @Description: If discount is amount
        if(this.state.discountSelected.type === 'amount'){
          discount = this.state.discountSelected.value;
        }

      }

      // @Var
      // @Description: Calculate total
      let total = Total(subtotal, discount);

      // @If
      // @Description: If total < 0 disabled discount
      if(total <= 0){

        // @Var
        // @Description: Set total with subtotal
        total    = subtotal;

        // @Var
        // @Description: Set discount
        discount = 0;

        // @State
        // @Description: Set discount value
        this.setState({ discountSelected: null });

      }

      // @Props
      // @Description: Balance to store
      this.props.checkoutBalanceStateDispatch({
        subtotal: subtotal,
        discount: discount,
        total: total
      });

    }

    // @Event
    // @Description: Balance combo
    handleBalanceCombo = (checkout) => {

      let subtotal = 0;

      if(checkout.products.length > 0){

        // @ForEach
        // @Description: Count value products
        checkout.products.forEach((product, index) => {
          subtotal = subtotal + (product.price * product.qty);
        });

      }else{

        // @State
        // @Description: Set discount value
        this.setState({ discountSelected: null });

      }

      // @Utils
      // @Description: Calculate discount
      let discount = 0;

      // @If
      // @Description: If discount exist
      if(this.state.discountSelected){

        // @If
        // @Description: If discount is percentage
        if(this.state.discountSelected.type === 'percentage'){
          discount = Discount(this.props.checkout.subtotal, this.state.discountSelected.value);
        }

        // @If
        // @Description: If discount is amount
        if(this.state.discountSelected.type === 'amount'){
          discount = this.state.discountSelected.value;
        }

      }

      // @Var
      // @Description: Calculate total
      let total = Total(subtotal, discount);

      // @If
      // @Description: If total < 0 disabled discount
      if(total <= 0){

        // @Var
        // @Description: Set total with subtotal
        total    = subtotal;

        // @Var
        // @Description: Set discount
        discount = 0;

        // @State
        // @Description: Set discount value
        this.setState({ discountSelected: null });

      }

      // @Props
      // @Description: Balance to store
      this.props.checkoutBalanceStateDispatch({
        subtotal: subtotal,
        discount: discount,
        total: total
      });

    }

    // @Event
    // @Description: Validation
    handleOnSelectDiscount = (values) => {

      if(values){

        // @State
        // @Description: Set discount
        this.setState({ discountSelected: values });

        let discount;

        // @Discount
        // @Description: Calculate discount
        if(values.type === 'percentage'){
          discount = Discount(this.props.checkout.subtotal, values.value);
        }

        if(values.type === 'amount'){
          discount = values.value;
        }

        let total = Total(this.props.checkout.subtotal, discount);

        // @Props
        // @Description: Balance to store
        this.props.checkoutBalanceStateDispatch({
          subtotal: this.props.checkout.subtotal,
          discount: discount,
          total: total
        });

      }else{

        // @State
        // @Description: Set discount
        this.setState({ discountSelected: values });

        // @Props
        // @Description: Balance to store
        this.props.checkoutBalanceStateDispatch({
          subtotal: this.props.checkout.subtotal,
          discount: 0,
          total: this.props.checkout.subtotal
        });

      }

    }

    render(){

      const { classes, checkout } = this.props;
      const { discountSelected, disabled, rootStyle, middleStyle } = this.state;

      return(
        <Paper disablePadding>
          <Box
            className={ classes.root }
            ref={ this.root }
            style={ rootStyle }
          >

            <Box ref={ this.toolbar }>
              <Toolbar
                title="Resumen"
                size="medium"
                variant="divider"
                disableMargin
              />
            </Box>

            {/*
            { checkout.client && (
              <Box className={ classes.top }>
                <Paper variant="primary" disablePadding style={{ padding: '6px 12px' }}>
                  <Typography color="secondary" variant="h6">
                    { checkout.client.name } { checkout.client.surname }
                  </Typography>
                  <Typography color="secondary" variant="body2">
                    { checkout.client.email }
                  </Typography>
                </Paper>
              </Box>
            )}
            */}

            <Box className={ classes.content } ref={ this.content }>
              <Box className={ classes.middle } ref={ this.middle } style={ middleStyle }>

                {/* Checkout Simple */}
                { checkout.type === 'simple' && (

                  <List
                    component="div"
                    dense
                    disablePadding
                  >
                    <ListItem disableGutters classes={{ secondaryAction: classes.balanceListAction }}>
                      <ListItemText
                        classes={{ root: classes.balanceListOption }}
                        primary="Monto"
                      />
                      <ListItemSecondaryAction>
                        <Currency value={ checkout.subtotal }/>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>

                )}

                {/* Checkout Combo */}
                { checkout.type === 'combo' && (
                  <Box className={ classes.productList }>
                    { (checkout.products && checkout.products.length > 0) ? (

                      <ProductCheckout
                        products={ checkout.products }
                        onDelete={ this.handleDelete }
                      />

                    ) : (

                      <Typography color="secondary" variant="h6" align="center">
                        No hay productos seleccionados
                      </Typography>

                    )}
                  </Box>
                )}

              </Box>

              <Box className={ classes.bottom } ref={ this.bottom }>

                {/* Discount List */}
                <Box className={ classes.discountList }>
                  <CheckoutDiscount
                    selected={ discountSelected }
                    disabled={ disabled }
                    onSelect={ this.handleOnSelectDiscount }
                  />
                </Box>

                {/* Balance */}
                <Box className={ classes.balanceList }>
                  <CheckoutBalance
                    subtotal={ checkout.subtotal }
                    discount={ checkout.discount }
                    total={ checkout.total }
                  />
                </Box>

              </Box>
            </Box>

          </Box>
        </Paper>
      )
    }

};

const mapStateToProps = (state, ownProps) => {
  return {
    checkout: state.mpCheckoutState
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkoutBalanceStateDispatch: (data) => {
      dispatch({
        type: 'MP_CHECKOUT_BALANCE',
        data: data
      });
    },
    checkoutRemoveStateDispatch: (data) => {
      dispatch({
        type: 'MP_CHECKOUT_PRODUCT_REMOVE',
        data: data
      });
    },
    checkoutClearStateDispatch: () => {
      dispatch({
        type: 'MP_CHECKOUT_CLEAR'
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UtilMPSummary));
