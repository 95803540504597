import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makeStyles } from './../../../../assets/theme/default';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from './../../../../assets/theme/list';
import { ShoppingBasketIcon, AccountBoxIcon, ExpandMoreIcon, ChevronRightIcon, MonetizationOnIcon, DvrIcon, DynamicFeedIcon, AssignmentIcon, PaymentIcon, ReceiptIcon, PersonIcon, DashboardIcon, SettingsIcon, PhotoIcon, FormatListBulletedIcon, FaceIcon, PersonPinIcon, ViewHeadlineIcon, NotesIcon, StoreIcon, PanoramaIcon, ViewColumnIcon, BusinessIcon, PinDropIcon, LocalOfferIcon, WidgetsIcon, RoomIcon, LocalAtmIcon, CenterFocusStrongIcon, MarkunreadIcon, MailIcon, ApartmentIcon } from './../../../../assets/theme/icon';
import { hasPermission, findPermissionProps } from './../../../../utilities/utils';

import API from './../../../../config/api';

const useStyles = makeStyles(theme => ({

  linkText:         { fontSize: theme.typography.fontSize, fontWeight: theme.typography.fontWeightBold },
  listItemGroup:    { paddingBottom: theme.spacing(1), borderBottom: `1px solid ${ theme.palette.navigation.light }` },
  listItemGroupHeader: {
                        color: theme.palette.navigation.contrastText,
                        paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1),
                        '&:not(:last-child)': {
                          marginBottom: theme.spacing(0.5)
                        },
                        '&:hover': {
                          background: theme.palette.navigation.dark
                        }
                      },
  listItemGroupHeaderActive: {
                      background: theme.palette.navigation.dark
  },
  listItemGroupItems: {marginLeft: theme.spacing(2), marginRight: theme.spacing(2)},
  listItem:         {
                      borderRadius: theme.spacing(0.5),
                      color: theme.palette.navigation.contrastSecondaryText,
                      '&:not(:last-child)': {
                        marginBottom: theme.spacing(0.5)
                      },
                      '&:hover': {
                        background: theme.palette.navigation.dark
                      }
                    },
  listItemActive:   {
                      background: theme.palette.navigation.dark,
                      color: theme.palette.navigation.contrastText
  },
  //listItemText:     { color: theme.palette.grey[600] },
  listIcon:         { color: theme.palette.primary.main, minWidth: theme.spacing(5) },
  listIconSecondary: { color: theme.palette.navigation.contrastSecondaryText },
  disablePadding:   { padding: 0 },
  disablePaddingBottom: { paddingBottom: 0 }
}));

const Nav = ({ children }) => {
  const classes = useStyles();
  return(
    <List
      component="div"
      dense
      className={classNames(
        classes.listItemGroup,
        classes.disablePadding
      )}
    >
      {children}
    </List>
  )
};

const Items = ({ children, subheader, icon, disablePadding, disablePaddingBottom, ...rest }) => {

  const [status, setStatus] = useState(false);
  const classes = useStyles();

  const toggle = () => {
    setStatus(!status)
  }

  return(
    <List
      component="div"
      dense
      className={ classNames(
          classes.listItemGroup,
          disablePadding ? classes.disablePadding : null,
          disablePaddingBottom ? classes.disablePaddingBottom : null
        )
      }
    >
      <ListItem button disableRipple className={classNames(classes.listItemGroupHeader, status ? classes.listItemGroupHeaderActive : null)} onClick={toggle}>
        { icon && (
          <ListItemIcon className={ classNames(classes.listIcon) }>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          primary={subheader}
          className={ classNames(classes.linkText) }
          disableTypography
        />
        { status ? <ExpandMoreIcon/> : <ChevronRightIcon/> }
      </ListItem>
      <Collapse in={status} timeout="auto" unmountOnExit>
        <List
          component="div"
          dense
          className={ classNames(
              classes.listItemGroupItems
            )
          }
        >
          {children}
        </List>
      </Collapse>
    </List>
  )

};

const Item = ({ name, to, icon, variant = 'secondary', ...rest }) => {
  const classes = useStyles();
  return(
    <ListItem button disableRipple component={ NavLink } to={ to } className={ classNames(classes.listItem, variant === 'primary' ? classes.listItemGroupHeader : null) } activeClassName={ classes.listItemActive } { ...rest }>
      <ListItemIcon className={ classNames(classes.listIcon, variant === 'primary' ? classes.listIcon : classes.listIconSecondary) }>
        { icon }
      </ListItemIcon>
      <ListItemText primary={ name } className={ classNames(classes.linkText, classes.listItemText) } disableTypography />
    </ListItem>
  )
};

const Menu = props => {

  const { t } = useTranslation();

  /*
  ** @Event
  ** @Description: Close navigation
  */
  const handleSidebarClose = () => {
    props.navigationToggle( { open: false } );
  };

  /*
  ** @Event
  ** @Description: Menu Item Props
  */
  const handleItemProps = (key, alternativeName) => {
    return findPermissionProps(props.session, key, alternativeName);
  };

  return (

    <React.Fragment>

      <Nav>
        <Item name={t('page.dashboard.name')} to="/dashboard" variant="primary" icon={ <DashboardIcon /> } onClick={ handleSidebarClose } />
      </Nav>

      { (
          hasPermission(props.session.user.permissions, API.permissions.categories) ||
          hasPermission(props.session.user.permissions, API.permissions.products) ||
          hasPermission(props.session.user.permissions, API.permissions.clients) ||
          hasPermission(props.session.user.permissions, API.permissions.entities) ||
          hasPermission(props.session.user.permissions, API.permissions.providers)
        ) && (
        <Items subheader={t('nav.group.administration')} disablePadding icon={<DvrIcon/>}>

          { hasPermission(props.session.user.permissions, API.permissions.categories) && (
            <Item name={handleItemProps(API.permissions.categories, t('page.categories.name')).name} to="/categories" icon={ <FormatListBulletedIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.products) && (
            <Item name={handleItemProps(API.permissions.products, t('page.products.name')).name} to="/products" icon={ <PhotoIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.clients) && (
            <Item name={handleItemProps(API.permissions.clients, t('page.clients.name')).name} to="/clients" icon={ <FaceIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.entities) && (
            <Item name={handleItemProps(API.permissions.entities, t('page.entities.name')).name} to="/entities" icon={ <BusinessIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.providers) && (
            <Item name={handleItemProps(API.permissions.providers, t('page.providers.name')).name} to="/providers" icon={ <WidgetsIcon /> } onClick={ handleSidebarClose } />
          )}

        </Items>
      )}

      {/* Sales */}
      {(
        hasPermission(props.session.user.permissions, API.permissions.payments) ||
        hasPermission(props.session.user.permissions, API.permissions.api_mercado_pago) ||
        hasPermission(props.session.user.permissions, API.permissions.api_todo_pago)
      ) && (
        <Items subheader={t('nav.group.sales')} disablePadding icon={ <MonetizationOnIcon/> }>
          {/* Payments */}
          { hasPermission(props.session.user.permissions, API.permissions.payments) && (
            <Item name={handleItemProps(API.permissions.payments, t('page.payments.name')).name} to="/payments" icon={ <PaymentIcon /> } onClick={ handleSidebarClose } />
          )}
          {/* Mercado Pago */}
          { hasPermission(props.session.user.permissions, API.permissions.api_mercado_pago) && (
            <Item name={handleItemProps(API.permissions.api_mercado_pago, "Mercado Pago").name} to="/api/mercadopago/checkout" icon={ <ReceiptIcon /> } onClick={ handleSidebarClose } />
          )}
          {/* Todo Pago */}
          { hasPermission(props.session.user.permissions, API.permissions.api_todo_pago) && (
            <Item name={handleItemProps(API.permissions.api_todo_pago, "Todo Pago").name} to="/api/todopago/checkout" icon={ <ReceiptIcon /> } onClick={ handleSidebarClose } />
          )}
        </Items>
      )}

      {/* Tracking */}
      { (
        hasPermission(props.session.user.permissions, API.permissions.leads) ||
        hasPermission(props.session.user.permissions, API.permissions.shop_cart) ||
        hasPermission(props.session.user.permissions, API.permissions.support) ||
        hasPermission(props.session.user.permissions, API.permissions.shipping)
      ) && (
        <Items subheader={t('nav.group.tracking')} disablePadding icon={<AssignmentIcon/>}>

          { hasPermission(props.session.user.permissions, API.permissions.leads) && (
            <Item name={handleItemProps(API.permissions.leads, t('page.leads.name')).name} to="/leads" icon={ <PersonPinIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.shop_cart) && (
            <Item name={handleItemProps(API.permissions.shop_cart, t('page.shop.cart.name')).name} to="/shop/cart" icon={ <ShoppingBasketIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.support) && (
            <Item name={handleItemProps(API.permissions.support, t('page.support.name')).name} to="/support" icon={ <MarkunreadIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.shipping) && (
            <Item name={handleItemProps(API.permissions.shipping, "Envíos").name} to="/shipping" icon={ <PinDropIcon /> } onClick={ handleSidebarClose } />
          )}

        </Items>
      )}

      {/* Content */}
      { (
        hasPermission(props.session.user.permissions, API.permissions.featured) ||
        hasPermission(props.session.user.permissions, API.permissions.slider) ||
        hasPermission(props.session.user.permissions, API.permissions.store) ||
        hasPermission(props.session.user.permissions, API.permissions.franchise) ||
        hasPermission(props.session.user.permissions, API.permissions.blog) ||
        hasPermission(props.session.user.permissions, API.permissions.offer) ||
        hasPermission(props.session.user.permissions, API.permissions.api_forms) ||
        hasPermission(props.session.user.permissions, API.permissions.shop_cupon)
      ) && (
        <Items subheader={t('nav.group.content')} disablePadding icon={<DynamicFeedIcon/>}>

          { hasPermission(props.session.user.permissions, API.permissions.offer) && (
            <Item name={handleItemProps(API.permissions.offer, t('page.offers.name')).name} to="/offer" icon={ <LocalOfferIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.shop_cupon) && (
            <Item name={handleItemProps(API.permissions.shop_cupon, t('page.cupons.name')).name} to="/cupons" icon={ <LocalAtmIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.qr_code) && (
            <Item name={handleItemProps(API.permissions.qr_code, t('page.qr_codes.name')).name} to="/qr-codes" icon={ <CenterFocusStrongIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.featured) && (
            <Item name={handleItemProps(API.permissions.featured, t('page.featured.name')).name} to="/featured" icon={ <PanoramaIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.slider) && (
            <Item name={handleItemProps(API.permissions.slider, t('page.sliders.name')).name} to="/slider" icon={ <ViewColumnIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.store) && (
            <Item name={handleItemProps(API.permissions.store, t('page.stores.name')).name} to="/stores" icon={ <StoreIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.franchise) && (
            <Item name={handleItemProps(API.permissions.franchise, t('page.franchises.name')).name} to="/franchises" icon={ <ApartmentIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.blog) && (
            <Item name={handleItemProps(API.permissions.blog, t('page.blog.name')).name} to="/blog" icon={ <NotesIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.api_forms) && (
            <Item name={handleItemProps(API.permissions.api_forms, t('page.forms.name')).name} to="/forms" icon={ <ViewHeadlineIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.god) && (
            <Item name={handleItemProps(API.permissions.god, 'Campañas').name} to="/campaigns" icon={ <MailIcon /> } onClick={ handleSidebarClose } />
          )}

        </Items>
      )}

      {/* System */}
      { (
          hasPermission(props.session.user.permissions, API.permissions.users) ||
          hasPermission(props.session.user.permissions, API.permissions.settings) ||
          hasPermission(props.session.user.permissions, API.shipping_location)
        ) && (
        <Items subheader={t('nav.group.system')} disablePadding icon={ <SettingsIcon /> }>

          { hasPermission(props.session.user.permissions, API.permissions.users) && (
            <Item name={handleItemProps(API.permissions.users, t('page.users.name')).name} to="/users" icon={ <PersonIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.shipping_location) && (
            <Item name={handleItemProps(API.permissions.shipping_location, t('page.locations.name')).name} to="/locations" icon={ <RoomIcon /> } onClick={ handleSidebarClose } />
          )}

          { hasPermission(props.session.user.permissions, API.permissions.settings) && (
            <Item name={handleItemProps(API.permissions.settings, t('page.settings.name')).name} to="/settings" icon={ <SettingsIcon /> } onClick={ handleSidebarClose } />
          )}

        </Items>
      )}

      {/* Profile */}
      <Nav>
        <Item name={t('page.profile.name')} to="/me" variant="primary" icon={ <AccountBoxIcon /> } onClick={ handleSidebarClose } />
      </Nav>

    </React.Fragment>

  )

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    navigationToggle: (data) => {
      dispatch({
        type: 'NAVIGATION_TOGGLE',
        data: data
      });
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
