import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Grid } from './../../../../assets/theme/layout';
import { Content, Toolbar, Tag, Data, Date, LinkClient } from './../../../../components/ui';
import { isGenre, isStatus, isStatusColor, isBinary, isBinaryColor } from './../../../../utilities/utils';

class UtilClientData extends React.Component {

    // @Event
    // @Description: Build client address
    handleAddress = (client) => {

      let address = '-';

      if(client.address_street){
        address = `${ client.address_street }`;
      }

      if(client.address_number){
        address = `${ address } ${ client.address_number }`;
      }

      if(client.address_floor){
        address = `${ address } ${ client.address_floor }`;
      }

      if(client.address_apartment){
        address = `${ address } ${ client.address_apartment }`;
      }

      return address;

    }

    render(){

      const { client, titlePersonal, hidePersonal, hideContact, hideLocation, hideConfiguration, isModal } = this.props;

      const _hidePersonal      = !hidePersonal ? false : hidePersonal;
      const _hideContact       = !hideContact ? false : hideContact;
      const _hideLocation      = !hideLocation ? false : hideLocation;
      const _hideConfiguration = !hideConfiguration ? false : hideConfiguration;

      let styleIsModal         = {};
      let styleIsModalFirst    = {};

      if(isModal){

        styleIsModalFirst.paddingTop   = 0;
        styleIsModal.paddingRight = 0;
        styleIsModal.paddingLeft  = 0;

      }

      return(

        client && (
          <React.Fragment>

            {/* Personal */}
            { !_hidePersonal && (
              <Content disableMargin divider style={{ ...styleIsModalFirst, ...styleIsModal }}>
                <Toolbar
                  title={ titlePersonal ? titlePersonal : this.props.t('text.personal') }
                  size="small"
                />
                <Grid container spacing={3}>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.name')}
                      text={ <LinkClient client={ client }/> }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.document_number')}
                      text={ client.identifire }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.gender')}
                      text={ client.genre ? isGenre(client.genre) : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.birthdate')}
                      text={ client.birthdate ? <Date value={ client.birthdate } /> : '-' }
                    />
                  </Grid>
                </Grid>
              </Content>
            )}

            {/* Contact information */}
            { !_hideContact && (
            <Content disableMargin divider style={styleIsModal}>
              <Toolbar
                title={this.props.t('text.contact')}
                size="small"
              />
              <Grid container spacing={3}>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                  <Data
                    label={this.props.t('field.company')}
                    text={ (client.entity && client.entity.name) ? client.entity.name : '-' }
                    gutterBottom
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                  <Data
                    label={this.props.t('field.email')}
                    text={ client.email }
                    email
                    gutterBottom
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                  <Data
                    label={this.props.t('field.phone')}
                    text={ client.phone ? client.phone : '-' }
                    gutterBottom
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                  <Data
                    label={this.props.t('field.mobile_phone')}
                    text={ client.mobile_phone ? client.mobile_phone : '-' }
                    gutterBottom
                  />
                </Grid>
              </Grid>
            </Content>
            )}

            {/* Location */}
            { !_hideLocation && (
              <Content disableMargin divider style={styleIsModal}>
                <Toolbar
                  title={this.props.t('text.address')}
                  size="small"
                />
                <Grid container spacing={3}>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('text.address')}
                      text={ this.handleAddress(client) }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.postal_code')}
                      text={ client.zip ? client.zip : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.city')}
                      text={ client.city ? client.city : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.state')}
                      text={ client.state ? client.state.name : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.country')}
                      text={ client.country ? client.country.name : '-' }
                      gutterBottom
                    />
                  </Grid>
                </Grid>
              </Content>
            )}

            {/* Configuratión */}
            { !_hideConfiguration && (
              <Content style={styleIsModal}>
                <Toolbar
                  title={this.props.t('text.configuration')}
                  size="small"
                />
                <Grid container spacing={3}>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.status')}
                      text={
                        <Tag
                          text={ isStatus(client.enabled) }
                          color={ isStatusColor(client.enabled) }
                        />
                      }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.reference')}
                      text={ client.reference ? client.reference : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.registered')}
                      text={
                        <Tag
                          text={ isBinary(client.registered) }
                          color={ isBinaryColor(client.registered) }
                        />
                      }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.verified')}
                      text={
                        <Tag
                          text={ isBinary(client.verified) }
                          color={ isBinaryColor(client.verified) }
                        />
                      }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.newsletter')}
                      text={
                        <Tag
                          text={ isBinary(client.newsletter) }
                          color={ isBinaryColor(client.newsletter) }
                        />
                      }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.last_login')}
                      text={client.last_login ? client.last_login : '-'}
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.login_count')}
                      text={client.login_count > 0 ? client.login_count : '-'}
                      gutterBottom
                    />
                  </Grid>
                </Grid>
              </Content>
            )}

          </React.Fragment>

        )

      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UtilClientData));
