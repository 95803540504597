import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import { makeStyles, Box, Typography, Chip } from './../../../assets/theme/default';
import { Grid } from './../../../assets/theme/layout';
import { AddCircleOutlineIcon } from './../../../assets/theme/icon';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { Modal, Content, Button, ButtonsGroup } from './../../../components/ui';
import { isNumber } from './../../../utilities/utils';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const useStyles = makeStyles(theme => ({
  discountListOptions: {
    whiteSpace: 'nowrap',
    overflowX: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
    '& > *': {
      marginRight: theme.spacing(0.5),
    }
  }
}));

const validate = values => {

  const errors = {};

  if(values.type === 'percentage'){
    if (!values.percentage) {
      errors.percentage = TEXT.validation.required;
    }else if(isNumber(values.percentage)){
      errors.percentage = TEXT.validation.number_invalid;
    }else if(values.percentage <= 0){
      errors.percentage = TEXT.validation.minlength(1);
    }else if(values.percentage > API.client.max_discount_percentage){
      errors.percentage = TEXT.validation.maxlength(API.client.max_discount_percentage);
    }
  }

  if(values.type === 'amount'){
    if (!values.amount) {
      errors.amount = TEXT.validation.required;
    }else if(isNumber(values.amount)){
      errors.amount = TEXT.validation.number_invalid;
    }else if(values.amount <= 0){
      errors.amount = TEXT.validation.minlength(1);
    }
  }

  return errors;

};

let CheckoutDiscount = ({ disabled = true, selected, onSelect, ...rest }) => {

  const classes = useStyles();

  const [ discounts, setDiscounts ] = useState([
    { value: 10, type: 'percentage' },
    { value: 20, type: 'percentage' },
    { value: 50, type: 'percentage' },
  ]);
  const [ discountSelected, setDiscountSelected ] = useState(null);
  const [ discountNew, setDiscountNew ] = useState(false);
  const [ discountType, setDiscountType ] = useState('percentage');

  if(selected !== discountSelected){
    console.log(selected);
    setDiscountSelected(selected);
  }

  /*
  ** @Submit
  ** @Description: Submit form
  */
  const handleSubmitForm = (values) => {

    let type  = values.type;
    let value;

    if(type === 'percentage'){
      value = parseInt(values.percentage,10);
    }

    if(type === 'amount'){
      value = parseInt(values.amount,10);
    }

    // @Find
    // @Description: Find discount, if not exist add
    let findDiscount = discounts.find((discount) => discount.value === value && discount.type === type);

    if(!findDiscount){
      setDiscounts([...discounts, {
        value: value,
        type: type
      }]);
    }

    // @Hook
    // @Description: Close modal
    setDiscountNew(false);

  }

  // @Event
  // @Description: On change type discount
  const handleOnChangeType = (event, value) => {

    rest.reset();

    // @Hook
    // @Description: Select discount type
    setDiscountType(value);

  }

  // @Event
  // @Description: On change type discount
  const handleDiscountLabel = (discount) => {

    let label;

    if(discount.type === 'percentage'){
      label = `${ discount.value }%`
    }

    if(discount.type === 'amount'){
      label = `${ API.client.currency } ${ discount.value }`
    }

    return label;

  }

  return (
    <Box>
      <Typography gutterBottom variant="h6">
        Descuento
      </Typography>

      {/* Add custom Discount */}
      <Modal
        title="Crear un descuento especial"
        status={ discountNew }
        maxWidth="xs"
        handleCancel={ () => { setDiscountNew(false); rest.reset(); } }
      >
        <form noValidate autoComplete="off" onSubmit={ rest.handleSubmit(handleSubmitForm) }>
          <Content disablePadding divider>
            <Grid container spacing={3}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Field
                  name="type"
                  component={ SelectField }
                  label="Tipo de descuento"
                  required
                  onChange={ handleOnChangeType }
                >
                  <OptionField value="percentage">Porcentaje</OptionField>
                  <OptionField value="amount">Monto</OptionField>
                </Field>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                { discountType === 'percentage' && (
                  <Field
                    name="percentage"
                    component={ InputTextField }
                    label="Porcentaje"
                    type="number"
                    required
                  />
                )}

                { discountType === 'amount' && (
                  <Field
                    name="amount"
                    component={ InputTextField }
                    label="Monto"
                    type="number"
                    required
                  />
                )}

              </Grid>
            </Grid>
          </Content>
          {/* Footer */}
          <Content disablePadding>
            <ButtonsGroup align="right">
              <Button
                color="secondary"
                onClick={ () => setDiscountNew(false) }
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                disabled={ rest.invalid }
              >
                Guardar
              </Button>
            </ButtonsGroup>
          </Content>
        </form>
      </Modal>

      <Box className={ classes.discountListOptions }>

        {/* Add custom Discount */}
        <Chip
          label="Nuevo"
          clickable
          color="primary"
          variant="default"
          disabled={ disabled }
          onClick={ () => setDiscountNew(true) }
          icon={<AddCircleOutlineIcon />}
        />

        {/* Discounts list */}
        {discounts.map(discount => {
          return (
            <Chip
              key={ `${ discount.type } ${ discount.value }` }
              label={ handleDiscountLabel(discount) }
              onClick={ () => { setDiscountSelected(discount); onSelect(discount) } }
              clickable={ discount !== discountSelected }
              color={ discount === discountSelected ? 'primary' : 'secondary' }
              variant='outlined'
              disabled={ disabled }
              onDelete={ discount === discountSelected ? () => { setDiscountSelected(null); onSelect(null)  } : null }
            />
          )
        })}

      </Box>
    </Box>
  )

};

CheckoutDiscount = reduxForm({
  form: 'CheckoutDiscount',
  validate,
  initialValues: {
    type: 'percentage'
  }
})(CheckoutDiscount);

export default CheckoutDiscount;
