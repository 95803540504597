import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, query) {
  return request({
    url:    `/product/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params:  query
  });
};

function listByCategoryId(id) {
  return request({
    url:    `/product/list/category/${ id }`,
    headers: { 'Authorization': localStorageToken() },
    method: 'GET'
  });
};

function save(values) {
  return request({
    url:    '/product/save',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

function update(id, values) {
  return request({
    url:    `/product/update/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'PUT',
    data:   values
  });
};

function updateManyPrices(values) {
  return request({
    url:    `/product/update-many-prices`,
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

function updateManyPricesOffer(values) {
  return request({
    url:    `/product/update-many-prices-offer`,
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

function removeManyPricesOffer() {
  return request({
    url:    `/product/remove-many-prices-offer`,
    headers: {'Authorization': localStorageToken()},
    method: 'POST'
  });
};

function find(id) {
  return request({
    url:    `/product/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function filter(params) {
  return request({
    url:    '/product/filter',
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: params
  });
};

function remove(id) {
  return request({
    url:    `/product/remove/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'DELETE'
  });
};

function fields() {
  return request({
    url:    '/product/fields',
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};


const ProductService = {
  list, listByCategoryId, save, update, find, filter, remove, fields,
  updateManyPrices, updateManyPricesOffer, removeManyPricesOffer
};

export default ProductService;
