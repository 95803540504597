import React from 'react';
import { withTranslation } from 'react-i18next';
import { Content, Alert, Table, Row, Cell, Tag, Currency, LinkClient, LinkPayment } from './../../../components/ui';
import { PaymentService } from './../../../services';
import { isPaymentStatus } from './../../../utilities/payment';

import TEXT from './../../../config/text';

class UtilPaymentList extends React.Component {

  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      error: false,
      entities: [],
      thead: [
        { id: 'preference_code', name: this.props.t('field.code') },
        { id: 'client', name: this.props.t('field.customer') },
        { id: 'total', name: this.props.t('field.total') },
        { id: 'payment_status', name: this.props.t('field.status') }
      ]
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {
    this._All(0);
  }

  /*
  ** @Service
  ** @Description: List
  */

  _All = (page) => {

    this._isMounted = true;

    PaymentService.list(page).then((response) => {

      if (this._isMounted) {
        // @State
        // @Description: Set response
        this.setState({
          loading: true,
          entities: response.payments
        });
      }

    }).catch((error) => {

      if (this._isMounted) {
        // @State
        // @Description: Set error
        this.setState({ error: true });
      }

    });

  }

  render(){

    const { loading, error, entities, thead } = this.state;

    return(

      !error ? (
        <Table
          thead={ thead }
          loading={ loading }
        >

          { entities.length > 0 && (
            entities.map(entity => {
              return (
                <Row key={ entity._id }>
                  <Cell>
                    <LinkPayment payment={ entity } />
                  </Cell>
                  <Cell>
                    <LinkClient client={ entity.client }/>
                  </Cell>
                  <Cell>
                    <Currency value={ entity.total }/>
                  </Cell>
                  <Cell>
                    <Tag
                      text={ isPaymentStatus(entity.payment_status).name }
                      color={ isPaymentStatus(entity.payment_status).color }
                    />
                  </Cell>
                </Row>
              )
            })
          )}

          { (loading && entities.length === 0) && (
            <Row>
              <Cell colSpan={ thead.length } align="center">
                { TEXT.message.without_results }
              </Cell>
            </Row>
          )}

        </Table>
      ) : (
        <Content>
          <Alert severity="info">
            <strong>{this.props.t('messages.attention')}:</strong> {this.props.t('messages.information_request_error')}
          </Alert>
        </Content>
      )

    )

  }

}

export default withTranslation()(UtilPaymentList);
