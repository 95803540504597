import React from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change  } from 'redux-form';
import { Grid } from './../../../../../assets/theme/layout';
import { Content, Button, ButtonsGroup } from './../../../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../../../components/ui/input';
import { stringifyQueryString, parseQueryString } from './../../../../../utilities/utils';

import TEXT from './../../../../../config/text';

const validate = values => {

  const errors = {};

  if (values.field && values.field !== 'none') {
    if (values.condition === 'none') {
      errors.condition = TEXT.validation.required;
    }
    if (!values.amount) {
      errors.amount = TEXT.validation.required;
    }else if(values.amount <= 0){
      errors.amount = TEXT.validation.minlength(1);
    }
  }

  if (values.condition && values.condition !== 'none') {
    if (values.field === 'none') {
      errors.field = TEXT.validation.required;
    }
    if (!values.amount) {
      errors.amount = TEXT.validation.required;
    }else if(values.amount <= 0){
      errors.amount = TEXT.validation.minlength(1);
    }
  }

  if (values.amount) {
    if (values.field === 'none') {
      errors.field = TEXT.validation.required;
    }
    if (values.condition === 'none') {
      errors.condition = TEXT.validation.required;
    }
  }

  return errors;

}

class UtilPreferenceFilter extends React.Component {

  componentDidMount(){

    const search = parseQueryString(this.props.location.search);

    Object.entries(search).forEach(([key,value]) => {
      this.props.dispatch(change(this.props.form, key, value));
    });

  }

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {
    this.props.history.push(`/api/todopago/checkout?${ stringifyQueryString(values) }`);
    this.props.onSuccess();
  }

  render() {

    const { handleSubmit, invalid } = this.props;

    return(

      <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

        <Content disablePadding divider>
          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="preference_code"
                  component={ InputTextField }
                  label="Código"
                  type="text"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="status"
                  component={ SelectField }
                  label="Estado">
                    <OptionField value="none">Todos</OptionField>
                    <OptionField value="pending">Pendiente</OptionField>
                    <OptionField value="approved">Aprobado</OptionField>
                    <OptionField value="failure">Fallido</OptionField>
              </Field>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Field
                  name="field"
                  component={ SelectField }
                  label="Campo">
                    <OptionField value="none">Seleccionar</OptionField>
                    <OptionField value="subtotal">Subtotal</OptionField>
                    <OptionField value="descuento">Descuento</OptionField>
                    <OptionField value="total">Total</OptionField>
              </Field>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Field
                  name="condition"
                  component={ SelectField }
                  label="Condición">
                    <OptionField value="none">Seleccionar</OptionField>
                    <OptionField value="greater">Mayor que</OptionField>
                    <OptionField value="smaller">Menor que</OptionField>
                    <OptionField value="equal">Igual que</OptionField>
              </Field>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Field
                  name="amount"
                  component={ InputTextField }
                  label="Monto"
                  type="number"
                  min={1}
              />
            </Grid>
          </Grid>
        </Content>

        <Content disablePadding>
          <ButtonsGroup align="right">
            <Button aria-label="Aplicar" icon="save" color="primary" type="submit" disabled={ invalid }>
              Aplicar
            </Button>
          </ButtonsGroup>
        </Content>

      </form>

    )
  }

}

UtilPreferenceFilter = reduxForm({
  form: 'UtilPreferenceFilter',
  validate,
  initialValues: {
    enabled: 'none',
    field: 'none',
    condition: 'none'
  },
  enableReinitialize: true
})(UtilPreferenceFilter);

export default withRouter(UtilPreferenceFilter);
