import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Typography } from './../../../assets/theme/default';
import { withTranslation } from 'react-i18next';
import { Content, Button, ButtonsGroup, Loading } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { UserService } from './../../../services';
import { isPasswordValid } from './../../../utilities/utils';

import TEXT from './../../../config/text';

const validate = values => {
  const errors = {};

  if (!values.password) {
    errors.password = TEXT.validation.required;
  } else if( !isPasswordValid(values.password) ){
    errors.password = TEXT.validation.wrong_password;
  }

  if (!values.repeat_password) {
    errors.repeat_password = TEXT.validation.required;
  } else if (values.repeat_password !== values.password) {
    errors.repeat_password = TEXT.validation.mismatched_password;
  }

  return errors;

}

class Util__UserPassword extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      submited: false
    };
  }

  /*
  ** @Service
  ** @Description: Change password
  */

  _Password = (values) => {

    // @State
    // @Description: Loading show
    this.setState({ loading: true, submited: true });

    let toSend = Object.assign({
      id: this.props.id
    }, values);

    // @Service
    UserService.password(toSend).then((response) => {

      // @Dispatch alert success
      this.props.alertShow({
        open: true,
        severity: 'success',
        message: this.props.t('messages.new_password_success')
      });

      // @Props
      this.props.onSuccess();

    }).catch((error) => {

      // @State
      // @Description: Loading hide
      this.setState({ loading: false, submited: false });

      // @Dispatch alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: TEXT.message.error
      });

    });

  }

  /*
  ** @Submit
  ** @Description: Submit password form
  */

  handleSubmitForm = (values) => {
    this._Password(values);
  }

  /*
  ** @Submit
  ** @Description: Cancel password form
  */

  handleCancel = () => {
    this.props.onCancel();
  }

  render() {

    const { handleSubmit, invalid, t } = this.props;
    const { loading, submited } = this.state;

    return(

      <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

        <Content disablePadding divider>

          { (submited || loading) && (
            <Loading variant="absolute"/>
          )}

          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Field
                name="password"
                component={ InputTextField }
                label={t('field.new_password')}
                type="password"
                required
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Field
                name="repeat_password"
                component={ InputTextField }
                label={t('field.repeat_password')}
                type="password"
                required
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Field
                name="password_reset"
                component={ SelectField }
                label={t('text.change_password_on_signin')}
              >
                  <OptionField value="true">{t('field.option_field.yes')}</OptionField>
                  <OptionField value="false">{t('field.option_field.no')}</OptionField>
              </Field>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="caption" align="center" component="p">
                { TEXT.message.pattern_password }
              </Typography>
            </Grid>
          </Grid>
        </Content>

        <Content disablePadding>
          <ButtonsGroup align="right">
            <Button
              color="secondary"
              onClick={ this.handleCancel }
              disabled={ submited }
            >
              {t('button.cancel')}
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={ invalid || submited }
            >
              {t('button.confirm')}
            </Button>
          </ButtonsGroup>
        </Content>

      </form>

    )
  }

}

Util__UserPassword = reduxForm({
  form: 'Util__UserPassword',
  validate,
  initialValues: {
    password_reset: 'false'
  },
  enableReinitialize: true
})(Util__UserPassword);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Util__UserPassword));
