import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { Grid } from './../../../../assets/theme/layout';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, ButtonsGroup } from './../../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../../components/ui/input';
import { CountrySelect } from './../../../../scenes';
import { ShippingService } from './../../../../services';
import { findPermissionProps } from './../../../../utilities/utils';

import API from './../../../../config/api';
import TEXT from './../../../../config/text';

const selector = formValueSelector('Page__ShippingLocationForm');

const validate = values => {

  const errors = {};

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 1024){
    errors.name = TEXT.validation.maxlength(1024);
  }

  return errors;

}

class Page__ShippingLocationForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        title: '...',
        loading: true,
        submited: false,
        id: null,
        entity: null,
        categories: [],
        images: [],
        country: null,
        countries: [],
        states: []
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.shipping_location, "Puertos") });

      const id = this.props.match.params.id;

      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false, title: 'Nuevo registro' });
      }

    };

    /*
    ** @Service
    ** @Description: Save Location
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      ShippingService.locationSave(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        // @Redirect
        this.props.history.push('/locations');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Location
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      ShippingService.locationUpdate(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        // @Redirect
        this.props.history.push('/locations');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Location
    */

    _Find = (id) => {

      // @Service
      ShippingService.locationFind(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set note update into state
        this.setState({
          loading: false,
          id: response.location._id,
          entity: response.location,
          title: response.location.name
        });

        // @Form
        // @Description: Set form fields

        // @General
        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));
        this.props.dispatch(change(form, 'prefix', this.state.entity.prefix ));

        if(this.state.entity.country){
          this.props.dispatch(change(form, 'country', this.state.entity.country._id ));
        }

      }).catch((error) => {

        // @State
        // @Description:
        this.setState({ loading: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };

    // @Render
    render(){

      const { handleSubmit, invalid } = this.props;
      const { permission, name, loading, title, submited } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/locations"
                icon="back"
                size="medium"
              >
                Volver
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              {/* Form Category */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* General */}
                  <Content marginBottom>
                    <Toolbar
                      title="General"
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Field
                          name="name"
                          component={ InputTextField }
                          label="Nombre"
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Field
                          name="prefix"
                          component={ InputTextField }
                          label="Prefijo"
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Field
                          name="country"
                          component={ CountrySelect }
                          label="País"
                          required
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label="Estado"
                          required
                        >
                          <OptionField value="true">Activo</OptionField>
                          <OptionField value="false">Bloqueado</OptionField>
                        </Field>
                      </Grid>
                    </Grid>
                  </Content>

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/locations"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      Guardar
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__ShippingLocationForm = reduxForm({
  form: 'Page__ShippingLocationForm',
  validate,
  initialValues: {
    enabled: true,
    address: {}
  }
})(Page__ShippingLocationForm);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

Page__ShippingLocationForm = connect(
  state => {
    const user = selector(state, 'user') || null;
    return { user }
  }
)(Page__ShippingLocationForm);

export default connect(mapStateToProps, mapDispatchToProps)(Page__ShippingLocationForm);
