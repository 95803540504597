import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { Box, Alert } from './../../../assets/theme/default';
import { Grid } from './../../../assets/theme/layout';
import { Button, Toolbar } from './../../../components/ui';
import { InputTextField } from './../../../components/ui/input';
import { AuthService } from './../../../services';
import { isEmail } from './../../../utilities/utils';

import TEXT from './../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.email) {
    errors.email = TEXT.validation.required;
  } else if (isEmail(values.email)) {
    errors.email = TEXT.validation.email_invalid;
  }

  if (!values.password) {
    errors.password = TEXT.validation.required;
  }

  return errors;

}

class LoginAuthentication extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      error: false,
      message: null
    };
  }

  /*
  ** @Service
  ** @Description: Authentication
  */
  _Auth = (values) => {

    // @State
    // @Description: Set show loading
    this.setState({ loading: true });

    AuthService.login(values).then((response) => {

      if(response.status){

        if(!response.user.password_reset){

          // @Language Default
          this.props.i18n.changeLanguage(response.user.language);

          // @LocalStorage
          localStorage.setItem('user', JSON.stringify(response.user));
          localStorage.setItem('token', JSON.stringify(response.token));
          localStorage.setItem('settings', JSON.stringify(response.settings));

          // @Props
          this.props.userSet(response);
          this.props.settingsSet(response.settings);

          // @Redirect
          if (this.props.location.state && this.props.location.state.from) {
            this.props.history.push(this.props.location.state.from);
          } else {
            this.props.history.push('/dashboard');
          }
          
        }else{

          this.props.onResetPassword({
            email: response.user.email
          });

        }

      }else{

        // @State
        // @Description: Set hide loading
        this.setState({ loading: false, error: true, message: TEXT.message.error });

      }

    }).catch((error) => {

      let message = TEXT.message.error;

      if(error.data.code === 'USER_WRONG_CREDENTIALS'){
        message = 'Usuario y/o contraseña no válidas.'
      }

      if(error.data.code === 'ENTITY_NOT_FOUNDED'){
        message = 'Usuario no encontrado.'
      }

      if(error.data.code === 'USER_BLOCK_CREDENTIALS'){
        message = 'Usuario bloqueado.'
      }

      // @State
      // @Description: Set hide loading
      this.setState({ loading: false, error: true, message: message });

    });

  }

  /*
  ** @Handle
  ** @Description: Submit Form
  */

  handleSubmitForm = (values) => {
    this._Auth(values);
  }

  render() {

    const { handleSubmit, invalid, t }  = this.props;
    const { loading, error, message }  = this.state;

    return (
      <Box>

        <Toolbar
          style={{ textAlign: 'center' }}
          title={t('page.login.title')}
        />

        <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>
          <Grid container spacing={3}>

            { error && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Alert severity="error">{ message }</Alert>
              </Grid>
            )}

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Field
                name="email"
                component={ InputTextField }
                label={t('field.email')}
                autoComplete="username"
                type="text"
              />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Field
                name="password"
                component={ InputTextField }
                label={t('field.password')}
                autoComplete={ { 'autoComplete': 'current-password' } }
                type="password"
              />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                fullWidth
                size="large"
                type="submit"
                disabled={ invalid }
                loading={ loading }
              >{t('button.signin')}</Button>
            </Grid>

          </Grid>
        </form>

      </Box>
    )
  }
}

LoginAuthentication = reduxForm({
  form: 'LoginAuthentication',
  validate
})(LoginAuthentication);

const mapStateToProps = (state) => {
  return {
    user: state.userState
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userSet: (data) => {
      dispatch({
        type: 'USER_SET',
        data: data
      });
    },
    settingsSet: (data) => {
      dispatch({
        type: 'SETTINGS_SET',
        data: data
      });
    }
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginAuthentication)));
