import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field, change, FieldArray, formValueSelector } from 'redux-form';
import { Grid } from './../../../../assets/theme/layout';
import { Divider } from './../../../../assets/theme/default';
import { InputAdornment } from './../../../../assets/theme/input';
import { Content, Loading, Button, Toolbar, Modal, ButtonsGroup, FieldsCustomArray } from './../../../../components/ui';
import { InputTextField, InputDateField, SelectField, OptionField, RadioGroupField, RadioField, InputContent } from './../../../../components/ui/input';
import { CountrySelect, StateSelect, EntityAutocomplete, FieldCustomList, FieldCustomForm } from './../../../../scenes';
import { ClientService } from './../../../../services';
import { isNumber, isEmail, numberStartWithTrueNumber } from './../../../../utilities/utils';

import API from './../../../../config/api';
import TEXT from './../../../../config/text';

const selector = formValueSelector('Component__ClientForm');

const validate = values => {

  const errors = {};

  if (!values.identifire) {
    errors.identifire = TEXT.validation.required;
  }else if(values.identifire.length < 7){
    errors.identifire = TEXT.validation.minlength(7);
  }else if(values.identifire.length > 8){
    errors.identifire = TEXT.validation.maxlength(8);
  }

  if (values.reference) {
    if (values.reference.length > 255) {
      errors.reference = TEXT.validation.maxlength(255);
    }
  }

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 1024){
    errors.name = TEXT.validation.maxlength(1024);
  }

  if (!values.surname) {
    errors.surname = TEXT.validation.required;
  }else if(values.surname.length > 1024){
    errors.surname = TEXT.validation.maxlength(1024);
  }

  if (!values.email) {
    errors.email = TEXT.validation.required;
  } else if (isEmail(values.email)) {
    errors.email = TEXT.validation.email_invalid;
  } else if (values.email.length > 255){
    errors.email = TEXT.validation.maxlength(255);
  }

  if (values.genre) {
    if (values.genre === '-1') {
      errors.genre = TEXT.validation.required;
    }
  }

  // @Mobile Phone
  if (values.mobile_phone) {
    if (values.mobile_phone.length < 10) {
      errors.mobile_phone = TEXT.validation.minlength(10);
    } else if (values.mobile_phone.length > 12) {
      errors.mobile_phone = TEXT.validation.maxlength(12);
    } else if (!isNumber(Number(values.mobile_phone))) {
      errors.mobile_phone = TEXT.validation.number;
    } else if (!numberStartWithTrueNumber(values.mobile_phone)) {
      errors.mobile_phone = 'El número debe comenzar con una característica válida';
    }
  }

  // @Address
  if (values.address_street) {
    if (values.address_street.length > 255) {
      errors.address_street = TEXT.validation.maxlength(255);
    }
  }

  if (values.address_number) {
    if (values.address_number.length > 255) {
      errors.address_number = TEXT.validation.maxlength(255);
    }
  }

  if (values.address_floor) {
    if (values.address_floor.length > 255) {
      errors.address_floor = TEXT.validation.maxlength(255);
    }
  }

  if (values.zip) {
    if (values.zip.length > 10) {
      errors.zip = TEXT.validation.maxlength(10);
    }
  }

  if (values.country) {
    if (values.country === '-1') {
      errors.country = TEXT.validation.required;
    }
  }

  if (values.state) {
    if (values.state.length > 255) {
      errors.state = TEXT.validation.maxlength(255);
    }
  }

  if (values.city) {
    if (values.city.length > 255) {
      errors.city = TEXT.validation.maxlength(255);
    }
  }

  return errors;

}

class Component__ClientForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: true,
        submited: false,
        id: null,
        entity: null,
        country: null,
        countries: [],
        states: [],
        fieldFormShow: false
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      const id = this.props.match.params.id || this.props.id;

      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false });
      }

    };

    /*
    ** @Service
    ** @Description: Save Client
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      ClientService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        if(this.props.onSubmit){
          this.props.onSubmit(response.client);
        }

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Client
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      ClientService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        if(this.props.onSubmit){
          this.props.onSubmit(response.client);
        }

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Client
    */

    _Find = (id) => {

      // @Service
      ClientService.find(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set category update into state
        this.setState({
          loading: false,
          id: response.client._id,
          entity: response.client
        });

        // @Form
        // @Description: Set form fields

        // @Personal
        this.props.dispatch(change(form, 'reference', this.state.entity.reference ));
        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'surname', this.state.entity.surname ));
        this.props.dispatch(change(form, 'genre', this.state.entity.genre ));
        this.props.dispatch(change(form, 'identifire', this.state.entity.identifire ));
        this.props.dispatch(change(form, 'birthdate', this.state.entity.birthdate ));

        // @Contact
        this.props.dispatch(change(form, 'email', this.state.entity.email ));
        this.props.dispatch(change(form, 'phone', this.state.entity.phone ));
        this.props.dispatch(change(form, 'mobile_phone', this.state.entity.mobile_phone ));

        // @Address
        this.props.dispatch(change(form, 'address_street', this.state.entity.address_street ));
        this.props.dispatch(change(form, 'address_number', this.state.entity.address_number ));
        this.props.dispatch(change(form, 'address_floor', this.state.entity.address_floor ));
        this.props.dispatch(change(form, 'address_apartment', this.state.entity.address_apartment ));
        this.props.dispatch(change(form, 'zip', this.state.entity.zip ));
        this.props.dispatch(change(form, 'city', this.state.entity.city ));

        // @config
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));
        this.props.dispatch(change(form, 'verified', this.state.entity.verified ));
        this.props.dispatch(change(form, 'newsletter', this.state.entity.newsletter ));
        this.props.dispatch(change(form, 'entity', this.state.entity.entity ));
        this.props.dispatch(change(form, 'fields', this.state.entity.fields ));

        // @Country
        if(this.state.entity.country){

          // @Form
          // @Description: Set country id
          this.props.dispatch(change(form, 'country', this.state.entity.country._id ));

          // @Store
          // @Description: Set country id
          this.setState({ country: this.state.entity.country._id });

        }

        // @State
        if(this.state.entity.state){

          // @Form
          // @Description: Set country id
          this.props.dispatch(change(form, 'state', this.state.entity.state._id ));

          // @Store
          // @Description: Set country id
          this.setState({ country: this.state.entity.country._id });

        }

        // @Event
        // @Description: Send client to parent
        if(this.props.onEdit){
          this.props.onEdit(response.client);
        }

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

        // @Event
        // @Description: Send error to parent
        if(this.props.onError){
          this.props.onError();
        };

      });

    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };

    /*
    ** @Submit
    ** @Description: Cancel form
    */
    handleCancel = () => {

      if(this.props.onCancel){
        this.props.onCancel();
      }else{
        this.props.history.push('/clients');
      }

    };

    // @Event
    // @Description: On change country
    handleOnChangeCountry = (event) => {

      // @Store
      // @Description: Set country id
      this.setState({ country: event.target.value });

      // @Form
      // @Description: Set state id
      this.props.dispatch(change(this.props.form, 'state', '' ));

    };

    // @Event
    // @Description: Load default country
    handleOnLoadCountry = (country) => {

      if(country){
        // @Store
        // @Description: Set country id
        this.setState({ country: country._id });

        // @Form
        // @Description: Set country id
        this.props.dispatch(change(this.props.form, 'country', country._id ));
      }

    };

    /*
    ** @Event
    ** @Description: On selected entity
    */
    handleOnSelectEntity = (entity) => {

      // @Form
      // @Description: Set client ID
      this.props.dispatch(change(this.props.form, 'entity', entity ? entity._id : '' ));

    };

    /*
    ** @Event
    ** @Description: Show Form Fields
    */

    handleField = () => {
      this.setState({ fieldFormShow: true });
    };

    /*
    ** @Event
    ** @Description: Close Form Fields
    */

    handleFieldClose = () => {
      this.setState({ fieldFormShow: false });
    };

    /*
    ** @Event
    ** @Description: OnChange Custom Field
    */

    handleOnSubmitCustomField = (fields) => {
      this.props.dispatch(change(this.props.form, 'fields', fields ));
      this.handleFieldClose();
    };

    /*
    ** @Event
    ** @Description: OnChange Field Enabled
    */

    handleChangeEnabled = (event) => {
      this.props.dispatch(change(this.props.form, 'notification_email', 'false' ));
    };

    /*
    ** @Event
    ** @Description: OnChange Field Verified
    */

    handleChangeVerified = (event) => {
      this.props.dispatch(change(this.props.form, 'notification_email', 'false' ));
    };

    // @Render
    render(){

      const { handleSubmit, invalid, isModal, entityAsCompany, fields, enabled, verified } = this.props;
      const { entity, loading, submited, country, fieldFormShow } = this.state;

      let styleIsModalBody   = {};
      let styleIsModalFooter = {};

      if(isModal){

        styleIsModalBody.paddingTop   = 0;
        styleIsModalBody.paddingRight = 0;
        styleIsModalBody.paddingLeft  = 0;

        styleIsModalFooter.paddingBottom = 0;
        styleIsModalFooter.paddingRight = 0;
        styleIsModalFooter.paddingLeft  = 0;

      }

      return(
        <React.Fragment>
          <Modal
            title={this.props.t('text.custom_fields')}
            status={fieldFormShow}
            handleCancel={() => this.handleFieldClose()}
          >
            <FieldCustomForm
              component={API.permissions.clients}
              currentValues={fields}
              onSubmit={this.handleOnSubmitCustomField}
            />
          </Modal>
          <form noValidate autoComplete="nope" onSubmit={ handleSubmit(this.handleSubmitForm) }>
            <Content divider disableMargin style={styleIsModalBody}>

              { (submited || loading) && (
                <Loading variant="absolute"/>
              )}

              {/* Personal */}
              <Content disablePadding divider>
                <Toolbar
                  title={this.props.t('text.personal')}
                  size="small"
                />
                <Grid container spacing={3}>
                  <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                    <Field
                      name="name"
                      component={ InputTextField }
                      label={this.props.t('field.name')}
                      type="text"
                      required
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                    <Field
                      name="surname"
                      component={ InputTextField }
                      label={this.props.t('field.surname')}
                      type="text"
                      required
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
                    <Field
                      name="identifire"
                      component={ InputTextField }
                      label={this.props.t('field.document_number')}
                      type="text"
                      required
                      onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
                    <Field
                      name="genre"
                      component={ SelectField }
                      label={this.props.t('field.gender')}
                      required
                    >
                      <OptionField value="-1">{this.props.t('field.option_field.select')}</OptionField>
                      <OptionField value="famale">{this.props.t('field.option_field.female')}</OptionField>
                      <OptionField value="male">{this.props.t('field.option_field.male')}</OptionField>
                      <OptionField value="none">{this.props.t('field.option_field.none')}</OptionField>
                    </Field>
                  </Grid>
                  <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
                    <Field
                      name="birthdate"
                      component={ InputDateField }
                      label={this.props.t('field.birthdate')}
                      type="text"
                      required
                      disableFuture
                    />
                  </Grid>
                </Grid>
              </Content>

              {/* Contact information */}
              <Content disablePadding divider>
                <Toolbar
                  title={this.props.t('text.contact')}
                  size="small"
                />
                <Grid container spacing={3}>
                  <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <Field
                      name="email"
                      component={ InputTextField }
                      label="Email"
                      type="email"
                      required
                    />
                  </Grid>
                  <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <Field
                      name="phone"
                      component={ InputTextField }
                      label={this.props.t('field.phone')}
                      placeholder="&nbsp;Ej. 45881211"
                      type="text"
                    />
                  </Grid>
                  <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <Field
                      name="mobile_phone"
                      component={ InputTextField }
                      label={this.props.t('field.mobile_phone')}
                      placeholder="&nbsp;Ej. 1157341234"
                      type="text"
                      startAdornment={ <InputAdornment position="start">0</InputAdornment> }
                    />
                  </Grid>
                </Grid>
              </Content>

              {/* Location */}
              <Content disablePadding divider>
                <Toolbar
                  title={this.props.t('text.address')}
                  size="small"
                />
                <Grid container spacing={3}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Field
                      name="address_street"
                      component={ InputTextField }
                      label={this.props.t('field.street')}
                      type="text"
                      autoComplete="no"
                    />
                  </Grid>
                  <Grid item xl={4} lg={5} md={12} sm={12} xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Field
                          name="address_number"
                          component={ InputTextField }
                          label={this.props.t('field.number')}
                          type="text"
                          autoComplete="no"
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                        <Field
                          name="address_floor"
                          component={ InputTextField }
                          label={this.props.t('field.floor')}
                          type="text"
                          autoComplete="no"
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                        <Field
                          name="address_apartment"
                          component={ InputTextField }
                          label={this.props.t('field.apartment')}
                          type="text"
                          autoComplete="no"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                    <Field
                      name="zip"
                      component={ InputTextField }
                      label={this.props.t('field.postal_code')}
                      type="text"
                      autoComplete="no"
                      placeholder="Ej. C1123 o 1123"
                    />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                    <Field
                      name="country"
                      component={ CountrySelect }
                      label={this.props.t('field.country')}
                      onLoad={ this.handleOnLoadCountry }
                      onChange={ this.handleOnChangeCountry }
                    />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                    <Field
                      name="state"
                      component={ StateSelect }
                      label={this.props.t('field.state')}
                      country={ country }
                    />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                    <Field
                      name="city"
                      component={ InputTextField }
                      label={this.props.t('field.city')}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Content>

              {/* Información de configuración */}
              <Content disablePadding divider>
                <Toolbar
                  title={this.props.t('text.configuration')}
                  size="small"
                />
                <Grid container spacing={3}>
                  <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                    <EntityAutocomplete
                      entity={entityAsCompany}
                      onSelect={this.handleOnSelectEntity}
                    />
                    <Field
                        name="entity"
                        component={ InputTextField }
                        type="text"
                        required
                        fieldHidden
                    />
                  </Grid>

                  <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
                    <Field
                      name="reference"
                      component={ InputTextField }
                      label={this.props.t('field.reference')}
                      type="text"
                      placeholder={this.props.t('text.system_reference')}
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                    <Field
                      name="enabled"
                      component={ SelectField }
                      label={this.props.t('field.status')}
                      required
                      onChange={this.handleChangeEnabled}
                    >
                      <OptionField value="true">{this.props.t('field.option_field.active')}</OptionField>
                      <OptionField value="false">{this.props.t('field.option_field.disabled')}</OptionField>
                    </Field>
                  </Grid>
                  <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                    <Field
                      name="verified"
                      component={ SelectField }
                      label={this.props.t('field.verified')}
                      required
                      onChange={this.handleChangeVerified}
                    >
                      <OptionField value="true">{this.props.t('field.option_field.yes')}</OptionField>
                      <OptionField value="false">{this.props.t('field.option_field.no')}</OptionField>
                    </Field>
                  </Grid>
                  <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                    <Field
                      name="newsletter"
                      component={ SelectField }
                      label={this.props.t('field.newsletter')}
                      required
                    >
                      <OptionField value="true">{this.props.t('field.option_field.active')}</OptionField>
                      <OptionField value="false">{this.props.t('field.option_field.disabled')}</OptionField>
                    </Field>
                  </Grid>
                </Grid>
              </Content>

              {/* Información de configuración */}
              <Content disablePadding divider>
                <Toolbar
                  title={this.props.t('text.notifications')}
                  size="small"
                />
                <Grid container spacing={3}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <InputContent>
                          <Field
                            name="notification_email"
                            component={RadioGroupField}
                          >
                            <RadioField
                              value="welcome"
                              label={`${this.props.t('text.new_account')} ${(entity && entity.registered) ? this.props.t('text.active_account') : ''}`}
                              disabled={enabled === false || enabled === 'false'}
                            />
                            <Divider/>
                            <RadioField
                              value="verified"
                              label={`${this.props.t('text.account_verification')} ${(entity && entity.verified) ? this.props.t('text.account_verified') : ''}`}
                              disabled={
                                !entity ||
                                (entity && (
                                  (enabled === false || enabled === 'false') || (entity && (verified === false || verified === 'false'))
                                ))
                              }
                            />
                          </Field>
                        </InputContent>
                      </Grid>

                  </Grid>
                </Grid>
              </Content>

              {/* Fields custom */}
              <Content disablePadding>
                <Grid container spacing={0}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FieldCustomList
                      component={API.permissions.clients}
                      currentValues={fields || []}
                      onOpen={this.handleField}
                    />
                    <FieldArray
                      name="fields"
                      component={FieldsCustomArray}
                    />
                  </Grid>
                </Grid>
              </Content>


            </Content>

            {/* Footer */}
            <Content style={styleIsModalFooter}>
              <ButtonsGroup align="right">
                <Button
                  onClick={ this.handleCancel }
                  color="danger"
                  size="medium"
                  icon="cancel"
                >
                  {this.props.t('button.cancel')}
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  icon="add"
                  color="success"
                  disabled={ invalid || submited }
                >
                  {this.props.t('button.save')}
                </Button>
              </ButtonsGroup>
            </Content>
          </form>
        </React.Fragment>
      )
    }

};

Component__ClientForm = reduxForm({
  form: 'Component__ClientForm',
  validate,
  initialValues: {
    enabled: true,
    verified: false,
    newsletter: false,
    welcome_email: false
  }
})(Component__ClientForm);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

Component__ClientForm = connect(
  state => {
    const entityAsCompany = selector(state, 'entity') || null;
    const fields = selector(state, 'fields') || [];
    const enabled = selector(state, 'enabled');
    const verified = selector(state, 'verified');
    const notification_email = selector(state, 'notification_email');
    return { entityAsCompany, fields, enabled, verified, notification_email }
  }
)(Component__ClientForm);

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Component__ClientForm)));
