import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, params) {
  return request({
    url:    `/category/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: params
  });
}

function listByIds(ids) {
  return request({
    url:    '/category/list/ids',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   ids
  });
}

function all() {
  return request({
    url:    '/category/all',
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function save(values) {
  return request({
    url:    '/category/save',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
}

function update(id, values) {
  return request({
    url:    `/category/update/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'PUT',
    data:   values
  });
}

function find(id) {
  return request({
    url:    `/category/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function remove(id) {
  return request({
    url:    `/category/remove/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'DELETE'
  });
}

const CategoryService = {
  list, listByIds, all, save, update, find, remove
}

export default CategoryService;
