import request from './../../../utilities/request';
import localStorageToken from './../../../utilities/storage';

function find(id) {
  return request({
    url:    `/site/overview/${id}`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function update(id, values) {
  return request({
    url:    `/site/update/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'PUT',
    data:   values
  });
};

const SettingsService = {
  find, update
};

export default SettingsService;
