import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, query) {
  return request({
    url:    `/lead/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: query
  });
};

function find(id) {
  return request({
    url:    `/lead/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function convert(id) {
  return request({
    url:    `/lead/convert/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function sources() {
  return request({
    url:    '/lead/sources',
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function generateTokenReport(values) {
  return request({
    url:    '/lead/report/token',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

const LeadService = {
  list, find, convert, sources, generateTokenReport
};

export default LeadService;
