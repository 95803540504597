import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Page, Wrapper, Content, Paper, Alert, AlertConfirm, Loading, Button, ButtonsGroup, Toolbar, Tabs, Tab, TabPanel } from './../../../components/ui';
import { ClientService } from './../../../services';
import { hasPermission } from './../../../utilities/utils';

import UtilClientData from './../section/data';
import UtilClientPayment from './../section/payment';
import UtilClientItem from './../section/item';
import UtilClientSupport from './../section/support';

import API from './../../../config/api';
import TEXT from './../../../config/text';

class Page__ClientProfile extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        id: null,
        loading: false,
        title: '...',
        subtitle: '...',
        entity: null,
        section: 0,
        clientActiveShow: false,
        clientEliminateShow: false
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    componentDidUpdate(prev_props){
      if(this.props.match.params.section !== prev_props.match.params.section ){
        this._Update(this.props.match.params.section);
      }
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      const id = this.props.match.params.id;
      const section = this.props.match.params.section;

      if(id){

        // @Service
        this._Find(id);
        this._Update(section);

      }else{

        // @Redirect
        // @Descripcion:
        this.props.history.push('/clients');

      }

    };

    /*
    ** @Event
    ** @Description: Update
    */

    _Update = (section) => {
      let stateIndex;
      switch(section){
        case 'information': stateIndex = 0;
          break;
        case 'payments': stateIndex = 1;
          break;
        case 'products': stateIndex = 2;
          break
        case 'support': stateIndex = 3;
          break
        default: stateIndex = 0;
      };
      this.setState({ section: stateIndex });
    };

    /*
    ** @Service
    ** @Description: Find Client By Id
    */

    _Find = (id) => {

      // @Service
      ClientService.find(id).then((response) => {

        // @State
        // @Description: Set client update into state
        this.setState({
          id: id,
          loading: true,
          title: `${ response.client.name } ${ response.client.surname }`,
          subtitle: response.client.email,
          entity: response.client
        });

      }).catch((error) => {

        // @Redirect
        // @Descripcion:
        this.props.history.push('/clients');

        // @Dispatch
        // @Descripcion: alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    }

    /*
    ** @Service
    ** @Description: Active
    */

    _Active = (id) => {

      ClientService.active(id).then((response) => {

        // @State
        this.setState({
          clientActiveShow: false,
          entity: response.client
        });

        // @Dispatch
        // @Descripcion: Alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: `El cliente fue activado.`
        });

      }).catch((error) => {

        // @Dispatch
        // @Descripcion: Alert errro
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Eliminate
    */

    _Eliminate = (id) => {

      ClientService.eliminate(id).then((response) => {

        // @Redirect
        this.props.history.push('/clients');

        // @Dispatch
        // @Descripcion: Alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: 'El cliente fue eliminado.'
        });

      }).catch((error) => {

        // @Dispatch
        // @Descripcion: Alert errro
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description: Change tab
    */

    handleChangeSection = (event: React.ChangeEvent<{}>, index: number) => {
      switch(index){
        case 0: this.props.history.push(`/clients/profile/${this.state.id}/information`);
          break;
        case 1: this.props.history.push(`/clients/profile/${this.state.id}/payments`);
          break;
        case 2: this.props.history.push(`/clients/profile/${this.state.id}/products`);
          break;
        case 3: this.props.history.push(`/clients/profile/${this.state.id}/support`);
          break;
        default: this.props.history.push(`/clients/profile/${this.state.id}/information`);
      }
    };

    /*
    ** @Event
    ** @Description: Active
    */

    handleActive = () => {
      this.setState({ clientActiveShow: true });
    };

    /*
    ** @Click
    ** @Description: Active confirm
    */

    handleConfirmActiveConfirm = () => {
      this._Active(this.state.id);
    };

    /*
    ** @Click
    ** @Description: Active cancel
    */

    handleConfirmActiveCancel = () => {
      this.setState({ clientActiveShow: false });
    };

    /*
    ** @Event
    ** @Description: Eliminate
    */

    handleEliminate = () => {
      this.setState({ clientEliminateShow: true });
    };

    /*
    ** @Click
    ** @Description: Eliminate confirm
    */

    handleConfirmEliminateConfirm = () => {
      this._Eliminate(this.state.id);
    };

    /*
    ** @Click
    ** @Description: Eliminate cancel
    */

    handleConfirmEliminateCancel = () => {
      this.setState({ clientEliminateShow: false });
    };

    render(){

      const { session } = this.props;
      const { id, loading, title, subtitle, entity, section, clientActiveShow, clientEliminateShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={ title }
              subtitle={ subtitle }
            >
              <ButtonsGroup>
                { loading && (
                  hasPermission(session.user.permissions, API.permissions.clients_management) && (
                    <Button
                      component={ NavLink }
                      to={`/clients/edit/${ entity._id }`}
                      size="medium"
                      icon="edit"
                    >
                      {this.props.t('button.edit')}
                    </Button>
                  )
                )}
              </ButtonsGroup>
            </Toolbar>

            <Content disablePadding>

              { loading ? (
                <React.Fragment>

                  { entity.removed && (
                    <Content disablePadding marginBottom>

                      <Alert
                        severity="info"
                        action={
                          <ButtonsGroup>
                            <Button color="danger" icon="cancel" onClick={ this.handleEliminate }>
                              {this.props.t('button.delete')}
                            </Button>
                            <Button color="success" icon="add" onClick={ this.handleActive }>
                              {this.props.t('button.activate')}
                            </Button>
                          </ButtonsGroup>
                        }
                      >
                        <strong>{this.props.t('text.attention')}</strong> {this.props.t('text.account_inactive')}
                      </Alert>

                      {/* Alert confirm Active */}
                      <AlertConfirm
                         status={ clientActiveShow }
                         message={this.props.t('messages.client_activation_confirm')}
                         cancel={ () => this.handleConfirmActiveCancel() }
                         confirm={ () => this.handleConfirmActiveConfirm() }
                      />

                      {/* Alert confirm Eliminate */}
                      <AlertConfirm
                         status={ clientEliminateShow }
                         message={this.props.t('messages.client_delete_confirm')}
                         cancel={ () => this.handleConfirmEliminateCancel() }
                         confirm={ () => this.handleConfirmEliminateConfirm() }
                      />

                    </Content>
                  )}

                  <Content marginBottom>
                    <Paper disablePadding>
                      <Tabs
                        value={ section }
                        onChange={this.handleChangeSection}
                      >
                        <Tab label={this.props.t('tab.infromation')} />
                        { hasPermission(session.user.permissions, API.permissions.payments) && (
                          <Tab label={this.props.t('tab.payments')} />
                        )}
                        { hasPermission(session.user.permissions, API.permissions.products) && (
                          <Tab label={this.props.t('tab.products')} />
                        )}
                        { hasPermission(session.user.permissions, API.permissions.support) && (
                          <Tab label={this.props.t('tab.support')} />
                        )}
                      </Tabs>
                    </Paper>
                  </Content>

                  <Content disablePadding>
                    <Paper disablePadding>
                      <TabPanel value={ section } index={0}>
                        <UtilClientData client={ entity } />
                      </TabPanel>

                      { hasPermission(session.user.permissions, API.permissions.payments) && (
                        <TabPanel value={ section } index={1}>
                          <UtilClientPayment id={ id } />
                        </TabPanel>
                      )}
                      { hasPermission(session.user.permissions, API.permissions.products) && (
                        <TabPanel value={ section } index={2}>
                          <UtilClientItem id={ id } />
                        </TabPanel>
                      )}
                      { hasPermission(session.user.permissions, API.permissions.support) && (
                        <TabPanel value={ section } index={3}>
                          <UtilClientSupport id={ id } />
                        </TabPanel>
                      )}
                    </Paper>
                  </Content>

                </React.Fragment>
              ) : (

                <Loading/>

              )}

            </Content>


          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__ClientProfile));
