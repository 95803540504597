import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from './../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: { display: 'inline-block', maxWidth: '100%' }
}));

const Image = ({ src, alt, ...rest }) => {

  const classes = useStyles();
  const { className } = rest;

  return (
    <img src={ src } alt={ alt } className={ classNames( classes.root, className ? className : null ) }/>
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default Image;
