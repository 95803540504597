import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change } from 'redux-form';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Typography } from './../../../assets/theme/default';
import { Grid } from './../../../assets/theme/layout';
import { Content, Button, ButtonsGroup, Loading, Date } from './../../../components/ui';
import { InputTextField, InputDateField, SelectField, OptionField } from './../../../components/ui/input';
import { PaymentService } from './../../../services';
import { stringifyQueryString, parseQueryString } from './../../../utilities/utils';
import { paymentsStatus, paymentsFrom } from './../../../utilities/payment';
import { UserSelect } from './../../../scenes';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const validate = values => {

  const errors = {};

  if (values.field && values.field !== 'none') {
    if (values.condition === 'none') {
      errors.condition = TEXT.validation.required;
    }
    if (!values.amount) {
      errors.amount = TEXT.validation.required;
    }else if(values.amount <= 0){
      errors.amount = TEXT.validation.minlength(1);
    }
  }

  if (values.condition && values.condition !== 'none') {
    if (values.field === 'none') {
      errors.field = TEXT.validation.required;
    }
    if (!values.amount) {
      errors.amount = TEXT.validation.required;
    }else if(values.amount <= 0){
      errors.amount = TEXT.validation.minlength(1);
    }
  }

  if (values.amount) {
    if (values.field === 'none') {
      errors.field = TEXT.validation.required;
    }
    if (values.condition === 'none') {
      errors.condition = TEXT.validation.required;
    }
  }

  return errors;

}

class UtilPaymentFilter extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      entity: null,
      paymentsStatus: [],
      paymentsFrom: []
    };
  }

  componentDidMount(){

    const search = parseQueryString(this.props.location.search);

    Object.entries(search).forEach(([key,value]) => {
      this.props.dispatch(change(this.props.form, key, value));
    });

    // @Store
    // @Description: Set Payment status and from
    this.setState({
      paymentsStatus: paymentsStatus(),
      paymentsFrom: paymentsFrom()
    });

  }

  /*
  ** @Service
  ** @Description: Export link to download
  */

  _Export = (values) => {

    // @State
    // @Description: Set loading show
    this.setState({ loading: false });

    // @Service
    // @Description:
    PaymentService.generateTokenReport(values).then((response) => {

      // @State
      // @Description: Set response
      this.setState({
        loading: false,
        entity: response
      });

    }).catch((error) => {

      // @State
      // @Description: Set loading hide
      this.setState({ loading: false });

      // @Dispatch
      // @Description: Show alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: error.data.message
      });

    });

  };

  /*
  ** @Service
  ** @Description: Filter
  */

  _Filter = (values) => {

    // @Redirect
    // @Description: Redirect with query filter
    this.props.history.push(`/payments?${ stringifyQueryString(values) }`);

    // @Props
    // @Description:
    this.props.onSuccess();

  };

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {

    // @If
    // @Description: Filter function
    if(values.action === 'filter'){
      this._Filter(values);
    }

    // @If
    // @Description: Export function
    if(values.action === 'export'){
      this._Export(values);
    }

  };

  /*
  ** @Event
  ** @Description: Copy link report
  */

  handleCopyLink = () => {

    // @Dispatch alert success
    this.props.alertShow({
      open: true,
      severity: 'info',
      message: this.props.t('text.download_link')
    });

  };

  /*
  ** @Event
  ** @Description: Back to form
  */

  handleBackToForm = () => {

    // @State
    // @Description: Set entity null
    this.setState({
      entity: null
    });

  };

  /*
  ** @Event
  ** @Description: On selected user
  */
  handleOnSelectUser = (user) => {
    this.props.dispatch(change(this.props.form, 'user', user ? user._id : '' ));
  };

  render() {
    const { handleSubmit, invalid , session, t } = this.props;
    const { loading, entity, paymentsStatus } = this.state;

    return(

      !loading ? (

        !entity ? (

          <form noValidate autoComplete="off">

            <Content disablePadding divider>
              <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Field
                      name="payment_code"
                      component={ InputTextField }
                      label={t('field.code')}
                      type="text"
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Field
                    name="date_from"
                    component={ InputDateField }
                    label={t('field.from')}
                    type="text"
                    required
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Field
                    name="date_to"
                    component={ InputDateField }
                    label={t('field.to')}
                    type="text"
                    disableFuture
                    required
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Field
                      name="from"
                      component={ SelectField }
                      label={t('field.channel')}>
                        <OptionField value="none">{t('field.option_field.select')}</OptionField>
                        <OptionField value="MERCADOPAGO_PREFERENCE">Mercado Pago</OptionField>
                        <OptionField value="TODOPAGO_PREFERENCE">Todo Pago</OptionField>
                  </Field>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Field
                      name="status"
                      component={ SelectField }
                      label={t('field.status')}>
                        <OptionField value="none">Todos</OptionField>
                        { paymentsStatus.map((status, index) => {
                          return(
                            <OptionField key={ index } value={ status.id }>
                              { status.name }
                            </OptionField>
                          )
                        })}
                  </Field>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Field
                      name="field"
                      component={ SelectField }
                      label={t('field.field')}>
                        <OptionField value="none">{t('field.option_field.select')}</OptionField>
                        <OptionField value="subtotal">{t('field.option_field.subtotal')}</OptionField>
                        <OptionField value="descuento">{t('field.option_field.discount')}</OptionField>
                        <OptionField value="total">{t('field.option_field.total')}</OptionField>
                  </Field>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Field
                      name="condition"
                      component={ SelectField }
                      label={t('field.condition')}>
                        <OptionField value="none">{t('field.option_field.select')}</OptionField>
                        <OptionField value="greater">{t('field.option_field.greater_than')}</OptionField>
                        <OptionField value="smaller">{t('field.option_field.less_than')}</OptionField>
                        <OptionField value="equal">{t('field.option_field.same_as')}</OptionField>
                  </Field>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Field
                      name="amount"
                      component={ InputTextField }
                      label={t('field.amount')}
                      type="number"
                      min={1}
                  />
                </Grid>

                {(session && session.user.profile === 'admin') && (
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Field
                      name="user"
                      component={UserSelect}
                      label={t('field.user')}
                      onChange={this.handleOnSelectUser}
                    />
                  </Grid>
                )}
              </Grid>
            </Content>

            <Content disablePadding>
              <ButtonsGroup align="right">
                <Button
                  aria-label="Exportar"
                  icon="export"
                  color="primary"
                  type="button"
                  disabled={ invalid }
                  onClick={
                    handleSubmit(values =>
                      this.handleSubmitForm({
                        ...values,
                        action: 'export'
                      }
                    ))
                  }
                >
                  {t('button.export')}
                </Button>
                <Button
                  aria-label="Confirmar"
                  icon="save"
                  color="primary"
                  type="button"
                  disabled={ invalid }
                  onClick={
                    handleSubmit(values =>
                      this.handleSubmitForm({
                        ...values,
                        action: 'filter'
                      }
                    ))
                  }
                >
                  {t('button.apply')}
                </Button>
              </ButtonsGroup>
            </Content>

          </form>

        ) : (

          <Content disablePadding disableMargin>

            <Content disablePadding divider>
              <Typography align="center" variant="body2">
                <strong>{t('text.download_link_ready1')},</strong>
                <br/>
                {t('text.download_link_ready2')}
                <br/>
                ({t('text.download_link_ready3')} <Date value={ moment.unix(entity.exp).format() } format="DD/MM/YYYY hh:mm a"/>)
              </Typography>
            </Content>

            <Content disablePadding>
              <ButtonsGroup align="center">
                <Button
                  color="primary"
                  size="medium"
                  component="a"
                  target="_blank"
                  href={ `${ API.endpoint.services }/payment/report/download/${ entity.token }` }
                >
                  {t('button.download')}
                </Button>
                <CopyToClipboard
                  text={ `${ API.endpoint.services }/payment/report/download/${ entity.token }` }
                  onCopy={ this.handleCopyLink }
                >
                  <Button color="primary" size="medium">
                    {t('button.copy')}
                  </Button>
                </CopyToClipboard>
                <Button
                  color="danger"
                  size="medium"
                  onClick={ this.handleBackToForm }
                >
                  {t('button.cancel')}
                </Button>
              </ButtonsGroup>
            </Content>

          </Content>

        )

      ) : (
        <Loading/>
      )

    )
  }

}

UtilPaymentFilter = reduxForm({
  form: 'UtilPaymentFilter',
  validate,
  initialValues: {
    status: 'none',
    field: 'none',
    condition: 'none',
    date_from: moment().subtract(7, 'days').startOf('day').format(),
    date_to: moment().endOf('day').format()
  },
  enableReinitialize: true
})(UtilPaymentFilter);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(UtilPaymentFilter)));
