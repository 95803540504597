import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Box, Typography } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'stretch'
    }
  },
  rowDirection: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  disableMargin: {
    marginBottom: 0
  },
  divider: {
    padding: theme.spacing(2),
    borderBottom: `2px solid ${ theme.palette.border.main }`
  },
  left: {
    flex: 1,
    paddingRight: theme.spacing(2)
  },
    dividerMobile: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${ theme.palette.border.dark }`
      }
    },
    title: {
      //fontSize: theme.typography.h2.fontSize,
      color: theme.palette.secondary.dark,
      fontWeight: theme.typography.fontWeightBold
    },
    large: {
      fontSize: theme.typography.h3.fontSize,
    },
    medium: {
      fontSize: theme.typography.h4.fontSize,
    },
    small: {
      fontSize: theme.typography.h5.fontSize,
    },
    xsmall: {
      fontSize: theme.typography.h6.fontSize,
    },
    subtitle: {
      fontSize: theme.typography.h6.fontSize,
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.2
    },
    subtitleSmall: {
      fontWeight: theme.typography.fontWeightRegular,
    },
  right: {

  }
}));

const Toolbar__c = ({ children, title, subtitle, size = 'large', variant, disableMargin = false, dividerMobile = false, direction, ...rest }) => {

  const classes        = useStyles();
  const _size          = classes[size];
  const _disableMargin = disableMargin ? classes['disableMargin'] : null;
  const _variant       = variant ? classes[variant] : null;
  const _dividerMobile = dividerMobile ? classes['dividerMobile'] : null;
  const _subtitleSize  = size === 'small' ? classes['subtitleSmall'] : null;
  const _direction     = direction ? classes[`${ direction }Direction`] : null;

  return(
    <Box className={classNames(
      classes.root,
      _disableMargin,
      _variant,
      _direction
    )} {...rest}>
      <Box className={ classNames(classes.left, _dividerMobile) }>
        <Typography
          component="h2"
          classes={{
            root: classNames(
              classes.title,
              _size
            )
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        { subtitle && (
          <Typography
            component="p"
            classes={{ root: classNames(classes.subtitle, _subtitleSize) }}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
      </Box>
      { children && (
        <Box className={ classes.right }>
          { children }
        </Box>
      )}
    </Box>
  )

}

Toolbar__c.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  disableMargin: PropTypes.bool,
  dividerMobile: PropTypes.bool,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  direction: PropTypes.oneOf(['row', 'column']),
  variant: PropTypes.oneOf(['divider'])
};

export default Toolbar__c;
