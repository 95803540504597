import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, Modal, Table, Row, Cell, Tag, Date, LinkClient } from './../../../components/ui';
import { LeadService } from './../../../services';
import { isUndefined, parseQueryString, findPermissionProps } from './../../../utilities/utils';
import UtilLeadFilter from './../filter';

import API from './../../../config/api';
import TEXT from './../../../config/text';

class Page__LeadList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        loading: false,
        page: 0,
        total: 0,
        id: null,
        entities: [],
        thead: [
          { id: 'source', name: this.props.t('field.campaign') },
          { id: 'client', name: `${this.props.t('field.name')}/${this.props.t('field.surname')}` },
          { id: 'email', name: this.props.t('field.email') },
          { id: 'mobile_phone', name: this.props.t('field.phone') },
          { id: 'client', name: this.props.t('field.client') },
          { id: 'created_at', name: this.props.t('field.date') },
          { id: 'actions', name: this.props.t('field.actions'), align: 'right' }
        ],
        leadFilterShow: false
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.leads, this.props.t('text.leads')) });

      const page = this.props.match.params.page || 0;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          this._All(page);
        }else{
          this.props.history.push('/leads');
        }
      }else{
        this._All(this.state.page);
      }

    };

    /*
    ** @Service
    ** @Description: FindAll
    */

    _All = (page) => {

      LeadService.list(page, parseQueryString(this.props.location.search)).then((response) => {

        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.leads,
          total: response.total
        });

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/leads/page/${ page }`,
        search: this.props.location.search
      });

    };

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ leadFilterShow: true });
    };

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ leadFilterShow: false });
    };

    render(){

      const { permission, name, loading, entities, thead, total, page, leadFilterShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <ButtonsGroup>
                { this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title="Eliminar filtros"
                    placement="left"
                    component={ NavLink }
                    to="/leads"
                  />
                )}
                <Button
                  icon="filter"
                  size="medium"
                  onClick={ this.handleFilter }
                >
                  {this.props.t('button.filter')}
                </Button>
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

              <Modal
                  status={ leadFilterShow }
                  title={this.props.t('text.filter_leads')}
                  handleCancel={ () => this.handleFilterClose() }
                  maxWidth="sm">
                  <UtilLeadFilter onSuccess={ this.handleFilterClose }/>
              </Modal>

              <Table
                thead={ thead }
                loading={ loading }
                total={ total }
                page={ page }
                onChangePage={ this.handleChangePage }
              >
                { entities.length > 0 && (
                  entities.map(entity => {
                    return (
                      <Row key={ entity._id }>
                        <Cell>
                          { entity.source ? (
                            <Tag
                              text={entity.source}
                              color="primary"
                            />
                          ) : (
                            <React.Fragment>
                              -
                            </React.Fragment>
                          )}
                        </Cell>
                        <Cell>
                          { entity.name } { entity.surname }
                        </Cell>
                        <Cell>{ entity.email }</Cell>
                        <Cell>{ entity.mobile_phone }</Cell>
                        <Cell>
                          { entity.client ? (
                            <LinkClient client={ entity.client }/>
                          ) : (
                            <React.Fragment>
                              -
                            </React.Fragment>
                          )}
                        </Cell>
                        <Cell>
                          <Date value={ entity.created_at } />
                        </Cell>
                        <Cell align="right" nowrap>
                          <Button
                            icon="info"
                            component={ NavLink }
                            title={this.props.t('button.information')}
                            to={ `/leads/detail/${ entity._id }` }
                          />
                        </Cell>
                      </Row>
                    )
                  })
                )}

                { (loading && entities.length === 0) && (
                  <Row>
                    <Cell colSpan={ thead.length } align="center">
                      { TEXT.message.without_results }
                    </Cell>
                  </Row>
                )}

              </Table>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__LeadList));
