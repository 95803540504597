import React from 'react';
import { withStyles, Box } from './../../../assets/theme/default';
import { Image } from './../../../components/ui';
import LoginAuthentication from './../../../scenes/login/authentication';
import LoginResetPassword from './../../../scenes/login/reset-password';
import { Logo } from './../../../assets/images';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  content: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
    brand: {
      width: 190
    },
    center: {
      background: theme.palette.common.white,
      padding: theme.spacing(3),
      margin: theme.spacing(3),
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${ theme.palette.grey.main }`
    },
    form: {
      maxWidth: 380
    }
});

class PageLogin extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      step: 'login',
      params: {}
    };
  }

  /*
  ** @Handle
  ** @Description: On need reset password
  */
  handleOnResetPassword = (params) => {
    this.setState({ step: 'reset_password', params: params });
  }

  /*
  ** @Handle
  ** @Description: On success reset password
  */
  handleOnSuccessPassword = () => {
    this.setState({ step: 'login' });
  }

  render(){

    const { classes } = this.props;
    const { step, params } = this.state;

    return (
      <React.Fragment>
        <Box className={ classes.root }>
          <Box className={ classes.content }>

            <Image src={ Logo } alt="Firehub" className={ classes.brand }/>

            <Box className={ classes.center }>

              <Box className={ classes.form }>

                { step === 'login' && (
                  <LoginAuthentication
                    onResetPassword={ this.handleOnResetPassword }
                  />
                )}

                { step === 'reset_password' && (
                  <LoginResetPassword
                    params={ params }
                    onSuccess={ this.handleOnSuccessPassword }
                  />
                )}

              </Box>

            </Box>

          </Box>
        </Box>
      </React.Fragment>
    )
  }

}

export default withStyles(styles)(PageLogin);
