import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { withTranslation } from 'react-i18next';
import { Typography } from './../../../assets/theme/default';
import { InputAdornment } from './../../../assets/theme/input';
import { AddIcon, RemoveIcon } from './../../../assets/theme/icon';
import { Paper, Content, Loading, Button, Table, Row, Cell, Currency, Alert, AlertConfirm, Tabs, Tab, TabPanel, ButtonsGroup } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { ProductService } from './../../../services';
import { isUndefined, isNumber, isCondition } from './../../../utilities/utils';
import { Percentage, sumByPercentaje, restByPercentaje } from './../../../utilities/operation';

import TEXT from './../../../config/text';

const validate = values => {
  const errors = {};

  if(values.section === 0){
    if (!values.percentage) {
      errors.percentage = TEXT.validation.required;
    } else if (!isNumber(Number(values.percentage))) {
      errors.percentage = TEXT.validation.number;
    } else if(Number(values.percentage) <= 0){
      errors.percentage = TEXT.validation.minlength(1);
    } else if(Number(values.percentage) > 100){
      errors.percentage = TEXT.validation.maxlength(100);
    }
  }

  if(values.section === 1){
    if (!values.percentage_offer) {
      errors.percentage_offer = TEXT.validation.required;
    } else if (!isNumber(Number(values.percentage_offer))) {
      errors.percentage_offer = TEXT.validation.number;
    } else if(Number(values.percentage_offer) <= 0){
      errors.percentage_offer = TEXT.validation.minlength(1);
    } else if(Number(values.percentage_offer) > 100){
      errors.percentage_offer = TEXT.validation.maxlength(100);
    }
  }

  if (values.condition && values.condition !== 'none') {
    if (!values.price) {
      errors.price = TEXT.validation.required;
    }else if(values.price < 1){
      errors.price = TEXT.validation.minlength(1);
    }
  }

  return errors;
};

class PageProductUpdate extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: true,
        step: 1,
        page: 0,
        total: 0,
        entities: [],
        message: null,
        messageSeverity: null,
        section: 0,
        removeManyPricesOfferStatus: false,
        thead: [
          { id: 'sku', name: this.props.t('field.sku') },
          { id: 'name', name: this.props.t('field.name') },
          { id: 'price', name: this.props.t('field.regular_price') },
          { id: 'price_offer', name: this.props.t('field.offer_price') }
        ]
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {
      this._Find(this.state.page);
    };

    /*
    ** @Service
    ** @Description: Update Many Prices
    */

    _UpdateManyPrices = (values) => {
      ProductService.updateManyPrices(values).then((response) => {

        // @Form
        this.props.reset();

        // @Service
        // @Descripcion: Refresh list of products
        this._Find(0);

        // @State
        // @Description: Service success
        this.setState({
          step: 1,
          loading: false,
          message: this.props.t('text.updated'),
          messageSeverity: 'success'
        });

      }).catch((error) => {

        // @State
        // @Description: Service error
        this.setState({
          loading: false,
          messageSeverity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Many Prices Offer
    */

    _UpdateManyPricesOffer = (values) => {

      ProductService.updateManyPricesOffer(values).then((response) => {

        // @Form
        this.props.reset();

        // @Service
        // @Descripcion: Refresh list of products
        this._Find(0);

        // @State
        // @Description: Service success
        this.setState({
          step: 1,
          loading: false,
          message: this.props.t('text.updated'),
          messageSeverity: 'success'
        });

      }).catch((error) => {

        // @State
        // @Description: Service error
        this.setState({
          loading: false,
          messageSeverity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Products
    */

    _RemoveManyPricesOffer = () => {

      // @State
      // @Description: Service start
      this.setState({ loading: true, message: null, messageSeverity: null });

      ProductService.removeManyPricesOffer().then((response) => {

        // @Service
        // @Descripcion: Refresh list of products
        this._Find(0);

        // @State
        // @Description: Service success
        this.setState({
          loading: false,
          message: this.props.t('text.deleted'),
          messageSeverity: 'success',
          removeManyPricesOfferStatus: false
        });

      }).catch((error) => {

        // @State
        // @Description:
        this.setState({
          loading: false,
          messageSeverity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Products
    */

    _Find = (page) => {

      // @State
      // @Description:
      this.setState({ message: null, messageSeverity: null });

      ProductService.list(page, {items: 10}).then((response) => {

        this.setState({
          loading: false,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.products,
          total: response.total
        });

      }).catch((error) => {

        // @State
        // @Description:
        this.setState({
          loading: false,
          messageSeverity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Submit
    ** @Description: Change step
    */
    handleChangeStep = (step) => {
      this.setState({step});
    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {

      // @Condition
      // @Descripcion: Prices
      if(this.state.section === 0){
        this._UpdateManyPrices(values);
      }

      // @Condition
      // @Descripcion: Prices Offer
      if(this.state.section === 1){
        this._UpdateManyPricesOffer(values);
      }

    };

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {
      this.setState({ page: page, loading: false }, () => {
        this._Find(this.state.page);
      });
    };

    /*
    ** @Event
    ** @Description: Change section tab
    */
    handleChangeSection = (event: React.ChangeEvent<{}>, index: number) => {

      // @State
      this.setState({section: index});

      // @Form
      this.props.reset();
      this.props.dispatch(change(this.props.form, 'section', index ));

    };

    /*
    ** @Event
    ** @Description: Delete all price offers
    */
    handleRemoveManyPricesOffer = () => {
      this.setState({removeManyPricesOfferStatus: true});
    };

    handleRemoveManyPricesOfferCancel = () => {
      this.setState({removeManyPricesOfferStatus: false});
    };

    handleRemoveManyPricesOfferConfirm = () => {
      this._RemoveManyPricesOffer();
    };

    /*
    ** @Event
    ** @Description: Close alert message
    */

    handleAlertMessageClose = () => {
      this.setState({ message: null, messageSeverity: null });
    };

    /*
    ** @Event
    ** @Description: OnChange Select Condition
    */

    handleOnChangeCondition = (event) => {
      if(event.target.value === 'none'){
        this.props.dispatch(change(this.props.form, 'price', '0'));
      }
    };

    handleOnChangeOperation = (event) => {
      if(event.target.value === 'none'){
        this.props.dispatch(change(this.props.form, 'operation', '0'));
      }
    };

    render(){

      const { handleSubmit, invalid, percentage, percentageOffer, condition, price, operation, operation_offer, t } = this.props;
      const { loading, thead, submited, entities, total, page, step, message, messageSeverity, section, removeManyPricesOfferStatus } = this.state;
      return(
        <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>
          { !loading ? (

            <Content disablePadding>

              { message && (
                <Content disablePadding marginBottom>
                  <Alert severity={messageSeverity} onClose={this.handleAlertMessageClose}>
                    { message }
                  </Alert>
                </Content>
              )}

              { step === 1 && (
                <React.Fragment>
                  <Content disablePadding>
                    <Paper disablePadding>
                      <Content disablePadding disableMargin divider>

                        <Field name="section" component={InputTextField} fieldHidden/>

                        <Tabs value={section} onChange={this.handleChangeSection} divider>
                          <Tab label={t('field.update_prices')}/>
                          <Tab label={t('field.update_offers')}/>
                        </Tabs>

                        {/* Update Prices */}
                        <TabPanel value={section} index={0}>
                          <Content divider>
                            <Grid container spacing={3}>
                            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                <Field
                                  name="operation"
                                  component={ SelectField }
                                  label={t('field.operation')}
                                  onChange={this.handleOnChangeCondition}
                                >
                                <OptionField value="increment">{t('field.option_field.increment')}</OptionField>
                                <OptionField value="decrease">{t('field.option_field.decrease')}</OptionField>
                                </Field>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                <Field
                                  label={t('field.percentage')}
                                  name="percentage"
                                  component={ InputTextField }
                                  type="number"
                                  required
                                  min={0}
                                  max={100}
                                  startAdornment={ <InputAdornment position="start">
                                      {operation === 'increment' ? <AddIcon/> : <RemoveIcon/>}
                                  </InputAdornment> }
                                />
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                <Field
                                  name="condition"
                                  component={ SelectField }
                                  label={t('field.condition')}
                                  onChange={this.handleOnChangeCondition}
                                >
                                <OptionField value="none">{t('field.option_field.select')}</OptionField>
                                <OptionField value="greater">{t('field.option_field.greater_than')}</OptionField>
                                <OptionField value="smaller">{t('field.option_field.less_than')}</OptionField>
                                <OptionField value="equal">{t('field.option_field.same_as')}</OptionField>
                                </Field>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                <Field
                                    name="price"
                                    component={InputTextField}
                                    label={t('field.price')}
                                    type="number"
                                    min={0}
                                    disabled={condition === 'none'}
                                />
                              </Grid>
                            </Grid>
                          </Content>
                          <Content marginLeft marginRight>
                            <ButtonsGroup align="right">
                              <Button
                                aria-label="Siguiente"
                                icon="forward"
                                color="primary"
                                disabled={invalid || submited}
                                onClick={() => {this.handleChangeStep(2)}}
                              >{t('button.next')}</Button>
                            </ButtonsGroup>
                          </Content>
                        </TabPanel>

                        {/* Update Offers */}
                        <TabPanel value={section} index={1}>

                          {/* Alert confirm Delete Prices Offer */}
                          <AlertConfirm
                             status={removeManyPricesOfferStatus}
                             message={t('messages.confirm_offer_price_delete')}
                             cancel={() => this.handleRemoveManyPricesOfferCancel()}
                             confirm={() => this.handleRemoveManyPricesOfferConfirm()}
                          />

                          <Content divider>
                            <Grid container spacing={3}>
                            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                <Field
                                  name="operation_offer"
                                  component={ SelectField }
                                  label={t('field.operation')}
                                  onChange={this.handleOnChangeCondition}
                                >
                                <OptionField value="increment">{t('field.option_field.increment')}</OptionField>
                                <OptionField value="decrease">{t('field.option_field.decrease')}</OptionField>
                                </Field>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                <Field
                                  label={t('field.percentage')}
                                  name="percentage_offer"
                                  component={ InputTextField }
                                  type="number"
                                  required
                                  min={0}
                                  max={100}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {operation_offer === 'increment' ? <AddIcon/> : <RemoveIcon/>}
                                    </InputAdornment>
                                  }
                                />
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                <Field
                                  name="condition"
                                  component={ SelectField }
                                  label={t('field.condition')}
                                  onChange={this.handleOnChangeCondition}
                                >
                                    <OptionField value="none">{t('field.option_field.select')}</OptionField>
                                    <OptionField value="greater">{t('field.option_field.greater_than')}</OptionField>
                                    <OptionField value="smaller">{t('field.option_field.less_than')}</OptionField>
                                    <OptionField value="equal">{t('field.option_field.same_as')}</OptionField>
                                </Field>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                <Field
                                    name="price"
                                    component={InputTextField}
                                    label={t('field.price')}
                                    type="number"
                                    min={0}
                                    disabled={condition === 'none'}
                                />
                              </Grid>
                            </Grid>
                          </Content>
                          <Content marginLeft marginRight>
                            <Grid container spacing={3}>
                              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                <ButtonsGroup align="left">
                                  <Button
                                    aria-label="Eliminar ofertas"
                                    icon="cancel"
                                    color="danger"
                                    onClick={this.handleRemoveManyPricesOffer}
                                  >{t('button.offer_delete')}</Button>
                                </ButtonsGroup>
                              </Grid>
                              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                <ButtonsGroup align="right">
                                  <Button
                                    aria-label="Siguiente"
                                    icon="forward"
                                    color="primary"
                                    disabled={invalid || submited}
                                    onClick={() => {this.handleChangeStep(2)}}
                                  >{t('button.next')}</Button>
                                </ButtonsGroup>
                              </Grid>
                            </Grid>
                          </Content>

                        </TabPanel>

                      </Content>

                      {/* List of products */}
                      <Table
                        thead={thead}
                        loading={true}
                        total={ total }
                        page={ page }
                        onChangePage={ this.handleChangePage }
                      >
                        { entities.length > 0 && (
                          entities.map(entity => {
                            return (
                              <Row key={ entity._id }>
                                <Cell nowrap>
                                  { entity.sku ? entity.sku : '-' }
                                </Cell>
                                <Cell>
                                  { entity.name ? entity.name : '-' }
                                </Cell>
                                <Cell>
                                  { entity.price ? (
                                    <Currency value={
                                      operation === 'increment' ? 
                                      Math.round(sumByPercentaje(entity.price,percentage)) 
                                      : 
                                      Math.round(restByPercentaje(entity.price,percentage))}/>
                                  ) : (
                                    <React.Fragment>-</React.Fragment>
                                  )}
                                </Cell>
                                <Cell>
                                  { (entity.price && entity.price_offer) ? (
                                    <React.Fragment>

                                      {/* Price update */}
                                      { section === 0 && (
                                        <React.Fragment>
                                          { (entity.price_offer && (percentage === 0 || percentage === '0')) ? (
                                            <React.Fragment>
                                              <Currency value={entity.price_offer}/> ({Percentage(entity.price, entity.price_offer)}%)
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>-</React.Fragment>
                                          )}
                                        </React.Fragment>
                                      )}

                                      {/* Price Offer update */}
                                      { section === 1 && (
                                        <React.Fragment>
                                          { (percentageOffer && (percentageOffer === 0 || percentageOffer === '0')) ? (
                                            <React.Fragment>
                                              <Currency value={operation_offer === 'decrease' ? restByPercentaje(entity.price_offer,percentageOffer) : sumByPercentaje(entity.price_offer,percentageOffer) }/>
                                              &nbsp; ({Percentage(entity.price, entity.price_offer)}%)
                                            </React.Fragment>
                                          ) : (
                                            <Currency value={ operation_offer === 'decrease' ? Math.round(restByPercentaje(entity.price,percentageOffer)) : Math.round(sumByPercentaje(entity.price,percentageOffer))}/>
                                          )}
                                        </React.Fragment>
                                      )}

                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>

                                      {/* Price update */}
                                      { section === 0 && (
                                        <React.Fragment>-</React.Fragment>
                                      )}

                                      {/* Price Offer update */}
                                      { section === 1 && (
                                        <React.Fragment>
                                          { (percentageOffer && (percentageOffer > 0 || percentageOffer !== '0')) ? (
                                            <Currency value={ operation_offer === 'decrease' ?  Math.round(restByPercentaje(entity.price,percentageOffer)) :  Math.round(sumByPercentaje(entity.price,percentageOffer))}/>
                                          ) : (
                                            <React.Fragment>-</React.Fragment>
                                          )}
                                        </React.Fragment>
                                      )}

                                    </React.Fragment>
                                  )}
                                </Cell>
                              </Row>
                            )
                          })
                        )}
                        { (loading && entities.length === 0) && (
                          <Row>
                            <Cell colSpan={thead.length} align="center">
                              {t('messages.no_results_found')}
                            </Cell>
                          </Row>
                        )}
                      </Table>

                    </Paper>
                  </Content>
                </React.Fragment>
              )}

              { step === 2 && (
                <React.Fragment>
                  <Content disablePadding divider>
                    <Typography align="center" variant="h4" color="primary">
                      {t('messages.attention_required')}
                    </Typography>

                    { section === 0 && (
                      <React.Fragment>
                        <Typography align="center" variant="body2" gutterBottom>
                          {t('messages.changes_will_be_apply_prices')}
                        </Typography>
                        <Typography align="center" variant="body2" gutterBottom>
                          {t('field.percentage')}: <strong>{percentage}%</strong>
                          <span>&nbsp;—&nbsp;</span>
                          {t('field.condition')}: <strong>{isCondition(condition)}</strong>
                          {price !== '0' && (
                            <React.Fragment>
                              <span>&nbsp;—&nbsp;</span>
                              {t('field.price')}: <strong><Currency value={price}/></strong>
                            </React.Fragment>
                          )}
                        </Typography>
                        <Typography align="center" variant="body2" color="textSecondary">
                          {t('messages.offer_prices_delete')}
                        </Typography>
                      </React.Fragment>
                    )}

                    { section === 1 && (
                      <React.Fragment>
                        <Typography align="center" variant="body2" gutterBottom>
                          {t('messages.changes_will_be_apply_prices_offer')}
                        </Typography>
                        <Typography align="center" variant="body2">
                            {t('field.percentage')}: <strong>{percentageOffer}%</strong>
                          <span>&nbsp;—&nbsp;</span>
                            {t('field.condition')}: <strong>{isCondition(condition)}</strong>
                          {price !== '0' && (
                            <React.Fragment>
                              <span>&nbsp;—&nbsp;</span>
                                {t('field.price')}: <strong><Currency value={price}/></strong>
                            </React.Fragment>
                          )}
                        </Typography>
                      </React.Fragment>
                    )}
                  </Content>
                </React.Fragment>
              )}

              { step === 2 && (
                <Content disablePadding>
                  <ButtonsGroup align="right">
                    <Button
                      aria-label="Siguiente"
                      icon="back"
                      color="primary"
                      disabled={ invalid || submited }
                      onClick={() => {this.handleChangeStep(1)}}
                    >
                      {t('button.back')}
                    </Button>
                    <Button aria-label="Aplicar" icon="save" color="primary" type="submit" disabled={ invalid || submited }>
                      {t('button.apply')}
                    </Button>
                  </ButtonsGroup>
                </Content>
              )}

            </Content>
          ) : (
            <Loading/>
          )}
        </form>
      )
    }

};

const selector = formValueSelector('PageProductUpdate');

PageProductUpdate = reduxForm({
  form: 'PageProductUpdate',
  validate,
  initialValues: {
    section: 0,
    percentage: '0',
    percentage_offer: '0',
    price: '0',
    condition: 'none',
    operation: 'increment',
    operation_offer: 'decrease'
  }
})(PageProductUpdate);

PageProductUpdate = connect(
  state => {
    const percentage = selector(state, 'percentage') || 0;
    const percentageOffer = selector(state, 'percentage_offer') || 0;
    const condition = selector(state, 'condition') || 'none';
    const price = selector(state, 'price') || 0;
    const operation = selector(state, 'operation') || 'increment';
    const operation_offer = selector(state, 'operation_offer') || 'decrease';
    return { percentage, percentageOffer, condition, price, operation, operation_offer }
  }
)(PageProductUpdate);

export default withTranslation()(PageProductUpdate);
