import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from './../../../../../assets/theme/layout';
import { Content, Loading, Toolbar, Alert, Data, Currency } from './../../../../../components/ui';
import { TodoPagoService } from './../../../../../services';

import TEXT from './../../../../../config/text';

class TodoPagoPaymentDetail extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: false,
        error: false,
        errorMessage: null,
        entity: null
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      // @Service
      // @Description:
      this._Find(this.props.payment);

    }

    // @Service
    // @Description: Find payment detail
    _Find = (id) => {

      TodoPagoService.paymentById(id).then((response) => {

        // @State
        // @Description:
        this.setState({
          loading: true,
          entity: response.payment
        });

      }).catch((error) => {

        let message = TEXT.message.error;

        if(error.data.message){
          message = error.data.message;
        }

        // @State
        // @Description:
        this.setState({
          loading: true,
          error: true,
          errorMessage: message
        });

      });

    }

    render(){

      const { loading, error, errorMessage, entity } = this.state;

      return(

        <React.Fragment>

          <Toolbar
            title="Todo Pago"
            size="small"
          />

          { loading ? (

            <React.Fragment>

              { !error ? (

                <React.Fragment>
                  <Content disablePadding marginBottom>

                    <Grid container spacing={3}>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label="Orden"
                          text={ entity.order.id }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label="Concepto"
                          text={ entity.id }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label="Cuenta"
                          text={ entity.statement_descriptor }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label="Total (abonado)"
                          text={
                              <Currency
                                value={ entity.transaction_details.total_paid_amount }
                              />
                          }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                        <Data
                          label="Total (neto)"
                          text={
                              <Currency
                                value={ entity.transaction_details.net_received_amount }
                              />
                          }
                        />
                      </Grid>
                    </Grid>

                  </Content>

                  {/* Payer */}
                  { entity.payer && (
                    <Content disablePadding marginBottom>

                      <Toolbar
                        title="Usuario"
                        size="xsmall"
                      />

                      <Grid container spacing={3}>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Nombre/Apellido"
                            text={ `${ entity.payer.first_name } ${ entity.payer.last_name }` }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Identificación"
                            text={ `${ entity.payer.identification.type } ${ entity.payer.identification.number }` }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Email"
                            text={ entity.payer.email }
                            email
                          />
                        </Grid>
                      </Grid>

                    </Content>
                  )}

                  {/* Credit Card */}
                  { entity.payment_type_id === 'credit_card' && (
                    <Content disablePadding marginBottom>

                      <Toolbar
                        title="Tarjeta"
                        size="xsmall"
                      />

                      <Grid container spacing={3}>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Nombre/Apellido"
                            text={ entity.card.cardholder.name }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Identificación"
                            text={ `${ entity.card.cardholder.identification.type } ${ entity.card.cardholder.identification.number }` }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Fecha de vencimiento"
                            text={
                              (entity.card.expiration_month && entity.card.expiration_year) ? (
                                <React.Fragment>
                                  `${ entity.card.expiration_month }/${ entity.card.expiration_year }`
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  -
                                </React.Fragment>
                              )
                            }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Últimos 4 dígitos"
                            text={ `${ entity.card.last_four_digits } (${ entity.payment_method_id })` }
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                          <Data
                            label="Cuotas"
                            text={ entity.installments }
                          />
                        </Grid>
                      </Grid>

                    </Content>
                  )}

                </React.Fragment>

              ) : (

                <Alert severity="warning">
                  { errorMessage }
                </Alert>

              )}

            </React.Fragment>

          ) : (

            <Loading/>

          )}

        </React.Fragment>

      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

TodoPagoPaymentDetail.propTypes = {
  payment: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(TodoPagoPaymentDetail);
