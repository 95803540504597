import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles, Typography } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
    borderRadius: theme.spacing(1.5),
    whiteSpace: 'nowrap'
  },
  primary: {
    color: theme.palette.primary.main,
    background: theme.palette.primary.light
  },
  secondary: {
    color: theme.palette.secondary.main,
    background: theme.palette.secondary.ultraLight
  },
  success: {
    color: theme.palette.success.main,
    background: theme.palette.success.ultraLight
  },
  info: {
    color: theme.palette.info.main,
    background: theme.palette.info.ultraLight
  },
  warning: {
    color: theme.palette.warning.main,
    background: theme.palette.warning.ultraLight
  },
  danger: {
    color: theme.palette.danger.main,
    background: theme.palette.danger.ultraLight
  }
}));

const Tag = ({ text, color = 'primary', ...rest }) => {

  const classes  = useStyles();
  const variant  = color ? classes[color] : classes['primary'];

  return (
    <Typography classes={{ root: classnames(classes.root, variant) }} component="span">
      { text }
    </Typography>
  )

};

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'info', 'warning', 'danger'])
};

export default Tag;
