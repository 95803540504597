import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { Grid } from './../../../../assets/theme/layout';
import { Content, Button, ButtonsGroup } from './../../../../components/ui';
import { InputTextField, InputDateField, SelectField, OptionField } from './../../../../components/ui/input';
import { ShippingLocationAutocomplete } from './../../../../scenes';
import { ShippingService } from './../../../../services';


//import API from './../../../../config/api';
import TEXT from './../../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.etd) {
    errors.etd = TEXT.validation.required;
  }

  if (!values.eta) {
    errors.eta = TEXT.validation.required;
  }

  if (values.observation) {
    if(values.observation.length > 1024){
      errors.observation = TEXT.validation.maxlength(1024);
    }
  }

  if (values.entity) {
    if (values.entity === '-1') {
      errors.entity = TEXT.validation.required;
    }
  }

  if (values.vehicle) {
    if (values.vehicle.length > 255) {
      errors.vehicle = TEXT.validation.maxlength(255);
    }
  }

  if (values.journey) {
    if (values.journey.length > 255) {
      errors.journey = TEXT.validation.maxlength(255);
    }
  }

  return errors;

};

class ShippingEventForm extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      submited: false,
      orderId: null,
      entity: null,
      enableConfirm: false,
      events: []

    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  };

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {

    const orderId = this.props.orderId;
    const eventValues = this.props.eventValues;

    if(orderId){
      this.setState({orderId: orderId});
    }

    if(eventValues){
      this._Find(eventValues);
      this.handleEventsPreviousStatus();
    }

  };

  /*
  ** @Service
  ** @Description: Save Event
  */

  _Save = (values) => {

    // @State
    // @Description: Loading show
    this.setState({ loading: true, submited: true });

    // @Service
    ShippingService.saveEvent(this.state.orderId, values).then((response) => {

      if(this.props.onSuccess){
        this.props.onSuccess(response.events);
      }

      // @State
      // @Description: Loading hide
      this.setState({ loading: false, submited: false });

    }).catch((error) => {

      // @State
      // @Description: Loading hide
      this.setState({ loading: false, submited: false });

    });

  };

  /*
  ** @Service
  ** @Description: Save Event
  */

  _Update = (id, values) => {

    // @State
    // @Description: Loading show
    this.setState({ loading: true, submited: true });

    // @Service
    ShippingService.updateEvent(this.state.orderId, values).then((response) => {

      if(this.props.onSuccess){
        this.props.onSuccess(response.events);
      }

      // @State
      // @Description: Loading hide
      this.setState({ loading: false, submited: false });

      this.props.form.reset();

    }).catch((error) => {

      // @State
      // @Description: Loading hide
      this.setState({ loading: false, submited: false });

    });

  };


  /*
  ** @Service
  ** @Description: Find Event
  */

  _Find = (values) => {

    const form = this.props.form;

    // @State
    // @Description: Set entity update into state
    this.setState({ entity: values }, () => {
      this.props.dispatch(change(form, '_id', this.state.entity._id ));
      this.props.dispatch(change(form, 'created_at', this.state.entity.created_at ));
      this.props.dispatch(change(form, 'etd', this.state.entity.etd ));
      this.props.dispatch(change(form, 'eta', this.state.entity.eta ));
      this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));
      this.props.dispatch(change(form, 'vehicle', this.state.entity.vehicle ));
      this.props.dispatch(change(form, 'journey', this.state.entity.journey ));
      this.props.dispatch(change(form, 'observation', this.state.entity.observation ));

      if(this.state.entity.location){
        this.props.dispatch(change(form, 'location', this.state.entity.location._id ));
      }

    });

  };

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {
    if (this.state.entity) {
      this._Update(this.state.id, values);
    } else {
      this._Save(values);
    }
  };

  /*
  ** @events
  ** @Description: check previous events status
  */
  handleEventsPreviousStatus = (e) => {
    if(!e && this.props.eventValues){
      e=this.props.eventValues.eta;
    }
    let estadosAnteriores = [];
    let eventosAnteriores = this.props.entityEvents;
    const form = this.props.form;
    eventosAnteriores.forEach((evento) => {
      if(e > evento.eta){
        estadosAnteriores.push(evento.enabled);
      }

    });

    if(estadosAnteriores.includes(false)){
      this.setState({ enableConfirm: false });
      this.props.dispatch(change(form, 'enabled', false ));
    }else{
      this.setState({ enableConfirm: true });
    }


  };

  /*
  ** @Event
  ** @Description: On selected location
  */
  handleOnSelectLocation = (location) => {
    this.props.dispatch(change(this.props.form, 'location', location ? location._id : '' ));
  };

  render() {

    const { handleSubmit, invalid, fieldEta, etaTo, etdFrom } = this.props;
    const { entity } = this.state;

    return(
      <form noValidate autoComplete="off">
        <Content disablePadding divider>

          <Field name="_id" component={ InputTextField } required fieldHidden/>
          <Field name="created_at" component={ InputTextField } required fieldHidden/>

          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                name="eta"
                component={ InputDateField }
                label="ETA"
                type="text"
                required
                onChange={this.handleEventsPreviousStatus}
                minDate={new Date(etdFrom)}
                maxDate={new Date(etaTo)}
                />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                name="etd"
                component={ InputDateField }
                label="ETD"
                type="text"
                required
                disabled={!fieldEta}
                minDate={new Date(fieldEta)}
                maxDate={new Date(etaTo)}
                />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                name="enabled"
                component={ SelectField }
                label="Estado"
                disabled={!this.state.enableConfirm}
                required
                >
                <OptionField value="false">A confirmar</OptionField>
                <OptionField value="true">Confirmado</OptionField>
              </Field>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <ShippingLocationAutocomplete
                label="Trasbordo"
                location={entity && entity.location ? entity.location : null}
                onSelect={this.handleOnSelectLocation}
                />
              <Field
                name="location"
                component={ InputTextField }
                type="text"
                required
                fieldHidden
                />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                name="vehicle"
                component={ InputTextField }
                label="Medio de transporte"
                type="text"
                />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                name="journey"
                component={ InputTextField }
                label="Viaje"
                type="text"
                />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Field
                name="observation"
                component={ InputTextField }
                label="Observación"
                type="text"
                multiline
                rows={4}
                />
            </Grid>
          </Grid>
        </Content>
        <Content disablePadding>
          <ButtonsGroup align="right">
            <Button
              aria-label="Guardar"
              icon="add"
              color="success"
              type="button"
              disabled={ invalid }
              onClick={ handleSubmit(values => this.handleSubmitForm(values)) }
              >Guardar</Button>
          </ButtonsGroup>
        </Content>
      </form>
    )
  }

};

ShippingEventForm.propTypes = {
  onSuccess: PropTypes.func
};

const selector = formValueSelector('ShippingEventForm');

ShippingEventForm = reduxForm({
  form: 'ShippingEventForm',
  validate,
  initialValues: {
    enabled: 'false'
  },
  enableReinitialize: true
})(ShippingEventForm);

ShippingEventForm = connect(
  state => {
    const fieldEta = selector(state, 'eta');
    return { fieldEta }
  }
)(ShippingEventForm);

export default ShippingEventForm;
