import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { throttle } from 'lodash';
import { makeStyles, Box, Typography } from './../../../assets/theme/default';
import { TextField, Autocomplete } from './../../../assets/theme/input';
import { List, ListItem, ListItemText, ListItemSecondaryAction } from './../../../assets/theme/list';
import { Button } from './../../../components/ui';
import { ProductService } from './../../../services';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // alignItems: 'center'
  },
  container: {
    border: `1px solid ${ theme.palette.grey.main }`,
    background: '#fafbfc',
    borderRadius: 3,
    padding: theme.spacing(1)
  },
  paper: {
    boxShadow: theme.shadows[2]
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
    position: 'relative',
    transition: 'none',
    transform: 'none',
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.fontSize,
    color: theme.palette.secondary.main
  },
  inputRoot: {
    marginTop: `0 !important`,
    border: `1px solid ${ theme.palette.grey.dark }`,
    background: '#fbfbfb',
    padding: `${ theme.spacing(1) }px ${ theme.spacing(5) }px ${ theme.spacing(1) }px ${ theme.spacing(1) }px !important`,
    borderRadius: `3px !important`,
    '&:before': {
      display: 'none !important'
    },
    '&:after': {
      display: 'none !important'
    }
  },
  inputRootContaier: {
    background: theme.palette.common.white
  },
  inputInput: {
    padding: '0 !important'
  }
}));

const ProductItem = ({ item }) => {

  const name     = item.name;
  const sku      = item.sku ? item.sku : 'No disponible';
  const sessions = item.sessions ? item.sessions : 'No disponible';
  let category   = 'No disponible';

  if(item.categories.length > 0){
    category = item.categories[0];
  }

  return(
    <React.Fragment>
      <ListItemText
        primary={ name }
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              display="inline"
              color="textPrimary"
            >
              { sku }
            </Typography>
            {" — "} { category.name } {" — Sesiones "} { sessions }
          </React.Fragment>
        }
      />
    </React.Fragment>
  )

};

const ProductAutocomplete = ({ products, onSelect, label = 'Productos', list = true, container = false, alertShow, ...rest }) => {

  const classes = useStyles();
  const [value] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  // @Styles
  const _container = container ? classes.container : null;
  const _inputOnContainer = container ? classes.inputRootContaier : null;

  /*
  ** @Service
  ** @Description: Find products by keyword
  */
  const service = (request, callback) => {
    ProductService.filter({ keyword: request.input }).then((response) => {
      return((request, callback(response.products)))
    });
  }

  /*
  ** @useMemo
  ** @Description:
  */
  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        service(request, callback);
      }, 1000),
    [],
  );

  /*
  ** @Event
  ** @Description: Add product
  */
  const handleAdd = (newInputValue) => {

    const alreadyExist = selected.some(value => {
      return value._id === newInputValue._id;
    });

    if(!alreadyExist){

      const items = [...selected, newInputValue ? newInputValue : null];

      // @Set
      // @Description: Set state for selected
      setSelected(items);

      // @Prop
      // @Description: Send selected to parent
      if(onSelect){
        onSelect(items);
      }

    }else{

      alertShow({
        open: true,
        severity: 'warning',
        message: `El producto ${ newInputValue.name } ya se encuentra en la lista.`
      });

    }

  };

  /*
  ** @Event
  ** @Description: Delete product
  */
  const handleDelete = (index) => {

    const items = selected.filter((value, i, arr) => {
      return i !== index;
    });

    // @Set
    // @Description: Set state for selected
    setSelected(items);

    // @Prop
    // @Description: Send selected to parent
    if(onSelect){
      onSelect(items);
    }

  };

  /*
  ** @useEffect
  ** @Description:
  */
  useEffect(() => {
    setSelected(products);
  }, [products]);

  /*
  ** @useEffect
  ** @Description:
  */
  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {

      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);

      }
    });

    return () => {
      active = false;
    };

  }, [value, inputValue, fetch]);

  return (
    <Box className={ classnames(classes.root, _container) }>
      <Box className={ classes.autocomplete }>
        <Autocomplete
          classes={{
            paper: classes.paper,
            inputRoot: classnames(classes.inputRoot, _inputOnContainer),
            input: classes.inputInput
          }}
          noOptionsText="No se encontraron resultados"
          freeSolo
          clearOnEscape
          size="small"
          filterOptions={(x) => x}
          options={options}
          value={value}
          autoComplete
          includeInputInList
          filterSelectedOptions
          getOptionLabel={
            (option) => `${ option.name }`
          }
          onChange={(event, newInputValue) => {
            handleAdd(newInputValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => {
            return(
              <TextField
                {...params}
                label={label}
                placeholder="Buscar por nombre, título o sku..."
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: classes.label
                  }
                }}
              />
            )
          }}
          renderOption={(option, { selected }) => (
            <ProductItem item={option}/>
          )}
        />
      </Box>
      <Box>
        { (selected.length > 0) && (
          <List classes={{ root: classes.list }}>
            { selected.map((item, index) => {
              return(
                <ListItem dense divider disableGutters key={index}>
                  <ProductItem item={item}/>
                  <ListItemSecondaryAction>
                    <Button
                      color="danger"
                      size="small"
                      style={{padding:4}}
                      icon="cancel"
                      title="Eliminar"
                       onClick={ () => handleDelete(index) }
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        )}
      </Box>
    </Box>
  );

};

ProductAutocomplete.propTypes = {
  products: PropTypes.array,
  label: PropTypes.string,
  list: PropTypes.bool,
  container: PropTypes.bool,
  onSelect: PropTypes.func
};

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAutocomplete);
