import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from './../../../../assets/theme/default';
import { TableCell } from './../../../../assets/theme/table';
import ThemeCustom from './../../../../assets/theme/custom';

const useStyles = makeStyles(theme => ({
  root: {
    border: 'none',
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    //fontSize: 13,
    color: theme.palette.secondary.dark,
    "& > *": {
      "&:not(:last-child)": {
        marginRight: theme.spacing(1)
      }
    }
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  primary: {
    color: theme.palette.primary.main,
    background: theme.palette.primary.light
  },
  secondary: {
    color: theme.palette.secondary.main,
    background: theme.palette.secondary.ultraLight
  },
  success: {
    color: ThemeCustom.palette.success.main,
    background: ThemeCustom.palette.success.ultraLight
  },
  info: {
    color: ThemeCustom.palette.info.main,
    background: ThemeCustom.palette.info.ultraLight
  },
  warning: {
    color: ThemeCustom.palette.warning.main,
    background: ThemeCustom.palette.warning.ultraLight
  },
  danger: {
    color: ThemeCustom.palette.danger.main,
    background: ThemeCustom.palette.danger.ultraLight
  }
}));

const Cell = ({ children, nowrap, color, ...rest}) => {

  const classes = useStyles();
  const _nowrap = nowrap ? classes['nowrap'] : null;
  const _color  = color ? classes[color] : null;

  return (
    <TableCell className={ classNames(classes.root, _color, _nowrap) } { ...rest }>
      { children }
    </TableCell>
  );

}

Cell.propTypes = {
  children: PropTypes.node.isRequired,
  nowrap: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'inherit'])
};

export default Cell;
