import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const Empty = ({ text, ...rest }) => {

  const classes = useStyles();

  return (
    <Box className={ classes.root }>
      <Typography>{ text }</Typography>
    </Box>
  )

};

Empty.propTypes = {
  text: PropTypes.string.isRequired
};

export default Empty;
