import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';

const Alert__c = ({ children, title, elevation = 0, ...rest }) => {
  return (
    <Alert elevation={ elevation } {...rest}>
      { title && (
        <AlertTitle>{ title }</AlertTitle>
      )}
      { children }
    </Alert>
  )
};

Alert__c.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  elevation: PropTypes.number
};

export default Alert__c;
