import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change, FieldArray } from 'redux-form';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { Drawer, Box } from './../../../../../assets/theme/default';
import { Grid } from './../../../../../assets/theme/layout';
import { Page, Wrapper, Button, Label, ButtonsGroup, Toolbar, Paper, Content, Loading } from './../../../../../components/ui';
import { InputTextField, SelectField, OptionField, InputDateField } from './../../../../../components/ui/input';
import { ClientAutocomplete, CurrencySelect } from './../../../../../scenes';
import { MercadoPagoService } from './../../../../../services';
import { filterPaymentMethodsIds } from './../../../../../utilities/utils';

import UtilMPPaymentMethods from './../../utils/payment_methods';
import UtilMPSummary from './../../../utils/summary';
import UtilProductsSelector from './../../../../../scenes/product/selector';

import API from './../../../../../config/api';
import TEXT from './../../../../../config/text';

// @Validation
// @Description: Validation Form
const validate = values => {

  const errors = {};

  if (!values.client) {
    errors.client = TEXT.validation.required;
  }

  if (values.currency) {
    if (values.currency === '-1') {
      errors.currency = TEXT.validation.required;
    }
  }

  if (values.currency) {
    if (values.currency === '-1') {
      errors.currency = TEXT.validation.required;
    }
  }

  if (!values.amount) {
    errors.amount = TEXT.validation.required;
  }

  if (!values.reference) {
    errors.reference = TEXT.validation.required;
  }

  // if (values.installments) {
  //   if (values.installments === '-1') {
  //     errors.installments = TEXT.validation.required;
  //   }
  // }
  //
  // if (values.default_installments) {
  //   if (values.default_installments !== '-1') {
  //     if (parseInt(values.default_installments,10) > parseInt(values.installments,10)) {
  //       errors.default_installments = 'Preferencia de cuotas no puede ser mayor a el Máximo número de cuotas';
  //     }
  //   }
  // }

  return errors;

};

const renderItemsForExcludedPaymentMethods = ({ fields, meta: { error, submitFailed } }) => (
  fields.length > 0 && (
    fields.map((entity, index) => (
      <Field
          key={ index }
          name={ `${ entity }.id` }
          component={ InputTextField }
          type="text"
          required
          fieldHidden
      />
    ))
  )
);

class Page__MP_PreferenceForm extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      submited: false,
      expires: true,
      installments: [1,3,6,9,12],
      type: 'combo',
      productSelectorShow: false,
      parentRef: {}
    };

    this.contentRef = React.createRef();
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){

    this.handleOnReize();

    // @Event
    // @Description: Event on reize window
    window.addEventListener("resize", this.handleOnReize.bind(this));

  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component will unmount.
  */
  componentWillUnmount(){
   this.props.checkoutClearStateDispatch({
     type: 'combo',
     update: Date.now()
   });
  }

  /*
  ** @Event
  ** @Description: On resize
  */
  handleOnReize = () => {
    let current = this.contentRef.current;
    if(current){
      this.setState({ parentRef: current.getBoundingClientRect() });
    }
  }

  /*
  ** @Event
  ** @Description: On selected client
  */
  handleOnSelectClient = (client) => {

    // @Form
    // @Description: Set client ID
    this.props.dispatch(change(this.props.form, 'client', client ? client._id : '' ));

    // @Store
    // @Description: Set client into stroe
    this.props.checkoutClientAddStateDispatch(client);

  }

  /*
  ** @Event
  ** @Description: On select exlude payment methods
  */
  handleOnSelectExludePaymentMethods = (paymentMethods) => {
    this.props.dispatch(change(this.props.form, 'excluded_payment_methods', paymentMethods ? filterPaymentMethodsIds(paymentMethods) : [] ));
  }

  // @Event
  // @Description: On change expires
  handleOnChangeExpires = (event, value) => {

    // @State
    // @Description: Select checkout type
    this.setState({ expires: JSON.parse(value) });

    // @Form
    // @Description: Clear values
    this.props.dispatch(change(this.props.form, 'expiration_date_to', new Date() ));

  }

  // @Event
  // @Description: On change type payment
  handleOnChangeType = (event, value) => {

    // @State
    // @Description: Select checkout type
    this.setState({ type: value });

    // @Dispatch
    // @Description: Clear checkout
    this.props.checkoutClearStateDispatch({
      type: value,
      update: Date.now()
    });

    // @Form
    // @Description: Clear values
    this.props.dispatch(change(this.props.form, 'amount', '' ));

  }

  // @Event
  // @Description: Add total to store
  handleOnChangeAmount = (event) => {

    let amount = parseInt(event.target.value !== '' ? event.target.value : 0, 10);

    // @Store
    // @Description: Send amount to store
    this.props.checkoutAmountAddStateDispatch({
      amount: amount,
      update:  Date.now()
    });

  }

  // @Event
  // @Description: Add total to store
  handleOnLoadCurrency = (currency) => {
    this.props.dispatch(change(this.props.form, 'currency', currency.prefix ));
  }

  // @Event
  // @Description: Add product to store
  handleToggleProductSelector = (status) => {
    this.setState({ productSelectorShow: status });
  }
  // @Event
  // @Description: Add product to store
  handleSelectProduct = (product) => {
    this.props.checkoutProductAddStateDispatch({
      product: product,
      update:  Date.now()
    });
  }

  /*
  ** @Event
  ** @Description: Submit form
  */
  handleSubmitForm = (values) => {

    let toSend = Object.assign({
      products: this.props.checkout.products,
      total:    this.props.checkout.total,
      subtotal: this.props.checkout.subtotal,
      discount: this.props.checkout.discount
    }, values);

    // @Loadig
    // @Description: Show loading
    this.setState({ loading: true });

    // // @Service

    MercadoPagoService.preferenceSave(toSend).then((response) => {

      // @Dispatch alert success
      this.props.alertShow({
        open: true,
        severity: 'success',
        message: `El checkout fue creado.`
      });

      // @Redirect
      this.props.history.push(`/api/mercadopago/checkout/detail/${ response.preference._id }`);

    }).catch((error) => {

      // @State
      // @Description: Loading hide
      this.setState({ loading: false });

      // @Dispatch alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: TEXT.message.error
      });

    });

  }

  render(){

    const { handleSubmit, invalid } = this.props;
    const { submited, loading, installments, type, expires, productSelectorShow, parentRef } = this.state;

    return(
      <Page>

        <Wrapper>

          <Toolbar
            title="Mercado Pago"
            subtitle="Formulario de checkout"
            dividerMobile
          >
            <Button
              size="medium"
              component={ NavLink }
              to="/api/mercadopago/checkout"
              icon="back"
            >
              Volver
            </Button>
          </Toolbar>

          <Box ref={ this.contentRef }>
            <Content disablePadding>

              { (submited || loading) && (
                <Loading variant="absolute"/>
              )}

              <Grid container spacing={1}>

                {/* Form */}
                <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                  <Paper disablePadding>

                    <Toolbar
                      title="Nuevo checkout"
                      size="medium"
                      variant="divider"
                      disableMargin
                    />

                    <Drawer
                      anchor="top"
                      open={ productSelectorShow }
                      onClose={ () => this.handleToggleProductSelector(false) }
                    >
                      <UtilProductsSelector
                        onSelect={ this.handleSelectProduct }
                        onClose={ () => this.handleToggleProductSelector(false)  }
                      />
                    </Drawer>

                    <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>
                      <Content>

                        {/* Client Autocomplete */}
                        <Content divider marginBottom>
                          <ClientAutocomplete
                            onSelect={ this.handleOnSelectClient }
                          />
                          <Field
                              name="client"
                              component={ InputTextField }
                              type="text"
                              required
                              fieldHidden
                          />
                        </Content>

                        {/* Tipo de pago */}
                        <Content divider disablePadding>
                          <Toolbar
                            title="Tipo"
                            subtitle="Selección de típo de checkout a generar."
                            size="small"
                            style={{ marginBottom: 24 }}
                          />

                          <Grid container spacing={3}>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                              <Field
                                name="type"
                                component={ SelectField }
                                label="Tipo"
                                required
                                onChange={ this.handleOnChangeType }
                              >
                                <OptionField value="simple">Simple</OptionField>
                                <OptionField value="combo">Combo</OptionField>
                              </Field>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                              <Field
                                name="currency"
                                component={ CurrencySelect }
                                label="Moneda"
                                required
                                onLoad={ this.handleOnLoadCurrency }
                              />
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                              { type === 'simple' && (
                                <Field
                                  name="amount"
                                  component={ InputTextField }
                                  label="Monto"
                                  type="number"
                                  required
                                  onChange={ this.handleOnChangeAmount }
                                />
                              )}
                              { type === 'combo' && (
                                <React.Fragment>
                                  <Label>
                                    Productos
                                  </Label>
                                  <Button
                                    size="medium"
                                    icon="add"
                                    fullWidth
                                    onClick={ () => this.handleToggleProductSelector(true) }
                                  >
                                      Agregar
                                  </Button>
                                </React.Fragment>
                              )}
                            </Grid>
                          </Grid>
                        </Content>

                        {/* Mercado Pago Information */}
                        <Content divider disablePadding>
                          <Toolbar
                            title="Configuración"
                            subtitle="Información para generar el checkout sobre Mercado Pago."
                            size="small"
                            style={{ marginBottom: 24 }}
                          />

                          <Grid container spacing={3}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Field
                                name="reference"
                                component={ InputTextField }
                                label="Detalle de la compra"
                                type="text"
                                required
                              />
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                              <Field
                                name="expires"
                                component={ SelectField }
                                label="¿Fecha de expiración?"
                                required
                                onChange={ this.handleOnChangeExpires }
                              >
                                <OptionField value="true">SI</OptionField>
                                <OptionField value="false">NO</OptionField>
                              </Field>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                              <Field
                                name="expiration_date_from"
                                component={ InputDateField }
                                label="Válido desde"
                                type="text"
                                disablePast
                                required
                              />
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                              <Field
                                name="expiration_date_to"
                                component={ InputDateField }
                                label="Válido hasta"
                                type="text"
                                disablePast
                                required={ expires }
                                disabled={ !expires }
                              />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                              <UtilMPPaymentMethods
                                label="Medios de pago exluidos"
                                onSelect={ this.handleOnSelectExludePaymentMethods }
                              />

                              {/* Array of excluded payment methods */}
                              <FieldArray
                                name="excluded_payment_methods"
                                component={ renderItemsForExcludedPaymentMethods }
                              />

                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                              <Field
                                name="installments"
                                component={ SelectField }
                                label="Máximo número de cuotas"
                                required
                              >
                                <OptionField value="-1">Seleccionar...</OptionField>
                                { installments.map((installment, index) => {
                                  return(
                                    <OptionField key={ index } value={ installment }>
                                      { installment }
                                    </OptionField>
                                  )
                                })}
                              </Field>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                              <Field
                                name="default_installments"
                                component={ SelectField }
                                label="Preferencia de cuotas"
                              >
                                <OptionField value="-1">Seleccionar...</OptionField>
                                { installments.map((installment, index) => {
                                  return(
                                    <OptionField key={ index } value={ installment }>
                                      { installment }
                                    </OptionField>
                                  )
                                })}
                              </Field>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                              <Field
                                name="email"
                                component={ SelectField }
                                label="¿Enviar email?"
                              >
                                <OptionField value="true">Si</OptionField>
                                <OptionField value="false">No</OptionField>
                              </Field>
                            </Grid>
                          </Grid>
                        </Content>

                        {/* Footer */}
                        <Content disablePadding>
                          <ButtonsGroup align="right">
                            <Button
                              component={ NavLink }
                              to="/api/mercadopago/checkout"
                              color="danger"
                              size="medium"
                              icon="cancel"
                            >
                              Cancelar
                            </Button>
                            <Button
                              type="submit"
                              size="medium"
                              icon="add"
                              color="success"
                              disabled={ invalid || submited }
                            >
                              Confirmar
                            </Button>
                          </ButtonsGroup>
                        </Content>

                      </Content>
                    </form>

                  </Paper>
                </Grid>

                {/* Sumarry */}
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <UtilMPSummary parentRef={ parentRef }/>
                </Grid>

              </Grid>

            </Content>
          </Box>

        </Wrapper>

      </Page>
    )
  }

};

Page__MP_PreferenceForm = reduxForm({
  form: 'Page__MP_PreferenceForm',
  validate,
  initialValues: {
    type: 'combo',
    currency: '-1',
    email: false,

    expires: API.mercadopago.preference.expires,
    expiration_date_from: moment.utc().format(),
    expiration_date_to: moment.utc().add(API.mercadopago.preference.expires_add_days, 'days').format()

  }
})(Page__MP_PreferenceForm);

const mapStateToProps = (state, ownProps) => {
  return {
    checkout: state.mpCheckoutState
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    },
    checkoutClientAddStateDispatch: (data) => {
      dispatch({
        type: 'MP_CHECKOUT_CLIENT_ADD',
        data: data
      });
    },
    checkoutAmountAddStateDispatch: (data) => {
      dispatch({
        type: 'MP_CHECKOUT_AMOUNT_ADD',
        data: data
      });
    },
    // checkoutDiscountClearStateDispatch: () => {
    //   dispatch({
    //     type: 'MP_CHECKOUT_DISCOUNT_CLEAR'
    //   });
    // },
    checkoutProductAddStateDispatch: (data) => {
      dispatch({
        type: 'MP_CHECKOUT_PRODUCT_ADD',
        data: data
      });
    },
    checkoutClearStateDispatch: (data) => {
      dispatch({
        type: 'MP_CHECKOUT_CLEAR',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page__MP_PreferenceForm);
