import React from 'react';
import { SelectField, OptionField } from './../../../../components/ui/input';
import { ShippingService } from './../../../../services';

class ShippingContainerSelect extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      entities: []
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._All();
  }

  /*
  ** @Service
  ** @Description: List all containers
  */

  _All = () => {
    ShippingService.containersList({enabled:true}).then((response) => {
      this.setState({ entities: response.containers || [] });
    });
  }

  render() {

    const { entities } = this.state;

    return(
      <SelectField { ...this.props }>
        { entities.length > 0 ? (
          <React.Fragment>
            <OptionField value="-1">Seleccionar...</OptionField>
            { entities.map((entity, index) => {
              return(
                <OptionField key={ index } value={ entity._id }>
                  { entity.name }
                </OptionField>
              )
            })}
          </React.Fragment>
        ) : (
          <OptionField value="-1">Cargando...</OptionField>
        )}
      </SelectField>
    )
  }

};

export default ShippingContainerSelect;
