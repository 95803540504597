import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page) {
  return request({
    url:    `/item/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function listByClientId(id) {
  return request({
    url:    `/item/list/client/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

const ItemService = {
  list, listByClientId
}

export default ItemService;
