import currencyFormatter from 'currency-formatter';

function format (value) {
    return currencyFormatter.format(value, {
      code: '',
      decimal: ',',
      thousand: '.',
      precision: 2
    });
  };
  
  function unformat (value) {
    return currencyFormatter.unformat(value, {
      code: '',
      decimal: ',',
      thousand: '.',
      precision: 2
    });
  };

  export { format, unformat };
  