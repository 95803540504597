import React from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { withTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';
import { withStyles, Box, Typography } from './../../../assets/theme/default';
import { Container, Grid, AppBar, Toolbar as ToolbarMUI } from './../../../assets/theme/layout';
import { Image, Toolbar, Button, ButtonsGroup, Loading, Modal, Jumbotron, Alert } from './../../../components/ui';
import { FormLabelField, InputTextField, InputDateField, SelectField, OptionField, RadioField, RadioGroupField } from './../../../components/ui/input';
import { FormClientAutocomplete } from './../../../scenes';
import { FormService } from './../../../services';
import { isEmail } from './../../../utilities/utils';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const styles = theme => ({
  root: {
    flex: 1,
    position: 'relative',
    background: theme.palette.common.white,
    paddingBottom: theme.spacing(10)
  },
  container: {},
  teaser: {
    background: API.palette.primary.main,
    marginBottom: theme.spacing(2),
    position: 'relative',
    zIndex: 99
  },
    teaserContainer: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      textAlign: 'center'
    },
  signature: {
    borderRadius: 2,
    //border: `1px solid ${ theme.palette.grey.main }`
  },
  buttonBar: {
    top: 'auto',
    bottom: 0
  },
  signatureCanvas: {
    position: 'relative',
    display: 'block',
    width: '100%',
    padding: 0,
    overflow: 'hidden',
    background: theme.palette.grey.main,
    borderRadius: 2,
    marginBottom: theme.spacing(1),
    '&::before': {
      display: 'block',
      content: '""',
      paddingTop: '56.25%'
    },
    '& > canvas': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  }
});

const validate = values => {

  const errors = {};

  if(values.autocomplete){

    if (!values.name) {
      errors.name = TEXT.validation.required;
    }else if(values.name.length > 255){
      errors.name = TEXT.validation.maxlength(255);
    }

    if (!values.surname) {
      errors.surname = TEXT.validation.required;
    }else if(values.surname.length > 255){
      errors.surname = TEXT.validation.maxlength(255);
    }

    if (!values.identifire) {
      errors.identifire = TEXT.validation.required;
    }else if(values.identifire.length < 7){
      errors.identifire = TEXT.validation.minlength(7);
    }else if(values.identifire.length > 8){
      errors.identifire = TEXT.validation.maxlength(8);
    }

    if (!values.email) {
      errors.email = TEXT.validation.required;
    } else if (isEmail(values.email)) {
      errors.email = TEXT.validation.email_invalid;
    } else if (values.email.length > 255){
      errors.email = TEXT.validation.maxlength(255);
    }

  }

  return errors;

}

const required = value => (
  value ? undefined : TEXT.validation.required
);

const minLength = min => value => (
  value && value.length < min ? TEXT.validation.minlength(min) : undefined
);

const maxLength = max => value => (
  value && value.length > max ? TEXT.validation.maxlength(max) : undefined
);

const option = value => {
  if (value) {
    if (value === '-1') {
      return TEXT.validation.required;
    }
  }
}

class Page__Form extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      submited: false,
      error: false,
      success: false,
      notFound: false,
      message: null,
      id: null,
      entity: null,
      fields: null,
      signatureCanvasWidth: 'auto',
      signatureCanvasHeight: 'auto',
      autoCompleteShow: false
    };
    this.signatureCanvasRef = React.createRef();
    this.signatureContainerRef = React.createRef();
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){

    // @Function
    // @Description: Init configuration
    this._Init();

    // @Event
    // @Description: Event on reize window
    window.addEventListener("resize", this.handleOnReize.bind(this));

  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component will recive props.
  */
  componentDidUpdate(prev_props, prev_state){
    if(this.props.match.params.slug !== prev_props.match.params.slug){
      this._Init();
    }
  }

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {

    const slug = this.props.match.params.slug;

    if(slug){

      // @State
      // @Description: Set state default
      this.setState({ notFound: false, success: false, error: false });

      // @Service
      this._Find(slug);

    }else{

      // @State
      // @Description: Set state default
      this.setState({ notFound: true });

    }

  }

  /*
  ** @Service
  ** @Description: Find form
  */

  _Find = (slug) => {

    // @State
    // @Description: Set submited loading
    this.setState({ loading: false });

    // @Service
    FormService.find(slug).then((response) => {

      // @State
      // @Description: Set form into state
      this.setState({
        loading: true,
        id: response.form._id,
        entity: response.form
      });

      // @Form
      // @Description: Set form id
      this.props.dispatch(change(this.props.form, 'form', response.form._id ));
      this.props.dispatch(change(this.props.form, 'autocomplete', response.form.autocomplete ));

      // @Service
      // @Description: Find form fields
      this._Fields(this.state.id);

    }).catch((error) => {

      // @State
      // @Description: Set form into state
      this.setState({ loading: true, notFound: true });

    });

  }

  /*
  ** @Service
  ** @Description: Find fields form
  */

  _Fields = (id) => {

    // @Service
    FormService.fields(id).then((response) => {

      // @State
      // @Description: Set fields into state
      this.setState({
        fields: response.fields
      });

      this.handleOnReize();

    }).catch((error) => {

      // @State
      // @Description: Remove submited
      this.setState({
        submited: false,
        error: true,
        message:  error.data.message ? error.data.message : TEXT.message.error
      });

    });

  }

  /*
  ** @Event
  ** @Description: On resize
  */
  handleOnReize = () => {
    let current = this.signatureContainerRef.current;
    if(current){

      if(current.clientWidth !== this.state.signatureCanvasWidth){
        this.signatureCanvasRef.clear();
      }

      this.setState({
        signatureCanvasWidth: current.clientWidth,
        signatureCanvasHeight: current.clientHeight,
      });
    }
  }

  /*
  ** @Submit
  ** @Description: Submit form
  */
  handleSubmitForm = (values) => {

    // @State
    // @Description: Set submited loading
    this.setState({ submited: true });

    // @Service
    FormService.save(values).then((response) => {

      // @State
      // @Description: Set submited loading
      this.setState({ submited: false, success: true });

    }).catch((error) => {

      window.scrollTo(0, 0);

      // @State
      // @Description: Remove submited
      this.setState({
        submited: false,
        error: true,
        message:  error.data.message ? error.data.message : TEXT.message.error
      });

    });

  }

  /*
  ** @Handler
  ** @Description: Choose label
  */
  handleLabelOrigin = (field) => {
    if(field.block.label){
      return field.label;
    }else{
      return field.block.name;
    }
  }

  /*
  ** @Event
  ** @Description: AutoComplete Modal
  */
  handleAutocomplete = (status) => {
    this.setState({ autoCompleteShow: status });
  }

  /*
  ** @Event
  ** @Description: AutoComplete Success
  */
  onAutocompleteSuccess = (client) => {

    const form = this.props.form;

    this.props.dispatch(change(form, 'client', client._id ));
    this.props.dispatch(change(form, 'name', client.name ));
    this.props.dispatch(change(form, 'surname', client.surname ));
    this.props.dispatch(change(form, 'identifire', client.identifire ));
    this.props.dispatch(change(form, 'email', client.email ));

    this.handleAutocomplete(false);

    this.setState({ error: false, message: null });

  }

  /*
  ** @Event
  ** @Description: Clear signature
  */
  handleClearSignature = (field) => {

    // @Clear canvas
    this.signatureCanvasRef.clear();

    // Clear input
    this.props.dispatch(change(this.props.form, field, null ));

  }

  /*
  ** @Event
  ** @Description: OnEnd Signature
  */
  handleOnEndSignature = (field) => {
    this.props.dispatch(change(this.props.form, field, this.signatureCanvasRef.toDataURL() ));
  }

  /*
  ** @Event
  ** @Description: Set default value on input date
  */
  handleInputDateValue = (field) => {
    this.props.dispatch(change(this.props.form, field, moment().format() ));
  }

  render(){

    const { classes, handleSubmit, invalid } = this.props;
    const { loading, notFound, success, error, submited, message, entity, fields, autoCompleteShow, signatureCanvasWidth, signatureCanvasHeight } = this.state;

    return (
      <Box className={ classes.root }>

        {/* Teaser */}
        <Box className={ classes.teaser }>
          <Container maxWidth="sm">
            <Box className={ classes.teaserContainer }>
              <Image src={ `/resources/images/logo-notification.png` } alt={ API.client.name }/>
            </Box>
          </Container>
        </Box>

        <Container maxWidth="sm">
          <Box className={classes.container}>
            { loading ? (

              !notFound ? (

                <React.Fragment>

                  { submited && (
                    <Loading variant="fixed"/>
                  )}

                  { !success ? (

                    <React.Fragment>

                      <Toolbar
                        align="center"
                        title={ entity.title }
                        subtitle={ entity.subtitle }
                        size="small"
                      />

                      { entity.autocomplete && (
                        <Modal
                          maxWidth="xs"
                          title={this.props.t('text.enter_data_to_continue')}
                          status={ autoCompleteShow }
                          handleCancel={ () => this.handleAutocomplete(false) }
                        >
                          <FormClientAutocomplete onSuccess={ this.onAutocompleteSuccess }/>
                        </Modal>
                      )}

                      { (fields && fields.length > 0) && (
                        <form autoComplete="nope" noValidate onSubmit={ handleSubmit(this.handleSubmitForm) }>
                          <Grid container spacing={3}>

                            { error && (
                              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Alert severity="warning">
                                  <strong>{this.props.t('text.attention')}</strong> {message}
                                </Alert>
                              </Grid>
                            )}

                            <Field
                                name="form"
                                component={ InputTextField }
                                type="text"
                                required
                                fieldHidden
                            />

                            <Field
                                name="autocomplete"
                                component={ InputTextField }
                                type="text"
                                fieldHidden
                            />

                            {/* Autocomple Client Data */}
                            { entity.autocomplete && (
                              <React.Fragment>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <Button
                                    size="medium"
                                    color="primary"
                                    fullWidth
                                    onClick={ () => this.handleAutocomplete(true) }
                                  >
                                    {this.props.t('text.already_client')} <br/>{this.props.t('text.autocomplete_form')}
                                  </Button>
                                  <Field
                                      name="client"
                                      component={ InputTextField }
                                      type="text"
                                      required
                                      fieldHidden
                                  />
                                </Grid>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <Field
                                    name="name"
                                    component={InputTextField}
                                    label={this.props.t('field.name')}
                                    type="text"
                                    required
                                  />
                                </Grid>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <Field
                                    name="surname"
                                    component={InputTextField}
                                    label={this.props.t('field.surname')}
                                    type="text"
                                    required
                                  />
                                </Grid>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <Field
                                    name="identifire"
                                    component={InputTextField}
                                    label={this.props.t('field.document_number')}
                                    type="text"
                                    required
                                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                  />
                                </Grid>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <Field
                                    name="email"
                                    component={InputTextField}
                                    label="Email"
                                    type="email"
                                    required
                                  />
                                </Grid>
                              </React.Fragment>
                            )}

                            {/* Dynamic Fields */}
                            { fields.map(field => {
                              return(
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} key={field._id}>

                                  {/* ---- inputs ---- */}
                                  { (field.block && field.block.element === 'input') && (
                                    <React.Fragment>

                                      {/* Input: Text/Number */}
                                      {(field.block.input.type === 'text' || field.block.input.type === 'number'|| field.block.input.type === 'email') && (
                                        <Field
                                          name={field.block.input.name ? field.block.input.name : `__${field._id}`}
                                          component={InputTextField}
                                          label={this.handleLabelOrigin(field)}
                                          type={field.block.input.type}
                                          validate={[
                                            field.required ? required : null,
                                            field.input.minlength ? minLength(field.input.minlength) : null,
                                            field.input.maxlength ? maxLength(field.input.maxlength) : null
                                          ]}
                                        />
                                      )}

                                      {/* Input: Date */}
                                      {(field.block.input.type === 'date') && (
                                        <React.Fragment>
                                          <Field
                                            name={`__${field._id}`}
                                            component={InputDateField}
                                            label={this.handleLabelOrigin(field)}
                                            type="text"
                                            validate={[field.required ? required : null]}
                                            disablePast={field.input.disable_past}
                                            disableFuture={field.input.disable_future}
                                            //defaultValue={moment().format()}
                                          />
                                          { this.handleInputDateValue(`__${field._id}`) }
                                        </React.Fragment>
                                      )}

                                      {/* Input: Place */}
                                      {(field.block.input.type === 'place') && (
                                        <Field
                                          name={`__${field._id}`}
                                          component={InputTextField}
                                          label={this.handleLabelOrigin(field)}
                                          type="text"
                                          validate={[field.required ? required : null]}
                                        />
                                      )}

                                      {/* Input: Radio */}
                                      {(field.block.input.type === 'radio') && (
                                        <React.Fragment>
                                          <FormLabelField required={field.required}>
                                            {this.handleLabelOrigin(field)}
                                          </FormLabelField>
                                          { field.block.input.options.length > 0 && (
                                            <Field
                                              name={`__${field._id}`}
                                              row
                                              component={RadioGroupField}
                                              validate={[required]}
                                            >
                                              {field.block.input.options.map(option => {
                                                return(
                                                  <RadioField value={option} label={option} key={option}/>
                                                )
                                              })}
                                            </Field>
                                          )}
                                        </React.Fragment>
                                      )}

                                    </React.Fragment>

                                  )}

                                  {/* ---- select ---- */}
                                  { (field.block && field.block.element === 'select') && (
                                      <Field
                                        name={`__${field._id}`}
                                        component={ SelectField }
                                        label={this.handleLabelOrigin(field)}
                                        validate={[field.required ? option : null]}
                                      >
                                        <OptionField value="-1">Seleccionar...</OptionField>
                                        {field.block.select.options.map(option => {
                                          return(
                                            <OptionField key={ option } value={ option }>
                                              { option}
                                            </OptionField>
                                          )
                                        })}
                                      </Field>
                                  )}

                                  {/* ---- paragraph ---- */}
                                  { (field.block && field.block.element === 'paragraph') && (
                                    <Typography dangerouslySetInnerHTML={{ __html: field.label}} />
                                  )}

                                  {/* ---- signature ---- */}
                                  { (field.block && field.block.element === 'signature') && (
                                    <React.Fragment>

                                      <FormLabelField required={ field.required }>Firma</FormLabelField>

                                      <Field
                                          name={`__${field._id}`}
                                          component={ InputTextField }
                                          type="text"
                                          validate={[field.required ? required : null]}
                                          fieldHidden
                                      />

                                      <Box className={ classes.signatureCanvas } ref={ this.signatureContainerRef }>
                                        <SignatureCanvas
                                          penColor='black'
                                          //clearOnResize={true}
                                          backgroundColor="#f5f5f5"
                                          canvasProps={{
                                            width: signatureCanvasWidth,
                                            height: signatureCanvasHeight
                                          }}
                                          onEnd={() => this.handleOnEndSignature(`__${field._id}`) }
                                          ref={(ref) => this.signatureCanvasRef = ref }
                                        />
                                      </Box>

                                      <ButtonsGroup align="left">
                                        <Button icon="cancel" color="danger" onClick={ () => this.handleClearSignature(`__${field._id}`) }>
                                          Limpiar
                                        </Button>
                                      </ButtonsGroup>

                                    </React.Fragment>
                                  )}

                                </Grid>
                              )
                            })}

                          </Grid>

                          {/* Button Bar */}
                          <AppBar position="fixed" color="inherit" className={ classes.buttonBar }>
                            <ToolbarMUI>
                              <Container maxWidth="sm">
                                <Grid container spacing={3} alignItems="center">
                                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                    <Typography variant="subtitle2">(*) Campos requeridos.</Typography>
                                  </Grid>
                                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                    <ButtonsGroup align="right">
                                      <Button
                                        type="submit"
                                        size="medium"
                                        icon="add"
                                        color="success"
                                        loading={submited}
                                        loadingText={this.props.t('text.sending')}
                                        disabled={invalid}
                                      >
                                        {this.props.t('button.send')}
                                      </Button>
                                    </ButtonsGroup>
                                  </Grid>
                                </Grid>

                              </Container>
                            </ToolbarMUI>
                          </AppBar>

                        </form>
                      )}

                    </React.Fragment>

                  ) :(

                    <Jumbotron
                      style={{textAlign:'center'}}
                      title={this.props.t('text.thanks')}
                      subtitle={`${this.props.t('text.form_completed')} <br/>"${ entity.title }"`}
                      actions={
                        <Button component="a" href={ API.endpoint.web } size="medium">
                          {this.props.t('button.continue')}
                        </Button>
                      }
                    />

                  )}

                </React.Fragment>

              ) : (

                <Jumbotron
                  style={{textAlign:'center'}}
                  title={this.props.t('text.form_not_found')}
                  subtitle={this.props.t('text.form_not_found_subtitle')}
                  actions={
                    <Button component="a" href={ API.endpoint.web } size="medium">
                      {this.props.t('button.continue')}
                    </Button>
                  }
                />

              )

            ) : (
              <Loading/>
            ) }
          </Box>
        </Container>
      </Box>
    )
  }

}

Page__Form = reduxForm({
  form: 'Page__Form',
  validate,
  initialValues: {},
  enableReinitialize: true
})(Page__Form);

export default withTranslation()(withStyles(styles)(Page__Form));
