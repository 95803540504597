import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list() {
  return request({
    url:    '/permission/list',
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function listByIds(ids) {
  return request({
    url:    '/permission/list/ids',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   ids
  });
}



const PermissionService = {
  list, listByIds
}

export default PermissionService;
