import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete } from './../../../../assets/theme/input';

const Autocomplete__c = ({ label, options, optionLabel, onChange, ...rest }) => {

  return(
    <Autocomplete
      size="small"
      options={ options }
      onChange={(event, newInputValue) => {
        onChange(newInputValue)
      }}
      getOptionLabel={
        (option) => option[optionLabel]
      }
      renderInput={
        (params) => <TextField { ...params } label={ label } variant="outlined" InputLabelProps={{ shrink: true }} />
      }
      { ...rest }
    />
  )

}

Autocomplete__c.propTypes = {
  label:   PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Autocomplete__c;
