import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box } from './../../../../assets/theme/default';
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination } from './../../../../assets/theme/table';
import Loading from './../../loading/default';
import Row from './../row';
import Cell from './../cell';

import Api from './../../../../config/api';

//const rowsPerPage = Api.default.paginate_items;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'block',
    width: '100%'
  },
  responsive: {
    display: 'block',
    width: '100%',
    overflowX: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    '-ms-overflow-style': '-ms-autohiding-scrollbar'
  },
  loading: {
    position: 'absolute',
    top: 57,
    left: 0,
    width: '100%',
    height: 'calc(100% - 57px)',
    background: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cell: {
    color: theme.palette.secondary.dark,
    borderBottom: `1px solid ${ theme.palette.border.main }`
  },
  pagination: {
    //padding: theme.spacing(0.5),
    borderTop: `1px solid ${ theme.palette.border.main }`
  }
}));

const Table__c = ({ thead, children, loading, total, page, onChangePage, ...rest }) => {

  const classes = useStyles();

  return (
    <Box className={ classes.root }>

      <Box className={ classes.responsive }>
        <Table>
          <TableHead>
            <TableRow>
              { thead.map((item, index) => {
                return (
                  <TableCell key={ index } className={ classes.cell } align={ item.align }>
                    { item.name }
                  </TableCell>
                )
              }, this)}
            </TableRow>
          </TableHead>
          <TableBody>
            { children }

            { (total === 0 && !loading) && (
              <Row>
                <Cell colSpan={ thead.length }>
                  &nbsp;
                </Cell>
              </Row>
            )}

          </TableBody>
        </Table>
      </Box>

      { total > 0 && (
        <Box className={ classes.pagination }>
          <TablePagination
            component="div"
            count={ total }
            rowsPerPage={ Api.default.paginate_items }
            rowsPerPageOptions={[]}
            page={ parseInt(page,10) }
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={ onChangePage }
          />
        </Box>
      )}

      { !loading && (
        <Box className={ classes.loading }>
          <Loading/>
        </Box>
      )}

    </Box>
  )

};

Table__c.propTypes = {
  thead: PropTypes.array.isRequired,
  children: PropTypes.node,
  loading: PropTypes.bool
};

export default Table__c;
