import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Grid } from './../../../../assets/theme/layout';
import { Content, Toolbar, Data, Date, Tag } from './../../../../components/ui';
import { isGenre, isBinary, isBinaryColor } from './../../../../utilities/utils';

class UtilLeadData extends React.Component {

    // @Event
    // @Description: Build client address
    handleAddress = (client) => {

      let address = '-';

      if(client.address_street){
        address = `${ client.address_street }`;
      }

      if(client.address_number){
        address = `${ address } ${ client.address_number }`;
      }

      if(client.address_floor){
        address = `${ address } ${ client.address_floor }`;
      }

      if(client.address_apartment){
        address = `${ address } ${ client.address_apartment }`;
      }

      return address;

    }

    render(){

      const { lead, hideCampaing, hidePersonal, hideContact, hideLocation } = this.props;

      const _hideCampaing      = !hideCampaing ? false : hideCampaing;
      const _hidePersonal      = !hidePersonal ? false : hidePersonal;
      const _hideContact       = !hideContact ? false : hideContact;
      const _hideLocation      = !hideLocation ? false : hideLocation;

      return(

        lead && (
          <React.Fragment>

            {/* Campaing */}
            { !_hideCampaing && (
              <Content disableMargin divider>
                <Toolbar
                  title={this.props.t('text.campaign')}
                  size="small"
                />
                <Grid container spacing={3}>
                  <Grid item xl={2} lg={2} md={3} sm={3} xs={6}>
                    <Data
                      label={this.props.t('field.source')}
                      text={ lead.source ? lead.source : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={3} xs={6}>
                    <Data
                      label={this.props.t('field.code')}
                      text={ lead.code ? lead.code : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={3} xs={6}>
                    <Data
                      label={this.props.t('field.utm_campaign')}
                      text={ lead.utm_campaign ? lead.utm_campaign : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={3} xs={6}>
                    <Data
                      label={this.props.t('field.utm_content')}
                      text={ lead.utm_content ? lead.utm_content : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={3} xs={6}>
                    <Data
                      label={this.props.t('field.utm_medium')}
                      text={ lead.utm_medium ? lead.utm_medium : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={3} xs={6}>
                    <Data
                      label={this.props.t('field.utm_source')}
                      text={ lead.utm_source? lead.utm_source : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={3} xs={6}>
                    <Data
                      label={this.props.t('field.data_limit')}
                      text={
                        <Tag
                          color={lead.is_over_limit ? isBinaryColor(!lead.is_over_limit) : false}
                          text={lead.is_over_limit ? isBinary(lead.is_over_limit) : '-'}
                        />
                      }
                      gutterBottom
                    />
                  </Grid>
                </Grid>
              </Content>
            )}

            {/* Personal */}
            { !_hidePersonal && (
              <Content disableMargin divider>
                <Toolbar
                  title={this.props.t('text.personal')}
                  size="small"
                />
                <Grid container spacing={3}>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.name')}
                      text={`${ lead.name } ${ lead.surname }`}
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.document_number')}
                      text={ lead.identifire ? lead.identifire : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.gender')}
                      text={ lead.genre ? isGenre(lead.lead) : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.birthdate')}
                      text={ lead.birthdate ? <Date value={ lead.birthdate } /> : '-' }
                    />
                  </Grid>
                </Grid>
              </Content>
            )}

            {/* Contact information */}
            { !_hideContact && (
            <Content disableMargin divider>
              <Toolbar
                title={this.props.t('text.contact')}
                size="small"
              />
              <Grid container spacing={3}>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                  <Data
                    label={this.props.t('field.email')}
                    text={ lead.email }
                    email
                    gutterBottom
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                  <Data
                    label={this.props.t('field.phone')}
                    text={ lead.phone ? lead.phone : '-' }
                    gutterBottom
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                  <Data
                    label={this.props.t('field.mobile_phone')}
                    text={ lead.mobile_phone ? lead.mobile_phone : '-' }
                    gutterBottom
                  />
                </Grid>
              </Grid>
            </Content>
            )}

            {/* Location */}
            { !_hideLocation && (
              <Content disableMargin divider>
                <Toolbar
                  title={this.props.t('text.location')}
                  size="small"
                />
                <Grid container spacing={3}>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.address')}
                      text={ this.handleAddress(lead) }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.postal_code')}
                      text={ lead.zip ? lead.zip : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.locality')}
                      text={ lead.city ? lead.city : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.state')}
                      text={ lead.state ? lead.state.name : '-' }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.country')}
                      text={ lead.country ? lead.country.name : '-' }
                      gutterBottom
                    />
                  </Grid>
                </Grid>
              </Content>
            )}

          </React.Fragment>

        )

      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UtilLeadData));
