import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Content, Paper, Button, ButtonsGroup, Loading, Table, Row, Cell, Tabs, Tab, TabPanel, Date, Tag, LinkUser } from './../../../../../components/ui';
import { IntegrationCliniceaService, IntegrationLogService } from './../../../../../services';
import { isUndefined, isResponse, isResponseColor } from './../../../../../utilities/utils';

const CliniceaStaffSync = ({ onSuccess }) => {

    const { t } = useTranslation();
    const [ loading, setLoading ] = useState(true);
    const [ submited, setSubmited ] = useState(false);
    const [ entities, setEntities ] = useState([]);

    const [ thead ] = useState([
        { id: 'created_at', name: t('field.date') },
        { id: 'type', name: t('field.type') },
        { id: 'status', name: t('field.status') },
        { id: 'description', name: t('field.description') },
        { id: 'user', name: t('field.user') }
    ]);

    const [ page, setPage ] = useState(0);
    const [ total, setTotal ] = useState(0);
  
    /*
    ** @Service
    ** @Description: Find Logs
    */

    const _Find = (page) => {

        IntegrationLogService.list(page, { items: 10, name: 'CLINICEA', table: 'user' }).then((response) => {
            
            setEntities(response.logs);
            setPage(!isUndefined(page) ? parseInt(page,10) : 0);
            setTotal(response.total);
            setLoading(false);
            setSubmited(false);
  
        }).catch((error) => {

            setLoading(false);
            setSubmited(false);

        });

    };

    /*
    ** @Service
    ** @Description: Find Logs
    */

    const _Update = () => {

        setSubmited(true);

        IntegrationCliniceaService.staffSync({ lastSyncDTime: '1970-01-01T00:00:00' }).then((response) => {
            
            handleRefreshLogs();
            setSubmited(false);

            if (onSuccess) {
                onSuccess();
            }
  
        }).catch((error) => {

            setSubmited(false);

        });

    };

    const handleRunUpdate = () => {
        _Update();
    };

    /*
    ** @Handle
    ** @Description: Refresh logs
    */

    const handleRefreshLogs = () => {
        setSubmited(true);
        setPage(0);
        _Find(0);
    };

    /*
    ** @Handle
    ** @Description: Change page
    */

    const handleChangePage = (event, page) => {
        setPage(page);
    };
    
    useEffect(() => {
        _Find(page);
    }, [page]);

    return (
        !loading ? (
            <Content disablePadding>
                <Paper disablePadding>
                    <Content disablePadding disableMargin>

                        <Tabs value={0} divider>
                            <Tab label={t('field.logs')} />
                        </Tabs>

                        <TabPanel value={0} index={0}>
                            <Content divider >
                                <ButtonsGroup align="right">
                                    {/* <Button
                                        aria-label={t('button.refresh_logs')}
                                        icon="sync"
                                        color="primary"
                                        disabled={submited}
                                        onClick={handleRefreshLogs}
                                    >
                                        {t('button.refresh_logs')}
                                    </Button> */}
                                    <Button
                                        aria-label={t('button.run_update')}
                                        icon="sync"
                                        color="primary"
                                        disabled={submited}
                                        onClick={handleRunUpdate}
                                    >
                                        {t('button.run_update')}
                                    </Button>
                                </ButtonsGroup>
                            </Content>
                            <Content disablePadding>
                                <Table
                                    thead={thead}
                                    loading={true}
                                    total={total}
                                    page={page}
                                    onChangePage={handleChangePage}
                                >
                                    {entities.length > 0 && (
                                        entities.map(entity => {
                                            return (
                                                <Row key={entity._id}>
                                                    <Cell nowrap>
                                                        <Date value={ entity.created_at } format="DD/MM/YYYY hh:mm:ss A"/>
                                                    </Cell>
                                                    <Cell>
                                                        {entity.type ? entity.type : '-'}
                                                    </Cell>
                                                    <Cell>
                                                        <Tag
                                                            text={isResponse(entity.status)}
                                                            color={isResponseColor(entity.status)}
                                                        />
                                                    </Cell>
                                                    <Cell>
                                                        {entity.description ? entity.description : '-'}
                                                    </Cell>
                                                    <Cell>
                                                        {entity.user ? (
                                                            <LinkUser user={entity.user} />
                                                        ) : (
                                                            <React.Fragment>
                                                                -
                                                            </React.Fragment>
                                                        )}
                                                    </Cell>
                                                </Row>
                                            )
                                        })
                                    )}
                                    {(!loading && entities.length === 0) && (
                                        <Row>
                                            <Cell colSpan={thead.length} align="center">
                                                {t('messages.no_results_found')}
                                            </Cell>
                                        </Row>
                                    )}
                                </Table>
                            </Content>
                        </TabPanel>

                    </Content>
                </Paper>
            </Content>
        ) : (<Loading/>)
    )

};

export default CliniceaStaffSync;
