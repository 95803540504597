import React from 'react';
import { reduxForm, Field, FieldArray, change } from 'redux-form';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Grid } from './../../../../assets/theme/layout';
import { Typography } from './../../../../assets/theme/default';
import { InputAdornment } from './../../../../assets/theme/input';
import { Content, Button, ButtonsGroup } from './../../../../components/ui';
import { SessionsFieldArray } from './../../../../scenes';
import { FormLabelField, InputTextField, InputDateField, SelectField, OptionField, RadioField, RadioGroupField } from './../../input';

const validate = values => {
  const errors = {};
  return errors;
};

class FieldsCustom extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      fields: []
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  };

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {
    if(this.props.fields){
      this.setState({fields: this.props.fields});
      if(this.props.currentValues && this.props.currentValues.length > 0){
        for (const item of this.props.currentValues) {
          try {
            const stringToJson = JSON.parse(item.value)
            this.props.dispatch(change(this.props.form, item.entity, stringToJson ));
          } catch (error) {
            this.props.dispatch(change(this.props.form, item.entity, item.value ));
          }
        }
      }
    }
  };

  /*
  ** @Handler
  ** @Description:
  */
  handleLabelOrigin = (field) => {
    if(field.block.label){
      return field.label;
    }else{
      return field.block.name;
    }
  };

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {
    const toSend = Object.keys(values).reduce(function (accumulator, currentValue, index) {
      if(values[currentValue] !== 'nope'){
        accumulator.push({ 
          entity: currentValue, 
          value: typeof values[currentValue] === 'string' || typeof values[currentValue] === 'number' 
                  ? values[currentValue]
                  : JSON.stringify(values[currentValue]) 
        });
      }
      return accumulator;
    }, []);
    this.props.onSubmit(toSend);
  };

  /*
  ** @Event
  ** @Description: Set default value on input date
  */
  handleInputDateValue = (field) => {
    this.props.dispatch(change(this.props.form, field, moment().format() ));
  };

  render(){
    const { handleSubmit, t } = this.props;
    const { fields } = this.state;
    return(
      fields.length > 0 ? (
        <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>
          <Content disablePadding divider>
            <Grid container spacing={3}>
              {fields.map((field, index) => {
                return(
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} key={field._id}>

                    {/* ---- inputs ---- */}
                    { (field.block && field.block.element === 'input') && (
                      <React.Fragment>

                        {/* Input: Text/Number */}
                        {(field.block.input.type === 'text' || field.block.input.type === 'number'|| field.block.input.type === 'email') && (
                          <Field
                            name={field.block.input.name ? field.block.input.name : field._id}
                            component={InputTextField}
                            label={this.handleLabelOrigin(field)}
                            type={field.block.input.type}
                            required={field.required}
                            min={(field.input && field.input.min) ? field.input.min : null}
                            max={(field.input && field.input.max) ? field.input.max : null}
                            minLength={(field.input && field.input.minlength) ? field.input.minlength : null}
                            maxLength={(field.input && field.input.maxlength) ? field.input.maxlength : null}
                            endAdornment={ field.caption ? <InputAdornment position="end">{field.caption}</InputAdornment> : null }
                          />
                        )}

                        {/* Input: Date */}
                        {(field.block.input.type === 'date') && (
                          <React.Fragment>
                            <Field
                              name={field.block.input.name ? field.block.input.name : field._id}
                              component={InputDateField}
                              label={this.handleLabelOrigin(field)}
                              type="text"
                              disablePast={field.input.disable_past}
                              disableFuture={field.input.disable_future}
                            />
                            { /*this.handleInputDateValue(field._id)*/ }
                          </React.Fragment>
                        )}

                        {/* Input: Radio */}
                        {(field.block.input.type === 'radio') && (
                          <React.Fragment>
                            <FormLabelField required={field.required}>
                              {this.handleLabelOrigin(field)}
                            </FormLabelField>
                            { field.block.input.options.length > 0 && (
                              <Field
                                name={field._id}
                                row
                                component={RadioGroupField}
                              >
                                {field.block.input.options.map(option => {
                                  return(
                                    <RadioField value={option} label={option} key={option}/>
                                  )
                                })}
                              </Field>
                            )}
                          </React.Fragment>
                        )}

                      </React.Fragment>
                    )}

                    {/* ---- select ---- */}
                    { (field.block && field.block.element === 'select') && (
                        <Field
                          name={field._id}
                          component={ SelectField }
                          label={this.handleLabelOrigin(field)}
                        >
                          <OptionField value="nope">{t('field.option_field.select')}</OptionField>
                          {field.block.dynamic ? (
                            field.select.options.map(option => {
                              return(
                                <OptionField key={ option } value={ option }>
                                  { option}
                                </OptionField>
                              )
                            })
                          ) : (
                            field.block.select.options.map(option => {
                              return(
                                <OptionField key={ option } value={ option }>
                                  { option}
                                </OptionField>
                              )
                            })
                          )}

                        </Field>
                    )}

                    {/* ---- paragraph ---- */}
                    { (field.block && field.block.element === 'paragraph') && (
                      <Typography dangerouslySetInnerHTML={{ __html: field.label}} />
                    )}

                    {/* ---- discounts_intervals ---- */}
                    { (field.block && field.block.element === 'discounts_intervals') && (
                      <FieldArray
                        name={field._id}
                        component={SessionsFieldArray}
                      />
                    )}

                  </Grid>
                )
              })}
            </Grid>
          </Content>
          <Content disablePadding>
            <ButtonsGroup align="right">
              <Button aria-label="Aplicar" icon="save" color="primary" type="submit">
                {t('button.apply')}
              </Button>
            </ButtonsGroup>
          </Content>
        </form>
      ) : (
        <Typography align="center">{t('messages.custom_fields_not_found')}</Typography>
      )
    )
  };

};

FieldsCustom = reduxForm({
  form: 'FieldsCustom',
  validate,
  initialValues: {},
  enableReinitialize: true
})(FieldsCustom);

export default withTranslation()(FieldsCustom);
