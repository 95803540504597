import React from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change  } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { Grid } from './../../../assets/theme/layout';
import { Content, Button, ButtonsGroup } from './../../../components/ui';
import { CountrySelect } from './../../../scenes';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { stringifyQueryString, parseQueryString } from './../../../utilities/utils';

const validate = values => {
  const errors = {}
  return errors;
}

class PageStoreFilter extends React.Component {

  componentDidMount(){

    const search = parseQueryString(this.props.location.search);

    Object.entries(search).forEach(([key,value]) => {
      this.props.dispatch(change('PageStoreFilter', key, value));
    });

  }

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {
    this.props.history.push(`/stores?${ stringifyQueryString(values) }`);
    this.props.onSuccess();
  }

  // @Event
  // @Description: Set country id
  handleOnChangeCountry = (country) => {
    this.props.dispatch(change('PageStoreFilter', 'country', country.target.value));
  };

  render() {

    const { handleSubmit } = this.props;

    return(

      <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

        <Content disablePadding divider>
          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="name"
                  component={ InputTextField }
                  label={this.props.t('field.name')}
                  type="text"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="enabled"
                  component={ SelectField }
                  label={this.props.t('field.status')}>
                    <OptionField value="none">{this.props.t('field.option_field.all')}</OptionField>
                    <OptionField value="true">{this.props.t('field.option_field.active')}</OptionField>
                    <OptionField value="false">{this.props.t('field.option_field.disabled')}</OptionField>
              </Field>
            </Grid>
            <Grid item xl={4} lg={6} md={6} sm={6} xs={12}>
              <Field
                name="country"
                component={CountrySelect}
                label={this.props.t('field.country')}
                required
                onChange={this.handleOnChangeCountry}
              />
            </Grid>
          </Grid>
        </Content>
        <Content disablePadding>
          <ButtonsGroup align="right">
            <Button aria-label={this.props.t('button.apply')} icon="save" color="primary" type="submit">
              {this.props.t('button.apply')}
            </Button>
          </ButtonsGroup>
        </Content>

      </form>

    )
  }

}

PageStoreFilter = reduxForm({
  form: 'PageStoreFilter',
  validate,
  initialValues: {
    enabled: 'none',
    country: 'none'
  },
  enableReinitialize: true
})(PageStoreFilter);

export default  withTranslation()(withRouter(PageStoreFilter));
