import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Box } from './../../../assets/theme/default';
import { Grid } from './../../../assets/theme/layout';
import { ButtonCard } from './../../../components/ui';
import { hasPermission } from './../../../utilities/utils';

import API from './../../../config/api';

const DashboardAccess = ({ client, session, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Box>

      <Grid container spacing={3}>

        { hasPermission(session.user.permissions, API.permissions.users) && (
          <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
            <ButtonCard
              fullWidth
              icon="user"
              title={t('page.users.name')}
              component={ NavLink }
              to="/users"
              size="small"
            />
          </Grid>
        )}

        { hasPermission(session.user.permissions, API.permissions.clients) && (
          <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
            <ButtonCard
              fullWidth
              icon="client"
              title={t('page.clients.name')}
              component={ NavLink }
              to="/clients"
            />
          </Grid>
        )}

        { hasPermission(session.user.permissions, API.permissions.entities) && (
          <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
            <ButtonCard
              fullWidth
              icon="company"
              title={t('page.entities.name')}
              component={ NavLink }
              to="/entities"
            />
          </Grid>
        )}

        { hasPermission(session.user.permissions, API.permissions.payments) && (
          <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
            <ButtonCard
              fullWidth
              icon="payment"
              title={t('page.payments.name')}
              component={ NavLink }
              to="/payments"
            />
          </Grid>
        )}
        { hasPermission(session.user.permissions, API.permissions.leads) && (
          <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
            <ButtonCard
              fullWidth
              icon="lead"
              title={t('page.leads.name')}
              component={ NavLink }
              to="/leads"
            />
          </Grid>
        )}
        { hasPermission(session.user.permissions, API.permissions.api_forms) && (
          <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
            <ButtonCard
              fullWidth
              icon="form"
              title={t('page.forms.name')}
              component={ NavLink }
              to="/forms"
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

export default connect(mapStateToProps)(DashboardAccess);
