import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase } from './../../../../assets/theme/button';
import { makeStyles, Typography, Box } from './../../../../assets/theme/default';
import { PaymentIcon, PersonIcon, ReceiptIcon, PhotoIcon, FaceIcon, FormatListBulletedIcon, PersonPinIcon, ViewHeadlineIcon, BusinessIcon } from './../../../../assets/theme/icon';

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${ theme.palette.primary.main }`,
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: theme.palette.common.white,
    textAlign: 'center',
    '&:hover': {
      background: '#fbfbfb'
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    }
  },
  fullWidth: {
    width: '100%'
  },
  icon: {
    display: 'block',
    width: '100%',
    marginBottom: theme.spacing(1.5)
  },
    primaryIcon: {
      color: theme.palette.primary.main
    },
    secondaryIcon: {
      color: theme.palette.secondary.main
    },
    smallIcon: {
      fontSize: theme.spacing(4)
    },
    mediumIcon: {
      fontSize: theme.spacing(6)
    },
    largeIcon: {
      fontSize: theme.spacing(8)
    },
  text: {
    display: 'block',
    width: '100%'
  },
  title: {
    display: 'block',
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(0.5)
  },
    primaryTitle: {
      color: theme.palette.primary.main
    },
    secondaryTitle: {
      color: theme.palette.secondary.main
    },
    smallTitle: {
      fontSize: theme.typography.h6.fontSize,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.fontSize
      }
    },
    mediumTitle: {
      fontSize: theme.typography.h5.fontSize,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.fontSize
      }
    },
    largeTitle: {
      fontSize: theme.typography.h4.fontSize,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.fontSize
      }
    },
  subtitle: {
    display: 'block',
    lineHeight: 1,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 13
    }
  }
}));

const variantIcon = {
  user: PersonIcon,
  client: FaceIcon,
  category: FormatListBulletedIcon,
  payment: PaymentIcon,
  product: PhotoIcon,
  checkout: ReceiptIcon,
  lead: PersonPinIcon,
  form: ViewHeadlineIcon,
  company: BusinessIcon
};

const ButtonCard = ({ title, subtitle, icon, variant = 'primary', size = 'small', fullWidth = true, ...rest }) => {

  const classes = useStyles();

  const Icon          = icon ? variantIcon[icon] : null;
  const _fullWidth    = fullWidth ? classes['fullWidth'] : null;
  const _sizeRoot     = variant ? classes[`${ size }Root`] : null;
  const _variantTitle = variant ? classes[`${ variant }Title`] : null;
  const _sizeTitle    = variant ? classes[`${ size }Title`] : null;
  const _variantIcon  = variant ? classes[`${ variant }Icon`] : null;
  const _sizeIcon     = variant ? classes[`${ size }Icon`] : null;

  return (
    <ButtonBase
      { ...rest }
      disableRipple
      classes={{
        root: classNames(
          classes.root,
          _fullWidth,
          _sizeRoot
        )
      }}
    >

      <Box component="span" className={ classes.icon }>
        <Icon className={classNames(
          _variantIcon,
          _sizeIcon
        )}/>
      </Box>

      <Box component="span" className={ classes.text }>
        <Typography
          component="span"
          classes={{
            root: classNames(
              classes.title,
              _variantTitle,
              _sizeTitle
            )
          }}
        >
          { title }
        </Typography>

        { subtitle && (
          <Typography component="span" classes={{ root: classes.subtitle }}>
            { subtitle }
          </Typography>
        )}
      </Box>

    </ButtonBase>
  )

}

ButtonCard.propTypes = {
  //children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

export default ButtonCard;
