import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field, change, FieldArray, formValueSelector } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, ButtonsGroup, Upload, ButtonCustomFieldArray } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { StoreAutocompleteMultiple } from './../../../scenes'
import { OfferService } from './../../../services';
import { isSlug, findPermissionProps, filterIds } from './../../../utilities/utils';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const selector = formValueSelector('Page__OfferForm');

const validate = values => {

  const errors = {};

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 1024){
    errors.name = TEXT.validation.maxlength(1024);
  }

  if (!values.position) {
    errors.position = TEXT.validation.required;
  }else if(values.position <= 0){
    errors.position = TEXT.validation.minlength(1);
  }

  if (!values.title) {
    errors.title = TEXT.validation.required;
  }else if(values.title.length > 1024){
    errors.title = TEXT.validation.maxlength(1024);
  }

  if (values.paragraph) {
    if(values.paragraph.length > 1024){
      errors.paragraph = TEXT.validation.maxlength(1024);
    }
  }

  if (values.quote) {
    if(values.quote.length > 1024){
      errors.quote = TEXT.validation.maxlength(1024);
    }
  }

  if (!values.source) {
    errors.source = TEXT.validation.required;
  }else if(values.source.length > 255){
    errors.source = TEXT.validation.maxlength(255);
  }else if(!isSlug(values.source)){
    errors.source = TEXT.validation.slug_invalid;
  }

  if (!values.image_large) {
    errors.image_large = TEXT.validation.required;
  }

  if (!values.image_medium) {
    errors.image_medium = TEXT.validation.required;
  }

  if (!values.image_small) {
    errors.image_small = TEXT.validation.required;
  }

  if (!values.image_grid) {
    errors.image_grid = TEXT.validation.required;
  }

  if (values.email_notification) {
    if(JSON.parse(values.email_notification)){
      if (!values.email_paragraph) {
        errors.email_paragraph = TEXT.validation.required;
      }else if(values.email_paragraph.length > 1024){
        errors.email_paragraph = TEXT.validation.maxlength(1024);
      }
    }
  }

  if (values.buttons && values.buttons.length > 0) {
    const buttonsArrayErrors = [];
    values.buttons.forEach((button, buttonIndex) => {

      const buttonErrors = {};

      // Validation name
      if (!button || !button.name) {
        buttonErrors.name = TEXT.validation.required;
        buttonsArrayErrors[buttonIndex] = buttonErrors;
      }else if(button.name.length > 255){
        buttonErrors.name =  TEXT.validation.maxlength(255);
        buttonsArrayErrors[buttonIndex] = buttonErrors;
      }

      // Validation link
      if (!button || !button.path) {
        buttonErrors.path = TEXT.validation.required;
        buttonsArrayErrors[buttonIndex] = buttonErrors;
      }else if(button.path.length > 2048){
        buttonErrors.path = TEXT.validation.maxlength(2048);
        buttonsArrayErrors[buttonIndex] = buttonErrors;
      }

    })
    if (buttonsArrayErrors.length) {
      errors.buttons = buttonsArrayErrors;
    }

  }

  return errors;

}

const renderItemsForIds = ({ fields, meta: { error, submitFailed } }) => (
  fields.length > 0 && (
    fields.map((entity, index) => (
      <Field
          key={ index }
          name={ entity }
          component={ InputTextField }
          type="text"
          required
          fieldHidden
      />
    ))
  )
);
class Page__OfferForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: '...',
        title: '...',
        loading: true,
        submited: false,
        id: null,
        entity: null,
        categories: [],
        images: [],
        country: null,
        countries: [],
        states: [],
        stores: []
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.offer, "Ofertas") });

      const id = this.props.match.params.id;

      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false, title: 'Nuevo registro' });
      }

    };

    /*
    ** @Service
    ** @Description: Save Offer
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      OfferService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        // @Redirect
        this.props.history.push('/offer');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Offer
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      OfferService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        // @Redirect
        this.props.history.push('/offer');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Offer
    */

    _Find = (id) => {

      // @Service
      OfferService.find(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set note update into state
        this.setState({
          loading: false,
          id: response.offer._id,
          entity: response.offer,
          title: response.offer.name
        });

        // @Form
        // @Description: Set form fields

        // @General
        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));
        this.props.dispatch(change(form, 'published', this.state.entity.published ));
        this.props.dispatch(change(form, 'position', this.state.entity.position ));
        this.props.dispatch(change(form, 'title', this.state.entity.title ));
        this.props.dispatch(change(form, 'paragraph', this.state.entity.paragraph ));
        this.props.dispatch(change(form, 'quote', this.state.entity.quote ));

        // @Configuration
        this.props.dispatch(change(form, 'source', this.state.entity.source ));
        this.props.dispatch(change(form, 'allow_all', this.state.entity.allow_all ));
        this.props.dispatch(change(form, 'allow_limit', this.state.entity.allow_limit ));
        this.props.dispatch(change(form, 'allow_over_limit', this.state.entity.allow_over_limit ));
        this.props.dispatch(change(form, 'generate_code', this.state.entity.generate_code ));
        this.props.dispatch(change(form, 'email_notification', this.state.entity.email_notification ));
        this.props.dispatch(change(form, 'email_paragraph', this.state.entity.email_paragraph ));
        this.props.dispatch(change(form, 'buttons', this.state.entity.buttons ));
        this.props.dispatch(change(form, 'stores', filterIds(this.state.entity.stores) ));

        // @Images
        if (this.state.entity.image_large) {
          this.props.dispatch(change(form, 'image_large', JSON.stringify(this.state.entity.image_large) ));
        }
        if (this.state.entity.image_medium) {
          this.props.dispatch(change(form, 'image_medium', JSON.stringify(this.state.entity.image_medium) ));
        }
        if (this.state.entity.image_small) {
          this.props.dispatch(change(form, 'image_small', JSON.stringify(this.state.entity.image_small) ));
        }
        if (this.state.entity.image_aditional) {
          this.props.dispatch(change(form, 'image_aditional', JSON.stringify(this.state.entity.image_aditional) ));
        }
        if (this.state.entity.image_grid) {
          this.props.dispatch(change(form, 'image_grid', JSON.stringify(this.state.entity.image_grid) ));
        }
        if (this.state.entity.image_email) {
          this.props.dispatch(change(form, 'image_email', JSON.stringify(this.state.entity.image_email) ));
        }

        this.setState({ stores: this.state.entity.stores });

      }).catch((error) => {

        // @State
        // @Description:
        this.setState({ loading: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description: On select store
    */
    handleOnSelectStore = (stores) => {

      this.props.dispatch(change(this.props.form, 'stores', stores ? filterIds(stores) : [] ));

      this.setState({ stores });

    }

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };

    // @Event
    // @Description: On change country
    handleOnChangeCountry = (event) => {

      // @Entity
      // @Description: Set country id
      this.setState({ country: event.target.value });

      // @Form
      // @Description: Set state id
      this.props.dispatch(change(this.props.form, 'state', '-1' ));

    };

    // @Event
    // @Description: Load default country
    handleOnLoadCountry = (country) => {

      // @Entity
      // @Description: Set country id
      this.setState({ country: country._id });

      // @Form
      // @Description: Set country id
      this.props.dispatch(change(this.props.form, 'country', country._id ));

    };

    /*
    ** @Event
    ** @Description: On selected user
    */
    handleOnSelectUser = (user) => {

      // @Form
      // @Description: Set client ID
      this.props.dispatch(change(this.props.form, 'user', user ? user._id : '' ));

    };

    /*
    ** @Event
    ** @Description: OnUpload file
    */
    onUploadImage = (files, name) => {
      this.props.dispatch(change(this.props.form, name, files.length > 0 ? JSON.stringify(files[0]) : null ));
    };

    // @Event
    // @Description: On change allowAll
    handleOnChangeAllowAll = (event, value) => {
      if(!JSON.parse(value)){
        this.props.dispatch(change(this.props.form, 'allow_over_limit', false));
      }
    };

    // @Render
    render(){

      const { handleSubmit, invalid, allowAll, imageLarge, imageMedium, imageSmall, imageAditional, imageGrid, imageEmail, emailNotification, t } = this.props;
      const { loading, permission, title, name, submited, stores } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/offer"
                icon="back"
                size="medium"
              >
                {t('button.back')}
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              {/* Form Category */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* General */}
                  <Content marginBottom>
                    <Toolbar
                      title="General"
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                        <Field
                          name="name"
                          component={ InputTextField }
                          label={t('field.name')}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label={t('field.status')}
                          required
                        >
                          <OptionField value="true">Activo</OptionField>
                          <OptionField value="false">Bloqueado</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                        <Field
                          name="published"
                          component={ SelectField }
                          label={t('field.published')}
                          required
                        >
                          <OptionField value="true">Si</OptionField>
                          <OptionField value="false">No</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
                        <Field
                          name="position"
                          component={ InputTextField }
                          label={t('field.position')}
                          type="number"
                          min={1}
                          required
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="title"
                          component={ InputTextField }
                          label={t('field.title')}
                          type="text"
                          required
                          multiline
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="paragraph"
                          component={ InputTextField }
                          label={t('field.subtitle')}
                          type="text"
                          multiline
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="quote"
                          component={ InputTextField }
                          label={t('field.quote')}
                          type="text"
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Configuration */}
                  <Content disablePadding divider>
                    <Toolbar
                      title="Configuración"
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Field
                          name="source"
                          component={ InputTextField }
                          label={t('field.campaign')}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Field
                          name="allow_all"
                          component={ SelectField }
                          label={t('field.allow_registration_limit')}
                          required
                          onChange={this.handleOnChangeAllowAll}
                        >
                          <OptionField value="true">{t('field.option_field.yes')}</OptionField>
                          <OptionField value="false">{t('field.option_field.no')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Field
                          name="allow_limit"
                          component={ InputTextField }
                          label={t('field.registration_limit')}
                          type="number"
                          required
                          min={0}
                          disabled={JSON.parse(allowAll)}
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Field
                          name="allow_over_limit"
                          component={ SelectField }
                          label={t('field.allow_registration_overflow')}
                          required
                          disabled={JSON.parse(allowAll)}
                        >
                          <OptionField value="true">{t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Field
                          name="generate_code"
                          component={ SelectField }
                          label={t('field.registration_code')}
                          required
                        >
                          <OptionField value="true">{t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Field
                          name="email_notification"
                          component={ SelectField }
                          label={t('field.email_notification')}
                          required
                        >
                          <OptionField value="true">{t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>

                    </Grid>
                  </Content>

                  {/* Images */}
                  <Content marginBottom divider>
                    <Toolbar
                      title={t('text.images')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Upload
                          label={t('field.desktop')}
                          attachments={imageLarge ? [JSON.parse(imageLarge)] : []}
                          multiple={false}
                          avatar={true}
                          paper={true}
                          required
                          onSuccess={(files) => { this.onUploadImage(files, 'image_large') }}
                        />
                        <Field name="image_large" fieldHidden component={ InputTextField }/>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Upload
                          label={t('field.tablet')}
                          attachments={imageMedium ? [JSON.parse(imageMedium)] : []}
                          multiple={false}
                          avatar={true}
                          paper={true}
                          required
                          onSuccess={(files) => { this.onUploadImage(files, 'image_medium') }}
                        />
                        <Field name="image_medium" fieldHidden component={ InputTextField }/>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Upload
                          label={t('field.mobile')}
                          attachments={imageSmall ? [JSON.parse(imageSmall)] : []}
                          multiple={false}
                          avatar={true}
                          paper={true}
                          required
                          onSuccess={(files) => { this.onUploadImage(files, 'image_small') }}
                        />
                        <Field name="image_small" fieldHidden component={ InputTextField }/>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Upload
                          label={t('field.list')}
                          attachments={imageGrid ? [JSON.parse(imageGrid)] : []}
                          multiple={false}
                          avatar={true}
                          paper={true}
                          required
                          onSuccess={(files) => { this.onUploadImage(files, 'image_grid') }}
                        />
                        <Field name="image_grid" fieldHidden component={ InputTextField }/>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Upload
                          label={t('field.promotion')}
                          attachments={imageAditional ? [JSON.parse(imageAditional)] : []}
                          multiple={false}
                          avatar={true}
                          paper={true}
                          onSuccess={(files) => { this.onUploadImage(files, 'image_aditional') }}
                        />
                        <Field name="image_aditional" fieldHidden component={ InputTextField }/>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Upload
                          label={t('field.email')}
                          attachments={imageEmail ? [JSON.parse(imageEmail)] : []}
                          multiple={false}
                          avatar={true}
                          paper={true}
                          onSuccess={(files) => { this.onUploadImage(files, 'image_email') }}
                        />
                        <Field name="image_email" fieldHidden component={ InputTextField }/>
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Stores */}
                  <Content marginBottom divider>
                    <Toolbar
                      title={t('text.stores')}
                      subtitle={t('text.offer_store_configuration')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                        <StoreAutocompleteMultiple
                          stores={stores}
                          onSelect={this.handleOnSelectStore}
                        />

                        {/* Array of Ids */}
                        <FieldArray
                          name="stores"
                          component={ renderItemsForIds }
                        />

                      </Grid>
                    </Grid>
                  </Content>

                  {/* Email buttons */}
                  <Content disablePadding >
                    <Toolbar
                      title={t('field.email')}
                      subtitle={t('text.email_configuration')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="email_paragraph"
                          component={ InputTextField }
                          label={t('field.text')}
                          type="text"
                          required={emailNotification ? JSON.parse(emailNotification) : false}
                          multiline
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FieldArray
                          name="buttons"
                          component={ ButtonCustomFieldArray }
                        />
                      </Grid>
                    </Grid>
                  </Content>

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/offer"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      {t('button.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      {t('button.save')}
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__OfferForm = reduxForm({
  form: 'Page__OfferForm',
  validate,
  initialValues: {
    enabled: 'true',
    allow_all: 'false',
    allow_limit: 0,
    allow_over_limit: 'false',
    generate_code: 'true',
    email_notification: 'false',
    position: 1
  }
})(Page__OfferForm);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

Page__OfferForm = connect(
  state => {
    const allowAll = selector(state, 'allow_all') || null;
    const allowLimit = selector(state, 'allow_limit') || 0;
    const imageLarge = selector(state, 'image_large');
    const imageMedium = selector(state, 'image_medium');
    const imageSmall = selector(state, 'image_small');
    const imageAditional = selector(state, 'image_aditional');
    const imageGrid = selector(state, 'image_grid');
    const imageEmail = selector(state, 'image_email');
    const emailNotification = selector(state, 'email_notification');
    return { allowAll, allowLimit, imageLarge, imageMedium, imageSmall, imageAditional, imageGrid, imageEmail, emailNotification }
  }
)(Page__OfferForm);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__OfferForm));
