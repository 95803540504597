import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { SelectField, OptionField } from './../../../components/ui/input';
import { FranchiseService } from './../../../services';

class FranchiseSelect extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      entities: []
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._All();
  }

  /*
  ** @Service
  ** @Description: List all franchises
  */

  _All = () => {
    FranchiseService.listAll().then((response) => {
console.log('ENTRE: ', response);
      // @Sate
      this.setState({ entities: response.franchises });

      // @Find
      // @Description: Find franchise default
      let franchiseDefault = response.franchises.find((franchise) => franchise.default === true);

      // if(!franchiseDefault && response.franchises.length > 0){
      //   franchiseDefault = response.franchises[0];
      // }

      // @Event
      if(this.props.onLoad){
        this.props.onLoad(franchiseDefault);
      }

    });
  }

  /*
  ** @Render
  */

  render() {

    const { entities } = this.state;

    return(
      <SelectField { ...this.props }>
        { entities.length > 0 ? (
          <React.Fragment>
            <OptionField value="-1">{this.props.t('field.option_field.select')}</OptionField>
            { entities.map((entity, index) => {
              return(
                <OptionField key={index} value={entity._id}>
                  {entity.name}
                </OptionField>
              )
            })}
          </React.Fragment>
        ) : (
          <OptionField value="-1">{this.props.t('field.option_field.select')}</OptionField>
        )}
      </SelectField>
    )
  }

}

FranchiseSelect.propTypes = {
  onLoad: PropTypes.func
};

export default withTranslation()(FranchiseSelect);
