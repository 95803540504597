import React from 'react';
import { Content, Alert, Table, Row, Cell, Tag, LinkShipping, LinkEntity } from './../../../../components/ui';
import { ShippingService } from './../../../../services';

import TEXT from './../../../../config/text';

class ShippingOrderList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      error: false,
      entities: [],
      thead: [
        { id: 'code_incremental', name: 'Código' },
        { id: 'event', name: 'Estado' },
        { id: 'entity', name: 'Empresa' }
      ]
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  }

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {
    this._All(0);
  }

  /*
  ** @Service
  ** @Description: List
  */

  _All = (page) => {

    ShippingService.list(page).then((response) => {

      // @State
      // @Description: Set response
      this.setState({
        loading: true,
        entities: response.orders || []
      });

    }).catch((error) => {

      // @State
      // @Description: Set error
      this.setState({ error: true });

    });

  }

  render(){

    const { loading, error, entities, thead } = this.state;

    return(

      !error ? (
        <Table
          thead={ thead }
          loading={ loading }
        >

          { entities.length > 0 && (
            entities.map(entity => {
              return (
                <Row key={ entity._id }>
                  <Cell>
                    { entity.code_incremental ? (
                      <LinkShipping shipping={entity}/>
                    ) : (
                      <React.Fragment>
                        [No disponible]
                      </React.Fragment>
                    )}
                  </Cell>
                  <Cell>
                    { entity.event ? (
                      <Tag text={entity.event.name}/>
                    ) : (
                      <React.Fragment>
                        [No disponible]
                      </React.Fragment>
                    )}
                  </Cell>
                  <Cell>
                    { entity.entity ? (
                      <LinkEntity entity={ entity.entity }/>
                    ) : (
                      <React.Fragment>
                        {entity.company ? entity.company : '[No disponible]'}
                      </React.Fragment>
                    )}
                  </Cell>
                </Row>
              )
            })
          )}

          { (loading && entities.length === 0) && (
            <Row>
              <Cell colSpan={ thead.length } align="center">
                { TEXT.message.without_results }
              </Cell>
            </Row>
          )}

        </Table>
      ) : (
        <Content>
          <Alert severity="info">
            <strong>Atención:</strong> Hubo un error al solicitar la información.
          </Alert>
        </Content>
      )

    )

  }

}

export default ShippingOrderList;
