import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Box, Alert, Typography } from './../../../assets/theme/default';
import { Grid } from './../../../assets/theme/layout';
import { withTranslation } from 'react-i18next';
import { Button, Toolbar } from './../../../components/ui';
import { InputTextField } from './../../../components/ui/input';
import { AuthService } from './../../../services';
import { isPasswordValid } from './../../../utilities/utils';

import TEXT from './../../../config/text';
const validate = values => {
  const errors = {};
  if (!values.password) {

    errors.password = TEXT.validation.required;
  } else if( !isPasswordValid(values.password) ){
    errors.password = TEXT.validation.wrong_password;
  }

  if (!values.repeat_password) {
    errors.repeat_password = TEXT.validation.required;
  } else if (values.repeat_password !== values.password) {
    errors.repeat_password = TEXT.validation.mismatched_password;
  }

  return errors;

}

class LoginResetPassword extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      error: false,
      message: null
    };
  }

  /*
  ** @Service
  ** @Description: Reset Password
  */
  _ResetPassword = (values) => {

    // @State
    // @Description: Set show loading
    this.setState({ loading: true });

    let toSend = Object.assign({
      email: this.props.params.email
    }, values);

    AuthService.resetPassword(toSend).then((response) => {

      if(response.status){

        this.props.onSuccess();

      }else{

        // @State
        // @Description: Set hide loading
        this.setState({ loading: false, error: true, message: TEXT.message.error });

      }

    }).catch((error) => {

      let message = TEXT.message.error;

      // @State
      // @Description: Set hide loading
      this.setState({ loading: false, error: true, message: message });

    });

  }

  /*
  ** @Handle
  ** @Description: Submit Form
  */

  handleSubmitForm = (values) => {
    this._ResetPassword(values);
  }

  render() {

    const { handleSubmit, invalid, t }  = this.props;
    const { loading, error, message }  = this.state;

    return (
      <Box>

        <Toolbar
          style={{ textAlign: 'center' }}
          title={t('text.change_password')}
        />

        <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>
          <Grid container spacing={3}>

            { error && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Alert severity="error">{ message }</Alert>
              </Grid>
            )}

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Field
                name="password"
                component={ InputTextField }
                label={t('field.new_password')}
                type="password"
                required
              />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Field
                name="repeat_password"
                component={ InputTextField }
                label={t('field.repeat_password')}
                type="password"
                required
              />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                fullWidth
                size="large"
                type="submit"
                disabled={ invalid }
                loading={ loading }
              >
                {t('button.confirm')}
              </Button>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="caption" align="center" component="p">
                { TEXT.message.pattern_password }
              </Typography>
            </Grid>

          </Grid>
        </form>

      </Box>
    )
  }
}

LoginResetPassword = reduxForm({
  form: 'LoginResetPassword',
  validate
})(LoginResetPassword);

export default withTranslation()(LoginResetPassword);
