import React from 'react';
import { makeStyles, Box } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    boxShadow: '0px 10px 50px rgba(0,0,0,0.1)',
    marginTop: theme.spacing(2)
  },
  container: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      top: -6,
      right: 24,
      content: '""',
      width: 0,
      height: 0,
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderBottom: '6px solid #fff'
    }
  }
}));

const Dropdown = ({ children, minWidth = 190, ...rest }) => {
  const classes  = useStyles();
  return (
    <Box className={classes.root} style={{minWidth:minWidth}}>
      <Box className={classes.container}>
        {children}
      </Box>
    </Box>
  )
};

export default Dropdown;
