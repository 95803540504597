import React from 'react';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Grid } from './../../../../assets/theme/layout';
import { InputAdornment } from './../../../../assets/theme/input';
import { InputTextField, CheckboxField } from './../../input';
import { Box } from './../../../../assets/theme/default';

const InstallmentsFieldArray = ({ fields, meta: { error, submitFailed } }) => {
  const { t } = useTranslation();


  return(
    fields.length > 0 && (
      fields.map((entity, index) => (
        <Grid container spacing={1} key={index}>
          <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
            <Field
                name={`${entity}.installments`}
                component={ InputTextField }
                type="text"
                required
                readonly="readonly"
                startAdornment={
                  <InputAdornment position="start">{t('text.installments')}:</InputAdornment>
                }
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <Field
              name={`${entity}.percentage`}
              component={ InputTextField }
              required
              startAdornment={
                <InputAdornment position="start">{t('text.interest_rate')}:</InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">%</InputAdornment>
              }
            >
            </Field>
          </Grid>
          <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Field
                name={`${entity}.enabled`}
                component={ CheckboxField }
                required
            >
            </Field>
            </Box>
          </Grid>
        </Grid>
      ))
    )
  )
};

export default InstallmentsFieldArray;
