import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Page, Wrapper, Paper, Button, Toolbar, Modal, Table, Row, Cell, Date, Currency, LinkClient} from './../../../../components/ui';
import { ShopCartService } from './../../../../services';
import { hasPermission, findPermissionProps, parseQueryString, isUndefined } from './../../../../utilities/utils';
import UtilShopCartDetail from './../detail';

import API from './../../../../config/api';
import TEXT from './../../../../config/text';

class Page__ShopCartList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        loading: false,
        page: 0,
        total: 0,
        entities: [],
        clientName: null,
        items: [],
        thead: [
          { id: 'client', name: this.props.t('field.full_name') },
          { id: 'quantity', name: this.props.t('field.quantity') },
          { id: 'total', name: this.props.t('field.total') },
          { id: 'last_added', name: this.props.t('field.date') },
          { id: 'actions', name: this.props.t('field.actions'), align: 'right' }
        ],
        detailShow: false
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };


    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.shop_cart, this.props.t('text.carts')) });

      const page = this.props.match.params.page || 0;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          this._All(page);
        }else{
          this.props.history.push('/shop/cart/');
        }
      }else{
        this._All(this.state.page);
      }

    };

    /*
    ** @Service
    ** @Description: All Carts
    */

    _All = (page) => {

      // @State
      // @Description: Refresh after each service
      this.setState({ loading: false, entities: [] });

      // @Service

      ShopCartService.list(page).then((response) => {

        // @State
        // @Descripcion: Set values
        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          total: response.total,
          entities: response.carts,
        });

      }).catch((error) => {

        // @State
        // @Descripcion: Loading disabled
        this.setState({ loading: true });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find items by client id
    */

    _Find = (client) => {

      this.setState({ detailShow: true });

      ShopCartService.find(client._id).then((response) => {

          this.setState({
            clientName: `${client.name} ${client.surname}`,
            items: response.items
          });

      }).catch((error) => {

        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description: Open detail
    */

    handleItems = (id) => {
      this._Find(id);
    };

    /*
    ** @Event
    ** @Description: Close detail
    */

    handleItemsClose = () => {
      this.setState({ detailShow: false });
      setTimeout(() => {
        this.setState({ items: [] });
      },1000);
    };

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/shop/cart/page/${ page }`,
        search: this.props.location.search
      });

    };


    render(){

      const { session, t } = this.props;
      const { permission, name, loading, entities, items, thead, detailShow, clientName, total, page, } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
            />

            <Paper disablePadding>

                {/* Detail */}
                <Modal
                    status={detailShow}
                    title={`${t('text.purchase_details')} ${clientName}`}
                    handleCancel={() => this.handleItemsClose()}
                    maxWidth="md">
                    <UtilShopCartDetail entities={items}/>
                </Modal>

                <Table
                  thead={ thead }
                  total={ total }
                  page={ page }
                  loading={ loading }
                  onChangePage={ this.handleChangePage }
                >
                  { entities.length > 0 && (
                    entities.map((entity, index) => {
                      return (
                        <Row key={ index }>
                          <Cell nowrap>
                            <LinkClient client={ entity._id }/>
                          </Cell>
                          <Cell nowrap>
                            { entity.quantity }
                          </Cell>
                          <Cell nowrap>
                            <Currency value={ entity.total }/>
                          </Cell>
                          <Cell nowrap>
                          <Date value={ entity.last_added } />
                          </Cell>
                          <Cell align="right">
                            { hasPermission(session.user.permissions, API.permissions.support) && (
                              <React.Fragment>
                                <Button
                                  icon="email"
                                  component={ NavLink }
                                  title={t('button.send_support_email')}
                                  to={ `/support/create/${entity._id._id }`}
                                />
                              </React.Fragment>
                            )}
                            { hasPermission(session.user.permissions, API.permissions.shop_cart_management) && (
                              <React.Fragment>
                                <Button
                                  icon="info"
                                  title={t('button.information')}
                                  onClick={() => this.handleItems(entity._id)}
                                />
                              </React.Fragment>
                            )}
                          </Cell>
                        </Row>
                      )
                    })
                  )}
                  { (loading && entities.length === 0) && (
                    <Row>
                      <Cell colSpan={ thead.length } align="center">
                        {TEXT.message.without_results}
                      </Cell>
                    </Row>
                  )}
                </Table>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__ShopCartList));
