import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, query) {
  return request({
    url:    `/support/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params:  query
  });
};

function save(values) {
  return request({
    url:    '/support/save',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

function identifiers() {
  return request({
    url:    `/support/identifiers`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function listByClientId(id) {
  return request({
    url:    `/support/list/client/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function generateTokenReport(values) {
  return request({
    url:    '/support/report/token',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

const SupportService = {
  list, save, identifiers,
  listByClientId,
  generateTokenReport
};

export default SupportService;
