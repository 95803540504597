import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Typography } from './../../../../assets/theme/default';
import { Grid } from './../../../../assets/theme/layout';
import { Content, Toolbar, Button, ButtonsGroup, Loading, Modal, Date } from './../../../../components/ui';
import { InputDateField } from './../../../../components/ui/input';
import { NewsletterService } from './../../../../services';

import API from './../../../../config/api';

const validate = values => {
  const errors = {};
  return errors;
};

class SettingNewsletter extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      submited: false,
      entity: null,
      exportGeneratedShow: false
    };
  };

  /*
  ** @Service
  ** @Description: Export subscriptions
  */
  _Export = (values) => {

    // @State
    // @Description: Set loading show
    this.setState({ loading: false });

    // @Service
    // @Description:
    NewsletterService.generateTokenReport(values).then((response) => {

      // @State
      // @Description: Set response
      this.setState({
        loading: false,
        entity: response,
        exportGeneratedShow: true
      });

    }).catch((error) => {

      // @State
      // @Description: Set loading hide
      this.setState({ loading: false });

      // @Dispatch
      // @Description: Show alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: error.data.message
      });

    });

  };

  /*
  ** @Submit
  ** @Description: Submit form
  */
  handleSubmitForm = (values) => {
    this._Export(values);
  };

  /*
  ** @Event
  ** @Description: Close exported
  */
  handleExportGeneratedClose = () => {
    this.setState({ exportGeneratedShow: false });
  };

  render(){

    const { handleSubmit, invalid, t } = this.props;
    const { loading, submited, entity, exportGeneratedShow } = this.state;

    return(
      <React.Fragment>
        <Toolbar
          title={t('text.newsletter')}
          subtitle={t('text.sucription_export_form')}
          size="medium"
          variant="divider"
          disableMargin
        />

        <Modal
            status={ exportGeneratedShow }
            title={t('text.export_subscriptions')}
            handleCancel={ () => this.handleExportGeneratedClose() }
            maxWidth="sm">
            <Content disablePadding disableMargin>
              {entity && (
                <React.Fragment>
                  <Content disablePadding divider>
                    <Typography align="center" variant="body2">
                      <strong>{t('text.download_link_ready1')},</strong>
                      <br/>
                      {t('text.download_link_ready2')}
                      <br/>
                      ({t('text.download_link_ready3')} <Date value={ moment.unix(entity.exp).format() } format="DD/MM/YYYY hh:mm a"/>)
                    </Typography>
                  </Content>
                  <Content disablePadding>
                    <ButtonsGroup align="center">
                      <Button
                        color="primary"
                        size="medium"
                        component="a"
                        target="_blank"
                        href={ `${ API.endpoint.services }/newsletter/report/download/${ entity.token }` }
                      >
                        {t('button.download')}
                      </Button>
                      <CopyToClipboard
                        text={ `${ API.endpoint.services }/newsletter/report/download/${ entity.token }` }
                        onCopy={ this.handleCopyLink }
                      >
                        <Button color="primary" size="medium">
                          {t('button.copy')}
                        </Button>
                      </CopyToClipboard>
                      <Button
                        color="danger"
                        size="medium"
                        onClick={ this.handleExportGeneratedClose }
                      >
                        {t('button.cancel')}
                      </Button>
                    </ButtonsGroup>
                  </Content>
                </React.Fragment>
              )}
            </Content>
        </Modal>

        <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

          {/* Body */}
          <Content divider disableMargin>

            { (submited || loading) && (
              <Loading variant="absolute"/>
            )}

            <Content marginBottom>
              <Grid container spacing={3}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Field
                    name="date_from"
                    component={ InputDateField }
                    label={t('field.from')}
                    type="text"
                    required
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Field
                    name="date_to"
                    component={ InputDateField }
                    label={t('field.to')}
                    type="text"
                    disableFuture
                    required
                  />
                </Grid>
              </Grid>
            </Content>

          </Content>

          {/* Footer */}
          <Content>
            <ButtonsGroup align="right">
              <Button
                type="submit"
                size="medium"
                icon="add"
                color="success"
                disabled={ invalid || submited }
              >
                {t('button.process')}
              </Button>
            </ButtonsGroup>
          </Content>

        </form>

      </React.Fragment>
    )
  }

};

SettingNewsletter = reduxForm({
  form: 'SettingNewsletter',
  validate,
  initialValues: {
    date_from: moment().subtract(7, 'days').startOf('day').format(),
    date_to: moment().endOf('day').format()
  },
  enableReinitialize: true
})(SettingNewsletter);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SettingNewsletter));
