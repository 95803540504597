import React from 'react';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Grid } from './../../../../assets/theme/layout';
import { InputAdornment } from './../../../../assets/theme/input';
import { InputTextField, SelectField, OptionField } from './../../input';

const ScheduleFieldArray = ({ fields, meta: { error, submitFailed } }) => {
  const { t } = useTranslation();

  const timeSlots = Array.from(new Array(24 * 2)).map(
    (_, index) => `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${index % 2 === 0 ? '00' : '30'}`,
  );

  return(
    fields.length > 0 && (
      fields.map((entity, index) => (
        <Grid container spacing={1} key={index}>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
            <Field
                name={`${entity}.day`}
                component={ InputTextField }
                type="text"
                required
                readonly="readonly"
                startAdornment={
                  <InputAdornment position="start">{t('text.day')}:</InputAdornment>
                }
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
            <Field
              name={`${entity}.enabled`}
              component={ SelectField }
              required
              startAdornment={
                <InputAdornment position="start">{t('text.store_status')}:</InputAdornment>
              }
            >
              <OptionField value="true">{t('field.option_field.open')}</OptionField>
              <OptionField value="false">{t('field.option_field.closed')}</OptionField>
            </Field>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
            <Field
                name={`${entity}.open_at`}
                component={ SelectField }
                required
                startAdornment={
                  <InputAdornment position="start">{t('field.from')}:</InputAdornment>
                }
            >
              { timeSlots.map((slot, index) => (
                <option key={index}>{slot}</option>
              ))}
            </Field>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
            <Field
                name={`${entity}.close_at`}
                component={ SelectField }
                required
                startAdornment={
                  <InputAdornment position="start">{t('field.to')}:</InputAdornment>
                }
            >
              { timeSlots.map((slot, index) => (
                <option key={index}>{slot}</option>
              ))}
            </Field>
          </Grid>
        </Grid>
      ))
    )
  )
};

export default ScheduleFieldArray;
