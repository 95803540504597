import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import QRCode from 'qrcode.react';
import { Grid } from './../../../assets/theme/layout';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, ButtonsGroup } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { QrCodeService } from './../../../services';
import { findPermissionProps, buildSlug, isNumber } from './../../../utilities/utils';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 255){
    errors.name = TEXT.validation.maxlength(255);
  }

  if (!values.size) {
    errors.size = TEXT.validation.required;
  } else if (!isNumber(Number(values.size))) {
    errors.size = TEXT.validation.number;
  } else if(Number(values.size) > 4000){
    errors.size = TEXT.validation.maxlength(4000);
  } else if(Number(values.size) < 400){
    errors.size = TEXT.validation.minlength(400);
  }

  if (!values.link) {
    errors.link = TEXT.validation.required;
  }

  return errors;

}

class Page__QrCodeForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        title: '...',
        loading: true,
        submited: false,
        id: null,
        entity: null,
        qrCode: null
      };
      this.myRef = React.createRef();
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.qr_code, this.props.t('text.qr_code')) });

      const id = this.props.match.params.id;

      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false, title: this.props.t('text.new_entry') });
      }

    };

    /*
    ** @Service
    ** @Description: Save Store
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      QrCodeService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('messages.qr_code_created')
        });

        // @Redirect
        this.props.history.push('/qr-codes');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Featured
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      QrCodeService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('messages.qr_updated')
        });

        // @Redirect
        this.props.history.push('/qr-codes');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Featured
    */

    _Find = (id) => {

      // @Service
      QrCodeService.find(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set note update into state
        this.setState({
          loading: false,
          id: response.qrCode._id,
          entity: response.qrCode,
          title: response.qrCode.name
        });

        // @Form
        // @Description: Set form fields

        // @General
        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));
        this.props.dispatch(change(form, 'link', this.state.entity.link ));
        this.props.dispatch(change(form, 'size', this.state.entity.size ));

        this._QrCodeGenerator(this.state.entity.slug);

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description:
    */
    _QrCodeGenerator = (value) => {
      const slug = buildSlug(value);

      this.props.dispatch(change(this.props.form, 'slug', slug ));

      this.setState({ qrCode: `${ API.endpoint.app }/#/qr-code/${ slug }`}, () => {
        setTimeout(() => {
          const qrCanvas = document.getElementById('qr-canvas');
          if(qrCanvas){
            this.props.dispatch(change(this.props.form, 'code', qrCanvas.toDataURL() ));
          }
        }, 1000);
      });

    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };

    /*
    ** @Event
    ** @Description: OnChange Link
    */
    handleOnChangeName = (event) => {
      this._QrCodeGenerator(event.target.value);
    };

    // @Render
    render(){

      const { handleSubmit, invalid, size, t } = this.props;
      const { id, permission, name, loading, title, submited, qrCode } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/qr-codes"
                icon="back"
                size="medium"
              >
                {t('button.back')}
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              {/* Form Category */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Field
                  name="code"
                  component={InputTextField}
                  type="text"
                  required
                  fieldHidden
                />

                <Field
                  name="slug"
                  component={InputTextField}
                  type="text"
                  required
                  fieldHidden
                />

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* General */}
                  <Content marginBottom>
                    <Grid container spacing={3}>
                      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <Field
                          name="name"
                          component={ InputTextField }
                          label={t('field.name')}
                          type="text"
                          required
                          disabled={id ? true : false}
                          onChange={this.handleOnChangeName}
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <Field
                          name="size"
                          component={ InputTextField }
                          label={t('field.size')}
                          type="number"
                          required
                          disabled={true}
                          min={400}
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label={t('field.status')}
                          required
                        >
                          <OptionField value="true">{t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="link"
                          component={ InputTextField }
                          label={t('field.link')}
                          type="text"
                          required
                        />
                      </Grid>

                      { (qrCode && size) && (
                        <div style={{display:"none"}}>
                          <QRCode id="qr-canvas" size={Number(size)} renderAs="canvas" value={qrCode}/>
                        </div>
                      )}

                    </Grid>
                  </Content>
                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/qr-codes"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      {t('button.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      {t('button.save')}
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__QrCodeForm = reduxForm({
  form: 'Page__QrCodeForm',
  validate,
  initialValues: {
    enabled: true,
    size: 1000
  }
})(Page__QrCodeForm);

const selector = formValueSelector('Page__QrCodeForm');

Page__QrCodeForm = connect(state => {
  const name = selector(state, 'name');
  const link = selector(state, 'link');
  const size = selector(state, 'size');
  return { name, link, size }
})(Page__QrCodeForm)

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__QrCodeForm));
