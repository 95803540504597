import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, Modal, Table, Row, Cell, Tag, Date } from './../../../components/ui';
import { FormService } from './../../../services';
import { isUndefined, isStatus, isStatusColor, parseQueryString } from './../../../utilities/utils';
import UtilLeadFilter from './../filter';

import TEXT from './../../../config/text';

class Page__FormList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: false,
        page: 0,
        total: 0,
        id: null,
        entities: [],
        thead: [
          { id: 'name', name: this.props.t('field.name') },
          { id: 'enabled', name: this.props.t('field.status') },
          { id: 'created_at', name: this.props.t('field.date') },
          { id: 'actions', name: this.props.t('field.actions'), align: 'right' }
        ],
        formFilterShow: false
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      const page = this.props.match.params.page || 0;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          this._All(page);
        }else{
          this.props.history.push('/forms');
        }
      }else{
        this._All(this.state.page);
      }

    }

    /*
    ** @Service
    ** @Description: FindAll
    */

    _All = (page) => {

      FormService.list(page, parseQueryString(this.props.location.search)).then((response) => {

        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.forms,
          total: response.total
        });

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    }

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/forms/page/${ page }`,
        search: this.props.location.search
      });

    }

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ formFilterShow: true });
    }

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ formFilterShow: false });
    }

    render(){

      const { loading, entities, thead, total, page, formFilterShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar title={this.props.t('page.forms.name')} subtitle={this.props.t('text.forms_list')} dividerMobile>
              <ButtonsGroup>
                { this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title={this.props.t('button.remove_filters')}
                    placement="left"
                    component={ NavLink }
                    to="/forms"
                  />
                )}
                <Button
                  icon="filter"
                  size="medium"
                  onClick={ this.handleFilter }
                >
                  {this.props.t('button.filter')}
                </Button>
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

              <Modal
                  status={ formFilterShow }
                  title={this.props.t('text.filter_forms')}
                  handleCancel={ () => this.handleFilterClose() }
                  maxWidth="sm">
                  <UtilLeadFilter onSuccess={ this.handleFilterClose }/>
              </Modal>

              <Table
                thead={ thead }
                loading={ loading }
                total={ total }
                page={ page }
                onChangePage={ this.handleChangePage }
              >
                { entities.length > 0 && (

                  entities.map(entity => {
                    return (
                      <Row key={ entity._id }>
                        <Cell>
                          { entity.name }
                        </Cell>
                        <Cell>
                          <Tag
                            text={ isStatus(entity.enabled) }
                            color={ isStatusColor(entity.enabled) }
                          />
                        </Cell>
                        <Cell>
                          <Date value={ entity.created_at } />
                        </Cell>
                        <Cell align="right" nowrap>
                          <Button
                            icon="desktop"
                            title={this.props.t('text.check_form')}
                            component={ NavLink }
                            to={`/form/${ entity.slug }`}
                            target="_blank"
                          />
                          <Button
                            icon="records"
                            component={ NavLink }
                            title={this.props.t('text.records')}
                            to={ `/forms/records/${ entity._id }` }
                          />
                        </Cell>
                      </Row>
                    )
                  })

                )}

                { (loading && entities.length === 0) && (
                  <Row>
                    <Cell colSpan={ thead.length } align="center">
                      { TEXT.message.without_results }
                    </Cell>
                  </Row>
                )}

              </Table>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__FormList));
