import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles, Typography, Link } from './../../../../assets/theme/default';
import { OpenInNewIcon } from './../../../../assets/theme/icon';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: 1,
    whiteSpace: 'nowrap'
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main
  },
  icon: {
    fontSize: 14,
    marginLeft: 3
  }
}));

const LinkCategory = ({ category, ...rest }) => {

  const classes  = useStyles();

  return (
    category.children.length > 0 ? (

      <Link
        className={ classNames(classes.root, classes.link) }
        component={ NavLink }
        to={ `/categories/parent/${ category._id }` }
      >
        { category.name }
      </Link>

    ) : (

      category.type === 'link' ? (
        <Link href={ category.link } target="_blank" className={ classNames(classes.root, classes.link) }>
          { category.name } <OpenInNewIcon fontSize="small" classes={{ fontSizeSmall: classes.icon }}/>
        </Link>
      ) : (
        <Typography className={ classes.root }>
          { category.name }
        </Typography>
      )

    )
  )

};

LinkCategory.propTypes = {
  category: PropTypes.object.isRequired
};

export default LinkCategory;
