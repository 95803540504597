import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Page, Wrapper, Paper, Loading, Button, Toolbar, Tabs, Tab, TabPanel, Date } from './../../../../components/ui';
import { ShippingService } from './../../../../services';
import ShippingFormDetail from './../detail';
import ShippingFormSchedule from './../schedule';
import { findPermissionProps } from './../../../../utilities/utils';

import API from './../../../../config/api';
import TEXT from './../../../../config/text';

class Page__ShippingForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        title: '...',
        subtitle: null,
        loading: true,
        id: null,
        entity: null,
        section: 0,
        redirect: false
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is updated.
    */
    componentDidUpdate(prevProps, prevState){
      if(prevProps.match.params.id === undefined && this.props.match.params.id ){
        this._Init();
      }
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      const id = this.props.match.params.id;

      this.setState({ id: id, permission: findPermissionProps(this.props.session, API.permissions.shipping, "Envíos") });

      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false, title: 'Nuevo registro' });
      }

    };

    /*
    ** @Service
    ** @Description: Save Entity
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      ShippingService.orderSave(values).then((response) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: `El proceso se ejecutó correctamente.`
        });

        if(this.state.entity){

          this.props.history.push('/shipping');

        }else{

          window.scrollTo( 0, 0 );

          this.props.history.push(`/shipping/edit/${response.order._id}`);

        }

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Order
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      ShippingService.orderUpdate(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: `El proceso se ejecutó correctamente.`
        });

        window.scrollTo( 0, 0 );

        // @Reload data
        this._Find(id);

        // @Redirect
        //this.props.history.push('/shipping');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Entity
    */

    _Find = (id) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true });

      // @Service
      ShippingService.find(id).then((response) => {

        // @State
        // @Description: Set note update into state
        this.setState({
          loading: false,
          id: response.order._id,
          entity: response.order,
          title: 'Editar registro',
          subtitle: response.order.created_at
        });


      }).catch((error) => {

        // @State
        // @Description:
        this.setState({ loading: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Submit
    ** @Description: Submit form detail
    */
    onSubmitFormDetail = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };

    /*
    ** @Event
    ** @Description: Change section tab
    */
    handleChangeSection = (event: React.ChangeEvent<{}>, index: number) => {
      this.setState({ section: index });
    };

    // @Render
    render(){

      const { permission, name, loading, title, subtitle, section, entity } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/shipping"
                icon="back"
                size="medium"
              >
                Volver
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={title}
                size="medium"
                variant="divider"
                disableMargin
                >
                  {subtitle && (
                    <React.Fragment><span>Alta de reserva</span> <Date value={subtitle} /></React.Fragment>
                  )}
              </Toolbar>

              { loading && (
                <Loading variant="absolute"/>
              )}

              <Tabs divider value={section} onChange={this.handleChangeSection}>
                <Tab label="Detalle"/>
                <Tab label="Plan de embarque" disabled={(entity && entity._id) ? false : true}/>
              </Tabs>

              {/* Form Shipping Order */}
              <TabPanel value={section} index={0}>
                <ShippingFormDetail
                  entity={entity}
                  onSubmit={this.onSubmitFormDetail}
                />
              </TabPanel>

              {/* List Events of Shipping Order */}
              <TabPanel value={section} index={1}>
                { entity && (
                  <ShippingFormSchedule
                    entity={entity}
                    onSubmit={this.onSubmitFormDetail}
                  />
                )}
              </TabPanel>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Page__ShippingForm);
