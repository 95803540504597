import React from 'react';
import { makeStyles, Box } from './../../../assets/theme/default';
import { Grid } from './../../../assets/theme/layout';
import { Data, LinkClient } from './../../../components/ui';

const useStyles = makeStyles(theme => ({
  root: {
  //  marginBottom: theme.spacing(2)
  }
}));

const ClientData = ({ client, ...rest }) => {

  const classes = useStyles();

  // @Function
  // @Description: Validate if field exist
  const field = (value) => {
    if(!!client){
      return client[value] ? client[value] : '-';
    }else{
      return '-';
    }
  }

  return (
    <Box className={ classes.root }>
      <Grid container spacing={1}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Data
            label="Nombre/Apellido"
            text={ client ? <LinkClient client={ client }/> : '-' }
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Data
            label="Email"
            text={ field('email') }
            email
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Data
            label="Identificador"
            text={ field('identifire') }
          />
        </Grid>
      </Grid>
    </Box>
  )

};

ClientData.propTypes = {

};

export default ClientData;
