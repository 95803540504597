const ROUTER = {
  offer: {
    detail: {
      path: '/promociones/:slug',
      title: 'Promociones'
    }
  }
};

export default ROUTER;
