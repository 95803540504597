import request from './../../../utilities/request';
import localStorageToken from './../../../utilities/storage';

function paymentById(id) {
  return request({
    url:    `/todopago/payment/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function preferencesList(page, query) {
  return request({
    url:    `/todopago/preference/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params:  query
  });
}

function preferenceById(id) {
  return request({
    url:    `/todopago/preference/find/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function preferenceSave(values) {
  return request({
    url:    '/todopago/preference/save',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data: values
  });
}

function preferenceNotification(values) {
  return request({
    url:    '/todopago/preference/notification',
    method: 'POST',
    data: values
  });
}

const TodoPagoService = {
  paymentById,
  preferencesList,
  preferenceById,
  preferenceSave,
  preferenceNotification
}

export default TodoPagoService;
