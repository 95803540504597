import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function findLayouts() {
  return request({
    url:    '/email/layout/list',
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function findLayoutById(id) {
  return request({
    url:    `/email/layout/find/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function findTemplates() {
  return request({
    url:    '/email/template/list',
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function findTemplateById(id) {
  return request({
    url:    `/email/template/find/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

const EmailService = {
  findLayouts, findLayoutById, findTemplates, findTemplateById
};

export default EmailService;
