import request from './../../../utilities/request';

function findByCountryId(id) {
  return request({
    url:    `/state/find/country/${ id }`,
    method: 'GET'
  });
}

const StateService = {
  findByCountryId
}

export default StateService;
