import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Box, CircularProgress } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root:       {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  absolute: {
    zIndex: 12,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.common.white,
    height: '100%'
  },
  fixed:  {
    zIndex: 12,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.common.white,
    height: '100%'
  },
  default:    {
    padding: theme.spacing(1.5)
  }
}));

const Loading = ({ variant = 'default', ...rest }) => {

  const classes = useStyles();

  return (
    <Box className={ classNames( classes.root, classes[variant] ) }>
      <CircularProgress size={30}/>
    </Box>
  )

}

Loading.propTypes = {
  variant:  PropTypes.oneOf(['default', 'absolute', 'fixed'])
};

export default Loading;
