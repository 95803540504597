import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Tooltip } from './../../../../assets/theme/default';
import { InfoIcon } from './../../../../assets/theme/icon';

const useStyles = makeStyles(theme => ({
  root: {

  },
  smallSize: {
    fontSize: 14
  },
  mediumSize: {
    fontSize: 16
  },
  largeSize: {
    fontSize: 18
  }
}));

const Info = ({ title, size = "medium", placement = "top", ...rest }) => {

  const classes = useStyles();
  const _size   = classes[`${ size }Size`]

  return (
    <Tooltip title={ title } placement={ placement }>
      <InfoIcon
        className={classNames(
          classes.root,
          _size
        )}
      />
    </Tooltip>
  )

};

Info.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  placement: PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top'])
};

export default Info;
