import React from 'react';
import { withTranslation } from 'react-i18next';
import { Content, Alert, Table, Row, Cell, LinkClient } from './../../../components/ui';
import { ClientService } from './../../../services';

import TEXT from './../../../config/text';

class UtilClientList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      error: false,
      entities: [],
      thead: [
        { id: 'client', name: this.props.t('field.full_name') },
        { id: 'email', name: this.props.t('field.email') }
      ]
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  }

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {
    this._All(0);
  }

  /*
  ** @Service
  ** @Description: List
  */

  _All = (page) => {

    ClientService.list(page, { sortParam: 'created_at', sortValue: -1}).then((response) => {

      // @State
      // @Description: Set response
      this.setState({
        loading: true,
        entities: response.clients
      });

    }).catch((error) => {

      // @State
      // @Description: Set error
      this.setState({ error: true });

    });

  }

  render(){

    const { loading, error, entities, thead } = this.state;

    return(

      !error ? (
        <Table
          thead={ thead }
          loading={ loading }
        >

          { entities.length > 0 && (
            entities.map(entity => {
              return (
                <Row key={entity._id}>
                  <Cell>
                    <LinkClient client={entity}/>
                  </Cell>
                  <Cell>{entity.email}</Cell>
                </Row>
              )
            })
          )}

          { (loading && entities.length === 0) && (
            <Row>
              <Cell colSpan={ thead.length } align="center">
                { TEXT.message.without_results }
              </Cell>
            </Row>
          )}

        </Table>
      ) : (
        <Content>
          <Alert severity="info">
            <strong>{this.props.t('messages.attention')}:</strong> {this.props.t('messages.information_request_error')}
          </Alert>
        </Content>
      )

    )

  }

}

export default withTranslation()(UtilClientList);
