import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Paper, Table, Row, Cell, Date, LinkProduct, Currency } from './../../../components/ui';

import TEXT from './../../../config/text';

class ProductItemList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      entities: [],
      thead: [
        { id: 'sku', name: this.props.t('field.sku') },
        { id: 'name', name: this.props.t('field.name') },
        { id: 'quantity', name: this.props.t('field.quantity') },
        { id: 'price', name: this.props.t('field.price') },
        { id: 'total', name: this.props.t('field.total') },
        { id: 'created_at', name: this.props.t('field.date') }
      ]
    };
  };

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  };

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {
    this.setState({ entities: this.props.entities || [], loading: true });
  };

  render() {

    const { loading, thead, entities } = this.state;

    return(
      <Paper disablePadding>
        <Table thead={thead} loading={loading}>
          { (entities && entities.length > 0) && (
            entities.map((entity, index) => {
              return (
                <Row key={entity._id}>
                  <Cell nowrap>
                    {entity.product ? entity.product.sku : TEXT.message.not_available}
                  </Cell>
                  <Cell nowrap>
                    <LinkProduct product={entity}/>
                  </Cell>
                  <Cell>{entity.quantity}</Cell>
                  <Cell>
                    <Currency value={entity.unit_price}/>
                  </Cell>
                  <Cell>
                    <Currency value={entity.total}/>
                  </Cell>
                  <Cell>
                    <Date value={entity.created_at}/>
                  </Cell>
                </Row>
              )
            })
          )}

          {/* No results */}
          { (loading && (entities && entities.length === 0)) && (
            <Row>
              <Cell colSpan={ thead.length } align="center">
                {TEXT.message.without_results}
              </Cell>
            </Row>
          )}
        </Table>
      </Paper>
    )
  }

};

ProductItemList.propTypes = {
  items: PropTypes.array
};

export default withTranslation()(ProductItemList);
