import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Box, Typography } from './../../../assets/theme/default';
import { isPaymentStatus } from './../../../utilities/payment';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& > p': {
      textTransform: 'capitalize',
      fontSize: theme.typography.fontSize,
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  primary: {
    background: theme.palette.primary.ultraLight,
    '& > p': {
      color: theme.palette.primary.main
    }
  },
  success: {
    background: theme.palette.success.ultraLight,
    '& > p': {
      color: theme.palette.success.main
    }
  },
  danger: {
    background: theme.palette.danger.ultraLight,
    '& > p': {
      color: theme.palette.danger.main
    }
  },
  info: {
    background: theme.palette.info.ultraLight,
    '& > p': {
      color: theme.palette.info.main
    }
  },
  warning: {
    background: theme.palette.warning.ultraLight,
    '& > p': {
      color: theme.palette.warning.main
    }
  }
}));

const CheckoutStatus = ({ status, ...rest }) => {

  const classes = useStyles();

  return (
    <Box className={
      classNames( classes.root, classes[isPaymentStatus(status).color] )
    }>
      <Typography>
        { isPaymentStatus(status).name }
      </Typography>
    </Box>
  )

};

CheckoutStatus.propTypes = {
  status: PropTypes.string.isRequired
};

export default CheckoutStatus;
