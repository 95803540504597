import React from 'react';
import { makeStyles } from './../../../../assets/theme/default';
import { Tab } from './../../../../assets/theme/tab';

const Styles = makeStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: theme.spacing(12)
  }
}));

const Tab__c = ({ children, ...rest }) => {

  const classes = Styles();

  return (
    <Tab
      classes={{ root: classes.root }}
      disableRipple
      disableFocusRipple
      { ...rest }
    />
  )

};

export default Tab__c;
