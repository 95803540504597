import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { InputTextField } from './../../../components/ui/input';
import { Content, Button, ButtonsGroup, Loading, Alert } from './../../../components/ui';
import { ClientService } from './../../../services';

import TEXT from './../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.identifire) {
    errors.identifire = TEXT.validation.required;
  }else if(values.identifire.length < 7){
    errors.identifire = TEXT.validation.minlength(7);
  }else if(values.identifire.length > 8){
    errors.identifire = TEXT.validation.maxlength(8);
  }

  return errors;

}

class FormClientAutocomplete extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      error: false
    };
  }

  /*
  ** @Service
  ** @Description: Find client
  */

  _Find = (values) => {

    // @Loading Show
    this.setState({ loading: true });

    // @Service
    ClientService.findByPublicAccess(values).then((response) => {

      // @Props
      // @Description: Send props to parent
      this.props.onSuccess(response.client);

    }).catch((error) => {

      // @Loading Hide
      this.setState({
        loading: false,
        error: true,
        message:  error.data.message ? error.data.message : TEXT.message.error
      });

    });

  }

  /*
  ** @Submit
  ** @Description: Submit autocomplete form
  */

  handleSubmitForm = (values) => {
    this._Find(values);
  }

  /*
  ** @Render
  */

  render() {

    const { handleSubmit, invalid } = this.props;
    const { loading, error, message } = this.state;

    return(
      <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>
        <Content disablePadding divider>
          { loading && (
            <Loading variant="absolute"/>
          )}
          <Grid container spacing={3}>
            { error && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Alert severity="warning">
                  <strong>Atención:</strong> {message}
                </Alert>
              </Grid>
            )}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Field
                  name="identifire"
                  component={ InputTextField }
                  label="Documento"
                  type="text"
                  required
                  onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ''); this.setState({error:false}); }}
              />
            </Grid>
          </Grid>
        </Content>
        <Content disablePadding>
          <ButtonsGroup align="right">
            <Button
              type="submit"
              aria-label="Aplicar"
              icon="save"
              color="primary"
              disabled={ invalid || loading }
            >
              Aplicar
            </Button>
          </ButtonsGroup>
        </Content>
      </form>
    )
  }

}

FormClientAutocomplete = reduxForm({
  form: 'FormClientAutocomplete',
  validate,
  initialValues: {}
})(FormClientAutocomplete);

export default FormClientAutocomplete;
