import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Link } from './../../../assets/theme/default';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, AlertConfirm, Table, Row, Cell, Tag, Modal } from './../../../components/ui';
import { TokenPreviewGenerator } from './../../../scenes';
import { OfferService } from './../../../services';
import { isUndefined, isStatus, isBinary, isStatusColor, hasPermission, findPermissionProps, parseQueryString, truncate, replace, getRoute, getAbsoluteRoute } from './../../../utilities/utils';
import PageStoreFilter from './../filter';

import API from './../../../config/api';
import ROUTER from './../../../config/router';
import TEXT from './../../../config/text';

class Page__OfferList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        loading: false,
        page: 0,
        total: 0,
        id: null,
        entities: [],
        remove: false,
        thead: [
          { id: 'name', name: this.props.t('field.name') },
          { id: 'source', name: this.props.t('field.campaign') },
          { id: 'views', name: this.props.t('field.views') },
          { id: 'used_limit', name: this.props.t('field.records') },
          { id: 'enabled', name: this.props.t('field.status') },
          { id: 'published', name: this.props.t('field.published') },
          { id: 'actions', name: this.props.t('field.actions'), align: 'right' }
        ],
        tokenPreviewGeneratorIdentifire: null,
        tokenPreviewGeneratorLink: null,
        tokenPreviewGeneratorShow: false,
        storeFilterShow: false
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.match.params.id !== prev_props.match.params.id){
        this._All(this.props.match.params.page || 0, this.props.match.params.id);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.offer, this.props.t('text.offers')) });

      const page = this.props.match.params.page || 0;
      const id   = this.props.match.params.id;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          if(id){
            this._All(page, id);
          }else{
            this._All(page);
          }
        }else{
          this.props.history.push('/offer');
        }
      }else{
        this._All(this.state.page);
      }

    };

    /*
    ** @Service
    ** @Description: All offers
    */

    _All = (page, id) => {

      // @State
      // @Description: Refresh after each service
      this.setState({ loading: false, entities: [] });

      // @Service
      OfferService.list(page, parseQueryString(this.props.location.search)).then((response) => {

        // @State
        // @Descripcion: Set values
        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.offers || [],
          total: response.total
        });

      }).catch((error) => {

        // @State
        // @Descripcion: Loading disabled
        this.setState({ loading: true });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Remove Entity
    */

    _Remove = (id) => {

      OfferService.remove(id).then((response) => {

        // @State
        this.setState({ remove: false });

        // @Service find all by page
        this._Init();

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

      }).catch((error) => {

        // @State
        this.setState({ remove: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description: Remove category
    */

    handleRemove = (id) => {
      this.setState({ id: id, remove: true });
    };

    /*
    ** @Click
    ** @Description: Remove category confirm
    */

    handleConfirmRemoveConfirm = () => {
      this._Remove(this.state.id);
    };

    /*
    ** @Click
    ** @Description: Remove category cancel
    */

    handleConfirmRemoveCancel = () => {
      this.setState({ remove: false });
    };

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/offer/page/${ page }`,
        search: this.props.location.search
      });

    };

    /*
    ** @Handle
    ** @Description: Generate link preview
    */
    handleLinkPreview = (slug) => {
      return getAbsoluteRoute(getRoute(replace(ROUTER.offer.detail.path, ':slug', slug )));
    };

    /*
    ** @Handle
    ** @Description: Generate token preview
    */
    handleTokenPreviewGeneratorShow = (slug) => {
      this.setState({
        tokenPreviewGeneratorShow: true,
        tokenPreviewGeneratorIdentifire: slug,
        tokenPreviewGeneratorLink: this.handleLinkPreview(slug)
      });
    };

    /*
    ** @Event
    ** @Description: Close token preview
    */
    handleTokenPreviewGeneratorClose = () => {
      this.setState({ tokenPreviewGeneratorShow: false });
    };

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ storeFilterShow: true });
    };

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ storeFilterShow: false });
    };

    render(){

      const { session, t } = this.props;
      const { permission, name, loading, entities, thead, total, page, remove, tokenPreviewGeneratorIdentifire, tokenPreviewGeneratorLink, tokenPreviewGeneratorShow, storeFilterShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <ButtonsGroup>

                {this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title="Eliminar filtros"
                    placement="left"
                    component={NavLink}
                    to="/offer"
                  />
                )}

                <Button
                  icon="filter"
                  size="medium"
                  onClick={this.handleFilter}
                >
                  Filtrar
                </Button>

                { hasPermission(session.user.permissions, API.permissions.offer_management) && (
                  <Button
                    component={ NavLink }
                    to="/offer/create"
                    icon="add"
                    size="medium"
                  >
                    {t('button.create')}
                  </Button>
                )}
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

                {/* Alert confirm Delete */}
                <AlertConfirm
                   status={ remove }
                   message={t('messages.delete_confirm')}
                   cancel={() => this.handleConfirmRemoveCancel()}
                   confirm={() => this.handleConfirmRemoveConfirm()}
                />

                {/* Token Preview Generator */}
                <Modal
                  status={tokenPreviewGeneratorShow}
                  title={t('messages.preview_link')}
                  handleCancel={() => this.handleTokenPreviewGeneratorClose()}
                  maxWidth="sm"
                >
                  <TokenPreviewGenerator
                    identifire={tokenPreviewGeneratorIdentifire}
                    link={tokenPreviewGeneratorLink}
                  />
                </Modal>

                {/* Filter */}
                <Modal
                  status={storeFilterShow}
                  title="Filtrar"
                  handleCancel={() => this.handleFilterClose()}
                  maxWidth="sm">
                  <PageStoreFilter onSuccess={this.handleFilterClose} />
                </Modal>

                <Table
                  thead={ thead }
                  loading={ loading }
                  total={ total }
                  page={ page }
                  onChangePage={ this.handleChangePage }
                >
                  { entities.length > 0 && (

                    entities.map(entity => {
                      return (
                        <Row key={ entity._id }>
                          <Cell nowrap>
                            { truncate(entity.name, 40) }
                          </Cell>
                          <Cell>
                            <Tag
                              text={ String(entity.source) }
                              color={ isStatusColor(true) }
                            />
                          </Cell>
                          <Cell>
                            <Tag
                              text={ String(entity.views) }
                              color={ isStatusColor(true) }
                            />
                          </Cell>
                          <Cell>
                            <Tag
                              text={ String(entity.used_limit) }
                              color={ isStatusColor(true) }
                            />
                          </Cell>
                          <Cell>
                            <Tag
                              text={ isStatus(entity.enabled) }
                              color={ isStatusColor(entity.enabled) }
                            />
                          </Cell>
                          <Cell>
                            <Tag
                              text={ isBinary(entity.published) }
                              color={ isStatusColor(entity.published) }
                            />
                          </Cell>
                          <Cell align="right" nowrap>
                            { hasPermission(session.user.permissions, API.permissions.store_management) && (
                              <React.Fragment>
                                {entity.enabled ? (
                                  <Button
                                    icon="desktop"
                                    title={t('button.online_preview')}
                                    component={ Link }
                                    href={this.handleLinkPreview(entity.slug)}
                                    target="_blank"
                                  />
                                ) : (
                                  <Button
                                    icon="desktop"
                                    title={t('button.online_preview')}
                                    onClick={() => this.handleTokenPreviewGeneratorShow(entity.slug)}
                                  />
                                )}
                                <Button
                                  color="primary"
                                  icon="lead"
                                  component={ NavLink }
                                  title={t('button.leads')}
                                  to={ `/leads?source=${ entity.source }` }
                                />
                                <Button
                                  color="primary"
                                  icon="edit"
                                  component={ NavLink }
                                  title={t('button.edit')}
                                  to={ `/offer/edit/${ entity._id }` }
                                />
                                <Button
                                  color="danger"
                                  icon="cancel"
                                  title={t('button.delete')}
                                  onClick={ () => this.handleRemove(entity._id)  }
                                />
                              </React.Fragment>
                            )}
                          </Cell>
                        </Row>
                      )
                    })

                  )}
                  { (loading && entities.length === 0) && (
                    <Row>
                      <Cell colSpan={ thead.length } align="center">
                        { TEXT.message.without_results }
                      </Cell>
                    </Row>
                  )}
                </Table>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__OfferList));
