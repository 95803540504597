import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, query) {
  return request({
    url:    `/qr-code/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params:  query
  });
};

function find(id) {
  return request({
    url:    `/qr-code/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function save(values) {
  return request({
    url:    '/qr-code/save',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

function update(id, values) {
  return request({
    url:    `/qr-code/update/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'PUT',
    data:   values
  });
};

function remove(id) {
  return request({
    url:    `/qr-code/remove/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'DELETE'
  });
};

function findByPublicAccess(id) {
  return request({
    url:    `/p/qr-code/${ id }`,
    method: 'GET'
  });
};

const QrCodeService = {
  list, find, save, update, remove, findByPublicAccess
};

export default QrCodeService;
