import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, AlertConfirm, Table, Row, Cell, Date } from './../../../components/ui';
import { CampaignsService } from '../../../services';
import { isUndefined, hasPermission, findPermissionProps, parseQueryString } from './../../../utilities/utils';

import API from './../../../config/api';
import TEXT from './../../../config/text';

class Page__CampaignsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      permission: null,
      name: "...",
      loading: false,
      page: 0,
      total: 0,
      id: null,
      entities: [],
      remove: false,
      thead: [
        { id: 'name', name: 'Nombre' },
        { id: 'created_at', name: 'Fecha' },
        { id: 'actions', name: 'Acciones', align: 'right' }
      ]
    };
  };

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount() {
    this._Init();
  };

  /**
  * @Event
  * @Description: Is invoked immediately after a component will recive props.
  */
  componentDidUpdate(prev_props) {

    if (this.props.match.params.page !== prev_props.match.params.page) {
      this._All(this.props.match.params.page || 0);
    }

    if (this.props.match.params.id !== prev_props.match.params.id) {
      this._All(this.props.match.params.page || 0, this.props.match.params.id);
    }

    if (this.props.location.search !== prev_props.location.search) {
      this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
    }

  };

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {

    this.setState({ permission: findPermissionProps(this.props.session, API.permissions.god, "Campañas") });

    const page = this.props.match.params.page || 0;
    const id = this.props.match.params.id;

    if (!isUndefined(page)) {
      if (!isNaN(page) && page >= 0) {
        if (id) {
          this._All(page, id);
        } else {
          this._All(page);
        }
      } else {
        this.props.history.push('/campaigns');
      }
    } else {
      this._All(this.state.page);
    }

  };

  /*
  ** @Service
  ** @Description: All categories
  */

  _All = (page, id) => {

    // @State
    // @Description: Refresh after each service
    this.setState({ loading: false, entities: [] });

    // @Service
    CampaignsService.list(page, parseQueryString(this.props.location.search)).then((response) => {

      // @State
      // @Descripcion: Set values
      this.setState({
        loading: true,
        page: !isUndefined(page) ? parseInt(page, 10) : 0,
        entities: response.campaign,
        total: response.total
      });

    }).catch((error) => {

      // @State
      // @Descripcion: Loading disabled
      this.setState({ loading: true });

      // @Dispatch alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: error.data ? error.data.message : TEXT.message.error
      });

    });

  };

  /*
  ** @Service
  ** @Description: Remove Store
  */

  _Remove = (id) => {

    CampaignsService.remove(id).then((response) => {

      // @State
      this.setState({ remove: false });

      // @Service find all by page
      this._Init();

      // @Dispatch alert success
      this.props.alertShow({
        open: true,
        severity: 'success',
        message: `La campaña fue eliminada.`
      });

    }).catch((error) => {

      // @State
      this.setState({ remove: false });

      // @Dispatch alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: error.data ? error.data.message : `La campaña no pudo ser eliminada.`
      });

    });

  };

  /*
  ** @Event
  ** @Description: Remove category
  */

  handleRemove = (id) => {
    this.setState({ id: id, remove: true });
  };

  /*
  ** @Click
  ** @Description: Remove category confirm
  */

  handleConfirmRemoveConfirm = () => {
    this._Remove(this.state.id);
  };

  /*
  ** @Click
  ** @Description: Remove category cancel
  */

  handleConfirmRemoveCancel = () => {
    this.setState({ remove: false });
  };

  /*
  ** @Handle
  ** @Description: Change page
  */

  handleChangePage = (event, page) => {

    // @State
    this.setState({ page: page, loading: false });

    // @History
    this.props.history.push({
      pathname: `/campaigns/page/${page}`,
      search: this.props.location.search
    });

  };

  handlePreview = (html) => {
    const blob = new Blob([html], { type: 'text/html' });
    const fileUrl = URL.createObjectURL(blob);
    console.log(fileUrl);
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
  };

  render() {

    const { session } = this.props;
    const { permission, name, loading, entities, thead, total, page, remove } = this.state;

    return (
      <Page>

        <Wrapper>

          <Toolbar
            title={permission ? permission.name : name}
            dividerMobile
          >
            <ButtonsGroup>
              {hasPermission(session.user.permissions, API.permissions.god) && (
                <Button
                  component={NavLink}
                  to="/campaigns/create"
                  icon="add"
                  size="medium"
                >
                  Crear
                </Button>
              )}
            </ButtonsGroup>
          </Toolbar>

          <Paper disablePadding>

            {/* Alert confirm Delete */}
            <AlertConfirm
              status={remove}
              message="¿Confirma la eliminación de la campaña?"
              cancel={() => this.handleConfirmRemoveCancel()}
              confirm={() => this.handleConfirmRemoveConfirm()}
            />

            <Table
              thead={thead}
              loading={loading}
              total={total}
              page={page}
              onChangePage={this.handleChangePage}
            >

              {entities.length > 0 && (

                entities.map(entity => {
                  return (
                    <Row key={entity._id}>
                      <Cell nowrap>
                        {entity.name}
                      </Cell>
                      <Cell>
                        <Date value={entity.created_at} />
                      </Cell>

                      <Cell align="right" nowrap>
                        {hasPermission(session.user.permissions, API.permissions.god) && (
                          <React.Fragment>
                            <Button 
                            color="primary" 
                            icon="desktop" 
                            onClick={() => this.handlePreview(entity.html)} 
                            title="Previsualizar" 
                            />
                            <Button
                              color="primary"
                              icon="edit"
                              component={NavLink}
                              title="Editar"
                              to={`/campaigns/edit/${entity._id}`}
                            />
                            <Button
                              color="danger"
                              icon="cancel"
                              title="Eliminar"
                              onClick={() => this.handleRemove(entity._id)}
                            />
                          </React.Fragment>
                        )}
                      </Cell>
                    </Row>
                  )
                })

              )}

              {(loading && entities.length === 0) && (
                <Row>
                  <Cell colSpan={thead.length} align="center">
                    No pudimos encontrar resultados.
                  </Cell>
                </Row>
              )}

            </Table>

          </Paper>

        </Wrapper>

      </Page>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page__CampaignsList);
