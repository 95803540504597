import React from 'react';
import { parseQueryString } from './../../../../../utilities/utils';
import { MercadoPagoService } from './../../../../../services';
import { CheckoutNotification } from './../../../../../scenes';

class Page__MP_PreferenceNotification extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      payment: null
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  }

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {

    const id     = this.props.match.params.id;
    const status = this.props.match.params.status;
    const query  = parseQueryString(this.props.history.location.search);

    // @If
    // @Description: I need id, status and answer key to send a notification
    if(id && status && query['collection_id']){

      // @Service
      // @Description: Check notification status
      this._Notification(id, query['collection_id']);

    }

  }

  /*
  ** @Service
  ** @Description: Notification service
  */

  _Notification = (id, payment_id) => {

    MercadoPagoService.preferenceStatusById(id, { payment_id: payment_id }).then((response) => {

      this.setState({
        loading: true,
        payment: response
      });

    }).catch((error) => {

      this.setState({
        loading: true,
        payment: null
      });

    });

  }

  render(){

    const { loading, payment } = this.state;

    return(
      <CheckoutNotification
        loading={ loading }
        payment={ payment }
      />
    )

  }

}

export default Page__MP_PreferenceNotification;
