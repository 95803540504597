import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from './../../../assets/theme/default';
import { InputLabel } from './../../../assets/theme/input';

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 500,
    position: 'relative',
    transition: 'none',
    transform: 'none',
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.fontSize
  }
}));

const Label = ({ children, ...rest }) => {

  const classes = useStyles();

  return (
    <InputLabel
      shrink
      classes={{ root: classes.root }}
    >
      { children }
    </InputLabel>
  )

};

Label.propTypes = {
  children: PropTypes.node.isRequired
};

export default Label;
