import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Box, Typography } from './../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 8,
    listStyle: 'none',
    background: '#333333',
    color: '#ffffff',
    borderRadius: 4
  }
}));

const Console = ({ logs, ...rest }) => {

  const classes = useStyles();

  return (
    <React.Fragment>
    { (logs && logs.length > 0) && (
      <Box className={ classNames(classes.root)} component="ul">
        { logs.map((log, index) => {
          return(
            <Typography key={ index } component="li">
              { log }
            </Typography>
          )
        })}
      </Box>
    )}
    </React.Fragment>
  )

};

Console.propTypes = {
  logs: PropTypes.array.isRequired
};

export default Console;
