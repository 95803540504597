import React from 'react';
import { Loading, Alert } from './../../../components/ui';
import { List, ListItem, ListItemText } from './../../../assets/theme/list';
import { CategoryService } from './../../../services';

import TEXT from './../../../config/text';

class CategoryList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      error: false,
      entities: []
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Find();
  }

  /*
  ** @Service
  ** @Description: Find categories by ids
  */

  _Find = () => {

    console.log(this.props.ids);
    CategoryService.listByIds({ ids: JSON.stringify(this.props.ids) }).then((response) => {

      // @Store
      // @Description: Set state
      this.setState({ loading: true, entities: response.categories });

    }).catch((error) => {

      // @Store
      // @Description: Set state
      this.setState({ loading: true, error: true });

    });

  }

  render() {

    const { loading, error, entities } = this.state;

    return(
      <React.Fragment>

        { loading ? (

          !error ? (

            entities.length > 0 ? (

              <List
                component="ul"
                dense
                disablePadding
              >
                { entities.map(entity => {
                  return(
                    <ListItem key={ entity._id } divider disableGutters>
                      <ListItemText primary={ entity.name } />
                    </ListItem>
                  )
                })}
              </List>

            ) : (

              <Alert severity="info">
                { TEXT.message.without_results }
              </Alert>

            )

          ) : (

            <Alert severity="error">
              { TEXT.message.error }
            </Alert>

          )

        ) : (
          <Loading/>
        )}

      </React.Fragment>
    )
  }

}

export default CategoryList;
