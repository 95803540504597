import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field, FieldArray, change } from 'redux-form';

import { Grid } from '../../../../assets/theme/layout';
import { Content, Toolbar, Button, ButtonsGroup, Alert, ScheduleFieldArray, InstallmentsFieldArray } from '../../../../components/ui';
import { SiteService } from '../../../../services';

import { InputTextField, SelectField, OptionField } from '../../../../components/ui/input';

import API from '../../../../config/api';
import TEXT from '../../../../config/text';

// const validate = values => {

//   const errors = {};

//   return errors;

// }

class SettingGeneral extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      submited: false,
      error: false,
      success: false,
      message: null,
      entity: null,
    };
  }

  /**
* @Event
* @Description: Is invoked immediately after a component is mounted.
*/
  componentDidMount() {
    this._Init();
  };

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {

    this._Find();

  }

    /*
  ** @Service
  ** @Description: Find Store
  */

  _Find = () => {

    SiteService.find(API.client.id).then((response) => {

      const form = this.props.form;

      this.setState({
        loading: false,
        entity: response.settings || {}
      });

      if (this.state.entity.whatsapp) {
        this.props.dispatch(change(form, 'whatsapp.number', this.state.entity.whatsapp.number ));
        this.props.dispatch(change(form, 'whatsapp.enabled', this.state.entity.whatsapp.enabled ));
        this.props.dispatch(change(form, 'whatsapp.schedule', this.state.entity.whatsapp.schedule || API.whatsapp.schedule ));

      }
      if(this.state.entity.payments?.interest_rates){
        this.props.dispatch(change(form, 'payments.interest_rates.enabled', this.state.entity.payments.interest_rates.enabled ));
        this.props.dispatch(change(form, 'payments.interest_rates.installments', this.state.entity.payments.interest_rates.installments ));
      }

    }).catch((error) => {

    });

  };

  /*
  ** @Service
  ** @Description: Update Settings
  */

  _Update = (id, values) => {

    // @State
    // @Description: Loading show
    this.setState({ loading: true, submited: true });

    // @Service
    SiteService.update(id, values).then((response) => {

      // @Dispatch alert success
      this.props.alertShow({
        open: true,
        severity: 'success',
        message: TEXT.message.success
      });

      // @State
      // @Description: Loading hide
      this.setState({ loading: false, submited: false });

      // @Redirect
     // this.props.history.push('/offer');

    }).catch((error) => {

      // @State
      // @Description: Loading hide
      this.setState({ loading: false, submited: false });

      // @Dispatch alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: error.data ? error.data : TEXT.message.error
      });

    });

  };

  /*
  ** @Submit
  ** @Description: Submit
  */
  handleSubmitForm = (values) => {
    this._Update(API.client.id, values);
  }

  /*
  ** @Event
  ** @Description: Close alert
  */

  handleCloseAlert = (files) => {
    this.setState({
      success: false,
      error: false,
      message: null
    });
  }

  render() {
    const { t, handleSubmit, invalid } = this.props;
    const { submited, success, error, message, entity } = this.state;
    return (
      <React.Fragment>

        <Toolbar
          title={t('text.whatsapp')}
          subtitle={t('text.whatsapp_settings_form')}
          size="medium"
          variant="divider"
          disableMargin
        />

        {(success || error) && (
          <Content disableMargin style={{ paddingBottom: 0 }}>
            <Alert severity={success ? "success" : "error"} onClose={this.handleCloseAlert}>
              {message}
            </Alert>
          </Content>
        )}

        <form noValidate autoComplete="off" onSubmit={handleSubmit(this.handleSubmitForm)}>
          <Content divider disableMargin>

            <Grid container spacing={3}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Field
                  name="whatsapp.number"
                  component={InputTextField}
                  label={t('field.number')}
                  type="text"
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Field
                  name="whatsapp.enabled"
                  component={SelectField}
                  label={t('field.status')}
                >
                  <OptionField value="true">{t('field.option_field.active')}</OptionField>
                  <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
                </Field>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <FieldArray
                  name="whatsapp.schedule"
                  component={ScheduleFieldArray}
                />
              </Grid>
            </Grid>

          </Content>


{(entity && entity.payments?.interest_rates?.enabled) && (
  <Content>
        <Toolbar
          title={t('text.interest_rates')}
          subtitle={t('text.interest_rates_settings_form')}
          size="medium"
          variant="divider"
          disableMargin
        />
            <Content divider disableMargin>

            <Grid container spacing={3}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <FieldArray
                  name="payments.interest_rates.installments"
                  component={InstallmentsFieldArray}
                />
              </Grid>
            </Grid>

            </Content>
            </Content>
          )}

            <Content>
              <ButtonsGroup align="right">
                <Button
                  type="submit"
                  size="medium"
                  icon="add"
                  color="success"
                  disabled={invalid || submited}
                >
                  {t('button.save')}
                </Button>
              </ButtonsGroup>
            </Content>
          </form>
            


      </React.Fragment>
    )
  }

};

SettingGeneral = reduxForm({
  form: 'SettingGeneral',
  initialValues: {
    whatsapp: {
      enabled: true,
      schedule: []
    },
    payments: {
      interest_rates: {
        enabled: false,
        installments: []
      }
    }
  }
})(SettingGeneral);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SettingGeneral));
