import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { makeStyles, Typography } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: 1,
    whiteSpace: 'nowrap'
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const LinkUser = ({ user, ...rest }) => {

  const classes  = useStyles();

  return (
    user ? (
      <Typography className={ classNames(classes.root, classes.link) } component={ NavLink } to={ `/users/profile/${ user._id }` }>
        { user.name } { user.surname }
      </Typography>
    ) : (
      <Typography className={ classes.root }>
        [No disponible]
      </Typography>
    )
  )

};

LinkUser.propTypes = {
  user: PropTypes.object
};

export default LinkUser;
