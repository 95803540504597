import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, Box, Hidden } from './../../../../assets/theme/default';
import { AppBar, Toolbar } from './../../../../assets/theme/layout';
import { Button, Image } from './../../../ui';
import { Logo } from './../../../../assets/images';
import Menu from './../menu';

const styles = theme => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    zIndex: 2
  },
  header: {
    boxShadow: theme.shadows[0],
    borderBottom: `1px solid ${ theme.palette.border.main }`
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  left: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
    menuButton: {
      marginRight: theme.spacing(1)
    },
    brand: {
      width: 120
    },
  right: {
    display: 'flex',
    alignItems: 'center'
  },
    username: {
      marginRight: theme.spacing(1),
      textDecoration: 'none',
      color: theme.palette.dark.main
    }
});

class Header extends React.Component {


  /*
  ** @Event
  ** @Description: Toggle Sidebar
  */

  handleNavigationToggle = () => {
    this.props.navigationToggle({ open: true });
  };

  /*
  ** @Event
  ** @Description: SignOut
  */
  handleSignOut = () => {

    // @localStorage
    localStorage.clear();

    // @Dispatch
    this.props.signout();

  }

  render(){

    const { classes } = this.props;

    return (
      <Box className={ classes.root }>
        <AppBar color="inherit" position="relative" classes={{ root: classes.header }}>
          <Toolbar
            component="div"
            disableGutters
          >
            <Box className={ classes.container }>

              <Box className={ classes.left }>
                <Hidden mdUp>
                  <Box className={ classes.menuButton }>
                    <Button icon="menu" size="medium" onClick={ this.handleNavigationToggle }/>
                  </Box>
                </Hidden>

                <Image src={ Logo } alt="Firehub" className={ classes.brand }/>
              </Box>

              <Box className={ classes.right }>
                <Menu/>
              </Box>

            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    signout: () => {
      dispatch({
        type: 'USER_CLEAR'
      });
    },
    navigationToggle: (data) => {
      dispatch({
        type: 'NAVIGATION_TOGGLE',
        data: data
      });
    }
  }
}

Header.propTypes = {
  variant: PropTypes.oneOf(['regular', 'dense'])
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));
