import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field, change, FieldArray, formValueSelector } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Typography } from './../../../assets/theme/default';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, ButtonsGroup, Upload } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { UserService } from './../../../services';
import { PermissionAutocomplete } from './../../../scenes'
import { filterIds, isPasswordValid, isEmail } from './../../../utilities/utils';

import TEXT from './../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 2048){
    errors.name = TEXT.validation.maxlength(2048);
  }

  if (!values.surname) {
    errors.surname = TEXT.validation.required;
  }else if(values.surname.length > 2048){
    errors.surname = TEXT.validation.maxlength(2048);
  }

  if (!values.email) {
    errors.email = TEXT.validation.required;
  } else if (isEmail(values.email)) {
    errors.email = TEXT.validation.email_invalid;
  } else if (values.email.length > 255){
    errors.email = TEXT.validation.maxlength(255);
  }

  if (!values.commission) {
    errors.commission = TEXT.validation.required;
  }else if(parseInt(values.commission,10) < 0){
    errors.commission = TEXT.validation.minlength(0);
  }else if(parseInt(values.commission,10) > 100){
    errors.commission = TEXT.validation.maxlength(100);
  }

  if(values.id === 'none'){
    if (!values.password) {
      errors.password = TEXT.validation.required;
    } else if( !isPasswordValid(values.password) ){
      errors.password = TEXT.validation.wrong_password;
    }

    if (!values.repeat_password) {
      errors.repeat_password = TEXT.validation.required;
    } else if (values.repeat_password !== values.password) {
      errors.repeat_password = TEXT.validation.mismatched_password;
    }
  }

  return errors;

};

const renderItemsForIds = ({ fields, meta: { error, submitFailed } }) => (
  fields.length > 0 && (
    fields.map((entity, index) => (
      <Field
          key={ index }
          name={ entity }
          component={ InputTextField }
          type="text"
          required
          fieldHidden
      />
    ))
  )
);

class Page__UserForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        title: '...',
        loading: true,
        submited: false,
        id: null,
        entity: null,
        permissions: []
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      const id = this.props.match.params.id;

      if(id){

        // @Service
        this._Find(id);

      }else{

        // @State
        this.setState({ loading: false, title: this.props.t('text.new_user') });

      }

    }

    /*
    ** @Service
    ** @Description: Save User
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      UserService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('messages.user_created_success')
        });

        // @Redirect
        this.props.history.push('/users');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    }

    /*
    ** @Service
    ** @Description: Update User
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      UserService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('messages.user_updated')
        });

        // @Redirect
        this.props.history.push('/users');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: TEXT.message.error
        });

      });

    }

    /*
    ** @Service
    ** @Description: Save Category
    */

    _Find = (id) => {

      // @Service
      UserService.find(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set user update into state
        this.setState({
          loading: false,
          id: response.user._id,
          entity: response.user,
          title: this.props.t('text.edit_user')
        });

        // @Form
        // @Description: Set form fields
        this.props.dispatch(change(form, 'id', this.state.entity._id ));
        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'surname', this.state.entity.surname ));
        this.props.dispatch(change(form, 'email', this.state.entity.email ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));
        this.props.dispatch(change(form, 'profile', this.state.entity.profile ));
        this.props.dispatch(change(form, 'language', this.state.entity.language ));
        this.props.dispatch(change(form, 'commission', this.state.entity.commission.toString() ));
        this.props.dispatch(change(form, 'permissions', filterIds(this.state.entity.permissions) ));

        if (this.state.entity.image) {
          this.props.dispatch(change(form, 'image', JSON.stringify(this.state.entity.image) ));
        }

        this.setState({ permissions: this.state.entity.permissions });

      }).catch((error) => {

        // @Dispatch
        // @Description: Alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

        // @Redirect
        this.props.history.push('/users');

      });

    }

    /*
    ** @Event
    ** @Description: On select permissions
    */
    handleOnSelectPermission = (permissions) => {

      this.props.dispatch(change(this.props.form, 'permissions', permissions ? filterIds(permissions) : [] ));

      this.setState({ permissions: permissions });

    }

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    }

    /*
    ** @Event
    ** @Description: OnChange name
    */
    handleChangeName = (event) => {
      this.setState({ title: event.target.value !== '' ? event.target.value : '...' });
    }

    /*
    ** @Event
    ** @Description: OnUpload file
    */

    onUploadImagen = (files) => {
      this.props.dispatch(change(this.props.form, 'image', files.length > 0 ? JSON.stringify(files[0]) : null ));
    };

    render(){

      const { handleSubmit, invalid, image, t } = this.props;
      const { loading, id, title, submited, permissions } = this.state;


      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={t('text.users')}
              subtitle={t('text.users_form')}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/users"
                icon="back"
                color="primary"
                size="medium"
              >
                {t('button.back')}
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              {/* Form Category */}
              <form noValidate autoComplete="nope" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Field
                  name="id"
                  component={ InputTextField }
                  type="text"
                  fieldHidden
                />

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* Personal */}
                  <Content marginBottom>
                    <Toolbar
                      title={t('text.personal')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Field
                          name="name"
                          component={ InputTextField }
                          label={t('field.name')}
                          type="text"
                          required
                          autoComplete="no"
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Field
                          name="surname"
                          component={ InputTextField }
                          label={t('field.surname')}
                          type="text"
                          required
                          autoComplete="no"
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>

                        <Field
                          name="fake_email"
                          component={ InputTextField }
                          type="email"
                          fieldHidden
                        />

                        <Field
                          name="email"
                          component={ InputTextField }
                          label={t('field.email')}
                          type="email"
                          required
                          autoComplete="false"
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                        <Upload
                          label={t('field.profile_image')}
                          attachments={image ? [JSON.parse(image)] : []}
                          multiple={false}
                          avatar={true}
                          paper={true}
                          required
                          onSuccess={this.onUploadImagen.bind(this)}
                        />
                        <Field
                            name="image"
                            fieldHidden
                            component={ InputTextField }
                        />
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Configurtion */}
                  <Content marginBottom>
                    <Toolbar
                      title={t('text.configuration')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="language"
                          component={ SelectField }
                          label={t('field.language')}
                          required
                        >
                          <OptionField value="en">{t('field.option_field.english')}</OptionField>
                          <OptionField value="es">{t('field.option_field.spanish')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label={t('field.status')}
                          required
                        >
                          <OptionField value="true">{t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="profile"
                          component={ SelectField }
                          label={t('field.profile')}
                          required
                        >
                          <OptionField value="admin">{t('field.option_field.admin')}</OptionField>
                          <OptionField value="user">{t('field.option_field.user')}</OptionField>
                          <OptionField value="sales">{t('field.option_field.sales')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="commission"
                          component={ InputTextField }
                          label={t('field.commission_percentage')}
                          type="number"
                          min="0"
                          max="100"
                          required
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                        {/* Permission Autocomplete */}
                        <PermissionAutocomplete
                          permissions={ permissions }
                          onSelect={ this.handleOnSelectPermission }
                        />

                        {/* Array of Ids */}
                        <FieldArray
                          name="permissions"
                          component={ renderItemsForIds }
                        />

                      </Grid>
                    </Grid>
                  </Content>

                  {/* Password */}
                  { !id && (
                    <Content marginBottom>

                      <Toolbar title={t('text.password')} size="small" />

                      <Grid container spacing={3}>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <Field
                            name="fake_password"
                            component={ InputTextField }
                            type="password"
                            fieldHidden
                          />
                          <Field
                            name="password"
                            component={ InputTextField }
                            label={t('field.password')}
                            type="password"
                            required
                            autoComplete="no"
                          />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <Field
                            name="fake_repeat_password"
                            component={ InputTextField }
                            type="password"
                            fieldHidden
                          />
                          <Field
                            name="repeat_password"
                            component={ InputTextField }
                            label={t('field.repeat_password')}
                            type="password"
                            autoComplete="no"
                          />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="caption" align="center" component="p">
                            { TEXT.message.pattern_password }
                          </Typography>
                        </Grid>
                      </Grid>

                    </Content>
                  )}

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/users"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      {t('button.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      {t('button.save')}
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__UserForm = reduxForm({
  form: 'Page__UserForm',
  validate,
  initialValues: {
    id: 'none',
    profile: 'user',
    target: '_self',
    enabled: 'true',
    commission: '0',
    language: 'es'
  }
})(Page__UserForm);

const selector = formValueSelector('Page__UserForm');

Page__UserForm = connect(state => {
  const image = selector(state, 'image');
  return { image }
})(Page__UserForm);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__UserForm));
