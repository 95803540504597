import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field, change  } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Content, Button, ButtonsGroup } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { stringifyQueryString, parseQueryString } from './../../../utilities/utils';

class Util__UserFilter extends React.Component {

  componentDidMount(){

    const search = parseQueryString(this.props.location.search);

    Object.entries(search).forEach(([key,value]) => {
      this.props.dispatch(change(this.props.form, key, value));
    });

  }

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {
    this.props.history.push(`/users?${ stringifyQueryString(values) }`);
    this.props.onSuccess();
  }

  render() {

    const { handleSubmit, t } = this.props;

    return(

      <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

        <Content disablePadding divider>
          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="name"
                  component={ InputTextField }
                  label={t('field.name')}
                  type="text"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="surname"
                  component={ InputTextField }
                  label={t('field.surname')}
                  type="text"
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Field
                  name="email"
                  component={ InputTextField }
                  label={t('field.email')}
                  type="text"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="profile"
                  component={ SelectField }
                  label={t('field.profile')}>
                    <OptionField value="none">{t('field.option_field.all')}</OptionField>
                    <OptionField value="admin">{t('field.option_field.admin')}</OptionField>
                    <OptionField value="user">{t('field.option_field.user')}</OptionField>
                    <OptionField value="sales">{t('field.option_field.sales')}</OptionField>
              </Field>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="enabled"
                  component={ SelectField }
                  label={t('field.status')}>
                    <OptionField value="none">{t('field.option_field.all')}</OptionField>
                    <OptionField value="true">{t('field.option_field.active')}</OptionField>
                    <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
              </Field>
            </Grid>
          </Grid>
        </Content>

        <Content disablePadding>
          <ButtonsGroup align="right">
            <Button aria-label="Aplicar" icon="save" color="primary" type="submit">
              {t('button.apply')}
            </Button>
          </ButtonsGroup>
        </Content>

      </form>

    )
  }

}

Util__UserFilter = reduxForm({
  form: 'Util__UserFilter',
  initialValues: {
    enabled: 'none'
  },
  enableReinitialize: true
})(Util__UserFilter);

export default withTranslation()(withRouter(Util__UserFilter));
