import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, Modal, AlertConfirm, Table, Row, Cell, Tag, Currency, Date, LinkClient, LinkUser, LinkPayment } from './../../../components/ui';
import { PaymentService } from './../../../services';
import { isUndefined, hasPermission, parseQueryString, findPermissionProps } from './../../../utilities/utils';
import { isPaymentStatus, isPaymentFrom } from './../../../utilities/payment';

import UtilPaymentFilter from './../filter';

import API from './../../../config/api';
import TEXT from './../../../config/text';

class Page__PaymentList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        loading: false,
        page: 0,
        total: 0,
        id: null,
        entities: [],
        remove: false,
        thead: [
          { id: 'payment_code', name: this.props.t('field.code') },
          { id: 'client', name: this.props.t('field.client') },
          { id: 'user', name: this.props.t('field.user') },
          { id: 'total', name: this.props.t('field.total') },
          { id: 'payment_status', name: this.props.t('field.status') },
          { id: 'from', name: this.props.t('field.channel') },
          { id: 'created_at', name: this.props.t('field.date') },
          { id: 'actions', name: this.props.t('field.actions'), align: 'right' }
        ],
        paymentFilterShow: false
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){
      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.payments, this.props.t('text.payments')) });

      const page = this.props.match.params.page || 0;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          this._All(page);
        }else{
          this.props.history.push('/clients');
        }
      }else{
        this._All(this.state.page);
      }

    }

    /*
    ** @Service
    ** @Description: FindAll
    */

    _All = (page) => {

      PaymentService.list(page, parseQueryString(this.props.location.search)).then((response) => {
        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.payments,
          total: response.total
        });

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: TEXT.message.error
        });

      });

    }

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/payments/page/${ page }`,
        search: this.props.location.search
      });

    }

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ paymentFilterShow: true });
    }

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ paymentFilterShow: false });
    }

    render(){

      const { session, t } = this.props;
      const { permission, name, loading, entities, thead, total, page, remove, paymentFilterShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <ButtonsGroup>
                { this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title={t('button.remove_filters')}
                    placement="left"
                    component={ NavLink }
                    to="/payments"
                  />
                )}
                <Button
                  icon="filter"
                  size="medium"
                  onClick={ this.handleFilter }
                >
                  {t('button.filter')}
                </Button>
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

                {/* Alert confirm Delete */}
                <AlertConfirm
                   status={ remove }
                   message={t('messages.delete_confirm')}
                   cancel={ () => this.handleConfirmRemoveCancel() }
                   confirm={ () => this.handleConfirmRemoveConfirm() }
                />

                {/* Filter */}
                <Modal
                  status={ paymentFilterShow }
                  title={t('text.payment_filter')}
                  handleCancel={ () => this.handleFilterClose() }
                  maxWidth="sm"
                >
                  <UtilPaymentFilter onSuccess={ this.handleFilterClose }/>
                </Modal>

                <Table
                  thead={ thead }
                  loading={ loading }
                  total={ total }
                  page={ page }
                  onChangePage={ this.handleChangePage }
                >

                  { entities.length > 0 && (

                    entities.map(entity => {
                      return (
                        <Row key={ entity._id }>
                          <Cell>
                            <LinkPayment payment={ entity } />
                          </Cell>
                          <Cell>
                            <LinkClient client={ entity.client }/>
                          </Cell>
                          <Cell>
                            <LinkUser user={ entity.user }/>
                          </Cell>
                          <Cell>
                            <Currency value={ entity.total }/>
                          </Cell>
                          <Cell>
                            <Tag
                              text={ isPaymentStatus(entity.payment_status).name }
                              color={ isPaymentStatus(entity.payment_status).color }
                            />
                          </Cell>
                          <Cell>
                            <Tag text={ isPaymentFrom(entity.from) } />
                          </Cell>
                          <Cell>
                            <Date value={ entity.created_at } />
                          </Cell>
                          <Cell align="right" nowrap>
                            { (entity.client && hasPermission(session.user.permissions, API.permissions.support)) && (
                              <React.Fragment>
                                <Button
                                  icon="email"
                                  component={ NavLink }
                                  title={t('button.send_support_email')}
                                  to={ `/support/create/${ entity.client._id }` }
                                />
                              </React.Fragment>
                            )}
                            { hasPermission(session.user.permissions, API.permissions.payments_management) && (
                              <React.Fragment>
                                <Button
                                  icon="info"
                                  component={ NavLink }
                                  title={t('button.information')}
                                  to={ `/payments/detail/${ entity._id }` }
                                />
                              </React.Fragment>
                            )}
                          </Cell>
                        </Row>
                      )
                    })

                  )}

                  { (loading && entities.length === 0) && (
                    <Row>
                      <Cell colSpan={ thead.length } align="center">
                        { TEXT.message.without_results }
                      </Cell>
                    </Row>
                  )}

                </Table>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__PaymentList));
