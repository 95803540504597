import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, Modal, Table, Row, Cell, Tag, Currency, Date, LinkClient, LinkUser, LinkMPPreference } from './../../../../../components/ui';
import { MercadoPagoService } from './../../../../../services';
import { isUndefined, parseQueryString } from './../../../../../utilities/utils';
import { isPaymentStatus } from './../../../../../utilities/payment';

import UtilPreferenceFilter from './../filter';
import TEXT from './../../../../../config/text';

class Page__MP_PreferenceList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: false,
        page: 0,
        total: 0,
        entities: [],
        thead: [
          { id: 'preference_code', name: 'Código' },
          { id: 'client', name: 'Cliente' },
          { id: 'user', name: 'Usuario' },
          { id: 'total', name: 'Total' },
          { id: 'payment_status', name: 'Estado' },
          { id: 'created_at', name: 'Fecha' },
          { id: 'actions', name: 'Acciones', align: 'right' }
        ],
        preferenceFilterShow: false
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      const page = this.props.match.params.page || 0;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          this._All(page);
        }else{
          this.props.history.push('/api/mercadopago/checkout');
        }
      }else{
        this._All(this.state.page);
      }

    }

    /*
    ** @Service
    ** @Description: List all by page
    */

    _All = (page) => {

      MercadoPagoService.preferencesList(page, parseQueryString(this.props.location.search)).then((response) => {

        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.preferences,
          total: response.total
        });

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: TEXT.message.error
        });

      });

    }

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page });

      // @History
      this.props.history.push({
        pathname: `/api/mercadopago/checkout/page/${ page }`,
        search: this.props.location.search
      });

    }

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ preferenceFilterShow: true });
    }

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ preferenceFilterShow: false });
    }

    render(){

      const { loading, entities, thead, total, page, preferenceFilterShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title="Mercado Pago"
              subtitle="Listado de checkouts"
              dividerMobile
            >
              <ButtonsGroup>
                { this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title="Eliminar filtros"
                    placement="left"
                    component={ NavLink }
                    to="/api/mercadopago/checkout"
                  />
                )}
                <Button
                  icon="filter"
                  size="medium"
                  onClick={ this.handleFilter }
                >
                  Filtrar
                </Button>
                <Button
                  component={ NavLink }
                  to="/api/mercadopago/checkout/create"
                  icon="add"
                  size="medium"
                >
                  Crear
                </Button>
              </ButtonsGroup>

            </Toolbar>

            <Paper disablePadding>

              {/* Filter */}
              <Modal
                  status={ preferenceFilterShow }
                  title="Filtrar checkouts"
                  handleCancel={ () => this.handleFilterClose() }
                  maxWidth="sm">
                  <UtilPreferenceFilter onSuccess={ this.handleFilterClose }/>
              </Modal>

              <Table
                thead={ thead }
                loading={ loading }
                total={ total }
                page={ page }
                onChangePage={ this.handleChangePage }
              >

                { entities.length > 0 && (

                  entities.map(entity => {
                    return (
                      <Row key={ entity._id }>
                        <Cell>
                          <LinkMPPreference preference={ entity }/>
                        </Cell>
                        <Cell>
                          <LinkClient client={ entity.client }/>
                        </Cell>
                        <Cell>
                          <LinkUser user={ entity.user }/>
                        </Cell>
                        <Cell>
                          <Currency value={ entity.total }/>
                        </Cell>
                        <Cell>
                          <Tag
                            text={ isPaymentStatus(entity.payment_status).name }
                            color={ isPaymentStatus(entity.payment_status).color }
                          />
                        </Cell>
                        <Cell>
                          <Date value={ entity.created_at } />
                        </Cell>
                        <Cell align="right" nowrap>
                          <Button
                            icon="info"
                            component={ NavLink }
                            title="Información"
                            to={ `/api/mercadopago/checkout/detail/${ entity._id }` }
                          />
                        </Cell>
                      </Row>
                    )
                  })

                )}

                { (loading && entities.length === 0) && (
                  <Row>
                    <Cell colSpan={ thead.length } align="center">
                      { TEXT.message.without_results }
                    </Cell>
                  </Row>
                )}

              </Table>


            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page__MP_PreferenceList);
