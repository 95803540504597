import React from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change  } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { withTranslation } from 'react-i18next';
import { Content, Button, ButtonsGroup } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { stringifyQueryString, parseQueryString } from './../../../utilities/utils';

const validate = values => {

  const errors = {}

  return errors;

}

class PageProductFilter extends React.Component {

  componentDidMount(){

    const search = parseQueryString(this.props.location.search);

    Object.entries(search).forEach(([key,value]) => {
      this.props.dispatch(change('PageProductFilter', key, value));
    });

  }

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {
    this.props.history.push(`/products?${ stringifyQueryString(values) }`);
    this.props.onSuccess();
  }

  render() {

    const { handleSubmit, t } = this.props;

    return(

      <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

        <Content disablePadding divider>
          <Grid container spacing={3}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Field
                  name="sku"
                  component={ InputTextField }
                  label={t('field.sku')}
                  type="text"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="name"
                  component={ InputTextField }
                  label={t('field.name')}
                  type="text"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="enabled"
                  component={ SelectField }
                  label={t('field.status')}>
                    <OptionField value="none">{t('field.option_field.all')}</OptionField>
                    <OptionField value="true">{t('field.option_field.active')}</OptionField>
                    <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
              </Field>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="condition"
                  component={ SelectField }
                  label={t('field.condition')}>
                    <OptionField value="none">{t('field.option_field.select')}</OptionField>
                    <OptionField value="greater">{t('field.option_field.greater_than')}</OptionField>
                    <OptionField value="smaller">{t('field.option_field.less_than')}</OptionField>
                    <OptionField value="equal">{t('field.option_field.same_as')}</OptionField>
              </Field>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="price"
                  component={ InputTextField }
                  label={t('field.price')}
                  type="number"
              />
            </Grid>
          </Grid>
        </Content>

        <Content disablePadding>
          <ButtonsGroup align="right">
            <Button aria-label="Aplicar" icon="save" color="primary" type="submit">
              {t('button.apply')}
            </Button>
          </ButtonsGroup>
        </Content>

      </form>

    )
  }

}



PageProductFilter = reduxForm({
  form: 'PageProductFilter',
  validate,
  initialValues: {
    enabled: 'none',
    condition: 'none'
  },
  enableReinitialize: true
})(PageProductFilter);

export default withTranslation()(withRouter(PageProductFilter));
