import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Grid } from './../../../assets/theme/layout';
import { withTranslation } from 'react-i18next';
import { Page, Wrapper, Paper, Content, Loading, Button, ButtonsGroup, Toolbar, Data, Currency } from './../../../components/ui';
import { ProductService } from './../../../services';
import { isStatus, isBinary, hasPermission } from './../../../utilities/utils';
import { categoriesTree } from './../../../utilities/category';
import { getProductWebLink } from './../../../utilities/product';

import API from './../../../config/api';
import TEXT from './../../../config/text';

class Page__ProductDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: '...',
      loading: false,
      id: null,
      entity: null
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount() {
    this._Init();
  }

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {

    const id = this.props.match.params.id;

    if (id) {

      // @Service
      this._Find(id);

    } else {

      // @State
      this.setState({ loading: false, title: this.props.t('text.new_product') });

    }

  }

  /*
  ** @Service
  ** @Description: Save Category
  */

  _Find = (id) => {

    // @Service
    ProductService.find(id).then((response) => {

      // @State
      // @Description: Set category update into state
      this.setState({
        loading: true,
        id: response.product._id,
        entity: response.product,
        title: response.product.name
      });

    }).catch((error) => {

      // @Dispatch alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: error.data.message ? error.data.message : TEXT.message.error
      });

    });

  }

  render() {

    const { session, t } = this.props;
    const { loading, title, entity } = this.state;

    return (
      <Page>

        <Wrapper>

          <Toolbar
            title={t('page.products.name')}
            subtitle={t('page.products.subtitle')}
            dividerMobile
          >
            <Button
              component={NavLink}
              to="/products"
              icon="back"
              color="primary"
              size="medium"
            >
              {t('button.back')}
            </Button>
          </Toolbar>

          <Paper disablePadding>

            {loading ? (

              <React.Fragment>

                <Toolbar
                  title={title}
                  size="medium"
                  variant="divider"
                  disableMargin
                >
                  <ButtonsGroup>
                    { (API.routes.product.index_path && (entity.categories && entity.categories.length > 0)) && (
                      <Button
                        component="a"
                        target="_blank"
                        color="primary"
                        href={getProductWebLink(entity.categories[0], entity.slug)}
                      >
                        {t('button.link')}
                      </Button>
                    )}
                    {hasPermission(session.user.permissions, API.permissions.products_management) && (
                      <Button
                        component={NavLink}
                        color="primary"
                        to={`/products/edit/${entity._id}`}
                      >
                        {t('button.edit')}
                      </Button>
                    )}
                  </ButtonsGroup>
                </Toolbar>

                {/* Configuration */}
                <Content divider disableMargin>

                  <Toolbar
                    title={t('text.configuration')}
                    size="small"
                  />

                  <Grid container spacing={3}>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                      <Data
                        label={t('field.name')}
                        text={entity.name}
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                      <Data
                        label={t('field.categories')}
                        text={categoriesTree(entity.categories).label}
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                      <Data
                        label={t('field.sku')}
                        text={entity.sku ? entity.sku : '-'}
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                      <Data
                        label={t('field.status')}
                        text={isStatus(entity.enabled)}
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                      <Data
                        label={t('field.public')}
                        text={isBinary(entity.internal)}
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                      <Data
                        label={t('field.price')}
                        text={<Currency value={entity.price} />}
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                      <Data
                        label={t('field.stock_control')}
                        text={isStatus(entity.enabled)}
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                      <Data
                        label={t('field.stock')}
                        text={entity.stock}
                      />
                    </Grid>
                  </Grid>

                </Content>

                {/* Custom Fields */}
                <Content divider disableMargin>

                  <Toolbar
                    title={t('text.custom_fields')}
                    size="small"
                  />
                  {/* {entity.fields.map((field, index) => (
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={6} key={index}>
                      <Data
                        label={field.entity.title}
                        text={JSON.parse(field.value)}
                      />
                    </Grid>
                  ))} */}

                </Content>

                {/* Configuration */}
                <Content divider disableMargin>

                  <Toolbar
                    title={t('text.detail')}
                    size="small"
                  />

                  <Grid container spacing={3}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Data
                        label={t('field.title')}
                        text={entity.title}
                      />
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Data
                        label={t('field.subtitle')}
                        text={entity.subtitle ? entity.subtitle : '-'}
                      />
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Data
                        label={t('field.description')}
                        text={entity.description ? <div dangerouslySetInnerHTML={{ __html: entity.description }} /> : '-'}
                        html
                      />
                    </Grid>
                  </Grid>

                </Content>

              </React.Fragment>

            ) : (

              <Loading />

            )}

          </Paper>

        </Wrapper>

      </Page>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__ProductDetail));
