import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, query) {
  return request({
    url:    `/user/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: query
  });
}

function listFilter() {
  return request({
    url:    `/user/listAll`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function save(values) {
  return request({
    url:    '/user/save',
    method: 'POST',
    headers: {'Authorization': localStorageToken()},
    data:   values
  });
}

function update(id, values) {
  return request({
    url:    `/user/update/${ id }`,
    method: 'PUT',
    headers: {'Authorization': localStorageToken()},
    data:   values
  });
}

function password(values) {
  return request({
    url:    `/user/password`,
    method: 'PUT',
    headers: {'Authorization': localStorageToken()},
    data:   values
  });
}

function upload(id, values) {
  return request({
    url:    `/user/avatar/upload/${ id }`,
    method: 'POST',
    headers: {'Authorization': localStorageToken()},
    data:   values
  });
}

function find(id) {
  return request({
    url:    `/user/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function remove(_id) {
  return request({
    url:    `/user/remove/${ _id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'DELETE'
  });
}

function login(values) {
  return request({
    url:    '/user/login',
    method: 'POST',
    data:   values
  });
}

function filter(params) {
  return request({
    url:    '/user/filter',
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: params
  });
}

function signout() {
  return new Promise((resolve, reject) => {

    localStorage.clear();

    if(localStorage.getItem('token') && localStorage.getItem('user')){
      reject(false);
    }else{
      resolve(true);
    }

  });
}

const UserService = {
  login, signout, list, listFilter, save, update, find, remove, upload, password, filter
}

export default UserService;
