import React from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change  } from 'redux-form';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Grid } from './../../../assets/theme/layout';
import { Content, Button, ButtonsGroup } from './../../../components/ui';
import { InputTextField, InputDateField } from './../../../components/ui/input';
import { stringifyQueryString, parseQueryString } from './../../../utilities/utils';

class UtilSupportFilter extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      entity: null,
      sources: []
    };
  };

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  };

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {

    // @Search
    // @Description: Set query values
    const search = parseQueryString(this.props.location.search);

    Object.entries(search).forEach(([key,value]) => {
      this.props.dispatch(change(this.props.form, key, value));
    });

  };

  /*
  ** @Service
  ** @Description: Filter
  */

  _Filter = (values) => {

    // @Redirect
    // @Description: Redirect with query filter
    this.props.history.push(`/support?${ stringifyQueryString(values) }`);

    // @Props
    // @Description:
    this.props.onSuccess();

  };

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {
    this._Filter(values);
  };

  render() {

    const { handleSubmit, invalid } = this.props;

    return(
      <form noValidate autoComplete="off" onSubmit={handleSubmit(this.handleSubmitForm)}>
        <Content disablePadding divider>
          <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <Field
                name="date_from"
                component={InputDateField}
                label={this.props.t('field.from')}
                type="text"
                required
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <Field
                name="date_to"
                component={InputDateField}
                label={this.props.t('field.to')}
                type="text"
                disableFuture
                required
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Field
                name="seq"
                component={InputTextField}
                label={this.props.t('field.id')}
                type="number"
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Field
                name="subject"
                component={InputTextField}
                label={this.props.t('field.subject')}
                type="text"
              />
            </Grid>
          </Grid>
        </Content>

        <Content disablePadding>
          <ButtonsGroup align="right">
            <Button
              aria-label="Confirmar"
              icon="save"
              color="primary"
              type="submit"
              disabled={invalid}
              // onClick={
              //   handleSubmit(values =>
              //     this.handleSubmitForm({
              //       ...values,
              //       action: 'filter'
              //     }
              //     ))
              // }
            >
              {this.props.t('button.apply')}
            </Button>
          </ButtonsGroup>
        </Content>

      </form>
    )
  }

};

UtilSupportFilter = reduxForm({
  form: 'UtilSupportFilter',
  initialValues: {
    date_from: moment().subtract(7, 'days').startOf('day').format(),
    date_to: moment().endOf('day').format()
  },
  enableReinitialize: true
})(UtilSupportFilter);

export default withTranslation()(withRouter(UtilSupportFilter));
