import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, Modal, AlertConfirm, Table, Row, Cell, Tag, Chip, LinkUser } from './../../../components/ui';
import { UserService } from './../../../services';
import { isUndefined, isStatus, isStatusColor, isUserProfile, hasPermission, parseQueryString } from './../../../utilities/utils';
import { PermissionList, CliniceaStaffSync } from './../../../scenes';

import UtilUserFilter from './../filter';
import UtilUserPassword from './../password';

import API from './../../../config/api';
import TEXT from './../../../config/text';

class Page__UserList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: false,
        page: 0,
        total: 0,
        id: null,
        entities: [],
        remove: false,
        thead: [
          { id: 'user', name: this.props.t('field.full_name') },
          { id: 'email', name: this.props.t('field.email') },
          { id: 'profile', name: this.props.t('field.profile') },
          { id: 'enabled', name: this.props.t('field.status') },
          { id: 'permissions', name: this.props.t('field.permissions') },
          { id: 'actions', name: this.props.t('field.actions'), align: 'right' }
        ],
        userPasswordId: null,
        userPasswordTitle: null,
        userPasswordShow: false,
        userFilterShow: false,
        permissionsListShow: false,
        cliniceaSyncShow: false,
        permissionsListIds: []
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      const page = this.props.match.params.page || 0;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          this._All(page);
        }else{
          this.props.history.push('/users');
        }
      }else{
        this._All(this.state.page);
      }

    }

    /*
    ** @Service
    ** @Description: FindAll
    */

    _All = (page) => {

      UserService.list(page, parseQueryString(this.props.location.search)).then((response) => {

        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.users,
          total: response.total
        });

      }).catch((error) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: TEXT.message.error
        });

      });

    }

    /*
    ** @Service
    ** @Description: Remove user
    */

    _Remove = (id) => {

      UserService.remove(id).then((response) => {

        // @State
        this.setState({ remove: false });

        // @Service find all by page
        this._Init();

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('messages.user_deleted')
        });

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: this.props.t('messages.user_deleted_fail')
        });

      });

    }

    /*
    ** @Event
    ** @Description: Remove category
    */

    handleRemove = (id) => {
      this.setState({ id: id, remove: true });
    }

    /*
    ** @Click
    ** @Description: Remove category confirm
    */

    handleConfirmRemoveConfirm = () => {
      this._Remove(this.state.id);
    }

    /*
    ** @Click
    ** @Description: Remove category cancel
    */

    handleConfirmRemoveCancel = () => {
      this.setState({ remove: false });
    }

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/users/page/${ page }`,
        search: this.props.location.search
      });

    }

    /*
    ** @Event
    ** @Description: Show permissions
    */

    handlePermissions = (ids) => {
      this.setState({ permissionsListShow: true, permissionsListIds: ids });
    }

    /*
    ** @Event
    ** @Description: Hide permissions
    */

    handlePermissionsClose = () => {
      this.setState({ permissionsListShow: false, permissionsListIds: [] });
    }

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ userFilterShow: true });
    }

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ userFilterShow: false });
    }

    /*
    ** @Event
    ** @Description: Show password change
    */

    handlePasswordShow = (id, name, surname) => {
      this.setState({
        userPasswordShow: true,
        userPasswordId: id,
        userPasswordTitle: `${this.props.t('text.change_password_of')}: ${ name } ${ surname }`
      });
    }

    /*
    ** @Event
    ** @Description: Close password change
    */

    handlePasswordClose = () => {
      this.setState({ userPasswordShow: false });
    }

    /*
    ** @Event
    ** @Description: Show Clinicea Sync
    */

    handleCliniceaSync = () => {
      this.setState({ cliniceaSyncShow: true });
    }

    /*
    ** @Event
    ** @Description: Clinicea Sync
    */

    handleCliniceaSyncClose = () => {
      this.setState({ cliniceaSyncShow: false });
    };

    /*
    ** @Event
    ** @Description: Clinicea Sync Refresh
    */

    handleSuccesOnSync = () => {

      // @State
      this.setState({ loading: false });

      this._All(this.props.match.params.page || 0);
      
    };

    render(){

      const { session, t } = this.props;
      const { loading, entities, thead, total, page, remove, userPasswordId, userPasswordTitle, userFilterShow, userPasswordShow, permissionsListShow, permissionsListIds, cliniceaSyncShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar title={t('text.users')} subtitle={t('text.users_list')} dividerMobile>
              <ButtonsGroup>
                { this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title={t('button.remove_filters')}
                    placement="left"
                    component={ NavLink }
                    to="/users"
                  />
                )}
                <Button
                  icon="filter"
                  size="medium"
                  onClick={ this.handleFilter }
                >
                  {t('button.filter')}
                </Button>

                { hasPermission(session.user.permissions, API.permissions.clinicea_sync_users) && (
                  API.integration.clinicea.enabled && (
                    <Button
                      icon="sync"
                      size="medium"
                      onClick={this.handleCliniceaSync}
                    >
                      {this.props.t('button.sync')}
                    </Button>
                  )
                )}

                { hasPermission(session.user.permissions, API.permissions.users_management) && (
                  <Button
                    component={ NavLink }
                    to="/users/create"
                    icon="add"
                    size="medium"
                  >
                    {t('button.create')}
                  </Button>
                )}
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

                {/* Alert confirm Delete */}
                <AlertConfirm
                   status={ remove }
                   message={t('messages.delete_confirm')}
                   cancel={ () => this.handleConfirmRemoveCancel() }
                   confirm={ () => this.handleConfirmRemoveConfirm() }
                />

                {/* Permissions list by ids */}
                <Modal
                  title={t('text.permissions')}
                  status={ permissionsListShow }
                  handleCancel={ () => this.handlePermissionsClose() }
                >
                  <PermissionList ids={ permissionsListIds }/>
                </Modal>

                {/* Filter */}
                <Modal
                    status={ userFilterShow }
                    title={t('text.users_filter')}
                    handleCancel={ () => this.handleFilterClose() }
                    maxWidth="sm">
                    <UtilUserFilter onSuccess={ this.handleFilterClose }/>
                </Modal>

                {/* Password */}
                <Modal
                    status={ userPasswordShow }
                    title={ userPasswordTitle }
                    handleCancel={ () => this.handlePasswordClose() }
                    maxWidth="sm">
                    <UtilUserPassword
                      id={ userPasswordId }
                      onCancel={ this.handlePasswordClose }
                      onSuccess={ this.handlePasswordClose }
                    />
                </Modal>

                {/* Clinicea Sync */}
                {hasPermission(session.user.permissions, API.permissions.clinicea_sync_users) && (
                  API.integration.clinicea.enabled && (
                    <Modal
                      status={cliniceaSyncShow}
                      title={t('text.clinicea_user_sync')}
                      handleCancel={() => this.handleCliniceaSyncClose()}
                      maxWidth="md">
                      <CliniceaStaffSync
                        onSuccess={this.handleSuccesOnSync}
                      />
                    </Modal>
                  )
                )}

                <Table
                  thead={ thead }
                  loading={ loading }
                  total={ total }
                  page={ page }
                  onChangePage={ this.handleChangePage }
                >

                  { entities.length > 0 && (

                    entities.map(entity => {
                      return (
                        <Row key={ entity._id }>
                          <Cell>
                            <LinkUser user={ entity }/>
                          </Cell>
                          <Cell>{ entity.email }</Cell>
                          <Cell>
                            <Tag text={ isUserProfile(entity.profile) } color="info"/>
                          </Cell>
                          <Cell>
                            <Tag
                              text={ isStatus(entity.enabled) }
                              color={ isStatusColor(entity.enabled) }
                            />
                          </Cell>
                          <Cell>
                            <Chip
                              label={t('field.permissions')}
                              variant="outlined"
                              color="secondary"
                              onClick={ () => this.handlePermissions(entity.permissions) }
                              clickable
                            />
                          </Cell>
                          <Cell align="right" nowrap>
                            { hasPermission(session.user.permissions, API.permissions.users_management) && (
                              <React.Fragment>
                                <Button
                                  color="warning"
                                  icon="lock"
                                  title={t('button.change_password')}
                                  onClick={ () => this.handlePasswordShow(entity._id, entity.name, entity.surname) }
                                />
                                <Button
                                  color="primary"
                                  icon="edit"
                                  component={ NavLink }
                                  title={t('button.edit')}
                                  to={ `/users/edit/${ entity._id }` }
                                />
                                <Button
                                  color="danger"
                                  icon="cancel"
                                  title={t('button.delete')}
                                  onClick={ () => this.handleRemove(entity._id)  }
                                />
                              </React.Fragment>
                            )}
                          </Cell>
                        </Row>
                      )
                    })

                  )}

                  { (loading && entities.length === 0) && (
                    <Row>
                      <Cell colSpan={ thead.length } align="center">
                        { TEXT.message.without_results }
                      </Cell>
                    </Row>
                  )}

                </Table>


            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__UserList));
