import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Box } from './../../../../assets/theme/default';
import { Tabs } from './../../../../assets/theme/tab';

const styles = makeStyles(theme => ({
  root: {
    //borderBottom: `1px solid ${ theme.palette.border.main }`
  },
  divider: {
    borderBottom: `1px solid ${ theme.palette.border.main }`
  }
}));

const Tabs__c = ({ children, divider, ...rest }) => {

  const classes = styles();
  const _divider = divider ? classes['divider'] : null;

  return (
    <Box className={ classNames(classes.root, _divider) }>
      <Tabs { ...rest } textColor="primary" indicatorColor="primary">
        { children }
      </Tabs>
    </Box>
  )

};

Tabs__c.propTypes = {
  children: PropTypes.node.isRequired,
  divider: PropTypes.bool
};

export default Tabs__c;
