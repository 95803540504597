import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography, Link } from './../../../assets/theme/default';
import { InputBase} from './../../../assets/theme/input';
import { Currency } from './../../../components/ui';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    "&:not(:last-child)": {
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(1),
      borderBottom: `1px solid ${ theme.palette.secondary.light }`
    }
  },
    itemTop: {
      display: 'flex',
      //marginBottom: theme.spacing(0.5)
    },
    itemTopLeft: {
      flex: 1
    },
    itemTopRight: {
      display: 'flex',
      alignItems: 'center',
      width: 115
    },
    itemBottom: {
      display: 'flex'
    },
    itemBottomLeft: {
      flex: 1
    },
    itemBottomRight: {
      display: 'flex',
      alignItems: 'center',
      width: 115
    },
  input: {
    border: `1px solid ${ theme.palette.secondary.light }`,
    borderRadius: theme.spacing(0.5),
    paddingTop: 3,
    paddingBottom: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: theme.typography.fontSize,
    textAlign: 'center'
  }
}));

const ProductCheckout = props => {

  const classes = useStyles();

  // @Event
  // @Description: Delete
  const handleDelete = (event, index) => {

    // @Event
    // @Description: Prevent Default anchor
    event.preventDefault();

    // @Props
    // @Description: Send product deleted to parent
    props.onDelete(index);

  }

  // @Event
  // @Description: Change quantity
  const handleOnChangeQty = (event, index) => {
    props.checkoutProductQtyStateDispatch({
      index:  index,
      qty:    event.target.value,
      update: Date.now()
    });
  }

  return(
    <Box className={ classes.root }>
      { props.products.map((product, index) => {
        return (
          <Box
            key={ product._id }
            className={ classes.item }
          >

            <Box className={ classes.itemTop }>
              <Box className={ classes.itemTopLeft }>
                <Typography component="p" variant="subtitle2">
                  { product.name }
                </Typography>
                { product.sessions && (
                  <Typography component="p" variant="caption" color="secondary">
                    { `${ product.sessions } ${ product.sessions <= 1 ? 'sesion' : 'sesiones' }` }
                  </Typography>
                )}
                <Typography component="p" variant="subtitle2" color="secondary">
                  <Currency value={ product.price }/>
                </Typography>
              </Box>
              <Box className={ classes.itemTopRight }>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="secondary"
                  style={{ marginRight: 4 }}
                >
                  CANT.
                </Typography>
                <InputBase
                  classes={{
                    input: classes.input
                  }}
                  inputProps={{ min: 1 }}
                  type="number"
                  value={ product.qty }
                  onChange={ (event, value) => handleOnChangeQty(event, index) }
                />
              </Box>
            </Box>

            <Box className={ classes.itemBottom }>
              <Box className={ classes.itemBottomLeft }>
                <Typography variant="caption">
                  <Link href="#" onClick={ (event) => { handleDelete(event, index) } }>
                    Eliminar
                  </Link>
                </Typography>
              </Box>
            </Box>

          </Box>
        )
      })}
    </Box>
  )

};

ProductCheckout.propTypes = {
  products: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    checkout: state.mpCheckoutState
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    },
    checkoutProductQtyStateDispatch: (data) => {
      dispatch({
        type: 'MP_CHECKOUT_PRODUCT_QTY',
        data: data
      });
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCheckout);
