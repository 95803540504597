import { createMuiTheme } from './../default';
import { grey } from './../colors';

const ThemeCustom = createMuiTheme({
  shadows: [
    "none",
    "0 1px 3px 0 rgba(0, 0, 0, 0.04)",
    "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    "0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
    "0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
    "0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
    "0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    "0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)",
    "0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    "0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)",
    "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)",
    "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)",
    "0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)",
    "0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)",
    "0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
    "0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)",
    "0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)",
    "0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)",
    "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)",
    "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)",
    "0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)",
    "0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)",
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)"
  ],
  palette: {
    text: {
      primary: '#3f4958'
    //  primary: '#252a32'
    },
    border: {
      light: grey[50],
      main: grey[100],
      dark: grey[200],
      // light: "#F2F8FF",
      // main: "#EEF5FF",
      // dark: "#ADBEDB"
    },
    primary: {
      ultraLight: "#FAFBFF",
      light: "#E5EFFF",
      main: "#0089FF",
      dark: "#0B7FE3",
      contrastText: "#FFFFFF"
    },
    secondary: {
      ultraLight: "#EEF5FA",
      light: '#C2D3E4',
      main: '#7E8FA8',
      dark: '#3F5278',
      ultraDark: "#182550",
      contrastText: "#FFFFFF"
    },
    success: {
      ultraLight: "#E1FBD9",
      light: '#CFF1C5',
      main: '#3DBF4E',
      dark: '#1E8940',
      ultraDark: "#0B5B33",
      contrastText: "#FFFFFF"
    },
    info: {
      ultraLight: "#E4FBFB",
      light: '#A9E0EA',
      main: '#64A2BB',
      dark: '#326286',
      ultraDark: "#133259",
      contrastText: "#FFFFFF"
    },
    warning: {
      ultraLight: "#FEF6DA",
      light: '#FCDD91',
      main: '#F6B849',
      dark: '#B17424',
      ultraDark: "#76410E",
      contrastText: "#FFFFFF"
    },
    danger: {
      ultraLight: "#FFECE7",
      light: '#FFD8D7',
      main: '#FF899B',
      dark: '#B7456B',
      ultraDark: "#7A1A4C",
      contrastText: "#FFFFFF"
    },
    inherit: {
      ultraLight: "#FFECE7",
      light: '#FFD8D7',
      main: '#FF899B',
      dark: '#B7456B',
      ultraDark: "#7A1A4C",
      contrastText: "#FFFFFF"
    },
    grey: {
      light: grey[50],
      main: grey[100],
      dark: grey[300],
      contrastText: "#fff"
    },
    dark: {
      light: grey[100],
      main: grey[600],
      dark: grey[800],
      contrastText: "#fff"
    },
    background: {
      //default: "#f1f1f1"
      //default: "#f6f9ff"
      default: "#fbfbfb"
      //default: "#f7f7f7"
    },
    navigation: {
      ultraLight: "#242f56",
      light: "#242f56",
      main: "#172041",
      dark: "#0E1531",
      contrastText: "#FFFFFF",
      contrastSecondaryText: "#A1A8BB"
    },
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontSize: "2rem",
      fontWeight: 600
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 600
    },
    h3: {
      fontSize: "1.50rem",
      fontWeight: 600
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: 600
    },
    h5: {
      fontSize: "1rem",
      fontWeight: 600
    },
    h6: {
      fontSize: "0.875rem",
      fontWeight: 600
    }
  }
});

export default ThemeCustom;
