import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Page, Wrapper, Content, Paper, AlertConfirm, Loading, Button, ButtonsGroup, Toolbar, Tabs, Tab, TabPanel } from './../../../components/ui';
import { LeadService } from './../../../services';

import UtilLeadData from './../section/data';

import TEXT from './../../../config/text';

class Page__LeadDetail extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        id: null,
        loading: false,
        title: '...',
        subtitle: '...',
        entity: null,
        section: 0,
        leadToClientShow: false
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      const id = this.props.match.params.id;

      if(id){

        // @Service
        this._Find(id);

      }else{

        // @Redirect
        // @Descripcion:
        this.props.history.push('/leads');

      }

    }

    /*
    ** @Service
    ** @Description: Find Client By Id
    */

    _Find = (id) => {

      // @Service
      LeadService.find(id).then((response) => {

        // @State
        // @Description: Set client update into state
        this.setState({
          id: id,
          loading: true,
          title: `${ response.lead.name } ${ response.lead.surname }`,
          subtitle: response.lead.email,
          entity: response.lead
        });

      }).catch((error) => {

        // @Redirect
        // @Descripcion:
        //this.props.history.push('/leads');

        // @Dispatch
        // @Descripcion: alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    }

    /*
    ** @Service
    ** @Description: To Client
    */

    _Client = (id) => {

      LeadService.convert(id).then((response) => {

        // @State
        this.setState({
          leadToClientShow: false,
          entity: {
            ...this.state.entity,
            client: response.client
          }
        });

        // @Dispatch
        // @Descripcion: Alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('text.lead_convert')
        });

      }).catch((error) => {

        // @Dispatch
        // @Descripcion: Alert errro
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    }

    /*
    ** @Event
    ** @Description: Change tab
    */

    handleChangeSection = (event: React.ChangeEvent<{}>, index: number) => {
      this.setState({ section: index });
    }

    /*
    ** @Event
    ** @Description: To client
    */

    handleLeadToClient = () => {
      this.setState({ leadToClientShow: true });
    }

    /*
    ** @Click
    ** @Description: To client confirm
    */

    handleLeadToClientConfirm = () => {
      this._Client(this.state.id);
    }

    /*
    ** @Click
    ** @Description: To client clancel
    */

    handleLeadToClientCancel = () => {
      this.setState({ leadToClientShow: false });
    }

    render(){

      const { loading, title, subtitle, entity, section, leadToClientShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={ title }
              subtitle={ subtitle }
              dividerMobile
            >
              <ButtonsGroup>
                { loading && (

                  !entity.client ? (
                    <Button
                      icon="add"
                      color="primary"
                      size="medium"
                      onClick={ this.handleLeadToClient }
                    >
                      {this.props.t('button.convert')}
                    </Button>
                  ) : (
                    <Button
                      icon="client"
                      color="primary"
                      size="medium"
                      component={ NavLink }
                      to={`/clients/profile/${ entity.client._id }`}
                    >
                      {this.props.t('button.client')}
                    </Button>
                  )

                )}
              </ButtonsGroup>
            </Toolbar>

            <Content disablePadding>

              { loading ? (
                <React.Fragment>

                  {/* Alert confirm Active */}
                  <AlertConfirm
                     status={ leadToClientShow }
                     message={this.props.t('text.account_creation')}
                     cancel={ () => this.handleLeadToClientCancel() }
                     confirm={ () => this.handleLeadToClientConfirm() }
                  />

                  <Content marginBottom>
                    <Paper disablePadding>
                      <Tabs
                        value={ section }
                        onChange={this.handleChangeSection}
                      >
                        <Tab
                          label={this.props.t('tab.infromation')}
                        />
                      </Tabs>
                    </Paper>
                  </Content>

                  <Content disablePadding>
                    <Paper disablePadding>
                      <TabPanel value={ section } index={0}>
                        <UtilLeadData lead={ entity } />
                      </TabPanel>
                    </Paper>
                  </Content>

                </React.Fragment>
              ) : (

                <Loading/>

              )}

            </Content>


          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__LeadDetail));
