import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { reduxForm, Field, FieldArray, formValueSelector, change } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, ButtonsGroup, Upload, UploadImagesFieldArray } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { SliderService } from './../../../services';
import { findPermissionProps } from './../../../utilities/utils';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 255){
    errors.name = TEXT.validation.maxlength(255);
  }

  if (!values.position) {
    errors.position = TEXT.validation.required;
  }else if(values.position <= 0){
    errors.position = TEXT.validation.minlength(1);
  }

  if (values.disposition === '-1') {
    errors.disposition = TEXT.validation.required;
  }

  if (!values.title) {
    errors.title = TEXT.validation.required;
  }else if(values.title.length > 1024){
    errors.title = TEXT.validation.maxlength(1024);
  }

  if (values.paragraph) {
    if (values.paragraph.length > 2048) {
      errors.paragraph = TEXT.validation.maxlength(2048);
    }
  }

  if (values.link_primary_name) {
    if (values.link_primary_name.length > 255) {
      errors.link_primary_name = TEXT.validation.maxlength(255);
    }
  }

  if (values.link_primary_url) {
    if (values.link_primary_url.length > 2048) {
      errors.link_primary_url = TEXT.validation.maxlength(2048);
    }
  }

  if (values.link_secondary_name) {
    if (values.link_secondary_name.length > 255) {
      errors.link_secondary_name = TEXT.validation.maxlength(255);
    }
  }

  if (values.link_secondary_url) {
    if (values.link_secondary_url.length > 2048) {
      errors.link_secondary_url = TEXT.validation.maxlength(2048);
    }
  }

  return errors;

}

class Page__SliderForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        title: '...',
        loading: true,
        submited: false,
        id: null,
        entity: null
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.sliders, "Sliders") });

      const id = this.props.match.params.id;

      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false, title: 'Nuevo registro' });
      }

    }

    /*
    ** @Service
    ** @Description: Save Slider
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      SliderService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: `El slider fue creado.`
        });

        // @Redirect
        this.props.history.push('/slider');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Slider
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      SliderService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: `El slider fue editado.`
        });

        // @Redirect
        this.props.history.push('/slider');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Slider
    */

    _Find = (id) => {

      // @Service
      SliderService.find(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set slider update into state
        this.setState({
          loading: false,
          id: response.slider._id,
          entity: response.slider,
          title: response.slider.name
        });

        // @Form
        // @Description: Set form fields

        // @General
        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));
        this.props.dispatch(change(form, 'position', this.state.entity.position ));
        this.props.dispatch(change(form, 'source', this.state.entity.source ));

        // @Configuration
        this.props.dispatch(change(form, 'title', this.state.entity.title ));
        this.props.dispatch(change(form, 'label', this.state.entity.label ));
        this.props.dispatch(change(form, 'paragraph', this.state.entity.paragraph ));

        this.props.dispatch(change(form, 'link_name', this.state.entity.link_name ));
        this.props.dispatch(change(form, 'link_url', this.state.entity.link_url ));
        this.props.dispatch(change(form, 'link_target', this.state.entity.link_target ));

        this.props.dispatch(change(form, 'images', this.state.entity.images ));

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description: OnUpload file
    */

    onUploadFile = (files) => {
      this.props.dispatch(change(this.props.form, 'images', files ));
    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };

    // @Render
    render(){

      const { handleSubmit, invalid, images } = this.props;
      const { permission, name, loading, title, submited } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/slider"
                icon="back"
                size="medium"
              >
                Volver
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              {/* Form Category */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* General */}
                  <Content marginBottom>
                    <Toolbar
                      title="General"
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Field
                          name="name"
                          component={ InputTextField }
                          label="Nombre"
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Field
                          name="source"
                          component={ SelectField }
                          label="Categoría"
                          required
                        >
                          <OptionField value="-1">Seleccionar...</OptionField>
                          { API.default.slider_sources.map((entity, index) => {
                            return(
                              <OptionField key={ index } value={ entity.value }>
                                { entity.name }
                              </OptionField>
                            )
                          })}
                        </Field>
                      </Grid>
                      <Grid item xl={2} lg={2} md={6} sm={6} xs={4}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label="Estado"
                          required
                        >
                          <OptionField value="true">Activo</OptionField>
                          <OptionField value="false">Bloqueado</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={2} lg={2} md={6} sm={6} xs={4}>
                        <Field
                          name="position"
                          component={ InputTextField }
                          label="Posición"
                          type="number"
                          required
                          min={1}
                        />
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Configuration */}
                  <Content disablePadding divider>
                    <Toolbar
                      title="Configuración"
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="title"
                          component={ InputTextField }
                          label="Título"
                          type="text"
                          required
                          multiline
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Field
                          name="label"
                          component={ InputTextField }
                          label="Etiqueta"
                          type="text"
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Field
                          name="paragraph"
                          component={ InputTextField }
                          label="Texto"
                          type="text"
                          multiline
                        />
                      </Grid>

                      {/* Primary Button */}
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Field
                            name="link_name"
                            component={ InputTextField }
                            label="Nombre (Botón)"
                            type="text"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Field
                            name="link_url"
                            component={ InputTextField }
                            label="Link (Botón)"
                            type="text"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Field
                            name="link_target"
                            component={ SelectField }
                            label="Apertura (Botón)">
                              <OptionField key="_self" value="_self">Misma Página</OptionField>
                              <OptionField key="_blank" value="_blank">Nueva Página</OptionField>
                        </Field>
                      </Grid>

                    </Grid>
                  </Content>

                  {/* Images */}
                  <Content marginBottom>
                    <Toolbar
                      title="Imágenes"
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Upload
                          attachments={images}
                          multiple={true}
                          avatar={true}
                          paper={true}
                          onSuccess={this.onUploadFile.bind(this)}
                        />

                        {/* Array of Images */}
                        <FieldArray
                          name="images"
                          component={UploadImagesFieldArray}
                        />
                      </Grid>
                    </Grid>
                  </Content>

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/slider"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      Guardar
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__SliderForm = reduxForm({
  form: 'Page__SliderForm',
  validate,
  initialValues: {
    enabled: true,
    position: 1
  }
})(Page__SliderForm);

const selector = formValueSelector('Page__SliderForm');

Page__SliderForm = connect(state => {
  const images = selector(state, 'images') || [];
  return { images }
})(Page__SliderForm)

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page__SliderForm);
