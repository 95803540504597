import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Typography } from './../../../../../assets/theme/default';
import { Page, Wrapper, Paper, Content, Loading, Alert, Button, ButtonsGroup, Toolbar, Currency } from './../../../../../components/ui';
import { TodoPagoService } from './../../../../../services';
import { CheckoutStatus } from './../../../../../scenes';
import TodoPagoPaymentDetail from './../../payment/detail';
import UtilClientData from './../../../../client/section/data';
import UtilUserData from './../../../../user/section/data';

import TEXT from './../../../../../config/text';

class Page__TP_PreferenceDetail extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: false,
        entity: null
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      const id = this.props.match.params.id;

      /**
      * @Service
      */
      this._Find(id);

    }

    /*
    ** @Service
    ** @Description: FindAll
    */

    _Find = (id) => {

      TodoPagoService.preferenceById(id).then((response) => {

        this.setState({ loading: true, entity: response.preference });

      }).catch((error) => {

        let message = TEXT.message.error;

        // @Error
        // @Description: Entitad no encontrada
        // if(error.data.code === 'ENTITY_NOT_FOUNDED'){
        //
        //   message = TEXT.message.without_results;
        //
        //   this.props.history.push('/api/mercadopago/checkout');
        //
        // }

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: message
        });

      });

    }

    /*
    ** @Event
    ** @Description: Copy link checkout
    */
    handleLinkToCheckout = () => {
      return this.state.entity.todopago.init_point;
    }

    /*
    ** @Event
    ** @Description: Copy link checkout
    */
    handleCopyCheckout = () => {

      // @Dispatch alert success
      this.props.alertShow({
        open: true,
        severity: 'info',
        message: "El link de pago se copio a tu portapapeles"
      });

    }

    render(){

      const { loading, entity } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title="Todo Pago"
              subtitle={ loading ? entity.preference_id : '...' }
            >
              <ButtonsGroup>

                { loading && (

                  entity.payment_status === 'approved' ? (

                    <Button
                      size="medium"
                      component={ NavLink }
                      to={`/payments/detail/${ entity.payment_id }`}
                      icon="payment"
                    >
                      Pago
                    </Button>

                  ) : (

                    <CopyToClipboard text={ entity.init_point } onCopy={ this.handleCopyCheckout }>
                      <Button icon="link" size="medium">
                        Checkout
                      </Button>
                    </CopyToClipboard>

                  )

                )}

                <Button
                  size="medium"
                  component={ NavLink }
                  to="/api/todopago/checkout"
                  icon="back"
                >
                  Volver
                </Button>

              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

              { loading ? (

                <React.Fragment>

                  {/* Checkout Status */}
                  <CheckoutStatus status={ entity.payment_status }/>

                  {/* Client Information */}
                  <Content disableMargin disablePadding>
                    { entity.client ? (
                      <UtilClientData
                        client={ entity.client }
                        titlePersonal="Cliente"
                        hideContact
                        hideLocation
                        hideConfiguration
                      />
                    ) : (
                      <Content divider disableMargin>
                        <Alert severity="warning">
                          <strong>Atención</strong>: El cliente no fue encontrado.
                        </Alert>
                      </Content>
                    )}
                  </Content>

                  {/* User Information */}
                  <Content disableMargin disablePadding>
                    { entity.user ? (
                      <UtilUserData
                        user={ entity.user }
                        titlePersonal="Usuario"
                        hideConfiguration
                      />
                    ) : (
                      <Content divider disableMargin>
                        <Alert severity="warning">
                          <strong>Atención</strong>: El usuario no fue encontrado.
                        </Alert>
                      </Content>
                    )}
                  </Content>

                  {/* Mercadopago Information */}
                  <Content divider disableMargin>
                    <TodoPagoPaymentDetail
                      payment={ entity.preference_id }
                    />
                  </Content>

                  {/* Footer */}
                  <Content>
                    <Typography variant="h3" align="right">
                      Total <Currency value={ entity.total }/>
                    </Typography>
                  </Content>

                </React.Fragment>

              ) : (

                <Loading/>

              )}

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page__TP_PreferenceDetail);
