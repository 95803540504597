import queryString from 'querystring';
import slugify from 'slugify';
import moment from 'moment';
import API from './../../config/api';

const isUndefined = function checkIfIsUndefined (value) {
  return typeof value === 'undefined';
}

const isNull = function checkIfIsNull (value) {
  return value === null;
}

const isInt = function isInt(n){
    return Number(n) === n && n % 1 === 0;
}

const isFloat = function isFloat(n){
    return Number(n) === n && n % 1 !== 0;
}

const truncate = function truncateValue (value, max) {
    return value.substr(0,max-1)+(value.length>max?'...':'');
}

const replace = function replace (value, a, b) {
  return value.replace(a, b);
}

const isSlug = function checkIfIsValidSlug (value) {
  return /^[a-z0-9-]+$/.test(value);
}

const isBinary = function checkIfIsBinary (value) {
  return value ? 'Si' : 'No';
}

const isShippingOrderEvent = function checkShippingOrderEvent (value) {
  return value ? 'Confirmado' : 'No confirmado';
}

const isBinaryColor = function checkIfIsBinary (value) {
  return value ? 'success' : 'danger';
}

const isStatus = function checkIfIsStatus (value) {
  return value ? 'Activo' : 'Bloqueado';
}

const isResponse = function checkIfIsResponse (value) {
  let response;

  if( value === 'success' ) response = 'Success';
  if( value === 'error' ) response = 'Error';

  return response;
}

const isResponseColor = function checkIfIsResponseColor (value) {
  let response;

  if( value === 'success' ) response = 'success';
  if( value === 'error' ) response = 'danger';

  return response;
}

const isLevel = function checkIfIsStatus (parent) {
  return !parent ? 'Primario' : 'Secundario';
}

const isStatusColor = function checkIfIsStatusColor (value) {
  return value ? 'success' : 'danger';
}

const isCuponName = function checkIfIsStatusColor (value) {
  let name;

  if( value === 'amount' ) name = 'Monto fijo';
  if( value === 'percentage' ) name = 'Porcentaje';

  return name;
}

const isUserProfile = function checkUserProfile (value) {

  let profile;

  if( value === 'admin' ) profile = 'Admin';
  if( value === 'user' ) profile = 'Usuario';
  if( value === 'sales' ) profile = 'Ventas';

  return profile;

}

const isGenre = function checkUserProfile (value) {

  let genre;

  if( value === 'male' ) genre = 'Masculino';
  if( value === 'famale' ) genre = 'Femenino';

  return genre;

}

const isCondition = function checkConditionFilter (value) {

  let condition = 'Ninguna';

  if( value === 'none' ) condition = 'Ninguna';
  if( value === 'greater' ) condition = 'Mayor que';
  if( value === 'smaller' ) condition = 'Menor que';
  if( value === 'equal' ) condition = 'Igual que';

  return condition;

}

const isEmail = function checkIfEmailIsValid (value) {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
}

const isPasswordValid = function isPasswordValid (value) {
  return /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])[\w!@#$%^&*]{6,10}$/.test(value);
}

const isNumber = function checkIfIsValidNumber (value) {
  return typeof value === 'number';
}

const hasPermission = function checkIfUserHadPermission (permissions, permission) {
  return permissions.find((p) => (p.enabled && p.key === permission) );
}

const findPermissionByKey = function findPermissionByKey (permissions, key) {
  return (permissions && permissions.length > 0 && key)
        ? permissions.find((permission) => {
            return permission.key === key;
          })
        : null;
}

const findPermissionProps = function findPermissionProps (session, key, alternativeName) {
  let properties = { name: alternativeName };
  if(session && session.user && session.user.permissions){
    let permission = findPermissionByKey(session.user.permissions, key);
    if(permission){
      if(permission.name && !permission.translatable){
        properties.name = permission.name;
      }
    }
  }
  return properties;
}

const filterIds = function reduceIds (entities) {
  return entities.reduce((accumulator, currentValue, index) => {
    accumulator.push(currentValue._id);
    return accumulator;
  }, []);
}

const filterPaymentMethodsIds = function reduceIds (entities) {
  return entities.reduce((accumulator, currentValue, index) => {
    accumulator.push({ id: currentValue.id });
    return accumulator;
  }, []);
}

const parseQueryString = function parseQueryString (values){
  return queryString.parse(values.slice(1));
}

const stringifyQueryString = function stringifyQueryString (values){
  return queryString.stringify(values);
}

const formatDateToString = ({ value, format = "YYYY-MM-DD" }) => {
  return moment(value).format(format);
}

const isSorting = function isSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const desc = function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const numberStartWithFifthteen = function numberStartWithFifthteen(value) {
  return /^15/.test(value);
}

const numberStartWithZero = function numberStartWithZero(value) {
  return /^0/.test(value);
}

const numberStartWithTrueNumber = function numberStartWithFailNumber(value) {
  return /^(1|2|3)/.test(value);
}

const numberLength = function numberLength (value) {
  // Total de digitos 13, menos el 0 del codigo + 15 del ceular
  return 10 - value.length;
}

const maxMonths = function maxMonths (monthsToSubtract, format) {
  let date = moment().subtract(monthsToSubtract, 'month');
  return format ? date.format(format) : date.format();
}

const cleanPathImage = function cleanPathImage (path) {
  return path.substring(path.lastIndexOf('/') + 1, path.length);
}

const getRoute = function getRoute(path) {
    let args = Array.prototype.slice.call(arguments, 1);
    let count = -1;
    return path.replace(/:[a-zA-Z?]+/g, function (match) {
        count += 1;
        return args[count] !== undefined ? args[count] : match;
    });
};

const getAbsoluteRoute = function getAbsoluteRoute(slug) {
  return `${ API.endpoint.web }${ slug }`;
};

const getUploadsRoute = function getUploadsRoute(slug) {
  return `${ API.endpoint.uploads }${ slug }`;
};

const buildSlug = function buildSlug (value){
  return slugify(value, {
    replacement: '-',    // replace spaces with replacement
    remove: null,        // regex to remove characters
    lower: true          // result in lower case
  });
};

export {
  isUndefined,
  isNull,
  isSlug,
  isBinary,
  isBinaryColor,
  isStatus,
  isShippingOrderEvent,
  isStatusColor,
  isPasswordValid,
  isEmail,
  isNumber,
  isInt,
  isFloat,
  isLevel,
  truncate,
  replace,
  findPermissionByKey,
  findPermissionProps,
  hasPermission,
  filterIds,
  filterPaymentMethodsIds,
  isUserProfile,
  isGenre,
  parseQueryString,
  stringifyQueryString,
  formatDateToString,
  isSorting,
  numberStartWithFifthteen,
  numberStartWithZero,
  numberStartWithTrueNumber,
  numberLength,
  maxMonths,
  cleanPathImage,
  getRoute,
  getAbsoluteRoute,
  getUploadsRoute,
  isCuponName,
  isCondition,
  buildSlug,
  isResponse,
  isResponseColor
}
