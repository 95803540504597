import React from 'react';
import { withTranslation } from 'react-i18next';
import { Paper, Loading, Table, Row, Cell, Currency, Date, LinkProduct } from './../../../../components/ui';

class UtilShopCartDetail extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      thead: [
        { id: 'product', name: this.props.t('field.product') },
        { id: 'quantity', name: this.props.t('field.quantity') },
        { id: 'discount', name: this.props.t('field.discount') },
        { id: 'total', name: this.props.t('field.total') },
        { id: 'created_at', name: this.props.t('field.date') }
      ]
    };
  };

  render() {
    const { entities } = this.props;
    const { thead } = this.state;
    return(
      (entities && entities.length > 0) ? (
        <Paper disablePadding>
          <Table
            thead={ thead }
            loading={true}
          >
            {entities.map((entity, index) => {
              return (
                <Row key={index}>
                  <Cell nowrap>
                    <LinkProduct product={entity.product}/>
                  </Cell>
                  <Cell nowrap>
                    { entity.quantity }
                  </Cell>
                  <Cell nowrap>
                    <Currency value={ entity.discount }/>
                  </Cell>
                  <Cell nowrap>
                    <Currency value={ entity.total }/>
                  </Cell>
                  <Cell nowrap>
                    <Date value={ entity.created_at }/>
                  </Cell>
                </Row>
              )
            })}
          </Table>
        </Paper>
      ) : (
        <Loading/>
      )
    )
  }
};

export default withTranslation()(UtilShopCartDetail);
