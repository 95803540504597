import React from 'react';
import { FormattedNumber } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles, Box } from './../../../../assets/theme/default';
import { ArrowRightAltIcon } from './../../../../assets/theme/icon';

import API from './../../../../config/api';

const Currency = ({ value }) => {
  const formatOptions = { style: 'currency', currency: API.client.currency ? API.client.currency : 'ARS' };
  return(
      <FormattedNumber value={value} {...formatOptions} />
  )
};

const useCurrencyUpdatedStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.success.main
  }
}));

const CurrencyUpdated = ({prev, next}) => {
  const classes = useCurrencyUpdatedStyles();
  return(
    <Box className={classes.root}>
      {prev}
        <ArrowRightAltIcon classes={{root: classes.icon}}/>
      {next}
    </Box>
  )
};

CurrencyUpdated.propTypes = {
  prev: PropTypes.node.isRequired,
  next: PropTypes.node.isRequired
};

export default Currency;
export {CurrencyUpdated};
