import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Grid } from './../../assets/theme/layout';
import { Page, Wrapper, Content, Paper, Toolbar, Tabs, Tab, TabPanel } from './../../components/ui';
import { DashboardAccess, PaymentList, MPPreferenceList, TPPreferenceList, LeadList, ShippingOrderList, ClientList } from './../../scenes';
import { hasPermission } from './../../utilities/utils';

import API from './../../config/api';

class Page__Dashboard extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        panels: []
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {
      if(this.props.settings){
        if(this.props.settings.dashboard){
          if(this.props.settings.dashboard.panels.length > 0) {
            this.setState({ panels: this.props.settings.dashboard.panels });
          }
        }
      }
    };

    /*
    ** @Event
    ** @Description: Change tab
    */

    handleChangeSection = (event: React.ChangeEvent<{}>, index: number) => {
      console.log(index);
    };

    /*
    ** @Event
    ** @Description: Active tab section
    */

    handleActiveSection = (tabs) => {
      let active = 0;
      if(tabs && tabs.length > 0){
        tabs.forEach((tab, tabIndex) => {
          if(tab.active){
            active = tabIndex;
          }
        });
      }
      return active;
    };

    render(){

      const { t, session } = this.props;
      const { panels } = this.state;

      return(
        <Page>

          <Wrapper>

            {/* Toolbar */}
            <Toolbar
              title={t('page.dashboard.title', { name: session.user.name })}
              subtitle={t('page.dashboard.subtitle', { client: API.client.name })}
            />

            <Grid container spacing={3}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <DashboardAccess/>
              </Grid>
            </Grid>

            <Grid container spacing={3}>

              { panels.length > 0 && (
                  panels.map((panel, index) => {
                    return(
                      <Grid item xl={6} lg={6} md={12} sm={12} xs={12} key={index}>
                        <Content disablePadding>
                          <Toolbar
                            title={panel.title}
                            subtitle={panel.subtitle}
                            size="medium"
                          />
                          <Paper disablePadding>
                            <Tabs
                              divider
                              value={this.handleActiveSection(panel.tabs)}
                              onChange={(event: React.ChangeEvent<{}>, number: number) => {
                                this.setState({
                                  panels: this.state.panels.map((panel, panelIndex) => {
                                      if(index === panelIndex){
                                        let newTabs = [];
                                        panel.tabs.forEach((tab, tabIndex) => {
                                          newTabs.push(Object.assign({}, tab, { active: number === tabIndex }))
                                        })
                                        return {...panel, tabs: newTabs}
                                      }else{
                                        return panel
                                      }
                                  })
                                })
                              }}
                            >
                              { panel.tabs.map((tab, index) => {
                                return(
                                  (tab.permission && tab.permission.enabled) && (
                                    (hasPermission(session.user.permissions, tab.permission.key)) && (
                                      <Tab label={tab.permission.name} value={index} key={index}/>
                                    )
                                  )
                                )
                              })}
                            </Tabs>

                            { panel.tabs.map((tab, index) => {
                              return(
                                (tab.permission && tab.permission.enabled) && (
                                  (hasPermission(session.user.permissions, tab.permission.key)) && (
                                    <TabPanel value={this.handleActiveSection(panel.tabs)} index={index} key={index}>

                                      {/* Clients */}
                                      {tab.permission.key === API.permissions.clients && (
                                        <ClientList/>
                                      )}

                                      {/* Leads */}
                                      {tab.permission.key === API.permissions.leads && (
                                        <LeadList/>
                                      )}

                                      {/* Shipping */}
                                      {tab.permission.key === API.permissions.shipping && (
                                        <ShippingOrderList/>
                                      )}

                                      {/* Payments */}
                                      {tab.permission.key === API.permissions.payments && (
                                        <PaymentList/>
                                      )}

                                      {/* Mercado Pago */}
                                      {tab.permission.key === API.permissions.api_mercado_pago && (
                                        <MPPreferenceList/>
                                      )}

                                      {/* Todo Pago */}
                                      {tab.permission.key === API.permissions.api_todo_pago && (
                                        <TPPreferenceList/>
                                      )}

                                    </TabPanel>
                                  )
                                )
                              )
                            })}

                          </Paper>
                        </Content>
                      </Grid>
                    )
                  })
                )
              }

            </Grid>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState,
    settings: state.settingsState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__Dashboard));
