import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import moment from 'moment';
import { Grid } from './../../../../assets/theme/layout';
import { Content, Button, ButtonsGroup, Toolbar } from './../../../../components/ui';
import { InputTextField, InputDateField, InputDateTimeField, SelectField, OptionField } from './../../../../components/ui/input';
import { ShippingLocationAutocomplete } from './../../../../scenes';
import { ShippingEventList } from './../../event';

//import API from './../../../../config/api';
import TEXT from './../../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.from) {
    errors.from = TEXT.validation.required;
  }

  if (!values.to) {
    errors.to = TEXT.validation.required;
  }

  if (values.vehicle) {
    if (values.vehicle.length > 255) {
      errors.vehicle = TEXT.validation.maxlength(255);
    }
  }

  if (values.driver) {
    if (values.driver.length > 255) {
      errors.driver = TEXT.validation.maxlength(255);
    }
  }

  if (values.journey) {
    if (values.journey.length > 255) {
      errors.journey = TEXT.validation.maxlength(255);
    }
  }

  if (values.terminal) {
    if (values.terminal.length > 255) {
      errors.terminal = TEXT.validation.maxlength(255);
    }
  }

  if(values.eta) {

  }

  return errors;

};

class ShippingFormSchedule extends React.Component {

  constructor(props){
    super(props);
    this.changeMinETA = this.changeMinETA.bind(this)
    this.state = {
      loading: true,
      submited: false,
      entity: null,
      minETA: null
    };
  };

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  };

  /**
  * @Event
  * @Description: Is invoked immediately after props change
  */
  componentDidUpdate(prevProps, prevState){
    if(this.props.entity !== prevProps.entity){
      this._Update();
    }
    if(this.state.entity !== prevState.entity){
      this.changeMinETA();
    }
  };

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {
    this._Update();
  };

  /*
  ** @Event
  ** @Description: Init
  */

  _Update = () => {
    if (this.props.entity) {

      this.setState({ entity: this.props.entity }, () => {

        const form = this.props.form;

        this.props.dispatch(change(form, 'eta', this.state.entity.eta ));
        this.props.dispatch(change(form, 'eta_origin', this.state.entity.eta_origin ));
        this.props.dispatch(change(form, 'etd', this.state.entity.etd ));
        this.props.dispatch(change(form, 'cut_off_imo', this.state.entity.cut_off_imo ));
        this.props.dispatch(change(form, 'cut_off_doc', this.state.entity.cut_off_doc ));
        this.props.dispatch(change(form, 'cut_off_vgm', this.state.entity.cut_off_vgm ));
        this.props.dispatch(change(form, 'cut_off_phy', this.state.entity.cut_off_phy ));
        this.props.dispatch(change(form, 'vehicle', this.state.entity.vehicle ));
        this.props.dispatch(change(form, 'driver', this.state.entity.driver ));
        this.props.dispatch(change(form, 'terminal', this.state.entity.terminal ));
        this.props.dispatch(change(form, 'journey', this.state.entity.journey ));
        this.props.dispatch(change(form, 'to_status', this.state.entity.to_status ));
        this.props.dispatch(change(form, 'from_status', this.state.entity.from_status ));

        if(this.state.entity.from){
          this.props.dispatch(change(form, 'from', this.state.entity.from._id ));
        }

        if(this.state.entity.to){
          this.props.dispatch(change(form, 'to', this.state.entity.to._id ));
        }

/*        if(this.state.entity.events){
            this.changeMinETA();
        }*/

      });


    }
  };

  /*
  ** @Event
  ** @Description: change min ETA
  */
  changeMinETA = (new_eta) => {
    if( new_eta != null && this.state.entity.etd <= new_eta){
      this.setState({ minETA: new_eta});
    }
    else{
      this.setState({ minETA: null});
    }
  };

  /*
  ** @Event
  ** @Description: On selected from
  */
  handleOnSelectFrom = (location) => {
    this.props.dispatch(change(this.props.form, 'from', location ? location._id : '' ));
    this.setState({ entity: { ...this.state.entity, from: location }});
  };

  /*
  ** @Event
  ** @Description: On selected to
  */
  handleOnSelectTo = (location) => {
    this.props.dispatch(change(this.props.form, 'to', location ? location._id : '' ));
    this.setState({ entity: { ...this.state.entity, to: location }});
  };

  /*
  ** @Submit
  ** @Description: Submit form
  */
  handleSubmitForm = (values) => {
    this.props.onSubmit(values);
  };


  handleResetFechas = () => {

    alert('Importante: Recuerde eliminar los Trasbordos y/o Escalas (de existir) en forma manual.');

    [
    'eta_origin',
    'eta',
    'etd',
    'cut_off_imo',
    'cut_off_doc',
    'cut_off_vgm',
    'cut_off_phy',
    ].forEach((value) => {
      this.props.dispatch(change(this.props.form, value, '' ));
});
[
'from_status',
'to_status'
].forEach((value) => {
  this.props.dispatch(change(this.props.form, value, false ));
})
  }

  render(){

    const { handleSubmit, invalid, eta, eta_origin, etd, from, to, from_status, to_status, vehicle, journey, cut_off_imo, cut_off_doc, cut_off_vgm } = this.props;
    const { submited, entity, minETA } = this.state;

    return(
      <React.Fragment>
        <ButtonsGroup align="right">
        <Button
          type="button"
          onClick={this.handleResetFechas}
          size="medium"
          icon="cancel"
          color="danger"
        >
          Borrar Fechas
        </Button>
        </ButtonsGroup>
        <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

          {/* Form */}
          <Content divider disableMargin>

            {/* Configuration */}
            <Content disablePadding divider>
              <Toolbar
                title="Cut-Off"
                size="small"
              />
              <Grid container spacing={3}>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    name="cut_off_imo"
                    component={ InputDateTimeField }
                    label="Cut-Off IMO"
                    type="text"
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    name="cut_off_doc"
                    component={ InputDateTimeField }
                    label="Cut-Off Documental"
                    type="text"
                    minDate={new Date(cut_off_imo)}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    name="cut_off_vgm"
                    component={ InputDateTimeField }
                    label="Cut-Off VGM"
                    type="text"
                    minDate={new Date(cut_off_doc)}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    name="cut_off_phy"
                    component={ InputDateTimeField }
                    label="Cut-Off Físico"
                    type="text"
                    minDate={new Date(cut_off_vgm)}
                  />
                </Grid>
              </Grid>
            </Content>

            {/* Schedule */}
            <Content disablePadding marginBottom>
              <Toolbar
                title="Schedule"
                size="small"
              />
              <Grid container spacing={3}>
                <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                  <Field
                    name="eta_origin"
                    component={ InputDateField }
                    label="ETA origen"
                    type="text"
                  />
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                  <Field
                    name="etd"
                    component={ InputDateField }
                    label="ETD origen"
                    type="text"
                    disabled={!eta_origin}
                    minDate={new Date(eta_origin)}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <ShippingLocationAutocomplete
                    label="Origen"
                    location={entity && entity.from ? entity.from : null}
                    onSelect={this.handleOnSelectFrom}
                    required
                  />
                  <Field
                    name="from"
                    component={ InputTextField }
                    type="text"
                    required
                    fieldHidden
                  />
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                  <Field
                    name="eta"
                    component={ InputDateField }
                    label="ETA destino"
                    type="text"
                    disabled={!etd}
                    minDate={minETA ? new Date(minETA) : etd}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <ShippingLocationAutocomplete
                    label="Destino"
                    location={entity && entity.to ? entity.to : null}
                    onSelect={this.handleOnSelectTo}
                    required
                  />
                  <Field
                    name="to"
                    component={ InputTextField }
                    required
                    fieldHidden
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    name="from_status"
                    component={ SelectField }
                    label="Estado ETD origen"
                    disabled={!etd || (etd && !moment().isSameOrAfter(etd, 'day'))}
                  >
                    <OptionField value="true">Confirmado</OptionField>
                    <OptionField value="false">No confirmado</OptionField>
                  </Field>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    name="to_status"
                    component={ SelectField }
                    label="Estado ETA destino"
                    disabled={!eta || (eta && !moment().isSameOrAfter(eta, 'day'))}
                  >
                    <OptionField value="true">Confirmado</OptionField>
                    <OptionField value="false">No confirmado</OptionField>
                  </Field>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    name="driver"
                    component={ InputTextField }
                    label="Transportista"
                    type="text"
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    name="vehicle"
                    component={ InputTextField }
                    label="Medio de transporte"
                    type="text"
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    name="journey"
                    component={ InputTextField }
                    label="Viaje"
                    type="text"
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    name="terminal"
                    component={ InputTextField }
                    label="Terminal"
                    type="text"
                  />
                </Grid>
              </Grid>
            </Content>

            {/* Events */}
            <Content disablePadding>
              <ShippingEventList
                form={{
                  eta: eta,
                  eta_origin: eta_origin,
                  etd: etd,
                  from: from ? this.state.entity.from : null,
                  to: to ? this.state.entity.to : null,
                  from_status: from_status ? JSON.parse(from_status) : from_status,
                  to_status: to_status ? JSON.parse(to_status) : to_status,
                  vehicle: vehicle,
                  journey: journey
                }}
                actions={true}
                updateMinETA={this.changeMinETA}
              />
            </Content>

          </Content>

          {/* Footer */}
          <Content>
            <ButtonsGroup align="right">
              <Button
                component={ NavLink }
                to="/shipping"
                color="danger"
                size="medium"
                icon="cancel"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                size="medium"
                icon="add"
                color="success"
                disabled={ invalid || submited }
              >
                Guardar
              </Button>
            </ButtonsGroup>
          </Content>

        </form>
      </React.Fragment>
    )
  }

};

const selector = formValueSelector('ShippingFormSchedule');

ShippingFormSchedule = reduxForm({
  form: 'ShippingFormSchedule',
  validate,
  initialValues: {
    from_status: false,
    to_status: false,
    etd: '',
    eta_origin: '',
    eta: '',
    cut_off_imo: '',
    cut_off_doc: '',
    cut_off_vgm: '',
    cut_off_phy: ''
  },
  enableReinitialize: true
})(ShippingFormSchedule);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

ShippingFormSchedule = connect(
  state => {
    const cut_off_imo = selector(state, 'cut_off_imo');
    const cut_off_doc = selector(state, 'cut_off_doc');
    const cut_off_vgm = selector(state, 'cut_off_vgm');
    const cut_off_phy = selector(state, 'cut_off_phy');
    const eta_origin = selector(state, 'eta_origin');
    const eta = selector(state, 'eta');
    const etd = selector(state, 'etd');
    const from = selector(state, 'from');
    const to = selector(state, 'to');
    const from_status = selector(state, 'from_status');
    const to_status = selector(state, 'to_status');
    const vehicle = selector(state, 'vehicle');
    const journey = selector(state, 'journey');
    return { cut_off_imo, cut_off_doc, cut_off_vgm, cut_off_phy, eta, eta_origin, etd, from, to, vehicle, journey, from_status, to_status }
  }
)(ShippingFormSchedule);

export default connect(mapStateToProps, mapDispatchToProps)(ShippingFormSchedule);
