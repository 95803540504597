import React from 'react';
import { connect } from 'react-redux';
import { Content, Paper, Toolbar, Tabs, Tab, TabPanel } from './../../../components/ui';
import { hasPermission } from './../../../utilities/utils';
import LeadList from './../../lead/list';
import ShippingOrderList from './../../shipping/order/list';

import API from './../../../config/api';

class DashboardAdministration extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      section: 0
    };
  }

  /*
  ** @Event
  ** @Description: Change tab
  */

  handleChangeSection = (event: React.ChangeEvent<{}>, index: number) => {
    this.setState({ section: index });
  }

  render(){

    const { session } = this.props;
    const { section } = this.state;

    return (
      <Content disablePadding>

        <Toolbar
          title="Administración"
          subtitle="Listado de los últimos 10 movimientos generados."
          size="medium"
        />

        <Paper disablePadding>
          <Tabs
            divider
            value={ section }
            onChange={this.handleChangeSection}
          >
            { hasPermission(session.user.permissions, API.permissions.leads) && (
              <Tab label="Candidatos"/>
            )}
            { hasPermission(session.user.permissions, API.permissions.shipping) && (
              <Tab label="Reservas"/>
            )}
          </Tabs>

          { hasPermission(session.user.permissions, API.permissions.payments) && (
            <TabPanel value={ section } index={0}>
              <LeadList/>
            </TabPanel>
          )}
          { hasPermission(session.user.permissions, API.permissions.shipping) && (
            <TabPanel value={ section } index={1}>
              <ShippingOrderList/>
            </TabPanel>
          )}
        </Paper>

      </Content>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

export default connect(mapStateToProps)(DashboardAdministration);
