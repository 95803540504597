import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, query) {
  return request({
    url:    `/form/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: query
  });
}

function records(id, page, query) {
  return request({
    url:    `/form/recrods/list/${ id }/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: query
  });
}

function record(id) {
  return request({
    url:    `/form/recrods/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
}

function find(slug) {
  return request({
    url:    `/p/form/overview/${ slug }`,
    method: 'GET'
  });
}

function fields(id) {
  return request({
    url:    `/p/form/fields/${ id }`,
    method: 'GET'
  });
}

function save(values) {
  return request({
    url:    '/p/form/save',
    method: 'POST',
    data:   values
  });
}


const FormService = {
  list, records, record, find, fields, save
}

export default FormService;
