import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field, change  } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Content, Button, ButtonsGroup } from './../../../components/ui';
import { InputTextField } from './../../../components/ui/input';
import { stringifyQueryString, parseQueryString } from './../../../utilities/utils';

class UtilLeadFilter extends React.Component {

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){

    this._Init();

  }

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {

    // @Search
    // @Description: Set query values
    const search = parseQueryString(this.props.location.search);

    Object.entries(search).forEach(([key,value]) => {
      this.props.dispatch(change(this.props.form, key, value));
    });

  }

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {
    this.props.history.push(`/forms?${ stringifyQueryString(values) }`);
    this.props.onSuccess();
  }

  render() {

    const { handleSubmit } = this.props;

    return(

      <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

        <Content disablePadding divider>
          <Grid container spacing={3}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Field
                  name="name"
                  component={ InputTextField }
                  label={this.props.t('field.name')}
                  type="text"
              />
            </Grid>
          </Grid>
        </Content>

        <Content disablePadding>
          <ButtonsGroup align="right">
            <Button aria-label="Aplicar" icon="save" color="primary" type="submit">
              {this.props.t('button.apply')}
            </Button>
          </ButtonsGroup>
        </Content>

      </form>

    )
  }

}

UtilLeadFilter = reduxForm({
  form: 'UtilLeadFilter',
  initialValues: {},
  enableReinitialize: true
})(UtilLeadFilter);

export default withTranslation()(withRouter(UtilLeadFilter));
