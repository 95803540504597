import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { makeStyles, Box, Breadcrumbs, Link, Typography } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${ theme.palette.grey[200] }`
  },
  item: {
    fontSize: theme.typography.fontSize
  },
  active: {
    color: theme.palette.primary.main
  }
}));

const Breadcrumbs__c = ({ items, ...rest }) => {

  const classes = useStyles();

  return (
    <Box className={ classes.root }>
      { (items && items.length > 0) && (
        <Breadcrumbs aria-label="breadcrumb" separator="›" classes={{ li: classes.item }}>

          <Link color="inherit" component={ NavLink } to="/dashboard">
            Dashboard
          </Link>

          { items.map((item, index) => {
            return(
              item.route ? (
                <Link key={ index } component={ NavLink } to={ item.route }>
                  { item.name }
                </Link>
              ) : (
                <Typography  key={ index } component="span" variant="inherit" classes={{ root: classes.active }}>
                  { item.name }
                </Typography>
              )
            )
          })}
        </Breadcrumbs>
      )}
    </Box>
  )

};

Breadcrumbs__c.propTypes = {
  items: PropTypes.array.isRequired
};

export default Breadcrumbs__c;
