import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box } from './../../../assets/theme/default';
import { Container } from './../../../assets/theme/layout';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    padding: theme.spacing(2),

  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const Wrapper = ({ children, ...rest }) => {

  const classes = useStyles();

  return (
    <Container>
      <Box className={ classes.content }>
      { children }
      </Box>
    </Container>
  )

};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default Wrapper;
