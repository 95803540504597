import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from './../../../../components/ui/input';
import { CountryService } from './../../../../services';

class CountryPhoneSelect extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      entities: []
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._All();
  }

  /*
  ** @Service
  ** @Description: List all countries
  */

  _All = () => {
    CountryService.list().then((response) => {

      // @Sate
      this.setState({ entities: response.countries });

      // @Find
      // @Description: Find country default
      let countryDefault = response.countries.find((country) => country.default === true);

      // @Event
      if(this.props.onLoad){
        this.props.onLoad(countryDefault);
      }

    });
  };

  /*
  ** @Render
  */

  render() {

    const { entities } = this.state;

    return(
      <SelectField { ...this.props }>
        <option value="-1">Seleccionar...</option>
        { entities.length > 0 && (
            entities.map((entity, index) => {
              return(
                <option key={ index } value={ entity._id }>
                  {entity.name} ({entity.prefix}) +{entity.phone}
                </option>
              )
            })
        )}
      </SelectField>
    )
  }

}

CountryPhoneSelect.propTypes = {
  onLoad: PropTypes.func
};

export default CountryPhoneSelect;
