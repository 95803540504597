import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from './../../../assets/theme/default';
import { List, ListItem, ListItemSecondaryAction, ListItemText } from './../../../assets/theme/list';
import { Currency } from './../../../components/ui';

const useStyles = makeStyles(theme => ({
    balanceListOption: {
      margin: 0
    },
    balanceListAction: {
      paddingRight: theme.spacing(16),
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.2),
      right: 0
    }
}));

const Item = ({ primary, amount, ...rest }) => {

  const classes = useStyles();

  return (
    <ListItem disableGutters classes={{ secondaryAction: classes.balanceListAction }}>
      <ListItemText
        classes={{ root: classes.balanceListOption }}
        primary={ primary }
      />
      <ListItemSecondaryAction classes={{ root: classes.balanceListAction }}>
        <Currency value={ amount }/>
      </ListItemSecondaryAction>
    </ListItem>
  )

};

const CheckoutBalance = ({ subtotal, discount, total, ...rest }) => {

  const classes = useStyles();

  return (
    <Box>
      <Typography gutterBottom variant="h6">
        Balance
      </Typography>
      <Box className={ classes.balanceListOptions }>
        <List
          component="div"
          dense
          disablePadding
        >

          <Item
            primary="Subtotal"
            amount={ subtotal }
          />

          <Item
            primary="Descuento"
            amount={ discount }
          />

          <Item
            primary="Total"
            amount={ total }
          />

        </List>
      </Box>
    </Box>
  )

};

CheckoutBalance.propTypes = {
  subtotal: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default CheckoutBalance;
