import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, query) {
  return request({
    url:    `/store/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params:  query
  });
};

function find(id) {
  return request({
    url:    `/store/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function save(values) {
  return request({
    url:    '/store/save',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

function update(id, values) {
  return request({
    url:    `/store/update/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'PUT',
    data:   values
  });
};

function remove(id) {
  return request({
    url:    `/store/remove/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'DELETE'
  });
};

function filter(params) {
  return request({
    url:    '/store/filter',
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: params
  });
};

const StoreService = {
  list, find, save, update, remove, filter
};

export default StoreService;
