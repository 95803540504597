import React from 'react';
import { withStyles, Box } from './../../../assets/theme/default';
import { Tabs, Tab, Toolbar, Button, ButtonsGroup } from './../../../components/ui';
import { CategoryService } from './../../../services';

const styles = theme => ({
  root: {
    //display: 'flex',
    borderBottom: `1px solid ${ theme.palette.secondary.light }`,
    background: theme.palette.common.white
  },
  toolbar: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  left: {
    display: 'flex',
    //background: theme.palette.primary.light
  },
  right: {
    flex: 1,
    flexGrow: 1,
    width: '100%'
  },
  tabs: {
    background: theme.palette.primary.ultraLight
  },
  indicator: {
    //background: 'none'
  }
});

class Category__Selector extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      index: false,
      children: null,
      entities: [],
      currentCategories: [],

      currentCategory: null,
      recordCategories: []
    };

    this.rootRef = React.createRef();
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){

    /**
    * @Service
    */
    this._All();

  }

  /*
  ** @Service
  ** @Description: List all categories
  */

  _All = (page) => {

    CategoryService.all().then((response) => {

      this.setState({
        loading: true,
        entities: response.categories,
        currentCategories: response.categories,
        recordCategories: [response.categories]
      });

      // @Props
      // @Description: Send category to index
      if(response.categories.length > 0){
        // this.props.onChange(response.categories[0]);
      }

      if(this.props.onLoad){
        this.props.onLoad({
          ref: this.rootRef.current
        });
      }

    }).catch((error) => {


    });

  }

  // @Event
  // @Description: Reload page
  handleChange = (event: React.ChangeEvent<{}>, index: number) => {

    // @Description: Category selected
    const category = this.state.currentCategories[index];

    // @If
    // @Description: Select index when children not exist
    if(!category.children){
      this.setState({ index: index });
    }

    // @If
    // @Description: Select index when children exist and length is 0
    if(category.children && category.children.length === 0){
      this.setState({ index: index, currentCategory: category });
    }

    // @If
    // @Description: Check if category has children
    if(category.children && category.children.length > 0){
      this.setState({ currentCategories: category.children, currentCategory: category });
    }

    // @Props
    // @Description: Send category to index
    this.props.onChange(category);

  }

  // @Event
  // @Description: Close selector
  handleClose = () => {
    if(this.props.onClose){
      this.props.onClose();
    }
  }

    // @Event
    // @Description: Back into categories
    handleBack = (event: React.ChangeEvent<{}>, index: number) => {
      // this.setState({
      //   currentCategories: this.state.recordCategories[this.state.recordCategories.length - 2],
      //   recordCategories: this.state.recordCategories.pop()
      // });
    }


  render() {

    const { classes } = this.props;
    const { currentCategories, currentCategory, index } = this.state;

    return(
      <Box className={ classes.root } ref={ this.rootRef }>

        <Box className={ classes.toolbar }>
          <Toolbar
            title="Categoría"
            subtitle={ currentCategory ? currentCategory.name : 'Todas' }
            size="small"
            direction="row"
            disableMargin
          >
            <ButtonsGroup>
              <Button
                icon="back"
                size="medium"
                color="secondary"
                disabled={ true }
              />
              <Button
                icon="cancel"
                size="medium"
                color="danger"
                onClick={ this.handleClose }
              />
            </ButtonsGroup>
          </Toolbar>
        </Box>

        {currentCategories.length > 0 && (

          <Tabs
            value={ index }
            variant="scrollable"
            scrollButtons="on"
            classes={{ root: classes.tabs, indicator: classes.indicator }}
            onChange={ this.handleChange }
          >
            { currentCategories.map(category => {
              return(
                <Tab
                  key={ category._id }
                  label={ category.name }
                />
              )
            })}
          </Tabs>

        )}

      </Box>
    )
  }

}

export default withStyles(styles)(Category__Selector);
