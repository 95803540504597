import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, Modal, Table, Row, Cell, Date, LinkClient } from './../../../../components/ui';
import { FormService } from './../../../../services';
import { isUndefined, parseQueryString } from './../../../../utilities/utils';
import UtilFormRecordListFilter from './../filter';
import UtilRecordAnswer from './../answer';

import TEXT from './../../../../config/text';

class Page__FormRecordList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: false,
        title: '...',
        page: 0,
        total: 0,
        id: null,
        entities: [],
        entity: null,
        thead: [
          { id: 'client', name: 'Nombre/Apellido' },
          { id: 'created_at', name: 'Fecha' },
          { id: 'actions', name: 'Acciones', align: 'right' }
        ],
        formFilterShow: false,
        formAnswerShow: false
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.id !== prev_props.match.params.id || this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.id, this.props.match.params.page || 0);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.id, this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      const id   = this.props.match.params.id;
      const page = this.props.match.params.page || 0;

      if(!isUndefined(id) && !isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          this._All(id, page);
        }else{
          this.props.history.push('/forms');
        }
      }else{
        this.props.history.push('/forms');
      }

    }

    /*
    ** @Service
    ** @Description: FindAll
    */

    _All = (id, page) => {

      FormService.records(id, page, parseQueryString(this.props.location.search)).then((response) => {

        this.setState({
          loading: true,
          id: id,
          title: response.form.name,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.records,
          total: response.total
        });

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    }

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/forms/records/${this.state.id}/page/${ page }`,
        search: this.props.location.search
      });

    }

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ formFilterShow: true });
    }

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ formFilterShow: false });
    }

    /*
    ** @Event
    ** @Description: Show Answer
    */

    handleAnswer = (entity) => {
      this.setState({ formAnswerShow: true, entity: entity });
    }

    /*
    ** @Event
    ** @Description: Close Answer
    */

    handleAnswerClose = () => {
      this.setState({ formAnswerShow: false });
    }

    render(){

      const { loading, id, title, entities, entity, thead, total, page, formFilterShow, formAnswerShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar title={ title } subtitle="Listado de registros" dividerMobile>
              <ButtonsGroup>
                <Button
                  size="medium"
                  component={ NavLink }
                  to="/forms"
                  icon="back"
                >
                  Volver
                </Button>
                { this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title="Eliminar filtros"
                    placement="left"
                    component={ NavLink }
                    to={`/forms/records/${id}`}
                  />
                )}
                <Button
                  icon="filter"
                  size="medium"
                  onClick={ this.handleFilter }
                >
                  Filtrar
                </Button>
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

              <Modal
                  status={ formFilterShow }
                  title="Filtrar registros"
                  handleCancel={ () => this.handleFilterClose() }
                  maxWidth="sm">
                  <UtilFormRecordListFilter id={id} page={page} onSuccess={ this.handleFilterClose }/>
              </Modal>

              { entity && (
                <Modal
                    status={ formAnswerShow }
                    title={ entity.client ? `${ entity.client.name } ${ entity.client.surname }` : 'Anónimo' }
                    handleCancel={ () => this.handleAnswerClose() }
                    maxWidth="sm"
                >
                    <UtilRecordAnswer entity={entity}/>
                </Modal>
              )}

              <Table
                thead={ thead }
                loading={ loading }
                total={ total }
                page={ page }
                onChangePage={ this.handleChangePage }
              >

                { entities.length > 0 && (

                  entities.map(entity => {
                    return (
                      <Row key={ entity._id }>
                        <Cell>
                          { entity.client ? (
                            <LinkClient client={ entity.client }/>
                          ) : (
                            <span>[Anónimo]</span>
                          )}
                        </Cell>
                        <Cell>
                          <Date value={ entity.created_at } />
                        </Cell>
                        <Cell align="right">
                          <Button
                            icon="answers"
                            title="Respuestas"
                            onClick={ () => this.handleAnswer(entity) }
                          />
                        </Cell>
                      </Row>
                    )
                  })

                )}

                { (loading && entities.length === 0) && (
                  <Row>
                    <Cell colSpan={ thead.length } align="center">
                      { TEXT.message.without_results }
                    </Cell>
                  </Row>
                )}

              </Table>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page__FormRecordList);
