import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper, Toolbar, Button, Table, Row, Cell, Date, Tag, LinkUser, Modal, AlertConfirm } from './../../../../components/ui';
import { isStatusColor, isSorting, isShippingOrderEvent } from './../../../../utilities/utils';
import { ShippingService } from './../../../../services';
import ShippingEventForm from './../form';

import TEXT from './../../../../config/text';

class ShippingEventList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      subtitle: null,
      loading: false,
      id: null,
      entity: null,
      thead: [
        { id: 'root', name: '' },
        { id: 'eta', name: 'ETA' },
        { id: 'location', name: 'Lugar' },
        { id: 'transport', name: 'Transporte', nowrap: true },
        { id: 'journey', name: 'Viaje' },
        { id: 'etd', name: 'ETD' },
        { id: 'enabled', name: 'Estado' },
        { id: 'user', name: 'Usuario' },
        { id: 'updated_at', name: 'Fecha' },
        { id: 'actions', name: 'Acciones', align: 'right' }
      ],
      remove: false,
      currentEventValues: null,
      eventFormShow: false
    };
  };

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  };

  /**
  * @Event
  * @Description: Is invoked immediately after a component is updated.
  */
  componentDidUpdate(prevProps, prevState){
    if(this.state.entity !== prevState.entity && this.props.actions === true){
      let new_eta;
      this.state.entity.events.forEach((evento) => {
        if(!new_eta || new_eta <= evento.etd){
          new_eta=evento.etd;
        }
      });
      this.props.updateMinETA(new_eta);
      }
  };

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {
    this._Find();
  };

  /*
  ** @Service
  ** @Description: Find Entity
  */

  _Find = () => {

    const id = this.props.match.params.id;

    ShippingService.find(id).then((response) => {

      // @State
      // @Description: Set order into state
      this.setState({
        loading: true,
        id: response.order._id,
        entity: response.order
      });

    }).catch((error) => {

      // @State
      // @Description:
      this.setState({ loading: false });

      // @Dispatch alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: error.data ? error.data.message : TEXT.message.error
      });

    });

  };

  /*
  ** @Service
  ** @Description: Remove event
  */

  _Remove = (id) => {

    ShippingService.removeEvent(this.state.entity._id, {eventId: id}).then((response) => {

      // @State
      this.setState({ remove: false, entity: {
        ...this.state.entity,
        events: response.events
      }});

      // @Dispatch alert success
      this.props.alertShow({
        open: true,
        severity: 'success',
        message: 'El proceso se ejecutó correctamente.'
      });

    }).catch((error) => {

      // @State
      this.setState({ remove: false });

      // @Dispatch alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: 'El proceso no se ejecutó correctamente.'
      });

    });

  };

  /*
  ** @Event
  ** @Description: On Success Event Form
  */

  handleOnSuccessEventForm = (events) => {
    this.setState({ entity: {
      ...this.state.entity,
      events: events,
      currentEventValues: null
    }});
    this.handleEventFormClose();
  };

  /*
  ** @Event
  ** @Description: Show Form
  */

  handleEventFormShow = () => {
    this.setState({ eventFormShow: true });
  };

  /*
  ** @Event
  ** @Description: Close Form
  */

  handleEventFormClose = () => {
    this.setState({ eventFormShow: false, currentEventValues: null });
  };

  /*
  ** @Event
  ** @Description: Remove event
  */

  handleEventRemoveShow = (id) => {
    this.setState({ id: id, remove: true });
  };

  /*
  ** @Event
  ** @Description: Remove event confirm
  */

  handleConfirmRemoveConfirm = () => {
    this._Remove(this.state.id);
  };

  /*
  ** @Event
  ** @Description: Remove category cancel
  */

  handleConfirmRemoveCancel = () => {
    this.setState({ remove: false });
  };

  /*
  ** @Event
  ** @Description: Remove event
  */

  handleEventEditShow = (index) => {

    this.setState({ currentEventValues: this.state.entity.events[index] });

    this.handleEventFormShow();

  };

  render() {

    const { actions, form } = this.props;
    const { loading, thead, remove, entity, eventFormShow, currentEventValues } = this.state;

    return(
      <React.Fragment>
        <Toolbar
          title=""
          size="small"
        >
          { actions && (
            <Button icon="add" onClick={this.handleEventFormShow} disabled={form && (!form.eta_origin || !form.eta)}>
              Agregar trasbordo ó escala
            </Button>
          )}
        </Toolbar>
        <Paper disablePadding>

          {/* Alert confirm Delete */}
          <AlertConfirm
             status={ remove }
             message="¿Confirma la eliminación?"
             cancel={() => this.handleConfirmRemoveCancel()}
             confirm={() => this.handleConfirmRemoveConfirm()}
          />

          {/* Form new event */}
          <Modal
            status={eventFormShow}
            title="Trasbordo ó escala"
            handleCancel={() => this.handleEventFormClose()}
            maxWidth="sm"
          >
            <ShippingEventForm
              orderId={entity ? entity._id : null}
              eventValues={currentEventValues}
              onSuccess={this.handleOnSuccessEventForm}
              etdFrom={(form && form.etd) ? form.etd : null}
              etaTo={(form && form.eta) ? form.eta : null}
              entityEvents={entity ? entity.events : null}
            />
          </Modal>

          <Table thead={ thead } loading={ loading }>

            {/* From */}
            { (form && (form.eta_origin || form.from)) && (
              <Row>
                <Cell color="primary"><strong>Origen</strong></Cell>
                <Cell color="primary" nowrap>{form.eta_origin ? <Date value={form.eta_origin} /> : TEXT.message.not_available}</Cell>
                <Cell color="primary" nowrap>
                  { form.from ? (
                    <React.Fragment>
                      {form.from.name}
                      {form.from.country && (
                        <React.Fragment>
                          &nbsp;[{form.from.country.alpha2Code}]
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (
                    TEXT.message.not_available
                  )}
                </Cell>
                <Cell color="primary" nowrap>
                  {form.vehicle ? form.vehicle : TEXT.message.not_available}
                </Cell>
                <Cell color="primary" nowrap>
                  {form.journey ? form.journey : TEXT.message.not_available}
                </Cell>
                <Cell color="primary" nowrap>{form.etd ? <Date value={form.etd} /> : TEXT.message.not_available}</Cell>
                <Cell color="primary">
                  <Tag
                    text={ isShippingOrderEvent(form.from_status) }
                    color={ isStatusColor(form.from_status) }
                  />
                </Cell>
                <Cell color="primary">{form.eta_updated_by ? `${form.eta_updated_by.name} ${form.eta_updated_by.surname}` : `-`}</Cell>
                <Cell color="primary">{form.eta_update ? <Date value={form.eta_update} format="DD/MM/YYYY HH:mm [hs.]"/> : `-`}</Cell>
                <Cell color="primary" align="right" nowrap>&nbsp;</Cell>
              </Row>
            )}

            {/* Events */}
            { (entity && entity.events && entity.events.length > 0) && (
              entity.events.sort(isSorting('asc', 'eta')).map((event, index) => {
                return (
                  <Row key={ event._id }>
                    <Cell nowrap>
                      <strong>{`Trasbordo ${index+1}`}</strong>
                    </Cell>
                    <Cell>
                      <Date value={ event.eta } />
                    </Cell>
                    <Cell nowrap>
                      { event.location ? (
                        <React.Fragment>
                          {event.location.name}
                          {event.location.country && (
                            <React.Fragment>
                              &nbsp;[{event.location.country.alpha2Code}]
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>-</React.Fragment>
                      )}
                    </Cell>
                    <Cell>{event.vehicle ? event.vehicle : '-'}</Cell>
                    <Cell>{event.journey ? event.journey : '-'}</Cell>
                    <Cell><Date value={event.etd} /></Cell>
                    <Cell>
                      <Tag
                        text={ isShippingOrderEvent(event.enabled) }
                        color={ isStatusColor(event.enabled) }
                      />
                    </Cell>
                    <Cell>
                      { event.user ? (
                        <LinkUser user={event.user}/>
                      ) : (
                        <React.Fragment>-</React.Fragment>
                      )}
                    </Cell>
                    <Cell><Date value={event.updated_at} /></Cell>
                    <Cell align="right" nowrap>
                      { actions && (
                        <React.Fragment>
                          <Button
                            color="primary"
                            icon="edit"
                            title="Editar"
                            onClick={() => this.handleEventEditShow(index)}
                          />
                          <Button
                            color="danger"
                            icon="cancel"
                            title="Eliminar"
                            onClick={() => this.handleEventRemoveShow(event._id)}
                          />
                        </React.Fragment>
                      )}
                    </Cell>
                  </Row>
                )
              })
            )}

            {/* No results */}
            { (loading && (entity && entity.events.length === 0)) && (
              <Row>
                <Cell colSpan={ thead.length } align="center">
                  Servicio directo (sin transbordos/escalas)
                </Cell>
              </Row>
            )}

            {/* To */}
            { (form && (form.eta || form.to)) && (
              <Row>
                <Cell color="primary"><strong>Destino</strong></Cell>
                <Cell color="primary" nowrap>{form.eta ? <Date value={form.eta} /> : TEXT.message.not_available}</Cell>
                <Cell color="primary" nowrap>
                  { form.to ? (
                    <React.Fragment>
                      {form.to.name}
                      { form.to.country && (
                        <React.Fragment>
                          &nbsp;[{form.to.country.alpha2Code}]
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (
                    TEXT.message.not_available
                  )}
                </Cell>
                <Cell color="primary">
                  { (entity && entity.events.length > 0) ? (
                    <React.Fragment>
                      { entity.events[entity.events.length-1].vehicle }
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {form.vehicle ? form.vehicle : TEXT.message.not_available}
                    </React.Fragment>
                  ) }
                </Cell>
                <Cell color="primary">
                  { (entity && entity.events.length > 0) ? (
                    <React.Fragment>
                      { entity.events[entity.events.length-1].journey }
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {form.journey ? form.journey : TEXT.message.not_available}
                    </React.Fragment>
                  ) }
                </Cell>
                <Cell color="primary">-</Cell>
                <Cell color="primary">
                  <Tag
                    text={ isShippingOrderEvent(form.to_status) }
                    color={ isStatusColor(form.to_status) }
                  />
                </Cell>
                <Cell color="primary">{form.etd_updated_by ? `${form.etd_updated_by.name} ${form.etd_updated_by.surname}` : `-`}</Cell>
                <Cell color="primary">{form.etd_update ? <Date value={form.etd_update} format="DD/MM/YYYY HH:mm [hs.]"/> : `-`}</Cell>
                <Cell color="primary" align="right" nowrap>&nbsp;</Cell>
              </Row>
            )}

          </Table>

        </Paper>
      </React.Fragment>
    )
  }

};

ShippingEventList.propTypes = {
  onOpen: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShippingEventList));
