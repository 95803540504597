import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Box, Typography } from './../../../assets/theme/default';
import { PaymentApprovedIcon, PaymentRejectedIcon } from './../../../assets/images';
import { Container } from './../../../assets/theme/layout';
import { Image, Loading } from './../../../components/ui';

import API from './../../../config/api';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    position: 'relative'
  },
  teaser: {
    background: API.palette.primary.main
  },
    teaserContainer: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(12),
      textAlign: 'center'
    },
  contentSuccess: {
    marginTop: -55
  },
  contentError: {
    marginTop: theme.spacing(2)
  },
    containerIcon: {
      textAlign: 'center'
    },
      icon: {
        width: 110
      },
    containerText: {
      textAlign: 'center',
    },
      title: {
        fontSize: 28,
        lineHeight: 1.2,
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing(2)
      },
        titleSuccess: {
          color: '#63c2a6'
        },
        titleError: {
          color: '#f03546'
        },
      text: {
        fontSize: 18
      },
    containerButton: {
      textAlign: 'center',
      marginTop: theme.spacing(3)
    },
      button: {
        textDecoration: 'none',
        display: 'inline-block',
        border: 'none',
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        color: API.palette.primary.contrast,
        background: API.palette.primary.main,
        fontSize: 18,
        '&:hover': {
          background: API.palette.primary.dark
        }
      }
}));

const CheckoutNotification = ({ loading, payment }) => {

  const classes = useStyles();

  return(
    <Box className={ classes.root }>

      { loading ? (

        <React.Fragment>

          {/* Teaser */}
          <Box className={ classes.teaser }>
            <Container maxWidth="sm">
              <Box className={ classes.teaserContainer }>
                <Image src={ `/resources/images/logo-notification.png` } alt={ API.client.name }/>
              </Box>
            </Container>
          </Box>

          {/* Content */}
          <Box className={ classNames(
            classes.content,
            payment ? classes.contentSuccess : classes.contentError
          )}>
            <Container maxWidth="sm">

              <Box className={ classes.contentContainer }>

                { payment ? (

                  <React.Fragment>
                    <Box className={ classes.containerIcon }>

                      { payment.payment_status === 'approved' && (
                        <Image src={ PaymentApprovedIcon } alt="approved" className={ classes.icon } />
                      )}

                      { payment.payment_status === 'rejected' && (
                        <Image src={ PaymentRejectedIcon } alt="approved" className={ classes.icon } />
                      )}

                    </Box>

                    <Box className={ classes.containerText }>

                      { payment.payment_status === 'approved' && (
                        <Typography classes={{ root: classNames(classes.title, classes.titleSuccess) }}>
                          ¡LISTO!
                          <br/>
                          Se acreditó tu pago
                        </Typography>
                      )}

                      { payment.payment_status === 'rejected' && (
                        <React.Fragment>
                          <Typography classes={{ root: classNames(classes.title, classes.titleError) }}>
                            El pago fue rechazado
                          </Typography>
                          <Typography classes={{ root: classes.text }}>
                            LO SENTIMOS, LA OPERACIÓN FUE RECHAZADA.
                          </Typography>
                        </React.Fragment>
                      )}

                      <Typography classes={{ root: classes.text }}>
                        <strong>Código de transacción</strong>: { payment.payment_code }
                      </Typography>

                    </Box>

                    { payment.payment_status === 'approved' && (
                      <Box className={ classes.containerButton }>
                        <a href={ API.endpoint.web } className={ classes.button } title="CONTINUAR">
                          CONTINUAR
                        </a>
                      </Box>
                    )}
                  </React.Fragment>

              ) : (

                <Typography classes={{ root: classes.text }} align="center">
                  LO SENTIMOS, LA OPERACIÓN INDICADA NO FUE ENCONTRADA.
                </Typography>

              )}

              </Box>

            </Container>
          </Box>

        </React.Fragment>

      ) : (

        <Loading />

      )}

    </Box>
  )

}

CheckoutNotification.propTypes = {
  loading: PropTypes.bool,
  payment: PropTypes.object
};

export default CheckoutNotification;
