import React from 'react';
import { Route } from 'react-router-dom';
import { makeStyles, Box } from './../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    //background: theme.palette.secondary.ultraLight
  },
  container: {
    flex: 1,
    display: 'flex'
  }
}));

const Layout = ({children, ...rest}) => {

  const classes = useStyles();

  return (
    <React.Fragment>
      <Box className={ classes.root }>
        { children }
      </Box>
    </React.Fragment>
  )

}

const Frontend = ({ component: Component, ...rest }) => {
  return (
    <Route { ...rest } render={ matchProps => (
      <Layout>
          <Component { ...matchProps } />
      </Layout>
    )} />
  )
}

export default Frontend;
