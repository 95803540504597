import React, {useState} from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { makeStyles, Avatar, Popper, Grow, ClickAwayListener, Divider } from './../../../../assets/theme/default';
import { ButtonBase } from './../../../../assets/theme/button';
import { MenuList, MenuItem } from './../../../../assets/theme/input';
import { Dropdown } from './../../../../components/ui';

import API from './../../../../config/api';

const useStyles = makeStyles(theme => ({
  avatar: {
     width: theme.spacing(5),
     height: theme.spacing(5),
   }
}));

const Menu = ({ session, signout }) => {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  /*
  ** @Event
  ** @Description: Toggle Profile Options
  */

  const handleprofileOptionsToggle = () => {
    setOpen(!open);
  };

  /*
  ** @Event
  ** @Description: Close Profile Options
  */

  const handleprofileOptionsClose = event => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  /*
  ** @Event
  ** @Description: SignOut
  */
  const handleSignOut = () => {

    // @localStorage
    localStorage.clear();

    // @Dispatch
    signout();

  };

  return(
    <React.Fragment>
      <ButtonBase
        disableRipple
        disableTouchRipple
        buttonRef={node => { setAnchorEl(node); }}
        aria-owns={open ? 'menu-list-grow' : null}
        aria-haspopup={true}
        onClick={handleprofileOptionsToggle}
      >
        <Avatar className={classes.avatar} src={session.user.image ? `${ API.endpoint.uploads }${ session.user.image }` : undefined}>
          { session.user.name.slice(0, 1).toUpperCase() }{ session.user.surname.slice(0, 1).toUpperCase() }
        </Avatar>
      </ButtonBase>

      <Popper open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Dropdown minWidth={190}>
              <ClickAwayListener onClickAway={handleprofileOptionsClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem component={NavLink} onClick={handleprofileOptionsClose} to="/me">Mi perfil</MenuItem>
                    <Divider/>
                    <MenuItem onClick={handleSignOut}>Cerrar sesión</MenuItem>
                  </MenuList>
              </ClickAwayListener>
            </Dropdown>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    signout: () => {
      dispatch({
        type: 'USER_CLEAR'
      });
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
