import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { makeStyles, Typography } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: 1,
    whiteSpace: 'nowrap'
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  removed: {
    color: theme.palette.secondary.main
  }
}));

const LinkClient = ({ client, ...rest }) => {

  const classes  = useStyles();
  let removed;

  if(client){
    removed  = client.removed ? classes['removed'] : null;
  }

  return (
    client ? (
      <Typography
        className={ classNames(
          classes.root,
          classes.link,
          removed
        )
        }
        component={ NavLink }
        to={ `/clients/profile/${ client._id }` }
      >
        { client.name } { client.surname }
      </Typography>
    ) : (
      <React.Fragment>
        <Typography className={ classes.root }>
          [No disponible]
        </Typography>
      </React.Fragment>
    )
  )

};

LinkClient.propTypes = {
  client: PropTypes.object
};

export default LinkClient;
