import React from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change  } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Content, Button, ButtonsGroup } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { stringifyQueryString, parseQueryString } from './../../../utilities/utils';

const validate = values => {

  const errors = {}

  return errors;

}

class PageBlogFilter extends React.Component {

  componentDidMount(){

    const search = parseQueryString(this.props.location.search);

    Object.entries(search).forEach(([key,value]) => {
      this.props.dispatch(change('PageBlogFilter', key, value));
    });

  }

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {
    this.props.history.push(`/blog?${ stringifyQueryString(values) }`);
    this.props.onSuccess();
  }

  render() {

    const { handleSubmit } = this.props;

    return(

      <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

        <Content disablePadding divider>
          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="title"
                  component={ InputTextField }
                  label="Título"
                  type="text"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="enabled"
                  component={ SelectField }
                  label="Estado">
                    <OptionField value="none">Todos</OptionField>
                    <OptionField value="true">Activo</OptionField>
                    <OptionField value="false">Bloqueado</OptionField>
              </Field>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="featured"
                  component={ SelectField }
                  label="Destacada">
                    <OptionField value="none">Todos</OptionField>
                    <OptionField value="true">Activo</OptionField>
                    <OptionField value="false">Bloqueado</OptionField>
              </Field>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                  name="views"
                  component={ SelectField }
                  label="Visitas">
                    <OptionField value="none">Todos</OptionField>
                    <OptionField value="desc">Mayor a menos</OptionField>
                    <OptionField value="asc">Menor a mayor</OptionField>
              </Field>
            </Grid>
          </Grid>
        </Content>

        <Content disablePadding>
          <ButtonsGroup align="right">
            <Button aria-label="Aplicar" icon="save" color="primary" type="submit">
              Aplicar
            </Button>
          </ButtonsGroup>
        </Content>

      </form>

    )
  }

}

PageBlogFilter = reduxForm({
  form: 'PageBlogFilter',
  validate,
  initialValues: {
    enabled: 'none',
    featured: 'none'
  },
  enableReinitialize: true
})(PageBlogFilter);

export default withRouter(PageBlogFilter);
