let TEXT;
let user;
try{
 user = JSON.parse(localStorage.getItem('user'))
}catch(e){
  console.log(e)
}
if(user && user.language === 'es'){
 TEXT = {
  validation: {
    required:            'Campo requerido',
    email_invalid:       'Email inválido',
    number_invalid:      'El valor debe ser numérico',
    slug_invalid:        'Ingresa únicamente letras, números o guiones medios',
    minlength:           (value) => `El mínimo debe ser ${ value }`,
    maxlength:           (value) => `El máximo debe ser ${ value }`,
    mismatched_password: 'Las contraseñas no coinciden',
    wrong_password:      'La contraseña no cumple el patron necesario'
  },
  message: {
    pattern_password:    '6 a 10 caracteres, al menos 1 mayuscula y al menos 1 número',
    without_results:     'Atención, no pudimos encontrar resultados.',
    entity_not_founded:  'Atención, no pudimos encontrar un resultado para su búsqueda.',
    success:             'El proceso se ejecutó correctamente.',
    error:               'Atención, hubo un error interno.',
    fields_not_founded:  'Atención, no se encontraron campos personalizados disponibles.',
    not_available:       '[No disponible]'
  }
}
}else{
  TEXT = {
   validation: {
     required:            'Required field',
     email_invalid:       'Invalid Email',
     number_invalid:      'Value must be numeric',
     slug_invalid:        'Enter only letters, numbers, or hyphens',
     minlength:           (value) => `Minimum value must be ${ value }`,
     maxlength:           (value) => `Maximum value must be ${ value }`,
     mismatched_password: 'Passwords do not match',
     wrong_password:      'The password does not meet the necessary pattern'
   },
   message: {
     pattern_password:    '6 to 10 characters, at least 1 uppercase and at least 1 number',
     without_results:     `Attention, we couldn't find any results.`,
     entity_not_founded:  `Attention, we couldn't find a result for your search.`,
     success:             'The process ran successfully.',
     error:               'Attention, there was an internal error.',
     fields_not_founded:  'Attention, no custom fields were found available.',
     not_available:       '[Not available]'
   }

}
}


export default TEXT;
