import React from 'react';
import { Content, Alert, Table, Row, Cell, Tag, Currency, LinkClient, LinkTPPreference } from './../../../../../components/ui';
import { TodoPagoService } from './../../../../../services';
import { isPaymentStatus } from './../../../../../utilities/payment';

import TEXT from './../../../../../config/text';

class UtilTPPreferenceList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      error: false,
      entities: [],
      thead: [
        { id: 'preference_code', name: 'Código' },
        { id: 'client', name: 'Cliente' },
        { id: 'total', name: 'Total' },
        { id: 'payment_status', name: 'Estado' }
      ]
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  }

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {
    this._All(0);
  }

  /*
  ** @Service
  ** @Description: List
  */

  _All = (page) => {

    TodoPagoService.preferencesList(page).then((response) => {

      // @State
      // @Description: Set response
      this.setState({
        loading: true,
        entities: response.preferences
      });

    }).catch((error) => {

      // @State
      // @Description: Set error
      this.setState({ error: true });

    });

  }

  render(){

    const { loading, error, entities, thead } = this.state;

    return(

      !error ? (
        <Table
          thead={ thead }
          loading={ loading }
        >

          { entities.length > 0 && (
            entities.map(entity => {
              return (
                <Row key={ entity._id }>
                  <Cell>
                    <LinkTPPreference preference={ entity } />
                  </Cell>
                  <Cell>
                    <LinkClient client={ entity.client }/>
                  </Cell>
                  <Cell>
                    <Currency value={ entity.total }/>
                  </Cell>
                  <Cell>
                    <Tag
                      text={ isPaymentStatus(entity.payment_status).name }
                      color={ isPaymentStatus(entity.payment_status).color }
                    />
                  </Cell>
                </Row>
              )
            })
          )}

          { (loading && entities.length === 0) && (
            <Row>
              <Cell colSpan={ thead.length } align="center">
                { TEXT.message.without_results }
              </Cell>
            </Row>
          )}

        </Table>
      ) : (
        <Content>
          <Alert severity="info">
            <strong>Atención:</strong> Hubo un error al solicitar la información.
          </Alert>
        </Content>
      )

    )

  }

}

export default UtilTPPreferenceList;
