import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, AlertConfirm, Table, Row, Cell, Tag, Modal } from './../../../components/ui';
import { StoreService } from './../../../services';
import { isUndefined, isStatus, isStatusColor, hasPermission, findPermissionProps, parseQueryString } from './../../../utilities/utils';
import PageStoreFilter from './../filter';

import API from './../../../config/api';
import TEXT from './../../../config/text';

class Page__StoreList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        loading: false,
        page: 0,
        total: 0,
        id: null,
        entities: [],
        remove: false,
        thead: [
          { id: 'name', name: this.props.t('field.name') },
          { id: 'country', name: this.props.t('field.country') },
          { id: 'email', name: this.props.t('field.email') },
          { id: 'enabled', name: this.props.t('field.status') },
          { id: 'actions', name: this.props.t('field.actions'), align: 'right' }
        ],
        storeFilterShow: false
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.match.params.id !== prev_props.match.params.id){
        this._All(this.props.match.params.page || 0, this.props.match.params.id);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.store, this.props.t('text.stores')) });

      const page = this.props.match.params.page || 0;
      const id   = this.props.match.params.id;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          if(id){
            this._All(page, id);
          }else{
            this._All(page);
          }
        }else{
          this.props.history.push('/stores');
        }
      }else{
        this._All(this.state.page);
      }

    };

    /*
    ** @Service
    ** @Description: All categories
    */

    _All = (page, id) => {

      // @State
      // @Description: Refresh after each service
      this.setState({ loading: false, entities: [] });

      // @Service
      StoreService.list(page, parseQueryString(this.props.location.search)).then((response) => {

        // @State
        // @Descripcion: Set values
        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.stores,
          total: response.total
        });

      }).catch((error) => {

        // @State
        // @Descripcion: Loading disabled
        this.setState({ loading: true });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Remove Store
    */

    _Remove = (id) => {

      StoreService.remove(id).then((response) => {

        // @State
        this.setState({ remove: false });

        // @Service find all by page
        this._Init();

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('messages.store_deleted')
        });

      }).catch((error) => {

        // @State
        this.setState({ remove: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : this.props.t('messages.store_deleted_error')
        });

      });

    };

    /*
    ** @Event
    ** @Description: Remove category
    */

    handleRemove = (id) => {
      this.setState({ id: id, remove: true });
    };

    /*
    ** @Click
    ** @Description: Remove category confirm
    */

    handleConfirmRemoveConfirm = () => {
      this._Remove(this.state.id);
    };

    /*
    ** @Click
    ** @Description: Remove category cancel
    */

    handleConfirmRemoveCancel = () => {
      this.setState({ remove: false });
    };

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/stores/page/${ page }`,
        search: this.props.location.search
      });

    };

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ storeFilterShow: true });
    }

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ storeFilterShow: false });
    }

    render(){

      const { session, t } = this.props;
      const { permission, name, loading, entities, thead, total, page, remove, storeFilterShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <ButtonsGroup>

                {this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title="Eliminar filtros"
                    placement="left"
                    component={NavLink}
                    to="/stores"
                  />
                )}

                <Button
                  icon="filter"
                  size="medium"
                  onClick={this.handleFilter}
                >
                  Filtrar
                </Button>

                { hasPermission(session.user.permissions, API.permissions.blog_management) && (
                  <Button
                    component={ NavLink }
                    to="/stores/create"
                    icon="add"
                    size="medium"
                  >
                    {t('button.create')}
                  </Button>
                )}
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

                {/* Alert confirm Delete */}
                <AlertConfirm
                   status={ remove }
                   message={t('message.delete_confirm')}
                   cancel={ () => this.handleConfirmRemoveCancel() }
                   confirm={ () => this.handleConfirmRemoveConfirm() }
                />

                {/* Filter */}
                <Modal
                    status={ storeFilterShow }
                    title="Filtrar"
                    handleCancel={ () => this.handleFilterClose() }
                    maxWidth="sm">
                    <PageStoreFilter onSuccess={ this.handleFilterClose }/>
                </Modal>

                <Table
                  thead={ thead }
                  loading={ loading }
                  total={ total }
                  page={ page }
                  onChangePage={ this.handleChangePage }
                >

                  { entities.length > 0 && (

                    entities.map(entity => {
                      return (
                        <Row key={ entity._id }>
                          <Cell nowrap>
                            { entity.name }
                          </Cell>
                          <Cell nowrap>
                            {(entity.country && entity.country.name && entity.country.prefix) ? (
                              <span>
                                { entity.country.name } [{ entity.country.prefix}]
                              </span>
                            ) : (
                              <span>[No disponible]</span>
                            )}
                          </Cell>
                          <Cell nowrap>
                            { entity.email }
                          </Cell>
                          <Cell>
                            <Tag
                              text={ isStatus(entity.enabled) }
                              color={ isStatusColor(entity.enabled) }
                            />
                          </Cell>
                          <Cell align="right" nowrap>
                            { hasPermission(session.user.permissions, API.permissions.store_management) && (
                              <React.Fragment>
                                <Button
                                  color="primary"
                                  icon="edit"
                                  component={ NavLink }
                                  title={t('button.edit')}
                                  to={ `/stores/edit/${ entity._id }` }
                                />
                                <Button
                                  color="danger"
                                  icon="cancel"
                                  title={t('button.delete')}
                                  onClick={ () => this.handleRemove(entity._id)  }
                                />
                              </React.Fragment>
                            )}
                          </Cell>
                        </Row>
                      )
                    })

                  )}

                  { (loading && entities.length === 0) && (
                    <Row>
                      <Cell colSpan={ thead.length } align="center">
                        {t('messages.no_results_found')}
                      </Cell>
                    </Row>
                  )}

                </Table>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__StoreList));
