import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Drawer, AppBar, Toolbar, Box } from './../../../../assets/theme/default';
import { Button, Image } from './../../../ui';
import { LogoWhite } from './../../../../assets/images';

import Menu from './../menu';

const styles = theme => ({
  root:             { position: 'fixed', left: 0, top: 0, bottom: 0, width: 240, zIndex: 9 },
  rootPaper:        { position: 'relative', width: 240, background: theme.palette.navigation.main, borderRight: `1px solid ${ theme.palette.navigation.light }` },
  appBar:           { background: 'none', boxShadow: theme.shadows[0], borderBottom: `1px solid ${ theme.palette.navigation.light }` },
  toolbar:          { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) },
  brand:            {
                      width: 120,
                      [theme.breakpoints.down('sm')]: {
                        display: 'none'
                      }
                    },
  closeButton : {
                    [theme.breakpoints.up('sm')]: {
                      display: 'none'
                    }
  }
});

class Navigation extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      variant: 'permanent'
    };

    // @Window dimensions
    this.handleupdateWindowDimensions = this.handleupdateWindowDimensions.bind(this);

  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component will recive props.
  */
  componentDidMount(){

    // @Window
    // @Description: Check current dimensions
    this.handleupdateWindowDimensions();

    // @Window
    // @Description: Bind resize
    window.addEventListener("resize", this.handleupdateWindowDimensions.bind(this));

  }

  /*
  ** @Event
  ** @Description: Resize
  */
  handleupdateWindowDimensions() {
    if( window.innerWidth >= 960 ){

      this.setState({ variant: 'permanent' });

      this.props.navigationToggle( { open: false } );

    }else{
      this.setState({ variant: 'temporary' });
    }
  }

  /*
  ** @Event
  ** @Description: Close navigation
  */
  handleNavigationClose = () => {
    this.props.navigationToggle( { open: false } );
  };

  render(){

    const { classes, navigation } = this.props;
    const { variant } = this.state;

    return (
      <Drawer
        variant={ variant }
        open={ navigation.open }
        onClose={ this.handleNavigationClose }
        classes={{
          root: classes.root,
          paper: classes.rootPaper
        }}
      >

        <AppBar position="static" className={ classes.appBar }>
          <Toolbar classes={{root:classes.toolbar}}>
            <Box className={classes.closeButton}>
              <Button icon="cancel"  onClick={ this.handleNavigationClose }/>
            </Box>
            <Image src={ LogoWhite } alt="Firehub" className={ classes.brand }/>
          </Toolbar>
        </AppBar>

        {/* Menu Component */}
        <Menu/>

      </Drawer>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState,
    navigation: state.navigationState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    navigationToggle: (data) => {
      dispatch({
        type: 'NAVIGATION_TOGGLE',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Navigation));
