import React from 'react';
import classNames from 'classnames';
import { withStyles, makeStyles, Tooltip, Typography, Box } from './../../../../assets/theme/default';

const Tooltip__c = withStyles((theme) => ({
  arrow: {
      color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    color: theme.palette.text.primary,
    maxWidth: 'none',
    padding: 0,
    borderRadius: 4,
    transition: 'none !important'
  },
}))(Tooltip);

const TooltipContainerStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    fontSize: 12,
    overflow: 'auto'
  },
  disableGutters: {
    padding: 0
  }
}))

const TooltipContainer = ({ children, width = 'auto', height = 'auto', maxWidth = 'auto', maxHeight = 'auto', disableGutters = false }) => {
  const classes = TooltipContainerStyles()
  return (
    <Box className={classNames(classes.root,disableGutters ? classes.disableGutters : null)} style={{width:width,height:height,maxWidth:maxWidth,maxHeight:maxHeight}}>
      {children}
    </Box>
  )
}

const TooltipRowStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  row: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.grey[500],
    '& > span': {
      color: theme.palette.common.black
    }
  }
}))

const TooltipRow = ({ text, value }) => {
  const classes = TooltipRowStyles()
  return (
      <Typography classes={{ root: classes.row }}>{text}: <span>{value}</span></Typography>
  )
}

export default Tooltip__c;
export { TooltipContainer, TooltipRow }
