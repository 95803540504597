import { createStore, combineReducers, applyMiddleware } from 'redux';
import { reducer as formReducer } from 'redux-form';
import ReduxThunk from 'redux-thunk';

const user     = localStorage.getItem('user');
const token    = localStorage.getItem('token');
const settings = localStorage.getItem('settings');
const initialUserState = user && token ? { user: JSON.parse(user), status: false, token: token } : null;
const initialSettingsState = settings ? JSON.parse(settings) : null;

const initialNavigationState = {
  open: false
}

const initialAlertState = {
  open: false,
  severity: 'info',
  message: '',
  duration: 3000
};

const initialMpCheckoutState = {
  type:     'combo',
  client:   null,
  products: [],
  amount:   0,
  subtotal: 0,
  discount: 0,
  total:    0,
  step:     1,
  update:   Date.now()
}

const NavigationState = (state = initialNavigationState, action) => {

  let newState = Object.assign({}, state);

  switch(action.type){
    case 'NAVIGATION_TOGGLE':
      newState = action.data;
      return newState;
    default:
      return state;
  }

}

const AlertState = (state = initialAlertState, action) => {

  let newState = Object.assign({}, state);

  switch(action.type){
    case 'ALERT_SHOW':
      newState = action.data;
      return newState;
    case 'ALERT_HIDE':
      //newState = initialAlertState;
      newState = {
        ...state,
        open: false
      }
      return newState;
    default:
      return state;
  }

}

const UserState = (state = initialUserState, action) => {

  let newState = Object.assign({}, state);

  switch(action.type){
    case 'USER_SET':
      newState = action.data;
      return newState;
    case 'USER_CLEAR':
      newState = null;
      return newState;
    default:
      return state;
  }

}

const SettingsState = (state = initialSettingsState, action) => {

  let newState = Object.assign({}, state);

  switch(action.type){
    case 'SETTINGS_SET':
      newState = action.data;
      return newState;
    case 'SETTINGS_CLEAR':
      newState = null;
      return newState;
    default:
      return state;
  }

}

const SocketConnectionState = (state = {}, action) => {

  let newState = Object.assign({ status: false }, state);

  switch(action.type){
    case 'SOCKET_CONNECTION':
      newState = action.data;
      return newState;
    case 'SOCKET_CONNECTION_CLEAR':
      newState = {};
      return newState;
    default:
      return state;
  }

}

const AlertConnectionState = (state = { status: false }, action) => {

  let newState = Object.assign({}, state);

  switch(action.type){
    case 'ALERT_CONNECTION':
      newState = action.data;
      return newState;
    case 'ALERT_CONNECTION_CLEAR':
      newState = {};
      return newState;
    default:
      return state;
  }

}

const MPCheckoutState = (state = initialMpCheckoutState, action) => {

  let newState = Object.assign({}, state);

  switch(action.type){
    case 'MP_CHECKOUT_STEP':
      newState = {
        ...state,
        step: action.data
      }
      return newState;
    case 'MP_CHECKOUT_BALANCE':
      newState = {
        ...state,
        subtotal: action.data.subtotal,
        discount: action.data.discount,
        total: action.data.total
      }
      return newState;
    case 'MP_CHECKOUT_PRODUCT_ADD':
      let index = state.products.findIndex(p => p._id === action.data.product._id);
      if(index === -1){
        newState = {
          ...state,
          update: action.data.update,
          products: [...state.products, action.data.product]
        }
      }
      return newState;
    case 'MP_CHECKOUT_PRODUCT_REMOVE':
      let products = state.products.filter((v,i)=>i!==action.data.index);
      newState = {
        ...state,
        update: action.data.update,
        products: products
      }
      return newState;
    case 'MP_CHECKOUT_PRODUCT_QTY':
      newState = {
        ...state,
        update: action.data.update,
        products: state.products.map(
           (product, i) => i === action.data.index ? {...product, qty: action.data.qty}
                                   : product
       )
      }
      return newState;
    case 'MP_CHECKOUT_AMOUNT_ADD':
      newState = {
        ...state,
        update: action.data.update,
        subtotal: action.data.amount,
        total: action.data.amount
      }
      return newState;
    case 'MP_CHECKOUT_DISCOUNT_CLEAR':
      newState = {
        ...state,
        discount: 0
      }
      return newState;
    case 'MP_CHECKOUT_CLIENT_ADD':
      newState = {
        ...state,
        client: action.data
      }
      return newState;
    case 'MP_CHECKOUT_CLEAR':
      newState = {
        ...initialMpCheckoutState,
        type: action.data.type,
        update: action.data.update
      }
      return newState;
    default:
      return state;
  }

}

const reducers = combineReducers({
  navigationState: NavigationState,
  alertState: AlertState,
  userState: UserState,
  settingsState: SettingsState,
  socketConnectionState: SocketConnectionState,
  alertConnectionState: AlertConnectionState,
  mpCheckoutState: MPCheckoutState,

  form: formReducer
});

const store = createStore(reducers, applyMiddleware(ReduxThunk));

export default store;
