import React from 'react';
import { withTranslation } from 'react-i18next';
import { Typography } from './../../../../assets/theme/default';
import { Grid } from './../../../../assets/theme/layout';
import { Link } from './../../../../assets/theme/button';
import { Content, Toolbar, Upload, Button, ButtonsGroup, Alert } from './../../../../components/ui';
import { ClientService } from './../../../../services';
import { ImportClientesFile } from './../../../../assets/files';

class SettingClient extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      invalid: true,
      submited: false,
      error: false,
      success: false,
      message: null,
      attachments: []
    };
  }

  /*
  ** @Service
  ** @Description: Import clients
  */

  _Import = (file) => {

    this.setState({
      submited: true,
      success: false,
      error: false,
      message: null
    });

    ClientService.importMany({ file: file }).then((response) => {

      this.setState({
        attachments: [],
        submited: false,
        invalid: true,
        success: true,
        error: false,
        message: `${this.props.t('messages.file_process_success')} ${ this.props.t('messages.total') } ${ response.total }, ${ this.props.t('messages.new') } ${ response.inserted }, ${ this.props.t('messages.modified') } ${ response.updated }.`
      });

    }).catch((error) => {

      this.setState({
        attachments: [],
        submited: false,
        invalid: true,
        success: false,
        error: true,
        message: error.data.message
      });

    });

  }

  /*
  ** @Event
  ** @Description: OnUpload file
  */

  onUploadFile = (files) => {
    this.setState({ attachments: files.attachment, invalid: false });
  }

  /*
  ** @Submit
  ** @Description: Submit
  */
  handleSubmit = () => {
    this._Import(this.state.attachments[0]);
  }

  /*
  ** @Event
  ** @Description: Close alert
  */

  handleCloseAlert = (files) => {
    this.setState({
      success: false,
      error: false,
      message: null
    });
  }

  render(){
    const { t } = this.props;
    const { invalid, attachments, submited, success, error, message } = this.state;

    return(
      <React.Fragment>
        <Toolbar
          title={t('text.customer')}
          subtitle={t('text.customers_import_form')}
          size="medium"
          variant="divider"
          disableMargin
        />

        { (success || error) && (
          <Content disableMargin style={{ paddingBottom: 0 }}>
            <Alert severity={ success ? "success" : "error" } onClose={ this.handleCloseAlert }>
              { message }
            </Alert>
          </Content>
        )}

        <Content divider disableMargin>

          <Typography variant="body2" gutterBottom>
            {t('text.customers_import_form_help_1')}
            <br/>
            <strong>{t('text.customers_import_form_help_2')}</strong>.
            &nbsp;<Link href={ImportClientesFile} target="_blank">{t('text.customers_import_form_help_3')}</Link>
          </Typography>

          <Grid container spacing={3}>
            <Grid item xl={3} lg={3} md={6} sm={4} xs={6}>
              <Upload
                label={t('field.file')}
                attachments={ attachments }
                onSuccess={ this.onUploadFile.bind(this) }
              />
            </Grid>
          </Grid>

        </Content>

        <Content>
          <ButtonsGroup align="right">
            <Button
              size="medium"
              icon="add"
              color="success"
              disabled={ invalid }
              loading={ submited }
              onClick={ this.handleSubmit }
            >
              {t('button.upload')}
            </Button>
          </ButtonsGroup>
        </Content>

      </React.Fragment>
    )
  }

};

export default withTranslation()(SettingClient);
