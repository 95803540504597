import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { reduxForm, Field, change, FieldArray, formValueSelector } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { withTranslation } from 'react-i18next';
import { InputAdornment } from './../../../assets/theme/input';
import { Page, Wrapper, Paper, Content, Modal, Loading, Button, Toolbar, ButtonsGroup, TextEditor, Upload, FieldsCustomArray } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { ProductService } from './../../../services';
import { CategoryAutocomplete, ProductAutocomplete } from './../../../scenes'
import { filterIds, findPermissionProps } from './../../../utilities/utils';
import PageProductField from './../field';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const selector = formValueSelector('Page__ProductForm');

const validate = values => {

  const errors = {};

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 2048){
    errors.name = TEXT.validation.maxlength(2048);
  }

  if (!values.title) {
    errors.title = TEXT.validation.required;
  }else if(values.title > 2048){
    errors.title = TEXT.validation.maxlength(2048);
  }

  if (values.subtitle && values.subtitle.length > 2048) {
    errors.subtitle = TEXT.validation.maxlength(2048);
  }

  if (!values.price) {
    errors.price = TEXT.validation.required;
  }else if(values.price < 0){
    errors.price = TEXT.validation.minlength(0);
  }

  if (values.trend) {
    if (values.trend === '-1') {
      errors.trend = TEXT.validation.required;
    }
  }

  if (values.enabled) {
    if (values.enabled === '-1') {
      errors.enabled = TEXT.validation.required;
    }
  }

  if (values.public) {
    if (values.public === '-1') {
      errors.public = TEXT.validation.required;
    }
  }

  if (!values.sku) {
    errors.sku = TEXT.validation.required;
  }else if(values.sku.length > 255){
    errors.sku = TEXT.validation.maxlength(255);
  }

  if (values.stock_control) {
    if (values.stock_control === '-1') {
      errors.stock_control = TEXT.validation.required;
    }else if(JSON.parse(values.stock_control)){
      if (!values.stock) {
        errors.stock = TEXT.validation.required;
      }else if(values.stock < 0){
        errors.stock = TEXT.validation.minlength(0);
      }
    }
  }

  return errors;

};

const renderCategoriesForIds = ({ fields, meta: { error, submitFailed } }) => (
  fields.length > 0 && (
    fields.map((entity, index) => (
      <Field
          key={ index }
          name={ entity }
          component={ InputTextField }
          type="text"
          required
          fieldHidden
      />
    ))
  )
);

const renderProductsForIds = ({ fields, meta: { error, submitFailed } }) => (
  fields.length > 0 && (
    fields.map((entity, index) => (
      <Field
          key={ index }
          name={ entity }
          component={ InputTextField }
          type="text"
          required
          fieldHidden
      />
    ))
  )
);

const renderItemsForImages = ({ fields, meta: { error, submitFailed } }) => (
  fields.length > 0 && (
    fields.map((entity, index) => (
      <div key={index} style={{display:'none'}}>
        <Field
            name={`${entity}.name`}
            component={ InputTextField }
            type="text"
            required
        />
        <Field
            name={`${entity}.path`}
            component={ InputTextField }
            type="text"
            required
        />
        <Field
            name={`${entity}.size`}
            component={ InputTextField }
            type="text"
            required
        />
        <Field
            name={`${entity}.type`}
            component={ InputTextField }
            type="text"
            required
        />
      </div>
    ))
  )
);

class Page__ProductForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: '...',
        title: '...',
        loading: true,
        submited: false,
        id: null,
        entity: null,
        stockControl: false,
        productsFieldShow: false
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.product, "Productos") });

      const id = this.props.match.params.id;
      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false, title: 'Nuevo registro' });
      }

    };

    /*
    ** @Service
    ** @Description: Save Product
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      ProductService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        // @Redirect
        this.props.history.push('/products');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Product
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      ProductService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        // @Redirect
        this.props.history.push('/products');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Product
    */

    _Find = (id) => {

      // @Service
      ProductService.find(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set category update into state
        this.setState({
          loading: false,
          id: response.product._id,
          entity: response.product,
          title: response.product.name
        });

        // @Form
        // @Description: Set form fields

        // @General
        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'categories', filterIds(this.state.entity.categories) ));
        this.props.dispatch(change(form, 'related', filterIds(this.state.entity.related) ));
        this.props.dispatch(change(form, 'trend', this.state.entity.trend ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));
        this.props.dispatch(change(form, 'public', this.state.entity.public ));
        this.props.dispatch(change(form, 'price', this.state.entity.price ));
        this.props.dispatch(change(form, 'price_offer', this.state.entity.price_offer ));
        this.props.dispatch(change(form, 'stock_control', this.state.entity.stock_control ));
        this.props.dispatch(change(form, 'stock', this.state.entity.stock ));
        this.props.dispatch(change(form, 'sku', this.state.entity.sku ));
        this.props.dispatch(change(form, 'fields', this.state.entity.fields ));

        // @Images
        this.props.dispatch(change(form, 'images', this.state.entity.images ));
        //this.setState({ images: this.state.entity.images });

        // @Detail
        this.props.dispatch(change(form, 'title', this.state.entity.title ));
        this.props.dispatch(change(form, 'subtitle', this.state.entity.subtitle ));
        this.props.dispatch(change(form, 'description', this.state.entity.description ));

      }).catch((error) => {

        let message = TEXT.message.error;

        if(error.data.code === 'ENTITY_NOT_FOUNDED'){
          message = TEXT.message.entity_not_founded;
          this.props.history.push('/products');
        }

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: message
        });

      });

    };

    /*
    ** @Event
    ** @Description: On select category
    */
    handleOnSelectCategories = (categories) => {
      this.props.dispatch(change(this.props.form, 'categories', categories ? filterIds(categories.selected) : [] ));
    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };

    /*
    ** @Event
    ** @Description: OnChange name
    */
    handleChangeName = (event) => {
      this.setState({ title: event.target.value !== '' ? event.target.value : '...' });
    }

    // @Event
    // @Description: On change stock control
    handleOnChangeStockControl = (event, value) => {

      // @State
      // @Descripcion: Set sotck state
      this.setState({ stockControl: JSON.parse(value) });

      // @Form
      // @Descripcion: Set sotck in 0
      this.props.dispatch(change(this.props.form, 'stock', 0));

    }

    /*
    ** @Event
    ** @Description: OnUpload file
    */

    onUploadFile = (files) => {
      this.props.dispatch(change(this.props.form, 'images', files ));
    }

    /*
    ** @Event
    ** @Description: On select related products
    */
    handleOnSelectRelatedProducts = (products) => {
      this.props.dispatch(change(this.props.form, 'related', products ? filterIds(products) : [] ));
    };

    /*
    ** @Event
    ** @Description: OnChange Custom Field
    */

    handleOnSubmitCustomField = (fields) => {
      this.props.dispatch(change(this.props.form, 'fields', fields ));
      this.handleFieldClose();
    };

    /*
    ** @Event
    ** @Description: Show custom fields
    */
    handleField = () => {
      this.setState({ productsFieldShow: true });
    };

    /*
    ** @Event
    ** @Description: Close custom fields
    */

    handleFieldClose = () => {
      this.setState({ productsFieldShow: false });
    };

    render(){

      const { handleSubmit, invalid, images, fields, t } = this.props;
      const { permission, name, loading, title, submited, entity, stockControl, productsFieldShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/products"
                icon="back"
                color="primary"
                size="medium"
              >
                {t('button.back')}
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              <Modal
                title={t('text.custom_fields')}
                status={productsFieldShow}
                handleCancel={() => this.handleFieldClose()}
              >
                <PageProductField
                  currentValues={fields}
                  onSubmit={this.handleOnSubmitCustomField}
                />
              </Modal>

              {/* Form Category */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* General */}
                  <Content marginBottom>
                    <Toolbar
                      title={t('text.general')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Field
                          name="name"
                          component={ InputTextField }
                          label={t('field.name')}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>

                        {/* Category Autocomplete */}
                        <CategoryAutocomplete
                          categories={ entity ? entity.categories : [] }
                          onSelect={ this.handleOnSelectCategories }
                        />

                        {/* Categories of Ids */}
                        <FieldArray
                          name={t('field.categories')}
                          component={ renderCategoriesForIds }
                        />

                      </Grid>
                      <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label={t('field.status')}
                          required
                        >
                          <OptionField value="-1">{t('field.option_field.select')}</OptionField>
                          <OptionField value="true">{t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
                        <Field
                          name="public"
                          component={ SelectField }
                          label={t('field.public')}
                          required
                        >
                          <OptionField value="-1">{t('field.option_field.select')}</OptionField>
                          <OptionField value="true">{t('field.option_field.yes')}</OptionField>
                          <OptionField value="false">{t('field.option_field.no')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
                        <Field
                          name="trend"
                          component={ SelectField }
                          label={t('field.trend')}
                          required
                        >
                          <OptionField value="-1">{t('field.option_field.select')}</OptionField>
                          <OptionField value="true">{t('field.option_field.yes')}</OptionField>
                          <OptionField value="false">{t('field.option_field.no')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <Field
                          name="price"
                          component={ InputTextField }
                          label={t('field.price')}
                          type="number"
                          min="1"
                          required
                          startAdornment={ <InputAdornment position="start">{ API.client.currency }</InputAdornment> }
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <Field
                          name="price_offer"
                          component={ InputTextField }
                          label={t('field.offer')}
                          type="number"
                          min="1"
                          required
                          startAdornment={ <InputAdornment position="start">{ API.client.currency }</InputAdornment> }
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Field
                          name="stock_control"
                          component={ SelectField }
                          label={t('field.stock_control')}
                          required
                          onChange={ this.handleOnChangeStockControl }
                        >
                          <OptionField value="true">{t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Field
                          name="stock"
                          component={ InputTextField }
                          label={t('field.stock')}
                          type="number"
                          min="0"
                          required={ stockControl }
                          disabled={ !stockControl }
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Field
                          name="sku"
                          component={ InputTextField }
                          label={t('field.sku')}
                          type="text"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Configuration */}
                  <Content marginBottom>
                    <Toolbar
                      title={t('text.configuration')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Button onClick={this.handleField} fullWidth>
                          {t('button.custom_fields')}
                        </Button>
                        <FieldArray
                          name="fields"
                          component={FieldsCustomArray}
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                        {/* Product Autocomplete */}
                        <ProductAutocomplete
                          label={t('field.related_products')}
                          products={ entity ? entity.related : [] }
                          container={true}
                          list={true}
                          onSelect={ this.handleOnSelectRelatedProducts }
                        />

                        {/* Categories of Ids */}
                        <FieldArray
                          name="related"
                          component={ renderProductsForIds }
                        />

                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                        <Upload
                          label={t('field.images')}
                          attachments={images}
                          multiple={true}
                          avatar={true}
                          paper={true}
                          onSuccess={this.onUploadFile.bind(this)}
                        />

                        {/* Array of Images */}
                        <FieldArray
                          name="images"
                          component={renderItemsForImages}
                        />

                      </Grid>
                    </Grid>
                  </Content>

                  {/* Detail */}
                  <Content marginBottom>
                    <Toolbar title="Detalle" size="small" />
                    <Grid container spacing={3}>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Field
                          name="title"
                          component={ InputTextField }
                          label={t('field.title')}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Field
                          name="subtitle"
                          component={ InputTextField }
                          label={t('field.subtitle')}
                          type="text"
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="description"
                          component={ TextEditor }
                          label={t('field.description')}
                          type="text"
                        />
                      </Grid>
                    </Grid>
                  </Content>

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/products"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      {t('button.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      {t('button.save')}
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__ProductForm = reduxForm({
  form: 'Page__ProductForm',
  validate,
  initialValues: {
    type: 'category',
    position: 1,
    target: '_self',
    trend: 'false',
    enabled: 'true',
    public: 'true',
    stock_control: 'false',
    stock: 0,
    sessions: 1
  }
})(Page__ProductForm);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

Page__ProductForm = connect(
  state => {
    const images = selector(state, 'images') || [];
    const fields = selector(state, 'fields') || [];
    return {
      images, fields
    }
  }
)(Page__ProductForm);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__ProductForm));
