import request from './../../../utilities/request';
import localStorageToken from './../../../utilities/storage';

function find(params) {
  return request({
    url:    '/fields',
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params: params
  });
}

const FieldService = {
  find
}

export default FieldService;
