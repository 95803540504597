import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from './../../../../assets/theme/default';
import { IconButton } from './../../../../assets/theme/button'
import { CloseIcon } from './../../../../assets/theme/icon'
import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from './../../../../assets/theme/dialog';

const useStyles = makeStyles(theme => ({
  //content: { paddingTop: theme.spacing(3) },
  header:  {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2.5)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  actions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2)
  }
}));

const Modal = ({ children, status, title, actions, maxWidth = "sm", handleCancel, ...rest}) => {

  const classes = useStyles();

  return (
    <Dialog
        fullWidth
        open={ status }
        onClose={ handleCancel } maxWidth={ maxWidth }>

      { title && (
        <DialogTitle disableTypography className={ classes.header }>
          <Typography component="p" variant="h6">
            { title }
          </Typography>
          <IconButton aria-label="Close" className={ classes.closeButton } onClick={ handleCancel }>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}

      <DialogContent className={ classes.content }>
        <DialogContentText component="div" style={{ margin: 0 }}>
          { children }
        </DialogContentText>
      </DialogContent>

      { actions && (
        <DialogActions className={ classes.actions }>
          <div style={{ 'width': '100%' }}>
            { actions }
          </div>
        </DialogActions>
      )}

    </Dialog>
  )

}

Modal.propTypes = {
  children:  PropTypes.node.isRequired,
  status:  PropTypes.bool.isRequired
};

export default Modal;
