import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles, Box, Typography } from './../../../../assets/theme/default';

const Styles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  title: {
    "& > span": {
      fontWeight: 400
    }
  },
  subtitle: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  small:  theme.typography.h3,
  medium: theme.typography.h2,
  large:  theme.typography.h1,
}));

const Heading = ({ title, size = 'large', component = 'h2', subtitle, ...rest }) => {

  const classes = Styles();

  return(
    <Box className={ classes.root } { ...rest }>
      <Typography
        component={ component }
        classes={{ root: classnames(classes[size], classes.title) }}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      { subtitle && (
        <Typography component="p" classes={{ root: classes.subtitle }}>
          { subtitle }
        </Typography>
      )}
    </Box>
  )

};

Heading.propTypes = {
  title:    PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  size:     PropTypes.oneOf(['small', 'medium', 'large'])
};

export default Heading;
