import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import { Button, Table, Row, Cell, Tag, Currency, Date, LinkClient, LinkPayment } from './../../../../components/ui';
import { PaymentService } from './../../../../services';
import { hasPermission, isUndefined, findPermissionProps } from './../../../../utilities/utils';
import { isPaymentStatus, isPaymentFrom } from './../../../../utilities/payment';

import API from './../../../../config/api';
import TEXT from './../../../../config/text';

class UtilUserPayment extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: false,
        page: 0,
        total: 0,
        entities: [],
        thead: [
          { id: 'payment_code', name: this.props.t('field.code')},
          { id: 'client', name: this.props.t('field.customer') },
          { id: 'total', name: this.props.t('field.total') },
          { id: 'commission', name: this.props.t('field.commission') },
          { id: 'payment_status', name: this.props.t('field.status') },
          { id: 'from', name: this.props.t('field.channel') },
          { id: 'created_at', name: this.props.t('field.date') },
          { id: 'actions', name: this.props.t('field.actions'), align: 'right' }
        ]
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    }

    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        const page = this.props.match.params.page || 0;
        if(!isUndefined(page)){
          if(!isNaN(page) && page >= 0){
            this._All(page);
          }else{
            this.props.history.push(`/users/profile/${this.props.id}/payments`);
          }
        }else{
          this._All(this.state.page);
        }

      }
    }

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.payments, this.props.t('text.payments')) });

      const page = this.props.match.params.page || 0;
      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          this._All(page);
        }else{
          this.props.history.push(`/users/profile/${this.props.id}/payments`);
        }
      }else{
        this._All(this.state.page);
      }
    }

    /*
    ** @Service
    ** @Description: List
    */

    _All = (page) => {

      PaymentService.listByRoleAndId('user', this.props.id, page).then((response) => {
        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.payments,
          total: response.total
        });
      }).catch((error) => {

        this.setState({ loading: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: TEXT.message.error
        });

      });

    }

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/users/profile/${this.props.id}/payments/page/${page}`
      });

    };

    render(){

      const { session, t } = this.props;
      const { loading, entities, thead, total, page } = this.state;

      return(

        <Table
          thead={ thead }
          loading={ loading }
          total={ total }
          page={ page }
          onChangePage={ this.handleChangePage }
        >

          { entities.length > 0 && (

            entities.map(entity => {
              return (
                <Row key={ entity._id }>
                  <Cell>
                    <LinkPayment payment={ entity } />
                  </Cell>
                  <Cell>
                    <LinkClient client={ entity.client }/>
                  </Cell>
                  <Cell>
                    <Currency value={ entity.total }/>
                  </Cell>
                  <Cell>
                    <Tag text={ `${ entity.commission }%` } color="info"/>
                  </Cell>
                  <Cell>
                    <Tag
                      text={ isPaymentStatus(entity.payment_status).name }
                      color={ isPaymentStatus(entity.payment_status).color }
                    />
                  </Cell>
                  <Cell>
                    <Tag text={ isPaymentFrom(entity.from) } />
                  </Cell>
                  <Cell>
                    <Date value={ entity.created_at } />
                  </Cell>
                  <Cell align="right">
                    { hasPermission(session.user.permissions, API.permissions.payments_management) && (
                      <React.Fragment>
                        <Button
                          icon="info"
                          component={ NavLink }
                          title={t('button.information')}
                          to={ `/payments/detail/${ entity._id }` }
                        />
                      </React.Fragment>
                    )}
                  </Cell>
                </Row>
              )
            })

          )}

          { (loading && entities.length === 0) && (
            <Row>
              <Cell colSpan={ thead.length } align="center">
                { TEXT.message.without_results }
              </Cell>
            </Row>
          )}

        </Table>

      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(UtilUserPayment)));
