import React, { useEffect, useMemo } from 'react';
import { throttle } from 'lodash';
import { makeStyles, Box } from './../../../../assets/theme/default';
import { Autocomplete } from './../../../../assets/theme/input';
import { FormLabelField, InputTextField, OptionAutocomplete } from './../../../../components/ui/input';
import { ShippingService } from './../../../../services';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    background: theme.palette.background.default,
    border: `1px solid ${ theme.palette.grey.main }`
  },
  inputRoot: {
    marginTop: `0 !important`,
    border: `1px solid ${ theme.palette.grey.dark }`,
    background: '#fafbfc',
    padding: `${ theme.spacing(1) }px ${ theme.spacing(5) }px ${ theme.spacing(1) }px ${ theme.spacing(1) }px !important`,
    borderRadius: `3px !important`,
    '&:before': {
      display: 'none !important'
    },
    '&:after': {
      display: 'none !important'
    }
  },
  inputInput: {
    padding: '0 !important'
  },
  inputEndAdornment: {
    right: theme.spacing(1)
  }
}));

const ShippingLocationAutocomplete = ({ location, label, required, onSelect, ...rest }) => {

  const classes = useStyles();
  const [init, setInit] = React.useState(true);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  // @Service
  // @Description: Filter locations
  const filter = (request, callback) => {
    ShippingService.filterLocations({ keyword: request.input }).then((response) => {
      return((request, callback(response.locations)))
    });
  };

  // @Handler
  // @Description: Build label
  const handleOptionLabel = (option) => {
    return (
      <React.Fragment>
        { option.name }
        { option.country && (
          <React.Fragment>
            &nbsp;[{option.country.alpha2Code}]
          </React.Fragment>
        )}
      </React.Fragment>
    )
  };

  // @Memo
  // @Description:
  const fetch = useMemo(() =>
      throttle((request, callback) => {
        filter(request, callback);
      }, 1000),
    [],
  );

  useEffect(() => {
    let active = true;

    if(location && inputValue === ''){
      setValue(location);
      setInit(false);
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if(init){
      fetch({ input: inputValue }, (results) => {
        if (active) {
          let newOptions = [];
          if (value) {
            newOptions = [value];
          }
          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      });
    }

    return () => { active = false; };

  }, [value, inputValue, fetch, location, init]);

  return (
    <Box className={ classes.root }>
      <Autocomplete
        freeSolo
        classes={{
          paper: classes.paper,
          inputRoot: classes.inputRoot,
          input: classes.inputInput,
          endAdornment: classes.inputEndAdornment
        }}
        size="small"
        getOptionLabel={
          (option) => { return `${ option.name } ${ option.country ? '[' + option.country.alpha2Code + ']' : '' }`; }
        }
        filterOptions={(x) => x}
        options={options}
        value={value}
        autoComplete
        includeInputInList
        filterSelectedOptions
        onChange={(event, newInputValue) => {
          setOptions(newInputValue ? [newInputValue, ...options] : options);
          setValue(newInputValue);
          onSelect(newInputValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInit(true);
          setInputValue(newInputValue);
        }}
        renderInput={(params) => {
          return(
            <React.Fragment>
              <FormLabelField required={required}>{label}</FormLabelField>
              <InputTextField {...params} placeholder="Nombre o prefijo" />
            </React.Fragment>
          )
        }}
        renderOption={(option, { selected }) => (
          <OptionAutocomplete>
            { handleOptionLabel(option) }
          </OptionAutocomplete>
        )}
      />
    </Box>
  );
};

export default ShippingLocationAutocomplete;
