import request from './../../utilities/request';

function login(values) {
  return request({
    url:    '/auth/login',
    method: 'POST',
    data:   values
  });
}

function resetPassword(values) {
  return request({
    url:    '/auth/reset-password',
    method: 'POST',
    data:   values
  });
}

function signout() {
  return new Promise((resolve, reject) => {

    localStorage.clear();

    if(localStorage.getItem('token') && localStorage.getItem('user')){
      reject(false);
    }else{
      resolve(true);
    }

  });
}

const AuthService = {
  login, resetPassword, signout
}

export default AuthService;
