import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { SelectField, OptionField } from './../../../../components/ui/input';
import { StateService } from './../../../../services';

class StateSelect extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      entities: []
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component will recive props.
  */
  componentDidUpdate(prev_props){

    if(this.props.country !== prev_props.country){
      this._Find(this.props.country);
    }

  }
  /*
  ** @Service
  ** @Description: List all countries
  */

  _Find = (id) => {
    if(id !== '-1'){
      StateService.findByCountryId(id).then((response) => {
        this.setState({ entities: response.states });
      });
    }else{
      this.setState({ entities: [] });
    }
  }

  /*
  ** @Render
  */

  render() {

    const { entities } = this.state;

    return(
      <SelectField { ...this.props } disabled={entities.length > 0 ? false : true}>
        { entities.length > 0 ? (
          <React.Fragment>
            { entities.map((entity, index) => {
              return(
                <OptionField key={ index } value={ entity._id }>
                  { entity.name }
                </OptionField>
              )
            })}
          </React.Fragment>
        ) : (
          <OptionField value="-1">{this.props.t('field.option_field.select')}</OptionField>
        )}
      </SelectField>
    )
  }

}

StateSelect.propTypes = {
  country: PropTypes.string,
  onLoad: PropTypes.func
};

export default withTranslation()(StateSelect);
