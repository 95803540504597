import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Page, Wrapper, Content, Paper, Alert, Loading, Button, ButtonsGroup, Toolbar, Modal, Tabs, Tab, TabPanel } from './../../../components/ui';
import { UserService } from './../../../services';
import { hasPermission } from './../../../utilities/utils';
import { PermissionList } from './../../../scenes';

import UtilUserData from './../section/data';
import UtilUserPayment from './../section/payment';

import API from './../../../config/api';
import TEXT from './../../../config/text';

class Page__UserProfile extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        id: null,
        loading: false,
        title: '...',
        subtitle: '...',
        entity: null,
        section: 0,
        permissionsListShow: false,
        permissionsListIds: []
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    componentDidUpdate(prev_props){
      if(this.props.match.params.section !== prev_props.match.params.section ){
        this._Update(this.props.match.params.section);
      }
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      let id = this.props.match.params.id;
      const section = this.props.match.params.section;

      if(id){

        // @Service
        this._Find(id);
        this._Update(section);

      }else{

        if(this.props.match.path === '/me'){

          // @Service
          this._Find(this.props.session.user._id);
          this._Update(section);

        }else{

          this.props.history.push('/users');

        }

      }

    };

    /*
    ** @Event
    ** @Description: Update
    */

    _Update = (section) => {
      let stateIndex;
      switch(section){
        case 'information': stateIndex = 0;
          break;
        case 'payments': stateIndex = 1;
          break;
        default: stateIndex = 0;
      };
      this.setState({ section: stateIndex });
    };


    /*
    ** @Service
    ** @Description: Find Client By Id
    */

    _Find = (id) => {

      // @State
      // @Description: Set client id
      this.setState({ id: id });

      // @Service
      UserService.find(id).then((response) => {

        // @State
        // @Description: Set client update into state
        this.setState({
          loading: true,
          title: `${ response.user.name } ${ response.user.surname }`,
          subtitle: response.user.email,
          entity: response.user
        });

      }).catch((error) => {

        let message = TEXT.message.error;

        // @Error
        // @Description: Entitad no encontrada
        if(error.data.code === 'ENTITY_NOT_FOUNDED'){
          message = TEXT.message.entity_not_founded;
        }

        // @Error
        // @Description: Server error
        if(error.data.code === 'SERVER_ERROR'){
          message = TEXT.message.entity_not_founded;
        }

        // @Redirect
        this.props.history.push('/users');

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: message
        });

      });

    }

    /*
    ** @Event
    ** @Description: Change section tab
    */
    handleChangeSection = (event: React.ChangeEvent<{}>, index: number) => {
      switch(index){
        case 0: this.props.history.push(`/users/profile/${this.state.id}/information`);
          break;
        case 1: this.props.history.push(`/users/profile/${this.state.id}/payments`);
          break;
        default: this.props.history.push(`/users/profile/${this.state.id}/information`);
      }
    };

    /*
    ** @Event
    ** @Description: Show permissions
    */
    handlePermissions = (ids) => {
      this.setState({ permissionsListShow: true, permissionsListIds: ids });
    };

    /*
    ** @Event
    ** @Description: Hide permissions
    */
    handlePermissionsClose = () => {
      this.setState({ permissionsListShow: false, permissionsListIds: [] });
    };

    render(){

      const { session, t } = this.props;
      const { loading, id, title, subtitle, entity, section, permissionsListShow, permissionsListIds } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={ title }
              subtitle={ subtitle }
              dividerMobile
              >
                <ButtonsGroup>
                  { loading && (

                    <React.Fragment>
                      <Button
                        size="medium"
                        onClick={ () => this.handlePermissions(entity.permissions) }
                      >
                        {t('button.permissions')}
                      </Button>

                      { hasPermission(session.user.permissions, API.permissions.users_management) && (
                        <Button
                          component={ NavLink }
                          to={`/users/edit/${ entity._id }`}
                          size="medium"
                        >
                          {t('button.edit')}
                        </Button>
                      )}
                    </React.Fragment>

                  )}
                </ButtonsGroup>
              </Toolbar>

            <Content disablePadding>

              {/* Permissions list by ids */}
              <Modal
                title={t('text.permissions')}
                status={ permissionsListShow }
                handleCancel={ () => this.handlePermissionsClose() }
              >
                <PermissionList ids={ permissionsListIds }/>
              </Modal>

              { loading ? (
                <React.Fragment>

                  { entity.removed && (
                    <Content disablePadding marginBottom>
                      <Alert
                        severity="info"
                        action={
                          <ButtonsGroup>
                            <Button color="danger" icon="cancel">
                              {t('button.delete')}
                            </Button>
                            <Button color="success" icon="add">
                              {t('button.activate')}
                            </Button>
                          </ButtonsGroup>
                        }
                      >
                        <strong>{t('messages.attention')}:</strong> {t('messages.user_inactive')}
                      </Alert>
                    </Content>
                  )}

                  <Content marginBottom>
                    <Paper disablePadding>
                      <Tabs
                        value={ section }
                        onChange={this.handleChangeSection}
                      >
                        <Tab label={t('text.information')} />

                        { hasPermission(session.user.permissions, API.permissions.payments) && (
                          <Tab label={t('text.payments')} />
                        )}
                      </Tabs>
                    </Paper>
                  </Content>

                  <Content disablePadding>
                    <Paper disablePadding>
                      <TabPanel value={ section } index={0}>
                        <UtilUserData user={ entity } />
                      </TabPanel>

                      { hasPermission(session.user.permissions, API.permissions.payments) && (
                        <TabPanel value={ section } index={1}>
                          <UtilUserPayment id={ id } />
                        </TabPanel>
                      )}
                    </Paper>
                  </Content>

                </React.Fragment>
              ) : (

                <Paper disablePadding>
                  <Loading/>
                </Paper>

              )}

            </Content>


          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__UserProfile));
