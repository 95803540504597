import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { reduxForm, Field, change, FieldArray, formValueSelector } from 'redux-form';
import { Grid } from './../../../../assets/theme/layout';
import { InputAdornment } from './../../../../assets/theme/input';
import { Paper, Content, Loading, Button, Data, Date, Toolbar, ButtonsGroup, Modal, FieldsCustomArray } from './../../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../../components/ui/input';
import { ShippingTransportSelect, EntityAutocomplete, StoreAutocomplete, ProviderAutocomplete, FieldCustomList, FieldCustomForm, ShippingContainerFieldArray } from './../../../../scenes';
import { ShippingService, ClientService } from './../../../../services';

import API from './../../../../config/api';
import TEXT from './../../../../config/text';

const selector = formValueSelector('ShippingFormDetail');

const validate = values => {

  const errors = {};

  if (!values.code_internal) {
    errors.code_internal = TEXT.validation.required;
  }else if(values.code_internal.length > 255){
    errors.code_internal = TEXT.validation.maxlength(255);
  }

    if (!values.entity) {
      errors.entity = TEXT.validation.required;
    }

    if (!values.provider) {
      errors.provider = TEXT.validation.required;
    }

    if (!values.content) {
      errors.content = TEXT.validation.required;
    }else if(values.content.length > 1024){
      errors.content = TEXT.validation.maxlength(1024);
    }

    if (values.observation) {
      if (values.observation.length > 1024) {
        errors.observation = TEXT.validation.maxlength(1024);
      }
    }

    if (values.mbl_number) {
      if (values.mbl_number.length > 255) {
        errors.mbl_number = TEXT.validation.maxlength(255);
      }
    }

    if (values.transport) {
      if (values.transport === '-1') {
        errors.transport = TEXT.validation.required;
      }
    }

    if (values.consolidated) {
      if (JSON.parse(values.consolidated)) {

        if (!values.hbl_number) {
          errors.hbl_number = TEXT.validation.required;
        } else if (values.hbl_number.length > 255) {
          errors.hbl_number = TEXT.validation.maxlength(255);
        }

        if (values.hbl_express === '-1') {
          errors.hbl_express = TEXT.validation.required;
        }

        if (!values.agent) {
          errors.agent = TEXT.validation.required;
        } else if (values.agent.length > 255) {
          errors.agent = TEXT.validation.maxlength(255);
        }

      }
    }

    return errors;

  };

  class ShippingFormDetail extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: true,
        submited: false,
        id: null,
        entity: null,
        clients: [],
        events: [],
        fieldFormShow: false
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    componentDidUpdate(prevProps){
      if(this.props.entity !== prevProps.entity){
        this._Update();
      }
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this._Update();

      this._Events();


    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Update = () => {
      if (this.props.entity) {

        this.setState({ entity: this.props.entity }, () => {

          const form = this.props.form;

          // @General
          this.props.dispatch(change(form, 'code_internal', this.state.entity.code_internal ));
          this.props.dispatch(change(form, 'code_external', this.state.entity.code_external ));
          this.props.dispatch(change(form, 'code_incremental', this.state.entity.code_incremental ));
          this.props.dispatch(change(form, 'consolidated', this.state.entity.consolidated ));
          this.props.dispatch(change(form, 'mbl_number', this.state.entity.mbl_number ));
          this.props.dispatch(change(form, 'mbl_express', this.state.entity.mbl_express ));
          this.props.dispatch(change(form, 'hbl_number', this.state.entity.hbl_number ));
          this.props.dispatch(change(form, 'hbl_express', this.state.entity.hbl_express ));

          if(this.state.entity.event){
            this.props.dispatch(change(form, 'event', this.state.entity.event._id ));
          }

          if(this.state.entity.store){
            this.props.dispatch(change(form, 'store', this.state.entity.store._id ));
          }

          if(this.state.entity.entity){
            this.props.dispatch(change(form, 'entity', this.state.entity.entity._id ));
            this.handleFindClientsByEntity(this.state.entity.entity);
          }

          if(this.state.entity.importer){
            this.props.dispatch(change(form, 'importer', this.state.entity.importer._id ));
          }

          if(this.state.entity.exporter){
            this.props.dispatch(change(form, 'exporter', this.state.entity.exporter._id ));
          }

          if(this.state.entity.client){
            this.props.dispatch(change(form, 'client', this.state.entity.client._id ));
          }

          if(this.state.entity.provider){
            this.props.dispatch(change(form, 'provider', this.state.entity.provider._id ));
          }

          if(this.state.entity.etd){
            this.props.dispatch(change(form, 'etd', this.state.entity.etd ));
          }

          if(this.state.entity.eta){
            this.props.dispatch(change(form, 'eta', this.state.entity.eta ));
          }

          if(this.state.entity.from_status){
            this.props.dispatch(change(form, 'from_status', this.state.entity.from_status ));
          }

          if(this.state.entity.to_status){
            this.props.dispatch(change(form, 'to_status', this.state.entity.to_status ));
          }

          // @Configuration
          this.props.dispatch(change(form, 'content', this.state.entity.content ));
          this.props.dispatch(change(form, 'observation', this.state.entity.observation ));
          this.props.dispatch(change(form, 'reference', this.state.entity.reference ));
          this.props.dispatch(change(form, 'temperature', this.state.entity.temperature ));
          this.props.dispatch(change(form, 'fields', this.state.entity.fields ));

          if(this.state.entity.transport){
            this.props.dispatch(change(form, 'transport', this.state.entity.transport._id ));
          }

          if (this.state.entity.containers.length > 0) {
            const containers = this.state.entity.containers.reduce((accumulator, currentValue, index) => {
              accumulator.push({ ...currentValue, entity: currentValue.entity ? currentValue.entity._id : null });
              return accumulator;
            }, []);
            this.props.dispatch(change(form, 'containers', containers ));
          }

        });

      }
    };

    /*
    ** @Service
    ** @Description: Find Events
    */

    _Events = () => {
      ShippingService.eventsList().then((response) => {
        this.setState({ events: response.events });
      });
    };

    /*
    ** @Service
    ** @Description: Find Client By Entity
    */

    _ClientsByEntity = (id) => {
      ClientService.findByEntity(id).then((response) => {
        this.setState({ clients: response.clients || [] });
      });
    };



    handleEventChange = (e) => {
      const eventName = this.state.events.find(f => f._id === e.target.value)
      this.props.dispatch(change(this.props.form, 'event_name', eventName.name ));
    }
    /*
    ** @Event
    ** @Description: Find Clients By Entity
    */
    handleFindClientsByEntity = (entity) => {
      if(entity){
        this._ClientsByEntity(entity._id);
      }else{
        this.setState({ clients: [] });
      }
    };

    /*
    ** @Event
    ** @Description: On selected user
    */
    handleOnSelectUser = (user) => {
      this.props.dispatch(change(this.props.form, 'user', user ? user._id : '' ));
    };

    /*
    ** @Event
    ** @Description: On selected from
    */
    handleOnSelectFrom = (location) => {
      this.props.dispatch(change(this.props.form, 'from', location ? location._id : '' ));
      this.setState({ entity: { ...this.state.entity, from: location }});
    };

    /*
    ** @Event
    ** @Description: On selected to
    */
    handleOnSelectTo = (location) => {
      this.props.dispatch(change(this.props.form, 'to', location ? location._id : '' ));
      this.setState({ entity: { ...this.state.entity, to: location }});
    };

    /*
    ** @Event
    ** @Description: On selected entity
    */
    handleOnSelectEntity = (entity) => {
      this.setState({ entity: { ...this.state.entity, entity: entity }});
      this.props.dispatch(change(this.props.form, 'entity', entity ? entity._id : '' ));
      this.handleFindClientsByEntity(entity);
    };

    /*
    ** @Event
    ** @Description: On selected importer
    */
    handleOnSelectImporter = (importer) => {
      this.props.dispatch(change(this.props.form, 'importer', importer ? importer._id : '' ));
      this.setState({ entity: { ...this.state.entity, importer: importer }});
    };

    /*
    ** @Event
    ** @Description: On selected exporter
    */
    handleOnSelectExporter = (exporter) => {
      this.props.dispatch(change(this.props.form, 'exporter', exporter ? exporter._id : '' ));
      this.setState({ entity: { ...this.state.entity, exporter: exporter }});
    };

    /*
    ** @Event
    ** @Description: On selected client
    */
    handleOnSelectClient = (client) => {
      this.props.dispatch(change(this.props.form, 'client', client ? client._id : '' ));
      this.setState({ entity: { ...this.state.entity, client: client }});
    };

    /*
    ** @Event
    ** @Description: On selected store
    */
    handleOnSelectStore = (store) => {
      this.props.dispatch(change(this.props.form, 'store', store ? store._id : '' ));
      this.setState({ entity: { ...this.state.entity, store: store }});
    };

    /*
    ** @Event
    ** @Description: On selected provider
    */
    handleOnSelectProvider = (provider) => {
      this.props.dispatch(change(this.props.form, 'provider', provider ? provider._id : '' ));
      this.setState({ entity: { ...this.state.entity, provider: provider }});
    };

    /*
    ** @Event
    ** @Description: OnChange Custom Field
    */

    handleOnSubmitCustomField = (fields) => {
      this.props.dispatch(change(this.props.form, 'fields', fields ));
      this.handleFieldClose();
    };

    /*
    ** @Event
    ** @Description: Change section tab
    */
    handleOnChangeTransport = (event: React.ChangeEvent<{}>, value: string) => {
      this.props.dispatch(change(this.props.form, 'containers', [
        {
          entity: null,
          enrollment: '',
          weight: '',
          value: ''
        }
      ]));
    };

    /*
    ** @Event
    ** @Description: Show Form Fields
    */

    handleField = () => {
      this.setState({ fieldFormShow: true });
    };

    /*
    ** @Event
    ** @Description: Close Form Fields
    */

    handleFieldClose = () => {
      this.setState({ fieldFormShow: false });
    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      this.props.onSubmit(values);
    };

    render(){

      const { handleSubmit, invalid, fields, transport, consolidated } = this.props;
      const { submited, entity, clients, fieldFormShow } = this.state;

      return(
        <React.Fragment>
          <Modal
            title="Campos personalizados"
            status={fieldFormShow}
            handleCancel={() => this.handleFieldClose()}
            >
            <FieldCustomForm
              component={API.permissions.shipping}
              currentValues={fields}
              onSubmit={this.handleOnSubmitCustomField}
              />
          </Modal>
          <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>
            <Field
              name="etd"
              component={ InputTextField }
              required
              fieldHidden
              />
            <Field
              name="eta"
              component={ InputTextField }
              required
              fieldHidden
              />
            <Field
              name="event_name"
              component={ InputTextField }
              required
              fieldHidden
              />
            <Field
              name="from_status"
              component={ InputTextField }
              required
              fieldHidden
              />
              <Field
                name="to_status"
                component={ InputTextField }
                required
                fieldHidden
                />

            {/* Form */}
            <Content divider disableMargin>

              { submited && (
                <Loading variant="absolute"/>
              )}

              <Field name="code_incremental" component={InputTextField} type="text" required fieldHidden />

              {/* Configuration */}
              <Content marginBottom disablePadding>
                <Paper>
                  <Grid container spacing={3}>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                      <Data
                        label="ETD"
                        text={(entity && entity.etd) ? <Date value={entity.etd}/> : '-'}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                      <Data
                        label="Origen"
                        text={(entity && entity.from) ? entity.from.name : '-'}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                      <Data
                        label="ETA"
                        text={(entity && entity.eta) ? <Date value={entity.eta}/> : '-'}
                        />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                      <Data
                        label="Destino"
                        text={(entity && entity.to) ? entity.to.name : '-'}
                        />
                    </Grid>
                  </Grid>
                </Paper>
              </Content>

              {/* General */}
              <Content disablePadding divider>
                <Toolbar
                  title="General"
                  size="small"
                  />
                <Grid container spacing={3}>
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                    <Field
                      name="code_internal"
                      component={ InputTextField }
                      label="OV"
                      type="text"
                      required
                      />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                    <Field
                      name="code_external"
                      component={ InputTextField }
                      label="Reserva"
                      type="text"
                      />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>

                    <Data
                      name="event"
                      label="Estado"
                      text={(entity && entity.event) ? entity.event.name : '-'}
                      />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <EntityAutocomplete
                      entity={entity && entity.entity ? entity.entity : null}
                      onSelect={this.handleOnSelectEntity}
                      required
                      />
                    <Field
                      name="entity"
                      component={ InputTextField }
                      required
                      fieldHidden
                      />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Field
                      label="Cliente"
                      name="client"
                      component={ SelectField }
                      disabled={clients.length > 0 ? false : true}
                      >
                      <OptionField value="-1">Seleccionar...</OptionField>
                      { clients.length > 0 && (
                        <React.Fragment>
                          { clients.map((client, index) => {
                            return(
                              <OptionField key={ index } value={ client._id }>
                                { client.name } { client.surname }
                              </OptionField>
                            )
                          })}
                        </React.Fragment>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <EntityAutocomplete
                      label="Exportador"
                      entity={entity && entity.exporter ? entity.exporter : null}
                      onSelect={this.handleOnSelectExporter}
                      />
                    <Field
                      name="exporter"
                      component={ InputTextField }
                      required
                      fieldHidden
                      />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <EntityAutocomplete
                      label="Importador"
                      entity={entity && entity.importer ? entity.importer : null}
                      onSelect={this.handleOnSelectImporter}
                      />
                    <Field
                      name="importer"
                      component={ InputTextField }
                      required
                      fieldHidden
                      />
                  </Grid>
                  <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                    <Field
                      name="mbl_number"
                      component={ InputTextField }
                      label="MBL número"
                      type="text"
                      />
                  </Grid>
                  <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                    <Field
                      name="mbl_express"
                      component={ SelectField }
                      label="MBL express"
                      >
                      <OptionField value="true">Si</OptionField>
                      <OptionField value="false">No</OptionField>
                    </Field>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                    <ProviderAutocomplete
                      provider={entity && entity.provider ? entity.provider : null}
                      onSelect={this.handleOnSelectProvider}
                      required
                      />
                    <Field
                      name="provider"
                      component={ InputTextField }
                      required
                      fieldHidden
                      />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                    <Field
                      name="consolidated"
                      component={ SelectField }
                      label="Consolidado"
                      >
                      <OptionField value="true">Si</OptionField>
                      <OptionField value="false">No</OptionField>
                    </Field>
                  </Grid>

                  { JSON.parse(consolidated) && (
                    <React.Fragment>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <Field
                          name="hbl_number"
                          component={ InputTextField }
                          label="HBL número"
                          type="text"
                          required
                          />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <Field
                          name="hbl_express"
                          component={ SelectField }
                          label="HBL Express"
                          required
                          >
                          <OptionField value="-1">Seleccionar...</OptionField>
                          <OptionField value="true">Si</OptionField>
                          <OptionField value="false">No</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                        <StoreAutocomplete
                          label="Agente"
                          store={entity && entity.store ? entity.store : null}
                          onSelect={this.handleOnSelectStore}
                          required
                          />
                        <Field
                          name="store"
                          component={ InputTextField }
                          required
                          fieldHidden
                          />
                      </Grid>
                    </React.Fragment>
                  )}

                </Grid>
              </Content>

              {/* Configuration */}
              <Content marginBottom>
                <Toolbar
                  title="Detalle de embarque"
                  size="small"
                  />
                <Grid container spacing={3}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Field
                      name="content"
                      component={ InputTextField }
                      label="Mercadería"
                      type="text"
                      multiline
                      required
                      />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Field
                      name="temperature"
                      component={ InputTextField }
                      label="Temperatura"
                      type="number"
                      endAdornment={ <InputAdornment position="end">ºC</InputAdornment> }
                      />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Field
                      name="observation"
                      component={ InputTextField }
                      label="Observación"
                      type="text"
                      multiline
                      />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Field
                      name="reference"
                      component={ InputTextField }
                      label="Referencia cliente"
                      type="text"
                      multiline
                      />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Field
                      name="transport"
                      component={ ShippingTransportSelect }
                      label="Transporte"
                      onChange={this.handleOnChangeTransport}
                      required
                      />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FieldArray
                      name="containers"
                      transport={transport !== '-1' ? transport : null}
                      component={ShippingContainerFieldArray}
                      />
                  </Grid>
                </Grid>
              </Content>

              {/* Fields custom */}
              { (fields && fields.length > 0) && (
                <Content marginBottom>
                  <Grid container spacing={0}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <FieldCustomList
                        component={API.permissions.shipping}
                        currentValues={fields}
                        onOpen={this.handleField}
                        />
                      <FieldArray
                        name="fields"
                        component={FieldsCustomArray}
                        />
                    </Grid>
                  </Grid>
                </Content>
              )}

            </Content>

            {/* Footer */}
            <Content>
              <ButtonsGroup align="right">
                <Button
                  component={ NavLink }
                  to="/shipping"
                  color="danger"
                  size="medium"
                  icon="cancel"
                  >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  icon="add"
                  color="success"
                  disabled={ invalid || submited }
                  >
                  Guardar
                </Button>
              </ButtonsGroup>
            </Content>

          </form>
        </React.Fragment>
      );

    };

  };

  ShippingFormDetail = reduxForm({
    form: 'ShippingFormDetail',
    validate,
    initialValues: {
      client: '-1',
      transport: '-1',
      consolidated: false,
      mbl_express: false,
      from_status: false,
      to_status: false,
      containers: [
        {
          entity: null,
          enrollment: '',
          weight: '',
          value: ''
        }
      ]
    },
    enableReinitialize: true
  })(ShippingFormDetail);

  const mapStateToProps = (state) => {
    return {
      session: state.userState
    }
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      alertShow: (data) => {
        dispatch({
          type: 'ALERT_SHOW',
          data: data
        });
      }
    }
  };

  ShippingFormDetail = connect(
    state => {
      const consolidated = selector(state, 'consolidated') || false;
      const transport = selector(state, 'transport') || null;
      const user = selector(state, 'user') || null;
      const from = selector(state, 'from') || null;
      const to = selector(state, 'to') || null;
      const store = selector(state, 'store') || null;
      const provider = selector(state, 'provider') || null;
      const fields = selector(state, 'fields') || [];
      return { consolidated, transport, user, from, to, store, provider, fields }
    }
  )(ShippingFormDetail);

  export default connect(mapStateToProps, mapDispatchToProps)(ShippingFormDetail);
