import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { makeStyles, Box } from './../../../assets/theme/default';
import { Header, Navigation } from './../../shared';
import { Snackbar } from './../../ui';
import { PageNotFound } from './../../../pages';
import { hasPermission } from './../../../utilities/utils';

const useStyles = makeStyles(theme => ({
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    minWidth: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: 240
    }
  },
  main: {
    flex: '1 1',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    marginTop: 64,
    [theme.breakpoints.down('xs')]: {
      marginTop: 56
    }
  }
}));

const Layout = ({children, permission, session, ...rest}) => {

  const classes = useStyles();

  return (
    <React.Fragment>

      {/* Header component */}
      <Header/>

      {/* Navigation component */}
      <Navigation/>

      {/* Content component */}
      <Box className={ classes.content }>

        <Box className={ classes.main }>
          { (permission === undefined || hasPermission(session.user.permissions, permission)) ? (
            <React.Fragment>
              { children }
            </React.Fragment>
          ) : (
            <PageNotFound/>
          )}
        </Box>

      </Box>

      {/* Snackbar component */}
      <Snackbar/>

    </React.Fragment>
  )
}

const Backend = ({ component: Component, permission, session, ...rest }) => {
  return (
    <Route { ...rest } render={ matchProps => (
      <Layout session={ session } permission={ permission }>
        <Component { ...matchProps } />
      </Layout>
    )} />
  )
}

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

export default connect(mapStateToProps)(Backend);
