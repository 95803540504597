import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, Modal, AlertConfirm, Table, Row, Cell, Tag, Chip, Currency, LinkProduct } from './../../../components/ui';
import { ProductService } from './../../../services';
import { isUndefined, isBinary, isBinaryColor, isStatus, isStatusColor, hasPermission, findPermissionProps, parseQueryString } from './../../../utilities/utils';
import { CategoryList, CliniceaServiceSync } from './../../../scenes';
import PageProductFilter from './../filter';
import PageProductUpdate from './../update';
import WrapPageProductField from './../field/wrap';

import API from './../../../config/api';
import TEXT from './../../../config/text';

class Page__ProductList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        loading: false,
        page: 0,
        total: 0,
        id: null,
        entity: null,
        entities: [],
        remove: false,
        thead: [
          { id: 'sku', name: this.props.t('field.sku') },
          { id: 'name', name: this.props.t('field.name') },
          { id: 'price', name: this.props.t('field.price') },
          { id: 'enabled', name: this.props.t('field.status') },
          { id: 'public', name: this.props.t('field.public') },
          { id: 'category', name: this.props.t('field.categories') },
          { id: 'actions', name: this.props.t('field.actions'), align: 'right' }
        ],
        productsUpdateShow: false,
        productsFilterShow: false,
        categoriesListShow: false,
        cliniceaSyncShow: false,
        editCustomFields: false,
        categoriesListShowIds: []
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.match.params.id !== prev_props.match.params.id){
        this._All(this.props.match.params.page || 0, this.props.match.params.id);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.product, this.props.t('text.products')) });

      const page = this.props.match.params.page || 0;
      const id   = this.props.match.params.id;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          if(id){
            this._All(page, id);
          }else{
            this._All(page);
          }
        }else{
          this.props.history.push('/products');
        }
      }else{
        this._All(this.state.page);
      }

    };

    /*
    ** @Service
    ** @Description: All categories
    */

    _All = (page, id) => {

      // @State
      // @Description: Refresh after each service
      this.setState({ loading: false, entities: [] });

      // @Service
      ProductService.list(page, parseQueryString(this.props.location.search)).then((response) => {

        // @State
        // @Descripcion: Set values
        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.products,
          total: response.total
        });

      }).catch((error) => {

        // @State
        // @Descripcion: Loading disabled
        this.setState({ loading: true });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Remove category
    */

    _Remove = (id) => {

      ProductService.remove(id).then((response) => {

        // @State
        this.setState({ remove: false });

        // @Service find all by page
        this._Init();

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('messages.product_delete_success')
        });

      }).catch((error) => {

        // @State
        this.setState({ remove: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: this.props.t('messages.product_delete_fail')
        });

      });

    };

    /*
    ** @Event
    ** @Description: Remove category
    */

    handleRemove = (id) => {
      this.setState({ id: id, remove: true });
    };

    /*
    ** @Click
    ** @Description: Remove category confirm
    */

    handleConfirmRemoveConfirm = () => {
      this._Remove(this.state.id);
    };

    /*
    ** @Click
    ** @Description: Remove category cancel
    */

    handleConfirmRemoveCancel = () => {
      this.setState({ remove: false });
    };

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/products/page/${ page }`,
        search: this.props.location.search
      });

    };

    /*
    ** @Event
    ** @Description: Show categories
    */

    handleCategories = (ids) => {
      this.setState({ categoriesListShow: true, categoriesListIds: ids });
    };

    /*
    ** @Event
    ** @Description: Close categories
    */

    handleCategoriesClose = () => {
      this.setState({ categoriesListShow: false, categoriesListIds: [] });
    };

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ productsFilterShow: true });
    };

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ productsFilterShow: false });
    };

    /*
    ** @Event
    ** @Description: Show Update
    */

    handleUpdate = () => {
      this.setState({ productsUpdateShow: true });
    };

    /*
    ** @Event
    ** @Description: Close update
    */

    handleUpdateClose = () => {
      this.setState({ productsUpdateShow: false });
    };

    /*
    ** @Event
    ** @Description: Show Clinicea Sync
    */

    handleCliniceaSync = () => {
      this.setState({ cliniceaSyncShow: true });
    }

    /*
    ** @Event
    ** @Description: Clinicea Sync
    */

    handleCliniceaSyncClose = () => {
      this.setState({ cliniceaSyncShow: false });
    };

    /*
    ** @Event
    ** @Description: Clinicea Sync Refresh
    */

    handleSuccesOnSync = () => {

      // @State
      this.setState({ loading: false });

      this._All(this.props.match.params.page || 0);
      
    };

    /*
    ** @Event
    ** @Description: Success update
    */

    handleUpdateSuccess = (response) => {

      // @State close form
      this.handleUpdateClose();

      // @Dispatch alert success
      this.props.alertShow({
        open: true,
        severity: 'success',
        message: `${this.props.t('messages.success_update_1')} ${response.entities} ${this.props.t('messages.registies')}`
      });

      // @Service
      this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));

    };

    /*
    ** @Event
    ** @Description: Edit custom fields
    */

    handleEditCustomFields = (id) => {
      this.setState({ editCustomFields: true, id });
    }

    /*
    ** @Event
    ** @Description: Close update
    */

    handleEditCustomFieldsClose = () => {
      this.setState({ editCustomFields: false, id: null });
    };

    /*
    ** @Service
    ** @Description: Toggle Product Status
    */

    handleToggleProductProp = ({ id, name }, newValues) => {
      
      this.setState({ loading: false });

      // @Service
      // TODO: No necesito mandar name, pero "ProductService.update" lo pide indirectamente
      ProductService.update(id, { ...newValues, name }).then(({ product }) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: TEXT.message.success
        });

        const nextEntities = this.state.entities.map(entity => {
          if (entity.id === product.id) {
            
            return {
              ...entity,
              ...newValues 
            };
          } else {
            return entity;
          }
        });

        this.setState({
          entities: nextEntities,
          loading: true
        });
        

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: true });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });


      
    };

    render(){

      const { session, t } = this.props;
      const { id, permission, name, loading, entities, thead, total, page, remove, productsUpdateShow, productsFilterShow, categoriesListShow, editCustomFields, categoriesListIds, cliniceaSyncShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <ButtonsGroup>
                { this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title="Eliminar filtros"
                    placement="left"
                    component={ NavLink }
                    to="/products"
                  />
                )}
                <Button
                  icon="filter"
                  size="medium"
                  onClick={ this.handleFilter }
                >
                  {t('button.filter')}
                </Button>

                { hasPermission(session.user.permissions, API.permissions.products_management) && (
                  <React.Fragment>
                    <Button
                      icon="filter"
                      size="medium"
                      onClick={ this.handleUpdate }
                    >
                      {t('button.update')}
                    </Button>

                    {hasPermission(session.user.permissions, API.permissions.clinicea_sync_products) && (
                      API.integration.clinicea.enabled && (
                        <Button
                          icon="sync"
                          size="medium"
                          onClick={this.handleCliniceaSync}
                        >
                          {t('button.sync')}
                        </Button>
                      )
                    )}

                    <Button
                      component={ NavLink }
                      to="/products/create"
                      icon="add"
                      size="medium"
                    >
                      {t('button.create')}
                    </Button>
                  </React.Fragment>
                )}
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

                {/* Alert confirm Delete */}
                <AlertConfirm
                   status={ remove }
                   message={t('messages.delete_confirm')}
                   cancel={ () => this.handleConfirmRemoveCancel() }
                   confirm={ () => this.handleConfirmRemoveConfirm() }
                />

                {/* Categories list by ids */}
                <Modal
                  title={t('text.categories')}
                  status={ categoriesListShow }
                  handleCancel={ () => this.handleCategoriesClose() }
                >
                  <CategoryList ids={ categoriesListIds }/>
                </Modal>

                {/* Filter */}
                <Modal
                    status={ productsFilterShow }
                    title={t('text.filter')}
                    handleCancel={ () => this.handleFilterClose() }
                    maxWidth="sm">
                    <PageProductFilter onSuccess={ this.handleFilterClose }/>
                </Modal>

                {/* Update */}
                <Modal
                    status={ productsUpdateShow }
                    title={t('text.massive_price_update')}
                    handleCancel={ () => this.handleUpdateClose() }
                    maxWidth="md">
                    <PageProductUpdate onSuccess={ this.handleUpdateSuccess } />
                </Modal>

                {/* Categories list by ids */}
                <Modal
                  title={t('text.custom_fields')}
                  status={ editCustomFields }
                  handleCancel={ () => this.handleEditCustomFieldsClose() }
                >
                  <WrapPageProductField id={id} onSuccess={ this.handleEditCustomFieldsClose } />
                </Modal>

                {/* Clinicea Sync */}
                {hasPermission(session.user.permissions, API.permissions.clinicea_sync_products) && (
                  API.integration.clinicea.enabled && (
                    <Modal
                      status={cliniceaSyncShow}
                      title={t('text.clinicea_product_sync')}
                      handleCancel={() => this.handleCliniceaSyncClose()}
                      maxWidth="md">
                      <CliniceaServiceSync 
                        onSuccess={this.handleSuccesOnSync}
                      />
                    </Modal>
                  )
                )}

                {/* List */}
                <Table
                  thead={ thead }
                  loading={ loading }
                  total={ total }
                  page={ page }
                  onChangePage={ this.handleChangePage }
                >
                  { entities.length > 0 && (
                    entities.map(entity => {
                      return (
                        <Row key={ entity._id }>
                          {/* SKU */}
                          <Cell nowrap>
                            { entity.sku ? entity.sku : '-' }
                          </Cell>
                          {/* Product Name + Link */}
                          <Cell nowrap>
                            <LinkProduct product={ entity }/>
                          </Cell>
                          {/* Price */}
                          <Cell>
                            <Currency value={ entity.price }/>
                          </Cell>
                          {/* Status/Enabled */}
                          <Cell>
                            { hasPermission(session.user.permissions, API.permissions.products_management)?
                              <div
                                onClick={() => this.handleToggleProductProp(entity, { enabled: !(entity.enabled) })}
                                style={{ cursor: 'pointer' }}
                              >
                                <Tag
                                  text={ isStatus(entity.enabled) }
                                  color={ isStatusColor(entity.enabled) }
                                />
                              </div>
                              :
                              <Tag
                                text={isStatus(entity.enabled)}
                                color={isStatusColor(entity.enabled)}
                              />
                            }
                          </Cell>
                          {/* Public */}
                          <Cell>
                            {hasPermission(session.user.permissions, API.permissions.products_management) ?
                              <div
                                onClick={() => this.handleToggleProductProp(entity, { public: !(entity.public) })}
                                style={{ cursor: 'pointer' }}
                              >
                                <Tag
                                  text={ isBinary(entity.public) }
                                  color={ isBinaryColor(entity.public) }
                                />
                              </div>
                              :
                              <Tag
                                text={isBinary(entity.public)}
                                color={isBinaryColor(entity.public)}
                              />
                            }
                          </Cell>
                          {/* Categories */}
                          <Cell>
                            { entity.categories.length > 0 && (
                              <Chip
                                label={ entity.categories[0].name }
                                variant="outlined"
                                color="secondary"
                                onClick={ () => this.handleCategories(entity.categories) }
                                clickable
                              />
                            )}
                          </Cell>
                          {/* Actions */}
                          <Cell align="right" nowrap>
                            { hasPermission(session.user.permissions, API.permissions.products_management) && (
                              <React.Fragment>
                                <Button
                                  color="primary"
                                  icon="input"
                                  title={t('button.edit_custom_fields')}
                                  onClick={ () => this.handleEditCustomFields(entity._id)  }
                                />
                                <Button
                                  color="primary"
                                  icon="edit"
                                  component={ NavLink }
                                  title={t('button.edit')}
                                  to={ `/products/edit/${ entity._id }` }
                                />
                                <Button
                                  color="danger"
                                  icon="cancel"
                                  title={t('button.delete')}
                                  onClick={ () => this.handleRemove(entity._id)  }
                                />
                              </React.Fragment>
                            )}
                          </Cell>
                        </Row>
                      )
                    })
                  )}

                  {/* No results found */}
                  { (loading && entities.length === 0) && (
                    <Row>
                      <Cell colSpan={ thead.length } align="center">
                        { TEXT.message.without_results }
                      </Cell>
                    </Row>
                  )}

                </Table>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__ProductList));
