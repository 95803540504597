import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles, CircularProgress } from './../../../assets/theme/default';
import { TextField, Autocomplete, Checkbox } from './../../../assets/theme/input';
import { CheckBoxOutlineBlankIcon, CheckBoxIcon } from './../../../assets/theme/icon';
import { StoreService } from './../../../services';

const styles = theme => ({
  paper: {
    background: theme.palette.background.default,
    border: `1px solid ${ theme.palette.grey.main }`
  },
  label: {
    fontWeight: 500,
    position: 'relative',
    transition: 'none',
    transform: 'none',
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.fontSize
  },
  inputRoot: {
    marginTop: `0 !important`,
    border: `1px solid ${ theme.palette.grey.dark }`,
    background: '#fafbfc',
    padding: `${ theme.spacing(1) }px ${ theme.spacing(5) }px ${ theme.spacing(1) }px ${ theme.spacing(1) }px !important`,
    borderRadius: `3px !important`,
    '&:before': {
      display: 'none !important'
    },
    '&:after': {
      display: 'none !important'
    }
  },
  inputInput: {
    padding: '0 !important'
  },
  inputEndAdornment: {
    right: theme.spacing(1)
  }
});

class StoreAutocompleteMultiple extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      entities: [],
      selected: []
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._All();
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component will recive props.
  */
  componentDidUpdate(prev_props){
    if(this.props.stores !== prev_props.stores){
      this.handleSelect(this.props.stores);
    }
  }

  /*
  ** @Service
  ** @Description: List all stores
  */

  _All = () => {
    StoreService.list(0, { items: 1000 }).then((response) => {
      this.setState({ loading: false, entities: response.stores });
    });
  }

  /*
  ** @Event
  ** @Description: on select store
  */

  handleSelect = (listOfStores) => {
    this.setState({ selected: listOfStores });
  }

  /*
  ** @Event
  ** @Description: on change selection
  */

  handleOnChange = (value) => {

    // @If
    // @Description: If value !== null select category
    if (value) {

      this.handleSelect(value);

    }else{

      // @Store
      // @Description: Set store selected null
      this.setState({ selected: [] });

    }

    // @Props
    // @Description: Send value to parent
    this.props.onSelect(value);

  }

  /*
  ** @Render
  */

  render() {

    const { classes, t } = this.props;
    const { loading, entities, selected } = this.state;

    const uncheckediIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon    = <CheckBoxIcon fontSize="small" />;

    return(
      <Autocomplete
        loading={ loading }
        loadingText="Cargando..."
        classes={{
          paper: classes.paper,
          inputRoot: classes.inputRoot,
          input: classes.inputInput,
          endAdornment: classes.inputEndAdornment
        }}
        value={ selected }
        size="small"
        multiple
        disableCloseOnSelect
        noOptionsText="No se encontraron resultados"
        options={ entities }
        onChange={(event, newInputValue) => {
          this.handleOnChange(newInputValue)
        }}
        getOptionLabel={
          (option) => option.name
        }
        getOptionSelected={(option, value) => {
          return (option.name === value.name);
        }}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={ uncheckediIcon }
              checkedIcon={ checkedIcon }
              style={{ marginRight: 8 }}
              checked={ selected }
              size="small"
            />
            { option.name }
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('text.stores')}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.label
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    )
  }

}

export default withTranslation()(withStyles(styles)(StoreAutocompleteMultiple));
