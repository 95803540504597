import React from 'react';
import { makeStyles, Typography } from './../../../../assets/theme/default';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from './../../../../assets/theme/dialog';
import Button from './../../button/default';

const useStyles = makeStyles(theme => ({
  header: {
    borderBottom: `1px solid ${ theme.palette.border.main }`
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
    contentText: {
      margin: 0,
      textAlign: 'center'
    },
  actions: {
      borderTop: `1px solid ${ theme.palette.border.main }`,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
  }
}));

const AlertConfirm = ({ status, title = "Atención", message, cancel, confirm, handleConfirmDeleteUserCancel, ...rest}) => {

  const classes = useStyles();

  return (
    <Dialog
      open={ status }
      onClose={ handleConfirmDeleteUserCancel }
      fullWidth
      maxWidth="xs"
    >

      { title && (
        <DialogTitle className={ classes.header }>
          <Typography component="p" variant="body1">
            { title }
          </Typography>
        </DialogTitle>
      )}

      <DialogContent className={ classes.content }>
        <DialogContentText className={ classes.contentText } dangerouslySetInnerHTML={{ __html: message }}/>
      </DialogContent>

      <DialogActions className={ classes.actions }>
        <span>
          <Button aria-label="Cancelar" color="danger" icon="cancel" onClick={ cancel }>
              Cancelar
          </Button>
        </span>
        <span>
          <Button aria-label="Confirmar" onClick={ confirm } color="success" icon="add">
            Confirmar
          </Button>
        </span>
      </DialogActions>

    </Dialog>
  )

}

export default AlertConfirm;
