
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const RedirectToState = () => {
    const location = useLocation();
    return (
        <Redirect to={{ pathname: '/login', state: { from: location.pathname } }}/>
    )
}

export default RedirectToState
