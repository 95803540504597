import request from './../../../utilities/request';
import localStorageToken from './../../../utilities/storage';

function servicesSync(values) {
  return request({
    url:    `/integration/clinicea/services/sync`,
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data: values
  });
};

function patientSync(values) {
  return request({
    url:    `/integration/clinicea/patients/sync`,
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data: values
  });
};

function staffSync(values) {
  return request({
    url:    `/integration/clinicea/staff/sync`,
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data: values
  });
};


const IntegrationCliniceaService = {
    servicesSync,
    patientSync,
    staffSync
};

export default IntegrationCliniceaService;
