import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Typography } from './../../../../assets/theme/default';
import { Table, Row, Cell, LinkUser, Date, DateToString, Button, Modal } from './../../../../components/ui';
import { SupportService } from './../../../../services';
import { isUndefined } from './../../../../utilities/utils';


import TEXT from './../../../../config/text';

class UtilClientSupport extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: false,
        page: 0,
        total: 0,
        entities: [],
        thead: [
          { id: 'subject', name: this.props.t('field.subject') },
          { id: 'user', name: this.props.t('field.user') },
          { id: 'created_at', name: this.props.t('field.date') },
          { id: 'actions', name: this.props.t('field.actions'), align: 'right' }
        ],
        emailShow: false,
        emailTemplate: null 
      };
    }

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        const page = this.props.match.params.page || 0;
        if(!isUndefined(page)){
          if(!isNaN(page) && page >= 0){
            this._All(page);
          }else{
            this.props.history.push(`/clients/profile/${this.props.id}/support`);
          }
        }else{
          this._All(this.state.page);
        }

      }
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      const page = this.props.match.params.page || 0;
      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          this._All(page);
        }else{
          this.props.history.push(`/clients/profile/${this.props.id}/support`);
        }
      }else{
        this._All(this.state.page);
      }
    };

    /*
    ** @Service
    ** @Description: List
    */

    _All = (page) => {

      SupportService.listByClientId(this.props.id).then((response) => {

        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.messages || [],
          total: response.total
        });

      }).catch((error) => {

        this.setState({ loading: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: TEXT.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description: Show email
    */

    handleShowEmail = (entity) => {
      this.setState({ emailShow: true, emailTemplate: entity });
    };

    /*
    ** @Event
    ** @Description: Close email
    */

    handleShowEmailClose = () => {
      this.setState({ emailShow: false });
    };

       /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/clients/profile/${this.props.id}/support/page/${page}`
      });

    };

    render(){

      const { loading, entities, thead, total, page, emailShow, emailTemplate } = this.state;
      let emailTemplateTitle = ''

      if(emailTemplate){
        emailTemplateTitle = `${emailTemplate.subject} (${ DateToString(emailTemplate.created_at) })`;
      }

      return(
        <React.Fragment>
          <Modal
            status={ emailShow }
            title={emailTemplateTitle}
            handleCancel={ () => this.handleShowEmailClose() }
            maxWidth="md"
          >
              { emailTemplate ? (
                <div dangerouslySetInnerHTML={{__html: emailTemplate.message }}/>
              ) : (
                <Typography>Información no disponible.</Typography>
              )}
          </Modal>

          <Table
            thead={ thead }
            loading={ loading }
            total={ total }
            page={ page }
            onChangePage={ this.handleChangePage }
          >
            { entities.length > 0 && (
              entities.map(entity => {
                return (
                  <Row key={ entity._id }>
                    <Cell nowrap>
                    { entity.subject }
                    </Cell>
                    <Cell>
                      <LinkUser user={ entity.user }/>
                    </Cell>
                    <Cell>
                      <Date value={entity.created_at}/>
                    </Cell>
                    <Cell align="right">
                      <Button
                        icon="info"
                        title="Ver email enviado"
                        onClick={() => this.handleShowEmail(entity)}
                      />
                    </Cell>
                  </Row>
                )
              })
            )}

            { (loading && entities.length === 0) && (
              <Row>
                <Cell colSpan={ thead.length } align="center">
                  { TEXT.message.without_results }
                </Cell>
              </Row>
            )}

          </Table>
        </React.Fragment>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(UtilClientSupport)));