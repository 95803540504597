import React from 'react';
import PropTypes from 'prop-types';
import { SelectField, OptionField } from './../../../../components/ui/input';
import { CurrencyService } from './../../../../services';

class CurrencySelect extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      entities: []
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._All();
  }

  /*
  ** @Service
  ** @Description: List all permissions
  */

  _All = () => {
    CurrencyService.list().then((response) => {

      // @Sate
      this.setState({ entities: response.currencies });

      // @Find
      // @Description: Find currency default
      let currencyDefault = response.currencies.find((currency) => currency.default === true);

      // @Event
      if(this.props.onLoad){
        this.props.onLoad(currencyDefault);
      }

    });
  }

  /*
  ** @Render
  */

  render() {

    const { entities } = this.state;

    return(
      <SelectField { ...this.props }>
        { entities.length > 0 ? (
          <React.Fragment>
            <OptionField value="-1">Seleccionar...</OptionField>
            { entities.map((entity, index) => {
              return(
                <OptionField key={ index } value={ entity.prefix }>
                  { entity.name } ({ entity.prefix })
                </OptionField>
              )
            })}
          </React.Fragment>
        ) : (
          <OptionField value="-1">Cargando...</OptionField>
        )}
      </SelectField>
    )
  }

}

CurrencySelect.propTypes = {
  onLoad: PropTypes.func
};

export default CurrencySelect;
