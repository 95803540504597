import request from './../../../utilities/request';
import localStorageToken from './../../../utilities/storage';

function list(page) {
  return request({
    url:    `/shop/cart/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function listAll() {
  return request({
    url:    `/shop/cart/list/`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function find(id) {
  return request({
    url:    `/shop/cart/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function generateTokenReport(values) {
  return request({
    url:    '/shop/cart/report/token/',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

const ShopCartService = {
  list, listAll, find, generateTokenReport
};


export default ShopCartService;
