import React from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change  } from 'redux-form';
import moment from 'moment';
import { Grid } from './../../../../assets/theme/layout';
import { Content, Button, ButtonsGroup } from './../../../../components/ui';
import { InputDateField } from './../../../../components/ui/input';
import { stringifyQueryString, parseQueryString } from './../../../../utilities/utils';

class UtilFormRecordListFilter extends React.Component {

  constructor(props){
    super(props);
    this.state = {};
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){

    this._Init();

  }

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {

    // @Search
    // @Description: Set query values
    const search = parseQueryString(this.props.location.search);

    Object.entries(search).forEach(([key,value]) => {
      this.props.dispatch(change(this.props.form, key, value));
    });

  }

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {
    this.props.history.push(`/forms/records/${this.props.id}/page/${this.props.page}?${ stringifyQueryString(values) }`);
    this.props.onSuccess();
  }

  render() {

    const { handleSubmit } = this.props;

    return(

      <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

        <Content disablePadding divider>
          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                name="date_from"
                component={ InputDateField }
                label="Desde"
                type="text"
                required
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Field
                name="date_to"
                component={ InputDateField }
                label="Hasta"
                type="text"
                disableFuture
                required
              />
            </Grid>
          </Grid>
        </Content>

        <Content disablePadding>
          <ButtonsGroup align="right">
            <Button aria-label="Aplicar" icon="save" color="primary" type="submit">
              Aplicar
            </Button>
          </ButtonsGroup>
        </Content>

      </form>

    )
  }

}

UtilFormRecordListFilter = reduxForm({
  form: 'UtilFormRecordListFilter',
  initialValues: {
    date_from: moment().subtract(7, 'days').startOf('day').format(),
    date_to: moment().endOf('day').format()
  },
  enableReinitialize: true
})(UtilFormRecordListFilter);

export default withRouter(UtilFormRecordListFilter);
