import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Grid } from './../../../../assets/theme/layout';
import { Content, Toolbar, Tag, Data, Date, LinkUser } from './../../../../components/ui';
import { isStatus, isStatusColor } from './../../../../utilities/utils';

class UtilUserData extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        loading: false
      };
    }

    render(){

      const { user, titlePersonal, hidePersonal, hideConfiguration, t } = this.props;

      const _hidePersonal      = !hidePersonal ? false : hidePersonal;
      const _hideConfiguration = !hideConfiguration ? false : hideConfiguration;

      return(

        user && (
          <React.Fragment>

            {/* Personal */}
            { !_hidePersonal && (
              <Content disableMargin divider>
                <Toolbar
                  title={ titlePersonal ? titlePersonal : t('text.personal') }
                  size="small"
                />
                <Grid container spacing={3}>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={t('field.full_name')}
                      text={ <LinkUser user={ user }/> }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={t('field.email')}
                      text={ user.email }
                      email
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={t('field.last_login')}
                      text={ <Date value={ user.last_login }/> }
                      gutterBottom
                    />
                  </Grid>
                </Grid>
              </Content>
            )}

            {/* Configurtion */}
            { !_hideConfiguration && (
              <Content disableMargin>
                <Toolbar
                  title={t('text.configuration')}
                  size="small"
                />
                <Grid container spacing={3}>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={t('field.status')}
                      text={
                        <Tag
                          text={ isStatus(user.enabled) }
                          color={ isStatusColor(user.enabled) }
                        />
                      }
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={t('field.profile')}
                      text={
                        <Tag
                          text={ user.profile }
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={t('field.commission_percentage')}
                      text={
                        <Tag
                          text={ `${ user.commission }%` }
                          color="primary"
                        />
                      }
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={6}>
                    <Data
                      label={this.props.t('field.reference')}
                      text={ user.reference ? user.reference : '-' }
                      gutterBottom
                    />
                  </Grid>
                </Grid>
              </Content>
            )}

          </React.Fragment>

        )

      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UtilUserData));
