import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Page, Wrapper, Paper, Button, ButtonsGroup, Toolbar, AlertConfirm, Tag, Modal, Table, Row, Cell, Date, LinkEntity, LinkShipping } from './../../../components/ui';
import { ShippingService } from './../../../services';
import { isUndefined, parseQueryString, hasPermission, findPermissionProps } from './../../../utilities/utils';
import UtilShippingOrderFilter from './../filter';

import API from './../../../config/api';
import TEXT from './../../../config/text';

class Page__ShippingList extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        loading: false,
        page: 0,
        total: 0,
        id: null,
        entities: [],
        remove: false,
        thead: [
          { id: 'code_internal', name: 'OV' },
          { id: 'event', name: 'Estado' },
          { id: 'code_external', name: 'Reserva' },
          { id: 'entity', name: 'Empresa' },
          { id: 'from', name: 'Origen' },
          { id: 'etd', name: 'ETD' },
          { id: 'to', name: 'Destino' },
          { id: 'eta', name: 'ETA' },
          { id: 'updated_at', name: 'Actualizado' },
          { id: 'actions', name: 'Acciones', align: 'right' }
        ],
        leadFilterShow: false
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component will recive props.
    */
    componentDidUpdate(prev_props){

      if(this.props.match.params.page !== prev_props.match.params.page){
        this._All(this.props.match.params.page || 0);
      }

      if(this.props.location.search !== prev_props.location.search){
        this._All(this.props.match.params.page || 0, parseQueryString(this.props.location.search));
      }

    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.shipping, "Envíos") });

      const page = this.props.match.params.page || 0;

      if(!isUndefined(page)){
        if(!isNaN(page) && page >= 0){
          this._All(page);
        }else{
          this.props.history.push('/shipping');
        }
      }else{
        this._All(this.state.page);
      }

    };

    /*
    ** @Service
    ** @Description: FindAll
    */

    _All = (page) => {

      ShippingService.list(page, parseQueryString(this.props.location.search)).then((response) => {

        this.setState({
          loading: true,
          page: !isUndefined(page) ? parseInt(page,10) : 0,
          entities: response.orders || [],
          total: response.total
        });

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Remove Store
    */

    _Remove = (id) => {

      ShippingService.orderRemove(id).then((response) => {

        // @State
        this.setState({ remove: false });

        // @Service find all by page
        this._Init();

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: `El proceso se ejecuto correctamente.`
        });

      }).catch((error) => {

        // @State
        this.setState({ remove: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : `El proceso no pudo ser ejecutado.`
        });

      });

    };

    /*
    ** @Handle
    ** @Description: Change page
    */

    handleChangePage = (event, page) => {

      // @State
      this.setState({ page: page, loading: false });

      // @History
      this.props.history.push({
        pathname: `/shipping/page/${ page }`,
        search: this.props.location.search
      });

    };

    /*
    ** @Event
    ** @Description: Show filter
    */

    handleFilter = () => {
      this.setState({ leadFilterShow: true });
    };

    /*
    ** @Event
    ** @Description: Close filter
    */

    handleFilterClose = () => {
      this.setState({ leadFilterShow: false });
    };

    /*
    ** @Event
    ** @Description: Remove order
    */

    handleRemove = (id) => {
      this.setState({ id: id, remove: true });
    };

    /*
    ** @Click
    ** @Description: Remove order confirm
    */

    handleConfirmRemoveConfirm = () => {
      this._Remove(this.state.id);
    };

    /*
    ** @Click
    ** @Description: Remove order cancel
    */

    handleConfirmRemoveCancel = () => {
      this.setState({ remove: false });
    };

    render(){

      const { session } = this.props;
      const { permission, name, loading, entities, thead, total, page, remove, leadFilterShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <ButtonsGroup>
                { this.props.location.search !== '' && (
                  <Button
                    icon="cancel"
                    size="medium"
                    color="danger"
                    title="Eliminar filtros"
                    placement="left"
                    component={ NavLink }
                    to="/shipping"
                  />
                )}
                <Button
                  icon="filter"
                  size="medium"
                  onClick={ this.handleFilter }
                >
                  Filtrar
                </Button>
                { hasPermission(session.user.permissions, API.permissions.shipping_management) && (
                  <Button
                    component={ NavLink }
                    to="/shipping/create"
                    icon="add"
                    size="medium"
                  >
                    Crear
                  </Button>
                )}
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>

              {/* Alert confirm Delete */}
              <AlertConfirm
                 status={ remove }
                 message="¿Confirma la eliminación?"
                 cancel={ () => this.handleConfirmRemoveCancel() }
                 confirm={ () => this.handleConfirmRemoveConfirm() }
              />

              <Modal
                  status={ leadFilterShow }
                  title="Filtrar envíos"
                  handleCancel={ () => this.handleFilterClose() }
                  maxWidth="sm">
                  <UtilShippingOrderFilter onSuccess={ this.handleFilterClose }/>
              </Modal>

              <Table
                thead={ thead }
                loading={ loading }
                total={ total }
                page={ page }
                onChangePage={ this.handleChangePage }
              >
                { entities.length > 0 && (
                  entities.map(entity => {
                    return (
                      <Row key={entity._id}>
                        <Cell>
                          { entity.code_internal ? (
                            <LinkShipping shipping={entity}/>
                          ) : (
                            <React.Fragment>
                              [No disponible]
                            </React.Fragment>
                          )}
                        </Cell>
                        <Cell nowrap>
                          { entity.event ? (
                            <Tag text={entity.event.name}/>
                          ) : (
                            <React.Fragment>
                              [No disponible]
                            </React.Fragment>
                          )}
                        </Cell>
                        <Cell nowrap>
                          { entity.code_external ? entity.code_external : TEXT.message.not_available}
                        </Cell>
                        <Cell nowrap>
                          { entity.entity ? (
                            <LinkEntity entity={ entity.entity }/>
                          ) : (
                            <React.Fragment>
                              {entity.company ? entity.company : TEXT.message.not_available}
                            </React.Fragment>
                          )}
                        </Cell>
                        <Cell nowrap>
                          { entity.from ? (
                            <React.Fragment>
                              {entity.from.name}
                              { entity.from.country && (
                                <React.Fragment>
                                  &nbsp;[{entity.from.country.alpha2Code}]
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>{TEXT.message.not_available}</React.Fragment>
                          )}
                        </Cell>
                        <Cell nowrap>
                          { entity.etd ? (
                            <Date value={entity.etd} />
                          ) : (
                            TEXT.message.not_available
                          )}
                        </Cell>
                        <Cell nowrap>
                          { entity.to ? (
                            <React.Fragment>
                              {entity.to.name}
                              { entity.to.country && (
                                <React.Fragment>
                                  &nbsp;[{entity.to.country.alpha2Code}]
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          ) : (
                            TEXT.message.not_available
                          )}
                        </Cell>
                        <Cell nowrap>
                          { entity.eta ? (
                            <Date value={entity.eta} />
                          ) : (
                            TEXT.message.not_available
                          )}
                        </Cell>
                        <Cell>
                          <Date value={entity.updated_at} />
                        </Cell>
                        <Cell align="right" nowrap>
                          <Button
                            icon="info"
                            component={ NavLink }
                            title="Información"
                            to={ `/shipping/detail/${ entity._id }` }
                          />
                          { hasPermission(session.user.permissions, API.permissions.shipping_management) && (
                            <React.Fragment>
                              <Button
                                color="primary"
                                icon="edit"
                                component={ NavLink }
                                title="Editar"
                                to={ `/shipping/edit/${ entity._id }` }
                              />
                              <Button
                                color="danger"
                                icon="cancel"
                                title="Eliminar"
                                onClick={ () => this.handleRemove(entity._id)  }
                              />
                            </React.Fragment>
                          )}
                        </Cell>
                      </Row>
                    )
                  })
                )}

                { (loading && entities.length === 0) && (
                  <Row>
                    <Cell colSpan={ thead.length } align="center">
                      { TEXT.message.without_results }
                    </Cell>
                  </Row>
                )}

              </Table>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page__ShippingList);
