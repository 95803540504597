import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { withStyles, Typography, Box, Avatar } from './../../../../assets/theme/default';
import { List, ListItem, ListItemText, ListItemSecondaryAction, ListItemAvatar } from './../../../../assets/theme/list';
import { Link } from './../../../../assets/theme/button';
import { Modal } from './../../modal';
import Loading from './../../loading/default';
import { AttachmentService } from './../../../../services';
import { Button } from './../../../../components/ui';
import { cleanPathImage } from './../../../../utilities/utils';

import API from './../../../../config/api'

const styles = theme => ({
  root: { height: 200, position: 'relative' },
  container: {
    position: 'relative'
  },
  paper: {
    border: `1px solid ${ theme.palette.grey.dark }`,
    background: '#fbfbfb',
    borderRadius: 3,
    padding: theme.spacing(1)
  },
  label: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightBold
  },
  list: {},
  zone: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9999,
    background: 'rgba(255,255,255,0.8)',
    textAlign: 'center',
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px dashed ${ theme.palette.grey[200] }`,
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.grey[400]
    }
  },
  zone__text: theme.typography.display1
});


class Upload extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      open: false,
      dropzoneRef: null,
      dropzoneActive: true,
      attachments: []
    }
    this.dropzoneRef = React.createRef();
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component will recive props.
  */
  componentDidUpdate(prev_props){
    if(this.props.attachments){
      if(this.props.attachments.length !== prev_props.attachments.length){
        this.setState({ attachments: this.props.attachments });
      }
    }
  }

  /*
  ** @Service
  ** @Description: Find all
  */

  _Upload = (files) => {

    let formData = new FormData();

    files.forEach((file,index) => {
      formData.append('uploaded_file', files[0], files[0].name);
    });

    // @State
    // @Description: Loading show
    this.setState({ loading: true });

    AttachmentService.upload(formData).then((response) => {

      const newAttachments = response.attachment.map((attachment) => {
        return({
          name: attachment.name,
          path: cleanPathImage(attachment.path),
          size: attachment.size,
          type: attachment.type
        })
      });

      // @Return to component
      this.props.onSuccess([
        ...this.state.attachments,
        ...newAttachments
      ]);

      // @State
      this.setState({ open: false, loading: false });

    }).catch((error) => {

      // @State
      // @Description: Loading hide
      this.setState({ open: false, loading: false });

      // @Dispatch alert error
      this.props.AlertShow({ open: true, variant: 'error', message: Error.internal });

    });

  }

  /*
  ** @Event
  */

  onDragEnter() {}

  /*
  ** @Event
  */

  onDragLeave() {}

  /*
  ** @Event
  */

  onDrop(files) {

    if(files.length > 0){
      this._Upload(files);
    }

  }

  /*
  ** @Event
  */

  onDropRejected(files) {

    this.props.AlertShow({
      open: true,
      variant: 'error',
      message: `We found ${ files.length } file(s) that do not meet the requirements`
    });

  }

  /*
  ** @Event
  */

  onClose() {

    this.props.AtachmentUploadHide({
      open: false
    });

  }

  /*
  ** @Event
  ** @Description: Open upload
  */

  handleOpen() {
    this.setState({ open: true });
  }

  /*
  ** @Event
  ** @Description: Cancel upload
  */

  handleCancel() {
    this.setState({ open: false });
  }

  /*
  ** @Event
  ** @Description: Delete attachment
  */

  handleDelete = (index) => {
    this.setState(
      { attachments: this.state.attachments.filter(function(value, i, arr){ return i !== index;}) },
      () => this.props.onSuccess(this.state.attachments)
    );
  }

  render() {

    const { classes, title, label, button = this.props.t('button.select'), multiple = false, avatar = false, paper = false, required = false } = this.props;
    const { loading, open, attachments } = this.state;

    return(
      <React.Fragment>

        <div className={ classnames(classes.container, paper ? classes.paper : null ) }>

          { label && (
            <Typography classes={{ root: classes.label }}>
              { label } { required && (<span>*</span>)}
            </Typography>
          )}

          <Button
            aria-label={ button }
            color="primary"
            onClick={ this.handleOpen.bind(this) }
            fullWidth
          >
            { button }
          </Button>

          { (attachments.length > 0) && (
            <List classes={{ root: classes.list }}>
              { attachments.map((attachment, index) => {
                return(
                  <ListItem dense divider disableGutters key={index}>

                    { avatar && (
                      <ListItemAvatar>
                        <Avatar alt={ attachment.name } src={`${ API.endpoint.uploads }${ cleanPathImage(attachment.path) }`} />
                      </ListItemAvatar>
                    )}

                    <ListItemText>
                      <Link href={`${ API.endpoint.uploads }${ cleanPathImage(attachment.path) }`} target="_blank">
                        { attachment.name }
                      </Link>
                    </ListItemText>

                    <ListItemSecondaryAction>
                      <Button color="danger" size="small" style={{padding:4}} icon="cancel" title={this.props.t(button.delete)} onClick={ () => this.handleDelete(index) }/>
                    </ListItemSecondaryAction>

                  </ListItem>
                )
              })}
            </List>
          )}

        </div>

        <Modal
            status={ open }
            title={ title }
            handleCancel={ this.handleCancel.bind(this) }
            maxWidth="sm">

            { loading && (
              <Loading variant="absolute"/>
            )}

            <Dropzone
              disableClick
              ref={(node) => { this.dropzoneRef = node; }}
              maxSize={ API.upload.attachment_max_size }
              accept={ API.upload.attachment_available_ext.toString() }
              multiple={ multiple }
              onDrop={this.onDrop.bind(this)}
              onDragEnter={this.onDragEnter.bind(this)}
              onDragLeave={this.onDragLeave.bind(this)}
              onDropRejected={this.onDropRejected.bind(this)}
              onFileDialogCancel={ this.onClose.bind(this) }
            >

              {({getRootProps, getInputProps}) => (
                <section style={{position: 'relative', zIndex: 1}}>
                  <Box {...getRootProps()} className={ classes.root } style={{outline: 'none'}}>
                    <input {...getInputProps()} />
                    <Box className={ classes.zone }>
                      <Box className={ classnames(classes.zone__container, classes.zone__text ) } >
                        {this.props.t('text.drop_file')}
                      </Box>
                    </Box>
                  </Box>
                </section>
              )}

            </Dropzone>

        </Modal>

      </React.Fragment>
    )
  }

}

Upload.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    AttachmentUpload: state.Upload
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    AlertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    },
    AtachmentUploadHide: (data) => {
      dispatch({
        type: 'ATTACHMENT_UPLOAD_HIDE',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Upload)));
