import React from 'react';
import { Field } from 'redux-form';
import { Grid } from './../../../../assets/theme/layout';
import { InputAdornment } from './../../../../assets/theme/input';
import { InputTextField, SelectField, OptionField } from './../../input';
import Toolbar from './../../toolbar/default';
import Button from './../../button/default';
import ButtonsGroup from './../../button/group';
import Chip from './../../chip/default';
import Paper from './../../paper/default';

const ButtonCustomFieldArray = ({ fields, meta: { error, submitFailed } }) => {
  return(
    <React.Fragment>
      <Toolbar
        title="Botones"
        subtitle="Configuración de botones call to action disponibles en el correo electrónico."
        size="small"
      >
        <Button icon="add" onClick={() => fields.push({})}>
          Crear
        </Button>
      </Toolbar>
      <Grid container spacing={1}>
        {fields.length > 0 ? (
          fields.map((entity, index) => (
            <React.Fragment key={index}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <ButtonsGroup align="center">
                  <Chip
                    label={`Botón ${ index+1 }`}
                    variant="outlined"
                    color="primary"
                    clickable={true}
                    onDelete={() => fields.remove(index)}
                  />
                </ButtonsGroup>
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Field
                    name={`${entity}.name`}
                    component={ InputTextField }
                    type="text"
                    required
                    startAdornment={
                      <InputAdornment position="start">Nombre:</InputAdornment>
                    }
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Field
                    name={`${entity}.path`}
                    component={ InputTextField }
                    type="text"
                    required
                    startAdornment={
                      <InputAdornment position="start">Link:</InputAdornment>
                    }
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Field
                  name={`${entity}.target`}
                  component={ SelectField }
                  required
                  startAdornment={
                    <InputAdornment position="start">Apertura:</InputAdornment>
                  }
                >
                  <OptionField value="_self">Misma Página</OptionField>
                  <OptionField value="_blank">Nueva Página</OptionField>
                </Field>
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Field
                  name={`${entity}.type`}
                  component={ SelectField }
                  required
                  startAdornment={
                    <InputAdornment position="start">Tipo:</InputAdornment>
                  }
                >
                  <OptionField value="anchor">Link</OptionField>
                </Field>
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Field
                  name={`${entity}.color`}
                  component={ SelectField }
                  required
                  startAdornment={
                    <InputAdornment position="start">Color:</InputAdornment>
                  }
                >
                  <OptionField value="primary">Primario</OptionField>
                  <OptionField value="secondary">Secundario</OptionField>
                </Field>
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <Field
                  name={`${entity}.size`}
                  component={ SelectField }
                  required
                  startAdornment={
                    <InputAdornment position="start">Tamaño:</InputAdornment>
                  }
                >
                  <OptionField value="large">Grande</OptionField>
                  <OptionField value="medium">Mediano</OptionField>
                  <OptionField value="small">Chico</OptionField>
                </Field>
              </Grid>
            </React.Fragment>
          ))
        ) : (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Paper style={{textAlign:'center'}}>
              Atención, no se encontraron botones disponibles, para eso puedes dar click en "Crear".
            </Paper>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  )
};

export default ButtonCustomFieldArray;
