import React from 'react';
import { connect } from 'react-redux';
import { Page, Wrapper, Paper, Content, Toolbar, Tabs, Tab, TabPanel } from './../../../components/ui';
import SettingClient from './../section/client';
import { withTranslation } from 'react-i18next';
import SettingNewsletter from './../section/newsletter';
import SettingCarts from './../section/carts';
import SettingSupport from './../section/support';
import SettingGeneral from './../section/general';
import { hasPermission } from './../../../utilities/utils';

import API from './../../../config/api';

class Page__Setting extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      section: 0
    };
  }

  /*
  ** @Event
  ** @Description: Change section tab
  */
  handleChangeSection = (event: React.ChangeEvent<{}>, index: number) => {
    this.setState({ section: index });
  };

  render(){
    const { t } = this.props;
    const { section } = this.state;

    return(
      <Page>

        <Wrapper>

          <Toolbar
            title={t('text.configuration')}
            subtitle={t('text.configuration_settings')}
          />

          <Content marginBottom>
            <Paper disablePadding>
              <Tabs
                value={section}
                onChange={this.handleChangeSection}
              >
                <Tab label={t('field.general')}/>
                <Tab label={t('field.import')}/>
                <Tab label={t('field.export')}/>
              </Tabs>
            </Paper>
          </Content>

          <Content disablePadding>
            <TabPanel value={section} index={0}>
              { hasPermission(this.props.session.user.permissions, API.permissions.settings) && (
                <Content disablePadding marginBottom>
                  <Paper disablePadding>
                    <SettingGeneral />
                  </Paper>
                </Content>
              )}
            </TabPanel>
            <TabPanel value={section} index={1}>
              { hasPermission(this.props.session.user.permissions, API.permissions.clients_management) && (
                <Content disablePadding marginBottom>
                  <Paper disablePadding>
                    <SettingClient/>
                  </Paper>
                </Content>
              )}
            </TabPanel>
            <TabPanel value={section} index={2}>
              { hasPermission(this.props.session.user.permissions, API.permissions.newsletter_management) && (
                <Content disablePadding marginBottom>
                  <Paper disablePadding>
                    <SettingNewsletter/>
                  </Paper>
                </Content>
              )}
              { hasPermission(this.props.session.user.permissions, API.permissions.shop_cart_management) && (
                <Content disablePadding marginBottom>
                  <Paper disablePadding>
                    <SettingCarts/>
                  </Paper>
                </Content>
              )}
              { hasPermission(this.props.session.user.permissions, API.permissions.support) && (
                <Content disablePadding marginBottom>
                  <Paper disablePadding>
                    <SettingSupport/>
                  </Paper>
                </Content>
              )}
            </TabPanel>
          </Content>

        </Wrapper>

      </Page>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

export default withTranslation()(connect(mapStateToProps)(Page__Setting));
