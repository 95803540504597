import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Typography } from './../../../../assets/theme/default';
import { Content, Button, ButtonsGroup, Loading, Date } from './../../../../components/ui';
import { TokenService } from './../../../../services';

import TEXT from './../../../../config/text';

class TokenPreviewGenerator extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      entity: null
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  };

  /**
  * @Event
  * @Description: Init
  */
  _Init = () => {
    this._Generate();
  };

  /**
  * @Service
  * @Description: Generate token
  */
  _Generate = () => {

    // @Service
    // @Description:
    TokenService.generate({
      identifire: this.props.identifire,
      user: this.props.session.user._id
    }).then((response) => {

      // @State
      // @Description: Set response
      this.setState({
        loading: false,
        entity: response
      });

    }).catch((error) => {

      // @State
      // @Description: Set loading hide
      this.setState({ loading: false });

      // @Dispatch alert error
      this.props.alertShow({
        open: true,
        severity: 'error',
        message: error.data ? error.data.message : TEXT.message.error
      });

    });

  };

  /*
  ** @Event
  ** @Description: Copy link
  */

  handleCopyLink = () => {
    this.props.alertShow({
      open: true,
      severity: 'info',
      message: "El enlace se copio a tu portapapeles"
    });
  };

  /*
  ** @Render
  */
  render() {

    const { link } = this.props;
    const { loading, entity } = this.state;

    return(
      <Content disablePadding disableMargin>
        { !loading ? (
          <React.Fragment>
            <Content disablePadding divider>
              <Typography align="center" variant="body2">
                <strong>¡Tu enlace ya esta listo!</strong>
                <br/>
                (Este enlace expira el <Date value={ moment.unix(entity.exp).format() } format="DD/MM/YYYY hh:mm a"/>)
              </Typography>
            </Content>
            <Content disablePadding>
              <ButtonsGroup align="center">
                <Button
                  color="primary"
                  size="medium"
                  component="a"
                  target="_blank"
                  icon="link"
                  href={`${link}?token=${entity.token}`}
                >
                  Abrir
                </Button>
                <CopyToClipboard
                  text={`${link}?token=${entity.token}`}
                  onCopy={this.handleCopyLink}
                >
                  <Button
                    color="primary"
                    size="medium"
                    icon="copy"
                  >
                    Copiar
                  </Button>
                </CopyToClipboard>
              </ButtonsGroup>
            </Content>
          </React.Fragment>
        ) : (
          <Loading/>
        )}
      </Content>
    )
  }

}

TokenPreviewGenerator.propTypes = {
  identifire: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenPreviewGenerator);
