import request from './../../../utilities/request';

function list() {
  return request({
    url:    '/country/list',
    method: 'GET'
  });
}

const CountryService = {
  list
}

export default CountryService;
