import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function upload(files) {
  return request({
    url:    `/attachment/upload`,
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data: files
  });
}

function remove(id) {
  return request({
    url:    `/attachment/remove/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'DELETE'
  });
}

const AttachmentService = {
  upload, remove
}

export default AttachmentService;
