import React from 'react';
import { connect } from 'react-redux';
import { Content, Paper, Toolbar, Tabs, Tab, TabPanel } from './../../../components/ui';
import { hasPermission } from './../../../utilities/utils';
import UtilTPPreferenceList from './../../api/todopago/preference/list';
import UtilMPPreferenceList from './../../api/mercadopago/preference/list';

import API from './../../../config/api';

class DashboardPreference extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      section: 0
    };
  }

  /*
  ** @Event
  ** @Description: Change tab
  */

  handleChangeSection = (event: React.ChangeEvent<{}>, index: number) => {
    this.setState({ section: index });
  }

  render(){

    const { session } = this.props;
    const { section } = this.state;

    return (
      <Content disablePadding>

        <Toolbar
          title="Checkout"
          subtitle="Listado de los últimos 10 checkouts generados."
          size="medium"
        />

        <Paper disablePadding>

          <Tabs
            divider
            value={ section }
            onChange={this.handleChangeSection}
          >

            { hasPermission(session.user.permissions, API.permissions.api_todo_pago) && (
              <Tab
                label="Todo Pago"
              />
            )}

            { hasPermission(session.user.permissions, API.permissions.api_mercado_pago) && (
              <Tab
                label="Mercado Pago"
              />
            )}

          </Tabs>

          { hasPermission(session.user.permissions, API.permissions.api_todo_pago) && (
            <TabPanel value={ section } index={0}>
              <UtilTPPreferenceList/>
            </TabPanel>
          )}

          { hasPermission(session.user.permissions, API.permissions.api_mercado_pago) && (
            <TabPanel value={ section } index={1}>
              <UtilMPPreferenceList/>
            </TabPanel>
          )}

        </Paper>

      </Content>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

export default connect(mapStateToProps)(DashboardPreference);
