import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, ButtonsGroup, Upload } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { FeaturedService } from './../../../services';
import { findPermissionProps } from './../../../utilities/utils';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 255){
    errors.name = TEXT.validation.maxlength(255);
  }

  if (!values.position) {
    errors.position = TEXT.validation.required;
  }else if(values.position <= 0){
    errors.position = TEXT.validation.minlength(1);
  }

  if (values.disposition === '-1') {
    errors.disposition = TEXT.validation.required;
  }

  if (values.title) {
    if(values.title.length > 1024){
      errors.title = TEXT.validation.maxlength(1024);
    }
  }

  if (values.paragraph) {
    if (values.paragraph.length > 2048) {
      errors.paragraph = TEXT.validation.maxlength(2048);
    }
  }

  if (values.link_primary_name) {
    if (values.link_primary_name.length > 255) {
      errors.link_primary_name = TEXT.validation.maxlength(255);
    }
  }

  if (values.link_primary_url) {
    if (values.link_primary_url.length > 2048) {
      errors.link_primary_url = TEXT.validation.maxlength(2048);
    }
  }

  if (values.link_secondary_name) {
    if (values.link_secondary_name.length > 255) {
      errors.link_secondary_name = TEXT.validation.maxlength(255);
    }
  }

  if (values.link_secondary_url) {
    if (values.link_secondary_url.length > 2048) {
      errors.link_secondary_url = TEXT.validation.maxlength(2048);
    }
  }

  return errors;

}

class Page__StoreForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        title: '...',
        loading: true,
        submited: false,
        id: null,
        entity: null
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.featured, this.props.t('page.featured.name')) });

      const id = this.props.match.params.id;

      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false, title: this.props.t('text.new_entry') });
      }

    };

    /*
    ** @Service
    ** @Description: Save Store
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      FeaturedService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('messages.new_featured_created')
        });

        // @Redirect
        this.props.history.push('/featured');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Featured
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      FeaturedService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('messages.featured_edited')
        });

        // @Redirect
        this.props.history.push('/featured');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Featured
    */

    _Find = (id) => {

      // @Service
      FeaturedService.find(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set note update into state
        this.setState({
          loading: false,
          id: response.featured._id,
          entity: response.featured,
          title: response.featured.name
        });

        // @Form
        // @Description: Set form fields

        // @General
        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));
        this.props.dispatch(change(form, 'position', this.state.entity.position ));
        this.props.dispatch(change(form, 'disposition', this.state.entity.disposition ));
        this.props.dispatch(change(form, 'content_box', this.state.entity.content_box ));

        // @Configuration
        this.props.dispatch(change(form, 'title', this.state.entity.title ));
        this.props.dispatch(change(form, 'label', this.state.entity.label ));
        this.props.dispatch(change(form, 'paragraph', this.state.entity.paragraph ));

        this.props.dispatch(change(form, 'link_primary_name', this.state.entity.link_primary_name ));
        this.props.dispatch(change(form, 'link_primary_url', this.state.entity.link_primary_url ));
        this.props.dispatch(change(form, 'link_primary_target', this.state.entity.link_primary_target ));
        this.props.dispatch(change(form, 'link_secondary_name', this.state.entity.link_secondary_name ));
        this.props.dispatch(change(form, 'link_secondary_url', this.state.entity.link_secondary_url ));
        this.props.dispatch(change(form, 'link_secondary_target', this.state.entity.link_secondary_target ));

        // @Images
        if (this.state.entity.image_large) {
          this.props.dispatch(change(form, 'image_large', JSON.stringify(this.state.entity.image_large) ));
        }
        if (this.state.entity.image_medium) {
          this.props.dispatch(change(form, 'image_medium', JSON.stringify(this.state.entity.image_medium) ));
        }
        if (this.state.entity.image_small) {
          this.props.dispatch(change(form, 'image_small', JSON.stringify(this.state.entity.image_small) ));
        }

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description: OnUpload file
    */

    onUploadImagenLarge = (files) => {
      this.props.dispatch(change(this.props.form, 'image_large', files.length > 0 ? JSON.stringify(files[0]) : null ));
    };

    onUploadImagenMedium = (files) => {
      this.props.dispatch(change(this.props.form, 'image_medium', files.length > 0 ? JSON.stringify(files[0]) : null ));
    };

    onUploadImagenSmall = (files) => {
      this.props.dispatch(change(this.props.form, 'image_small', files.length > 0 ? JSON.stringify(files[0]) : null ));
    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };

    // @Render
    render(){

      const { handleSubmit, invalid, image_large, image_medium, image_small } = this.props;
      const {  permission, name, loading, title, submited } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/featured"
                icon="back"
                size="medium"
              >
                {this.props.t('button.back')}
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              {/* Form Category */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* General */}
                  <Content marginBottom>
                    <Toolbar
                      title={this.props.t('text.general')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <Field
                          name="name"
                          component={ InputTextField }
                          label={this.props.t('field.name')}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={2} lg={2} md={6} sm={6} xs={4}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label={this.props.t('field.status')}
                          required
                        >
                          <OptionField value="true">{this.props.t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{this.props.t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={2} lg={2} md={6} sm={6} xs={4}>
                        <Field
                          name="position"
                          component={ InputTextField }
                          label={this.props.t('field.position')}
                          type="number"
                          required
                          min={1}
                        />
                      </Grid>
                      <Grid item xl={2} lg={2} md={6} sm={6} xs={4}>
                        <Field
                          name="disposition"
                          component={ SelectField }
                          label={this.props.t('field.layout')}
                          required
                        >
                          <OptionField key="-1" value="-1">{this.props.t('field.option_field.select')}</OptionField>
                          <OptionField key="left" value="left">{this.props.t('field.option_field.left')}</OptionField>
                          <OptionField key="right" value="right">{this.props.t('field.option_field.right')}</OptionField>
                          <OptionField key="fluid" value="fluid">{this.props.t('field.option_field.fluid')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={2} lg={2} md={6} sm={6} xs={4}>
                        <Field
                          name="content_box"
                          component={ SelectField }
                          label={this.props.t('field.box_content')}
                          required
                        >
                          <OptionField value="true">{this.props.t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{this.props.t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Configuration */}
                  <Content disablePadding divider>
                    <Toolbar
                      title={this.props.t('text.configuration')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="title"
                          component={ InputTextField }
                          label={this.props.t('field.title')}
                          type="text"
                          multiline
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Field
                          name="label"
                          component={ InputTextField }
                          label={this.props.t('field.lable')}
                          type="text"
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Field
                          name="paragraph"
                          component={ InputTextField }
                          label={this.props.t('field.text')}
                          type="text"
                          multiline
                        />
                      </Grid>

                      {/* Primary Button */}
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Field
                            name="link_primary_name"
                            component={ InputTextField }
                            label={this.props.t('field.main_button_name')}
                            type="text"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Field
                            name="link_primary_url"
                            component={ InputTextField }
                            label={this.props.t('field.main_button_link')}
                            type="text"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Field
                            name="link_primary_target"
                            component={ SelectField }
                            label={this.props.t('field.main_link_target')}>
                              <OptionField key="_self" value="_self">{this.props.t('field.option_field.same_page')}</OptionField>
                              <OptionField key="_blank" value="_blank">{this.props.t('field.option_field.new_page')}</OptionField>
                              <OptionField key="scroll" value="scroll">Scroll</OptionField>
                        </Field>
                      </Grid>

                      {/* Secondary Button */}
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Field
                            name="link_secondary_name"
                            component={ InputTextField }
                            label={this.props.t('field.secondary_button_name')}
                            type="text"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Field
                            name="link_secondary_url"
                            component={ InputTextField }
                            label={this.props.t('field.secondary_button_link')}
                            type="text"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Field
                            name="link_secondary_target"
                            component={ SelectField }
                            label={this.props.t('field.secondary_link_target')}>
                              <OptionField key="_self" value="_self">{this.props.t('field.option_field.same_page')}</OptionField>
                              <OptionField key="_blank" value="_blank">{this.props.t('field.option_field.new_page')}</OptionField>
                              <OptionField key="scroll" value="scroll">Scroll</OptionField>
                        </Field>
                      </Grid>

                    </Grid>
                  </Content>

                  {/* Images */}
                  <Content marginBottom>
                    <Toolbar
                      title={this.props.t('text.images')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Upload
                          label="Large (1600x480)"
                          attachments={image_large ? [JSON.parse(image_large)] : []}
                          multiple={false}
                          avatar={true}
                          paper={true}
                          required
                          onSuccess={this.onUploadImagenLarge.bind(this)}
                        />
                        <Field
                            name="image_large"
                            fieldHidden
                            component={ InputTextField }
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Upload
                          label="Medium (768x365)"
                          attachments={image_medium ? [JSON.parse(image_medium)] : []}
                          multiple={false}
                          avatar={true}
                          paper={true}
                          required
                          onSuccess={this.onUploadImagenMedium.bind(this)}
                        />
                        <Field
                            name="image_medium"
                            fieldHidden
                            component={ InputTextField }
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Upload
                          label="Small (520x365)"
                          attachments={image_small ? [JSON.parse(image_small)] : []}
                          multiple={false}
                          avatar={true}
                          paper={true}
                          required
                          onSuccess={this.onUploadImagenSmall.bind(this)}
                        />
                        <Field
                            name="image_small"
                            fieldHidden
                            component={ InputTextField }
                        />
                      </Grid>
                    </Grid>
                  </Content>

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/featured"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      {this.props.t('button.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      {this.props.t('button.save')}
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__StoreForm = reduxForm({
  form: 'Page__StoreForm',
  validate,
  initialValues: {
    enabled: true,
    content_box: false,
    position: 1,
    image_large: null,
    image_medium: null,
    image_small: null,
    title: '',
    label: '',
    paragraph: '',
    link_primary_name: '',
    link_primary_url: '',
    link_secondary_name: '',
    link_secondary_url: ''
  }
})(Page__StoreForm);

const selector = formValueSelector('Page__StoreForm');

Page__StoreForm = connect(state => {
  const image_large = selector(state, 'image_large');
  const image_medium = selector(state, 'image_medium');
  const image_small = selector(state, 'image_small');
  return { image_large, image_medium, image_small }
})(Page__StoreForm);

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__StoreForm));
