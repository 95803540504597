import React from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change  } from 'redux-form';
import { Grid } from './../../../../assets/theme/layout';
import { Content, Button, ButtonsGroup, Loading } from './../../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../../components/ui/input';
import { CountrySelect } from './../../../../scenes';
import { stringifyQueryString, parseQueryString } from './../../../../utilities/utils';

class UtilShippingLocationFilter extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      entity: null
    };
  }

  /**
  * @Event
  * @Description: Is invoked immediately after a component is mounted.
  */
  componentDidMount(){
    this._Init();
  };

  /*
  ** @Event
  ** @Description: Init
  */

  _Init = () => {

    // @Search
    // @Description: Set query values
    const search = parseQueryString(this.props.location.search);

    Object.entries(search).forEach(([key,value]) => {
      this.props.dispatch(change(this.props.form, key, value));
    });

  };

  /*
  ** @Service
  ** @Description: Filter
  */

  _Filter = (values) => {

    // @Redirect
    // @Description: Redirect with query filter
    this.props.history.push(`/locations?${ stringifyQueryString(values) }`);

    // @Props
    // @Description:
    this.props.onSuccess();

  };

  /*
  ** @Submit
  ** @Description: Submit filter form
  */

  handleSubmitForm = (values) => {

    // @If
    // @Description: Filter function
    if(values.action === 'filter'){
      this._Filter(values);
    }

  };

  /*
  ** @Event
  ** @Description: Back to form
  */

  handleBackToForm = () => {

    // @State
    // @Description: Set entity null
    this.setState({
      entity: null
    });

  };

  render() {

    const { handleSubmit, invalid } = this.props;
    const { loading } = this.state;

    return(
      !loading ? (
        <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

          <Content disablePadding divider>
            <Grid container spacing={3}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Field
                    name="name"
                    component={ InputTextField }
                    label="Nombre"
                    type="text"
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Field
                    name="status"
                    component={ SelectField }
                    label="Estado">
                      <OptionField value="none">Todos</OptionField>
                      <OptionField value="enabled">Activo</OptionField>
                      <OptionField value="disabled">Bloqueado</OptionField>
                </Field>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Field
                  name="country"
                  component={ CountrySelect }
                  label="País"
                  required
                />
              </Grid>
            </Grid>
          </Content>

          <Content disablePadding>
            <ButtonsGroup align="right">
              <Button
                aria-label="Confirmar"
                icon="save"
                color="primary"
                type="button"
                disabled={ invalid }
                onClick={
                  handleSubmit(values =>
                    this.handleSubmitForm({
                      ...values,
                      action: 'filter'
                    }
                  ))
                }
              >
                Aplicar
              </Button>
            </ButtonsGroup>
          </Content>

        </form>
      ) : (
        <Loading/>
      )
    )
  }

}

UtilShippingLocationFilter = reduxForm({
  form: 'UtilShippingLocationFilter',
  initialValues: {
    status: 'none'
  },
  enableReinitialize: true
})(UtilShippingLocationFilter);

export default withRouter(UtilShippingLocationFilter);
