import request from './../../utilities/request';
import localStorageToken from './../../utilities/storage';

function list(page, query) {
  return request({
    url:    `/slider/list/page/${ page }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET',
    params:  query
  });
};

function find(id) {
  return request({
    url:    `/slider/overview/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'GET'
  });
};

function save(values) {
  return request({
    url:    '/slider/save',
    headers: {'Authorization': localStorageToken()},
    method: 'POST',
    data:   values
  });
};

function update(id, values) {
  return request({
    url:    `/slider/update/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'PUT',
    data:   values
  });
};

function remove(id) {
  return request({
    url:    `/slider/remove/${ id }`,
    headers: {'Authorization': localStorageToken()},
    method: 'DELETE'
  });
};

const SliderService = {
  list, find, save, update, remove
};

export default SliderService;
