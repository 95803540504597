import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Box, Typography, Link } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {

  },
  label: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    //textTransform: 'uppercase',
    lineHeight: 1,
    marginBottom: theme.spacing(1)
  },
  text: {
    lineHeight: 1,
    wordBreak: 'break-word'
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  link: {
    lineHeight: 1,
    wordBreak: 'break-word',
    '&:hover': {
      //textDecoration: 'none'
    }
  },
  gutterBottom: {
    marginBottom: theme.spacing(1.5)
  }
}));

const Data = ({ label, text, capitalize, email, gutterBottom, ...rest }) => {

  const classes       = useStyles();
  const _capitalize   = capitalize ? classes['capitalize'] : null;
  const _gutterBottom = gutterBottom ? classes['gutterBottom'] : null;

  return (
    <Box className={ classNames(classes.root, _gutterBottom) }>
      <React.Fragment>

        <Typography variant="body1" classes={{ root: classes.label }}>
          { label }
        </Typography>

        { !email ? (
          <Typography variant="body2" classes={{ root: classNames(classes.text, _capitalize) }} component="div">
            { text }
          </Typography>
        ) : (
          <Typography variant="body2" classes={{ root: classNames(classes.text) }}>
            <Link href={ `mailto:${ text }` } variant="body2" classes={{ root: classes.link }}>
              { text }
            </Link>
          </Typography>
        )}

      </React.Fragment>
    </Box>
  )

};

Data.propTypes = {
  label: PropTypes.string.isRequired,
  email: PropTypes.bool
};

export default Data;
