import React from 'react';
import CKEditor from 'ckeditor4-react';
import { v4 as uuidv4 } from 'uuid';
import { withStyles, Typography } from './../../../../assets/theme/default';
import { FormControl } from './../../../../assets/theme/input';
import Loading from './../../loading/default'; 

//CKEditor.editorUrl = 'http://cdn.firehub.com.ar/ckeditor/ckeditor.js';

const styles = theme => ({
  root: {
    '& > div > div': {
      border: `1px solid ${ theme.palette.grey.dark }`,
      borderRadius: theme.spacing(0.5),
      background: '#fbfbfb'
    }
  },
  label: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold
  },
  textEditor: {
    fontFamily: theme.typography.fontFamily
  }
});

class TextEditor extends React.Component {
  constructor( props ) {
		super( props );
		this.state = {
			data: undefined,
      key: undefined,
      loaded: false
		};
    this.onChange = this.onChange.bind(this);
	}

  /**
  * @Event
  * @Description: Is invoked immediately after a component mount.
  */
  componentDidMount() {
    this._Init();
  };

  /**
  * @Event
  * @Description: Is invoked immediately after a component will recive props.
  */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.input.value !== prevState.data) {
      this.setState({ data: this.props.input.value });
    }
  };

  _Init() {
    setTimeout(() => {
      this.setState({ data: this.props.input.value, key: uuidv4(), loaded: true });
    },1000)
  };

	onChange(event) {
    this.props.input.onChange(event.editor.getData())
	};

  render() {

    const { loaded, key, data } = this.state;
    const { required, label, classes } = this.props;

    return (
      <div className={classes.root}>
        <FormControl fullWidth>
          {label && (
            <Typography variant="caption" component="label" className={classes.label}>
              { label} { required && (
                <React.Fragment>*</React.Fragment>
              )}
            </Typography>
          )}
          {loaded ? (
            <CKEditor
              key={key}
              data={data}
              onChange={this.onChange}
              config={{
                extraPlugins: ['editorplaceholder', 'autogrow'],
                removePlugins: 'exportpdf',
                editorplaceholder: 'Comenzar a escribir aquí...',
                language: 'es',
                allowedContent: true,
                autoGrow_onStartup: true,
                autoGrow_minHeight: 430,
                toolbar: [
                  {
                    name: 'history',
                    items: ['Undo', 'Redo']
                  },
                  {
                    name: 'insert',
                    items: ['Format']
                  },
                  {
                    name: 'basicstyles',
                    items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat']
                  },
                  {
                    name: 'paragraph',
                    items: ['NumberedList', 'BulletedList', 'Table', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                  },
                  {
                    name: 'links',
                    items: ['Link', 'Unlink']
                  },
                  {
                    name: 'document',
                    items: ['Source']
                  },
                  {
                    name: 'tools',
                    items: ['Maximize', 'ShowBlocks']
                  }
                ]
              }}
            />
          ) : (
            <Loading/>
          )}
        </FormControl>
      </div>
    );
  }
};

export default withStyles(styles)(TextEditor);
