import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from './../../../../assets/theme/default';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  actions: {
    marginTop: theme.spacing(1)
  }
}));

const Jumbotron = ({ title, subtitle, actions, ...rest }) => {

  const classes  = useStyles();

  return (
    <Box className={ classes.root } {...rest}>
      { title && (
        <Typography variant="h3" component="p" classes={{ root: classes.title }}>
          { title }
        </Typography>
      )}
      { subtitle && (
        <Typography variant="subtitle2" color="secondary"  component="p" dangerouslySetInnerHTML={{ __html: subtitle }}/>
      )}
      { actions && (
        <Box className={ classes.actions }>
          { actions }
        </Box>
      )}
    </Box>
  )

};

Jumbotron.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.node
};

export default Jumbotron;
