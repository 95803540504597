import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Page, Wrapper, Content, Paper, Loading, Button, ButtonsGroup, Toolbar, Tabs, Tab, TabPanel, Date } from './../../../../components/ui';
import { ShippingService } from './../../../../services';
import { findPermissionProps } from './../../../../utilities/utils';

import ShippingDetail from './../detail';
import ShippingSchedule from './../schedule';

import API from './../../../../config/api';
import TEXT from './../../../../config/text';

class Page__ShippingDetail extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        id: null,
        loading: false,
        title: '...',
        subtitle: '...',
        entity: null,
        section: 0
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.shipping, "Envíos") });

      const id = this.props.match.params.id;

      if(id){
        this._Find(id);
      }else{
        this.props.history.push('/shipping');
      }

    };

    /*
    ** @Service
    ** @Description: Find Order By Id
    */

    _Find = (id) => {

      // @Service
      ShippingService.find(id).then((response) => {

        // @State
        // @Description: Set client update into state
        this.setState({
          id: id,
          loading: true,
          title: 'Información',
          subtitle: response.order.created_at,
          entity: response.order
        });

      }).catch((error) => {

        // @Redirect
        // @Descripcion:
        //this.props.history.push('/leads');

        // @Dispatch
        // @Descripcion: alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data.message ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description: Change tab
    */

    handleChangeSection = (event: React.ChangeEvent<{}>, index: number) => {
      this.setState({ section: index });
    };

    render(){

      const { permission, name, loading, title, subtitle, entity, section } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <ButtonsGroup>
                { entity && (
                  <Button
                    component={ NavLink }
                    to={`/shipping/edit/${entity._id}`}
                    icon="edit"
                    color="primary"
                    size="medium"
                  >
                    Editar
                  </Button>
                )}
                <Button
                  component={ NavLink }
                  to="/shipping"
                  icon="back"
                  color="primary"
                  size="medium"
                >
                  Volver
                </Button>
              </ButtonsGroup>
            </Toolbar>

            <Paper disablePadding>
              <Content disablePadding>

                { loading ? (
                  <React.Fragment>

                    <Toolbar
                      title={title}
                      size="medium"
                      variant="divider"
                      disableMargin
                      >
                        {subtitle && (
                          <React.Fragment><span>Alta de reserva</span> <Date value={subtitle} /></React.Fragment>
                        )}
                    </Toolbar>

                    <Tabs divider value={section} onChange={this.handleChangeSection}>
                      <Tab label="Detalle"/>
                      <Tab label="Plan de embarque"/>
                    </Tabs>

                    <TabPanel value={section} index={0}>
                      <ShippingDetail entity={entity}/>
                    </TabPanel>

                    <TabPanel value={section} index={1}>
                      <ShippingSchedule entity={entity}/>
                    </TabPanel>

                  </React.Fragment>
                ) : (
                  <Loading/>
                )}

              </Content>
            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Page__ShippingDetail);
